/* eslint-disable @typescript-eslint/no-explicit-any */
import { ADMIN_PATH } from 'app/components/router/route-path'
import { isEmpty, isEqual } from 'lodash'
import { colors, useResponsive } from 'parkway-web-common'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { SidebarNavigationItem, sidebarNavigation } from '../sidebar-navigation'
import * as S from './styles'

interface SliderContentProps {
  setCollapsed: (isCollapsed: boolean) => void
  isCollapsed?: boolean
}
const sidebarNavFlat = sidebarNavigation.reduce(
  (result: SidebarNavigationItem[], current) =>
    result.concat(
      current.children && current.children.length > 0
        ? current.children
        : current,
    ),
  [],
)
const SliderMenu: React.FC<SliderContentProps> = ({
  setCollapsed,
  isCollapsed,
}) => {
  const { t } = useTranslation()
  const { mobileOnly } = useResponsive()

  const navigate = useNavigate()

  const currentMenuItem = useMemo(() => {
    const itemExist = sidebarNavFlat.find(
      ({ url }) => url === location.pathname,
    )

    if (itemExist) {
      return itemExist
    }

    const pathNames = location.pathname
      .split('/')
      .filter(item => !isEmpty(item))

    const commonItem = sidebarNavFlat.find(side => {
      const exist = pathNames.find(item => isEqual(side?.url, `/${item}`))
      return !!exist
    })

    if (commonItem) {
      return commonItem
    }
  }, [sidebarNavFlat, location.pathname])

  const defaultSelectedKeys = currentMenuItem ? [currentMenuItem.key] : []

  const renderLabel = ({
    key,
    nav,
    isSubMenu,
  }: {
    key: string
    isSubMenu?: boolean
    nav: SidebarNavigationItem
  }) => {
    const isSelected = key === defaultSelectedKeys?.[0]

    const stylesOverwrite = (() => {
      if (!isCollapsed) {
        return
      }
      return {
        height: '30px',
      }
    })()

    return (
      <S.ItemMenuCustomWrapper
        align={'middle'}
        isSelected={isSelected}
        style={stylesOverwrite}
        onClick={() => navigate(nav.url ?? ADMIN_PATH.DASHBOARD)}
      >
        <S.LabelCustom isSelected={isSelected} isSubMenu={isSubMenu}>
          {t(nav.title)}
        </S.LabelCustom>
      </S.ItemMenuCustomWrapper>
    )
  }

  const onClickMenuItem = () => {
    if (mobileOnly) {
      setCollapsed(true)
    }
  }

  return (
    <S.Menu
      mode="inline"
      onClick={onClickMenuItem}
      items={sidebarNavigation.map(nav => {
        const isSubMenu = nav.children?.length
        return {
          key: nav.key,
          title: t(nav.title),
          label: renderLabel({ key: nav.key, isSubMenu: !!isSubMenu, nav }),
          style: {
            padding: 0,
            paddingLeft: 0,
            paddingInline: 0,
            backgroundColor: colors.white,
          },
        }
      })}
    />
  )
}

export default SliderMenu

import { CheckOutlined } from '@ant-design/icons'
import { BaseButton } from 'app/components/common/BaseButton'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseDivider } from 'app/components/common/BaseDivider'
import { BasePopover } from 'app/components/common/BasePopover'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import { BaseSwitch } from 'app/components/common/BaseSwitch'
import { BORDER_RADIUS_2, PaddingMain } from 'app/constant'
import {
  BORDER_RADIUS,
  FONT_SIZE,
  FONT_WEIGHT,
  PADDING,
} from 'parkway-web-common'
import styled from 'styled-components'

export const RootWrapper = styled.div`
  border-radius: 8px;
  padding-bottom: 20px;
`

export const HeaderWrapper = styled(BaseRow)`
  padding: ${PADDING.xxxxs} 0px;
`
export const TextDescription = styled.div`
  color: rgba(71, 85, 105, 1); //need change
  font-weight: ${FONT_WEIGHT.regular};
  font-size: ${FONT_SIZE.xxs};
`

// standard screen option
export const StandardScreenOptionWrapper = styled(BaseSpace)``

export const SplitDivider = styled(BaseDivider)`
  background-color: rgba(113, 113, 122, 1);
  margin: 0px;
`

export const TitleStandardScreenOption = styled.div`
  color: rgba(113, 113, 122, 1);
  font-weight: ${FONT_WEIGHT.medium};
  font-size: ${FONT_SIZE.xs};
`

export const OptionWrapper = styled(BaseRow)``

export const OptionItem = styled(BaseSpace)<{ $isSelected?: boolean }>`
  border-radius: ${BORDER_RADIUS};
  border: 1px solid ${props => (!props?.$isSelected ? '#C8D2DD' : '#54E9CD')}; // need change
  padding: ${PADDING.md};
  background-color: ${props =>
    props?.$isSelected ? 'rgba(84, 233, 205, 0.1)' : 'white'};
  cursor: pointer;
`

export const TitleOption = styled.div`
  font-weight: ${FONT_WEIGHT.medium};
  color: var(--primary-color);
  font-size: ${FONT_SIZE.xs};
`

export const DescriptionOption = styled.div`
  font-weight: ${FONT_WEIGHT.medium};
  color: #818181;
  font-size: ${FONT_SIZE.xxxs};
`
export const ButtonSelectOption = styled.button`
  background-color: transparent !important;
  border-radius: 40px;
  border: 1px solid rgba(217, 217, 217, 0.3); //need change
  padding: ${PADDING.xs};
  cursor: pointer;
  margin-top: ${PADDING.md};
`

export const DotCircleOption = styled.div<{ isSelected?: boolean }>`
  width: 40px;
  height: 40px;
  border-radius: ${BORDER_RADIUS_2};
  background-color: ${props =>
    props?.isSelected ? 'rgba(41, 204, 106, 1)' : 'transparent'};
`
export const OptionOtherWrapper = styled(BaseSpace)`
  width: 100%;
  padding: ${PADDING.xxl} ${PaddingMain.Desktop.defaultHz};
`

export const OptionOtherItem = styled(BaseRow)`
  width: 100%;
`

export const TitleOptionOtherItem = styled.div`
  font-weight: ${FONT_WEIGHT.bold};
  font-size: ${FONT_SIZE.xs};
  color: var(--background-color);
`

export const DescriptionOptionOtherItem = styled.div`
  font-weight: ${FONT_WEIGHT.regular};
  font-size: ${FONT_SIZE.xxs};
  color: rgba(107, 114, 128, 1); // need change
`

export const SwitchButton = styled(BaseSwitch)`
  &.ant-switch.ant-switch-checked {
    background: #43e4c6; // need change
  }

  &.ant-switch.ant-switch-checked:hover {
    background: #43e4c6; // need change
  }
`

// Transition Times
export const TransitionWrapper = styled(BaseSpace)`
  width: 100%;
  padding: 12px;
  border: 1px solid #efefef;
  border-radius: 12px;
`

export const TitleTransition = styled.div`
  color: var(--primary-color); //need change
  font-weight: ${FONT_WEIGHT.medium};
  font-size: ${FONT_SIZE.xs};
`

export const ListTransitionItemWrapper = styled(BaseSpace)`
  width: 100% !important;
`

export const ItemTransitionItemWrapper = styled.div`
  width: 100%;
`

export const ItemTransitionTime = styled(BaseSpace)`
  width: 100%;
  svg {
    fill: var(--primary-color);
  }
`
export const TextTransitionTime = styled(BaseCol)`
  color: var(--primary-color);
  font-weight: ${FONT_WEIGHT.regular};
  font-size: ${FONT_SIZE.xxs};
`

// FontColor Times
export const FontColorWrapper = styled(BaseSpace)`
  width: 100%;
`

export const TitleFontColor = styled.div`
  color: rgba(217, 217, 217, 0.3); //need change
  font-weight: ${FONT_WEIGHT.medium};
  font-size: ${FONT_SIZE.xs};
`

export const ListFontColorItemWrapper = styled(BaseSpace)`
  width: 100% !important;
  padding: 0px ${PADDING.xxl};
`

export const ItemFontColorItemWrapper = styled.div`
  padding-left: ${PADDING.xxxxl};
`

export const ItemFontColorTime = styled(BaseSpace)`
  padding: ${PADDING.md} ${PADDING.xxl};
  border: 1px solid rgba(217, 217, 217, 0.3); //need change
  border-radius: ${BORDER_RADIUS_2};
  width: 100%;
  svg {
    fill: var(--background-color);
  }
`
export const TextFontColorTime = styled(BaseCol)`
  color: var(--background-color);
  font-weight: ${FONT_WEIGHT.regular};
  font-size: ${FONT_SIZE.xxs};
`

export const IconChecked = styled(CheckOutlined)<{ isSelected?: boolean }>`
  margin-left: ${PADDING.xxxs};
  svg {
    fill: ${props => (props.isSelected ? '#29CC6A' : 'transparent')};
  }
`

export const ColorDot = styled.div<{ color?: string }>`
  border-radius: ${BORDER_RADIUS_2};
  width: 20px;
  height: 20px;
  background-color: ${props => props?.color ?? 'var(--background-color)'};
`

export const CustomPopover = styled(BasePopover)`
  .ant-popover-arrow {
    top: 1px;
    &::before,
    &::after {
      background: #1f1d2b;
    }
  }
  .ant-popover-content {
    width: 130px;
  }
  .ant-popover-inner {
    border: 1px solid white;
    background: #1f1d2b;
    .ant-popover-title {
      color: #ffffff;
    }
  }
`

export const ColorPickerFooter = styled(BaseRow)``

export const SaveButton = styled(BaseButton)`
  background-color: var(--admin-text-focus-setting-menu-color);
  color: var(--background-color);
  font-weight: ${FONT_WEIGHT.medium};
  border-color: transparent;
  transition: all 1s ease;
  margin-top: 12px;

  &:hover {
    border-color: var(--background-color) !important;
    color: var(--background-color) !important;
    transition: all 1s ease;
  }
`

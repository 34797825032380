import { BaseButton } from 'app/components/common/BaseButton'
import { BaseLayout } from 'app/components/common/BaseLayout'
import { BORDER_RADIUS, LAYOUT, media } from 'parkway-web-common'
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'

export const Slider = styled(BaseLayout.Sider)`
  position: fixed;
  overflow: visible;
  right: 0;
  z-index: 5;
  min-height: 100vh;
  max-height: 100vh;

  color: var(--text-secondary-color);
  background: transparent !important;
  @media only screen and ${media.md} {
    right: unset;
    left: 0;
  }

  @media only screen and ${media.xl} {
    position: unset;
  }
`

export const CollapseButton = styled(BaseButton)<{ $isCollapsed: boolean }>`
  background: var(--admin-collapse-background-color) !important;

  border: 1px solid var(--border-color);
  transition: all 0.2s ease;
  position: absolute;
  right: -0.5rem;
  top: 3.5rem;

  ${props =>
    props.$isCollapsed &&
    css`
      right: -1rem;
    `}

  color: var(--text-secondary-color);

  &:hover {
    color: var(--text-secondary-color);
    background: var(--admin-collapse-background-color);
    border: 1px solid var(--border-color);
  }

  &:focus {
    color: var(--text-secondary-color);
    background: var(--admin-collapse-background-color);
    border: 1px solid var(--border-color);
  }

  &.ant-btn.ant-btn-sm.ant-btn-icon-only {
    width: 28px;
    height: 28px;
  }
`

export const SliderContent = styled.div`
  overflow-y: auto;
  & ul {
    border-inline-end: none !important;
  }
  &::-webkit-scrollbar {
    background: transparent;
    width: 0px;
  }
  max-height: calc(100vh - ${LAYOUT.mobile.headerHeight});

  @media only screen and ${media.md} {
    max-height: calc(100vh - ${LAYOUT.desktop.headerHeight});
  }
`

export const SliderLogoLink = styled(Link)`
  display: flex;
  align-items: center;
  overflow: hidden;
  position: relative;
`

export const SliderLogoDiv = styled.div<{ isHome?: boolean }>`
  padding: ${LAYOUT.mobile.headerPadding};
  padding-top: 20px;
  padding-bottom: 50px;
  padding-right: 0rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom-right-radius: ${props =>
    props?.isHome ? `${BORDER_RADIUS}` : '0px'};
  border-top-right-radius: ${BORDER_RADIUS};
  background-color: white;

  padding-bottom: 50px;

  @media only screen and ${media.md} {
    padding-top: 20px;
    padding-bottom: 50px;
  }
`

export const BrandSpan = styled.span`
  margin: 0 1rem;
  font-weight: 700;
  font-size: 1.125rem;
  color: var(--white);
`

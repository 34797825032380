import { requestPostSubscriberEmail } from 'app/api/landing-page'
import R from 'app/assets/R'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseImage } from 'app/components/common/BaseImage'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { notificationController } from 'app/controllers/notification-controller'
import { REG_EMAIL } from 'parkway-web-common'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useInView } from 'react-intersection-observer'
import * as S from './styles'
import './styles.css'

const LayoutFourHome = () => {
  const { t } = useTranslation()
  const { ref, inView } = useInView({ triggerOnce: false })

  const { ref: ref01, inView: inView01 } = useInView({ triggerOnce: false })
  const { ref: ref02, inView: inView02 } = useInView({ triggerOnce: false })
  const { ref: ref03, inView: inView03 } = useInView({ triggerOnce: false })

  const classView = useMemo(() => {
    if (inView) {
      return {
        root: 'parent',
        text1: 'B',
        text2: 'C',
        text3: 'D',
        text4: 'E',
        text5: 'container-up-to',
      }
    }
    return {
      root: '',
      text1: '',
      text2: '',
    }
  }, [inView])

  const classView01 = useMemo(() => {
    if (inView01) {
      return {
        text1: 'container-animation-slide-up B',
      }
    }
    return {
      text1: '',
    }
  }, [inView01])

  const classView02 = useMemo(() => {
    if (inView02) {
      return {
        text2: 'container-animation-slide-up C',
      }
    }
    return {
      text2: '',
    }
  }, [inView02])

  const classView03 = useMemo(() => {
    if (inView03) {
      return {
        text3: 'container-animation-slide-up D',
        text4: 'container-animation-slide-up E',
      }
    }
    return {
      text3: '',
      text4: '',
    }
  }, [inView03])

  const [form] = BaseForm.useForm<{ email: string }>()

  const handleSubmit = async ({ email }: { email: string }) => {
    try {
      await requestPostSubscriberEmail({ email })
      notificationController.success({
        message: t(R.strings.thank_you_for_interest),
      })
      form.setFieldValue('email', '')
    } catch (error) {
      console.log({ error })
    }
  }

  return (
    <S.RootWrapper className={classView.root} ref={ref}>
      <BaseRow align={'middle'} justify={'center'} ref={ref01}>
        <BaseCol xl={14} className={`child ${classView01.text1}`}>
          <S.AreaText />
          <S.DescriptionText>
            {t(R.strings.sign_up_for_a_free_trial)}
          </S.DescriptionText>
          <S.AreaText>
            <S.TitleText>{t(R.strings.home_subcribe)}</S.TitleText>
          </S.AreaText>
          <S.AreaText />
          <S.AreaText />
        </BaseCol>
      </BaseRow>

      <BaseRow align={'middle'} justify={'center'} ref={ref02}>
        <BaseCol className={`child ${classView02.text2}`}>
          <BaseForm
            form={form}
            initialValues={{ email: '' }}
            onFinish={handleSubmit}
          >
            <BaseSpace size={16} style={{ alignItems: 'center' }}>
              <S.BaseFormItem
                name="email"
                style={{ textAlign: 'center' }}
                rules={[
                  {
                    pattern: REG_EMAIL,
                    message: t(R.strings.regex_email),
                  },
                ]}
              >
                <S.InputEmail
                  placeholder={t(R.strings.enter_your_email_address)}
                  style={{ textAlign: 'left' }}
                />
              </S.BaseFormItem>
              <S.ButtonEmail onClick={form?.submit}>
                {'Get for free'}
              </S.ButtonEmail>
            </BaseSpace>
          </BaseForm>
        </BaseCol>
      </BaseRow>

      <BaseSpace direction="vertical" size={16}>
        <div
          style={{
            width: '100%',
            paddingBottom: 20,
            paddingTop: 40,
            marginTop: 20,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'end',
          }}
          ref={ref03}
        >
          <BaseImage
            preview={false}
            src={R.images.img_card_footer}
            width={'90%'}
            className={`child ${classView03.text3}`}
          />
        </div>

        <div
          style={{
            paddingLeft: 24,
            paddingRight: 120,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'left',
          }}
          className={`${classView03.text4}`}
        >
          <BaseImage preview={false} src={R.images.ic_new_logo} width={100} />

          <div
            style={{
              fontSize: 16,
              fontWeight: 'lighter',
            }}
          >
            {'Kiosify: Elevate Your Customer Experience with Self-Service'}
          </div>
        </div>

        <div
          style={{
            paddingLeft: 24,
            paddingRight: 120,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'left',
          }}
          className={`${classView03.text4}`}
        >
          <BaseCol>
            <div
              style={{
                fontSize: 16,
                fontWeight: 'lighter',
              }}
            >
              {'Kiosify Copyright © 2024'}
            </div>
          </BaseCol>
        </div>
      </BaseSpace>
    </S.RootWrapper>
  )
}
export default LayoutFourHome

/* eslint-disable @typescript-eslint/no-explicit-any */
import { requestPostSquareCode } from 'app/api/auth'
import R from 'app/assets/R'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { Loading } from 'app/components/common/Loading'
import { AUTH_SERVICE_PACKAGE_PATH } from 'app/components/router/route-path'
import { getEmailAuth } from 'app/service/storage/storage-service'
import { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import * as S from './styles'
import { useTranslation } from 'react-i18next'
import { ENV_CONFIG } from 'app/common/config'

const AccountVerified = () => {
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()
  const code = searchParams.get('code')

  const email = getEmailAuth()

  const navigate = useNavigate()

  const [isSend, setIsSend] = useState(false)
  const [loading, setLoading] = useState(false)

  const onGoBack = () => {
    navigate(-1)
  }

  const handleVerify = async () => {
    if (!code && !email && !isSend) return
    setLoading(true)
    try {
      await requestPostSquareCode({
        code,
        email,
      })
      navigate(AUTH_SERVICE_PACKAGE_PATH, {
        replace: true,
      })
      setIsSend(true)
    } catch (err: any) {
      console.log({ err })
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (!email) {
      onGoBack()
    }
  }, [email])

  useEffect(() => {
    if (code && email && !isSend) {
      handleVerify()
    }
  }, [code, email])

  return (
    <S.RootWrapper>
      <S.FormWrapperCustom direction="vertical" size={25}>
        <S.Title>{t(R.strings.account_verified)}</S.Title>
        <S.Description>
          Congratulations, you have successfully created a KM account
        </S.Description>
        <S.ImageSuccess src={R.images.ic_success} width={100} preview={false} />

        {!code && (
          <BaseRow gutter={10} align={'middle'} justify={'center'}>
            <S.Description>{t(R.strings.didnt_sync_data)}</S.Description>
            <BaseCol>
              <S.ResendText href={ENV_CONFIG?.URL_REDIRECT_SQUARE}>
                {t(R.strings.click_here_to_connect_square_account)}
              </S.ResendText>
            </BaseCol>
          </BaseRow>
        )}
        {loading && <Loading />}
      </S.FormWrapperCustom>
    </S.RootWrapper>
  )
}

export default AccountVerified

import R from 'app/assets/R'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { BaseSelect } from 'app/components/common/selects/BaseSelect'
import { useAppDispatch, useAppSelector } from 'app/redux/hooks'
import { setPathReport } from 'app/redux/slices/pathReportSlice'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { URL_REPORTS } from '../../constant'
import * as S from './styles'

interface CardData {
  title: string
  path: string
  description?: string
}

const CARD_DATA: CardData[] = [
  {
    title: R.strings.reports_common_total_revenue,
    path: URL_REPORTS.TotalRevenue,
    description: 'Track total revenue',
  },
  {
    title: R.strings.reports_common_total_dish_ordered,
    path: URL_REPORTS.TotalDishOrdered,
    description: 'Track total dish ordered',
  },
  {
    title: R.strings.reports_common_total_customer,
    path: URL_REPORTS.TotalCustomer,
    description: 'Track total customers',
  },
  {
    title: R.strings.reports_common_order_report,
    path: URL_REPORTS.OrderReport,
    description: 'Track total orders',
  },
]

const HomeLayout = () => {
  const currentPath = useAppSelector(state => state?.pathReport)?.path

  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const handleNavigate = (path: string) => {
    dispatch(setPathReport(path))
  }

  return (
    <S.RootWrapper>
      <BaseRow justify={'end'} align={'middle'}>
        <BaseForm>
          <BaseForm.Item>
            <BaseSelectOverwrite
              options={CARD_DATA?.map(item => ({
                label: t(item.title),
                value: item.path,
              }))}
              defaultValue={currentPath}
              onChange={value => handleNavigate(value as string)}
            />
          </BaseForm.Item>
        </BaseForm>
      </BaseRow>
    </S.RootWrapper>
  )
}

export default HomeLayout

const BaseSelectOverwrite = styled(BaseSelect)`
  width: calc(100vw - 32px) !important;
`

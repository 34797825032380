import { ArrowLeftOutlined, DeleteOutlined } from '@ant-design/icons'
import {
  requestDeleteKioskV2,
  requestUpdateLanguageKiosk,
} from 'app/api/kiosk/api'
import { IUpdateKiosk } from 'app/api/kiosk/model'
import R from 'app/assets/R'
import { BaseButton } from 'app/components/common/BaseButton'
import { ButtonVariant } from 'app/components/common/BaseButtonVariant'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { Loading } from 'app/components/common/Loading'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { LanguagePicker } from 'app/components/header/admin/components/SettingsDropdown/settings-overlay/LanguagePicker'
import { URL_SETTING } from 'app/containers/admin/Settings/constant'
import { subscriptionPlans } from 'app/containers/landing-page/components/desktop-layout/LayoutThree'
import { notificationController } from 'app/controllers/notification-controller'
import { LanguageSupportType } from 'app/i18n/constant'
import { useUpdateKiosksByCode } from 'app/react-query/hook/kiosk'
import { KioskKeys } from 'app/react-query/query-key/kiosk'
import { useAppDispatch, useAppSelector } from 'app/redux/hooks'
import { setPathSetting } from 'app/redux/slices/pathSettingSlice'
import { getServicePackage } from 'app/service/storage/storage-service'
import { isEmpty, isEqual, isNil } from 'lodash'
import { formatMoney, PADDING } from 'parkway-web-common'
import { ReactNode, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import * as S from './styles'

interface IFormKiosk {
  name: string
}

const initData: IFormKiosk = {
  name: '',
}

export enum PaymentMethodEnum {
  cash = 'cash',
  creditCard = 'credit-card',
  giftCard = 'gift-card',
}

interface IPropsItemPaymentMethod {
  isSelected?: boolean
  keyItem: PaymentMethodEnum
  onChange?: (value: PaymentMethodEnum) => void
}

const listPaymentMethod = [
  PaymentMethodEnum.cash,
  PaymentMethodEnum.creditCard,
  PaymentMethodEnum.giftCard,
]

const DefineValueSwitch = {
  [PaymentMethodEnum.cash]: {
    title: R.strings.payment_method_cash,
    description: 'Kiosk accepts cash as payment method',
  },
  [PaymentMethodEnum.creditCard]: {
    title: R.strings.payment_method_credit_card,
    description: 'Kiosk accepts credit cards as payment method',
  },
  [PaymentMethodEnum.giftCard]: {
    title: R.strings.payment_method_gift_card,
    description: 'Kiosk accepts gift cards as payment method',
  },
}

export const ItemPaymentMethod = ({
  isSelected,
  keyItem,
  onChange,
}: IPropsItemPaymentMethod) => {
  const { t } = useTranslation()
  const onChangePaymentMethod = () => {
    if (onChange) {
      onChange?.(keyItem)
    }
  }
  return (
    <BaseRow justify={'space-between'} align={'middle'}>
      <BaseCol>
        <BaseSpace size={4}>
          <BaseText
            children={
              DefineValueSwitch[keyItem]?.title
                ? t(DefineValueSwitch[keyItem]?.title)
                : '-'
            }
            style={{ fontFamily: 'Raleway' }}
            fontWeight="medium"
          />
          <BaseText
            children={DefineValueSwitch[keyItem]?.description ?? '-'}
            style={{ color: '#818181', fontFamily: 'Raleway' }}
            fontWeight="medium"
            fontSize="xxxs"
          />
        </BaseSpace>
      </BaseCol>
      <BaseCol>
        <S.SwitchButton checked={isSelected} onChange={onChangePaymentMethod} />
      </BaseCol>
    </BaseRow>
  )
}

const ItemInfo = ({
  label,
  value,
}: {
  label: string
  value?: string | ReactNode
}) => {
  return (
    <BaseRow justify={'space-between'} gutter={16} align={'middle'}>
      <BaseCol>
        <BaseText
          style={{ color: '#818181', fontFamily: 'Raleway' }}
          fontWeight="medium"
          fontSize="xxxs"
        >
          {label}
        </BaseText>
      </BaseCol>

      <BaseCol>
        <BaseText fontWeight="medium" children={isNil(value) ? '-' : value} />
      </BaseCol>
    </BaseRow>
  )
}
const EditYourKioskLayout = () => {
  const { t, i18n } = useTranslation()
  const [form] = BaseForm.useForm<IFormKiosk>()
  const [isLoading, setLoading] = useState(false)

  const kioskUpdateItem = useAppSelector(
    state => state.pathSetting,
  ).kioskUpdateItem

  const dispatch = useAppDispatch()

  const goBack = () => {
    dispatch(setPathSetting(URL_SETTING.Kiosks))
  }

  if (!kioskUpdateItem) {
    goBack()
    return null
  }

  const packageData = getServicePackage()
  const [location, setLocation] = useState<string | undefined>(
    kioskUpdateItem?.location?.id,
  )

  const [paymentMethod, setPaymentMethod] = useState<PaymentMethodEnum[]>([])
  const [redeemLoyalty, setRedeemLoyalty] = useState<boolean>(false)
  const [redeemDiscount, setRedeemDiscount] = useState<boolean>(true)
  const [language, setLanguage] = useState<LanguageSupportType>(
    kioskUpdateItem?.language ?? 'en',
  )
  const [isActive, setIsActive] = useState(false)

  const servicePackage = useMemo(() => {
    const packageExist = subscriptionPlans().find(
      item => item?.package === packageData,
    )

    if (packageExist) {
      return packageExist
    }

    return subscriptionPlans()[0]
  }, [packageData, subscriptionPlans, i18n.language])

  useEffect(() => {
    if (kioskUpdateItem) {
      setLocation(kioskUpdateItem?.location?.id)

      const paymentMode: PaymentMethodEnum[] =
        kioskUpdateItem?.payment_mode ?? []

      const listCard: PaymentMethodEnum[] = []

      paymentMode?.map(item => {
        const listKey = Object.keys(PaymentMethodEnum)
        const index = listKey.findIndex(key => {
          return isEqual(PaymentMethodEnum[key], item)
        })
        const itemInListCard = listCard?.find(key =>
          isEqual(PaymentMethodEnum[key], item),
        )
        if (index !== -1 && !itemInListCard) {
          listCard.push(item)
        }
      })
      setPaymentMethod(listCard)
      setRedeemLoyalty(kioskUpdateItem?.reedem_loyalty ?? false)
      setRedeemDiscount(kioskUpdateItem?.reedem_discount ?? true)
      if (!isEmpty(kioskUpdateItem?.language)) {
        setLanguage(
          kioskUpdateItem?.language?.toLowerCase() as unknown as LanguageSupportType,
        )
      }

      form.setFieldsValue({
        name: kioskUpdateItem?.name,
      })

      setIsActive(kioskUpdateItem.status ?? false)
    }
  }, [kioskUpdateItem, form])

  const { mutateAsync: mutateAsyncUpdateKiosk, isLoading: loadingUpdate } =
    useUpdateKiosksByCode()

  const onSubmit = async (value: IFormKiosk) => {
    if (!servicePackage) {
      notificationController.error({
        message: t(R.strings.error_message_service_package),
      })

      return
    }

    if (!location) {
      notificationController.error({
        message: t(R.strings.error_message_select_location),
      })

      return
    }

    if (paymentMethod.length === 0) {
      notificationController.error({
        message: t(R.strings.error_message_select_payment_method),
      })

      return
    }

    if (!kioskUpdateItem || !kioskUpdateItem?.code) {
      notificationController.error({
        message: t(R.strings.error_message_select_kiosk),
      })
      goBack()
      return
    }

    const dataToSend: IUpdateKiosk = {
      name: value?.name ?? kioskUpdateItem.name,
      type: 'kiosk',
      location: location,
      payment_mode: paymentMethod,
      pricing_tier: `${servicePackage.priceNumber}`,
      reedem_loyalty: redeemLoyalty,
      reedem_discount: redeemDiscount,
      code: kioskUpdateItem?.code,
      status: isActive ?? kioskUpdateItem.status,
      language,
    }
    mutateAsyncUpdateKiosk(dataToSend)

    if (language && !isEqual(language, kioskUpdateItem?.language)) {
      await requestUpdateLanguageKiosk({
        code: kioskUpdateItem?.code,
        language,
      })
    }
  }

  const changePaymentMethod = (value: PaymentMethodEnum) => {
    const index = paymentMethod.findIndex(item => item === value)
    if (index !== -1) {
      const newPaymentMethod = paymentMethod.filter(item => item !== value)
      setPaymentMethod(newPaymentMethod)
    } else {
      setPaymentMethod([...paymentMethod, value])
    }
  }

  const onChangeRedeemLoyalty = () => {
    setRedeemLoyalty(!redeemLoyalty)
  }

  const onChangeRedeemDiscount = () => {
    setRedeemDiscount(!redeemDiscount)
  }

  const onChangeLanguageKiosk = (lang: LanguageSupportType) => {
    setLanguage(lang)
  }

  const query = useQueryClient()

  const onPressDelete = async () => {
    if (!kioskUpdateItem?.code) return
    try {
      setLoading(true)
      await requestDeleteKioskV2(kioskUpdateItem?.code?.toString())

      setTimeout(() => {
        query.invalidateQueries({
          queryKey: KioskKeys.all,
        })

        notificationController.success({
          message: t(R.strings.save_success),
        })

        goBack()
      }, 500)
    } catch (error) {
      console.log({ error })
    } finally {
      setLoading(false)
    }
  }

  return (
    <S.RootWrapper>
      <S.HeaderWrapper justify={'space-between'} align={'middle'}>
        <BaseCol>
          <BaseRow gutter={8} align={'middle'}>
            <BaseCol>
              <S.BackButton onClick={goBack}>
                <ArrowLeftOutlined rev={undefined} />
              </S.BackButton>
            </BaseCol>
            <BaseCol>
              <BaseText
                fontWeight="semibold"
                fontSize="xl"
                fontFamily="Raleway"
              >
                {kioskUpdateItem?.name ?? '-'}
              </BaseText>
            </BaseCol>
          </BaseRow>
        </BaseCol>
        <BaseCol>
          <BaseRow gutter={16} align={'middle'}>
            <BaseCol>
              <BaseButton
                style={{ color: 'red', borderColor: 'red' }}
                icon={<DeleteOutlined rev={undefined} />}
                loading={isLoading}
                onClick={onPressDelete}
              />
            </BaseCol>

            <BaseCol>
              <ButtonVariant onClick={form.submit}>
                <BaseText children={t(R.strings.save)} />
              </ButtonVariant>
            </BaseCol>
          </BaseRow>
        </BaseCol>
      </S.HeaderWrapper>

      <BaseSpace style={{ padding: `0 ${PADDING.xxs}` }}>
        <BaseSpace>
          <ItemInfo
            label={t(R.strings.kiosk_kiosk_name)}
            value={kioskUpdateItem?.name}
          />
          <ItemInfo
            label={t(R.strings.kiosk_kiosk_code)}
            value={`${kioskUpdateItem?.code}`}
          />
          <ItemInfo
            label={t(R.strings.kiosk_your_subscription)}
            value={`$${formatMoney(servicePackage.price)}/${
              servicePackage.title
            }`}
          />
          <ItemInfo label={'Transaction Fee'} value={'$0.10/transaction'} />

          <ItemInfo
            label={t(R.strings.language)}
            value={
              <LanguagePicker
                onChange={onChangeLanguageKiosk}
                initValue={language}
              />
            }
          />
        </BaseSpace>

        <S.FormDataWrapper
          form={form}
          initialValues={initData}
          onFinish={onSubmit}
        >
          <BaseSpace direction="vertical" size={10}>
            <BaseSpace>
              {listPaymentMethod.map((keyItem, index) => {
                const isSelected =
                  paymentMethod.findIndex(
                    keySelected => keySelected === keyItem,
                  ) !== -1
                return (
                  <ItemPaymentMethod
                    isSelected={isSelected}
                    key={index}
                    keyItem={keyItem}
                    onChange={changePaymentMethod}
                  />
                )
              })}
            </BaseSpace>

            <BaseRow justify={'space-between'} align={'middle'}>
              <BaseCol>
                <BaseSpace size={4}>
                  <BaseText
                    children={t(R.strings.kiosk_redeem_loyalty)}
                    style={{ fontFamily: 'Raleway' }}
                    fontWeight="medium"
                  />
                  <BaseText
                    children={
                      'Customers can redeem their loyalty rewards earned from previous purchases'
                    }
                    style={{ color: '#818181', fontFamily: 'Raleway' }}
                    fontWeight="medium"
                    fontSize="xxxs"
                  />
                </BaseSpace>
              </BaseCol>
              <BaseCol>
                <S.SwitchButton
                  checked={redeemLoyalty}
                  onChange={onChangeRedeemLoyalty}
                />
              </BaseCol>
            </BaseRow>

            <BaseRow justify={'space-between'} align={'middle'}>
              <BaseCol>
                <BaseSpace size={4}>
                  <BaseText
                    children={t(R.strings.kiosk_redeem_discount)}
                    style={{ fontFamily: 'Raleway' }}
                    fontWeight="medium"
                  />
                  <BaseText
                    children={
                      'Customers can redeem their discount coupons through this kiosk'
                    }
                    style={{ color: '#818181', fontFamily: 'Raleway' }}
                    fontWeight="medium"
                    fontSize="xxxs"
                  />
                </BaseSpace>
              </BaseCol>
              <BaseCol>
                <S.SwitchButton
                  checked={redeemDiscount}
                  onChange={onChangeRedeemDiscount}
                />
              </BaseCol>
            </BaseRow>

            <BaseRow justify={'space-between'} align={'middle'}>
              <BaseCol>
                <BaseSpace size={4}>
                  <BaseText
                    children={'Turn on Kiosk'}
                    style={{ fontFamily: 'Raleway' }}
                    fontWeight="medium"
                  />
                  <BaseText
                    children={
                      'Kiosks can be turned on/off when in use or not in use'
                    }
                    style={{ color: '#818181', fontFamily: 'Raleway' }}
                    fontWeight="medium"
                    fontSize="xxxs"
                  />
                </BaseSpace>
              </BaseCol>
              <BaseCol>
                <S.SwitchButton checked={isActive} onChange={setIsActive} />
              </BaseCol>
            </BaseRow>
          </BaseSpace>
          {loadingUpdate && <Loading />}
        </S.FormDataWrapper>
      </BaseSpace>
    </S.RootWrapper>
  )
}

export default EditYourKioskLayout

/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ENV_CONFIG } from 'app/common/config'
import { ApiServiceType } from 'parkway-web-common'
import { getToken, refetchToken } from './storage/storage-service'

const STATUS_AUTHORIZE = 401
const TIME_OUT_API = 100000

const createAPI = (baseUrl?: string) => {
  const APIInstant = require('axios').default.create()
  APIInstant.defaults.baseURL = baseUrl ?? ENV_CONFIG.BASE_URL
  APIInstant.defaults.timeout = TIME_OUT_API
  APIInstant.defaults.headers = { 'Content-Type': 'application/json' }
  const token = getToken()

  APIInstant.interceptors.request.use(
    async (config: { headers: { Authorization: any } }) => {
      config.headers.Authorization =
        token && token !== '' ? `Bearer ${token}` : ''
      return config
    },
    Promise.reject,
  )

  APIInstant.interceptors.response.use(
    (response: { data: any }) => {
      return response
    },
    (error: { message: string }) => {
      return Promise.reject(error)
    },
  )
  return APIInstant
}

/* Support function */
async function handleResult(api: Promise<any>) {
  return api
    .then((response: { data: any }) => {
      return Promise.resolve(response?.data)
    })
    .catch(
      (error: { response: { data: { message: any }; status?: number } }) => {
        let message = `Error: ${
          error?.response?.data?.message || error?.response?.status
        }`
        if (`${error?.response?.status}` === `${STATUS_AUTHORIZE}`) {
          message = 'Your session has expired. Please login again.'
          refetchToken()
        }
        console.log({ message })

        // notificationController.error({
        //   message,
        // })
        return Promise.reject(new Error(`${error?.response?.status}`))
      },
    )
}

export const ApiClient = {
  Get: ({ url, params }) => handleResult(createAPI()?.get(url, params)),
  Post: ({ url, body }) => handleResult(createAPI()?.post(url, body)),
  Put: ({ url, body }) => handleResult(createAPI()?.put(url, body)),
  Patch: ({ url, body }) => handleResult(createAPI()?.patch(url, body)),
  Delete: ({ url, body }) => handleResult(createAPI()?.delete(url, body)),
} as ApiServiceType

const ApiClientFunc = ({
  baseUrl = ENV_CONFIG.BASE_URL,
}: {
  baseUrl?: string
}) => {
  return {
    Get: ({ url, params }) =>
      handleResult(createAPI(baseUrl)?.get(url, params)),
    Post: ({ url, body }) => handleResult(createAPI(baseUrl)?.post(url, body)),
    Put: ({ url, body }) => handleResult(createAPI(baseUrl)?.put(url, body)),
    Patch: ({ url, body }) =>
      handleResult(createAPI(baseUrl)?.patch(url, body)),
    Delete: ({ url, body }) =>
      handleResult(createAPI(baseUrl)?.delete(url, body)),
  } as ApiServiceType
}

export const ApiClientV2 = ApiClientFunc({ baseUrl: ENV_CONFIG.BASE_URL_V2 })

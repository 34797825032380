import { BaseButton } from 'app/components/common/BaseButton'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import { SearchInput } from 'app/components/common/inputs/SearchInput'
import { BORDER_RADIUS, FONT_WEIGHT, PADDING } from 'parkway-web-common'
import styled from 'styled-components'

export const RootWrapper = styled(BaseSpace)`
  border-radius: 12px;
`

export const HeaderWrapper = styled(BaseRow)`
  padding: ${PADDING.xs} 0px;
`

export const ItemRestaurant = styled(BaseRow)`
  border: 1px solid rgba(0, 0, 0, 0.25);
  cursor: pointer;
  border-radius: ${BORDER_RADIUS};
  transition: all 1s ease;
  padding: 8px;
  margin-left: 0px !important;
  margin-right: 0px !important;
  &:hover {
    border-color: #54e9cd;
    transition: all 1s ease;
  }
`

export const ButtonHeader = styled(BaseButton)`
  margin-left: 8px;
  padding: ${PADDING.xl} ${PADDING.xl};
  background-color: rgba(41, 204, 106, 1); // need change
  border: none;
  color: var(--background-color);
  font-weight: ${FONT_WEIGHT.semibold};
  &:hover {
    color: var(--background-color) !important;
  }
`

export const SearchLayout = styled(SearchInput)`
  .ant-input-group-addon {
    display: none;
  }
  .ant-input-group .ant-input-affix-wrapper:not(:last-child) {
    border-radius: ${BORDER_RADIUS};
    border: 0;
    background: transparent !important;
    color: var(--background-color);
    border: 0.7px solid var(--background-color) !important;
  }
  input {
    background: transparent !important;
    color: var(--background-color);
  }
`

export const TableWrapper = styled(BaseSpace)`
  width: 100%;
`

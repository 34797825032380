import { BaseButton } from 'app/components/common/BaseButton'
import { ButtonVariant } from 'app/components/common/BaseButtonVariant'
import { BaseSpace } from 'app/components/common/BaseSpace'
import * as Auth from 'app/components/layouts/AuthLayout/styles'
import {
  BORDER_RADIUS,
  FONT_SIZE,
  FONT_WEIGHT,
  PADDING,
  media,
} from 'parkway-web-common'
import styled from 'styled-components'

export const LoginDescription = styled.div`
  margin-bottom: 1.875rem;
  color: var(--text-main-color);
  font-size: ${FONT_SIZE.xs};

  @media only screen and ${media.xs} {
    margin-bottom: 1.5625rem;
    font-weight: ${FONT_WEIGHT.bold};
  }

  @media only screen and ${media.md} {
    margin-bottom: 1.75rem;
    font-weight: ${FONT_WEIGHT.regular};
  }

  @media only screen and ${media.xl} {
    margin-bottom: 1.875rem;
  }
`

export const RememberMeText = styled.span`
  color: #959895;
  font-size: ${FONT_SIZE.xxs};
  font-weight: ${FONT_WEIGHT.regular};
`

export const ForgotPasswordText = styled.span`
  color: #29cc6a;
  font-size: ${FONT_SIZE.xs};
  transition: all 1.25s ease !important;
  font-weight: ${FONT_WEIGHT.regular};
  cursor: pointer;
`

export const BackButtonCustom = styled(BaseButton)`
  background-color: #42e5c6; // need change
  border-radius: ${BORDER_RADIUS};
  border-color: transparent;
  padding: ${PADDING.md};
  transition: all 1s ease;

  width: 44px;
  height: 44px;

  & svg {
    fill: black;
  }

  &:hover {
    border-color: #42e5c6 !important;
    transition: all 1s ease;
  }
`

export const FormWrapperCustom = styled(BaseSpace)`
  width: 100%;
  height: fit-content;
  border-radius: ${BORDER_RADIUS};
  background-color: rgba(255, 255, 255, 0.25);
  padding: ${PADDING.xxxl};
  align-items: center;
  border: 0.5px solid #f7ffe5;
  .ant-space-item {
    width: 100%;
  }

  @media only screen and (max-width: 768px) {
    width: 90%;
  }
`

export const Title = styled.div`
  font-size: 42px;
  font-weight: ${FONT_WEIGHT.semibold};
  color: white;
  text-align: center;
`

export const Description = styled.div`
  font-size: 14px;
  font-weight: ${FONT_WEIGHT.regular};
  color: #42e5c6;
  text-align: center;
`

export const EmailText = styled.span`
  font-size: 14px;
  font-weight: ${FONT_WEIGHT.regular};
  color: white;
  text-align: center;
`

export const SubmitButton = styled(ButtonVariant)`
  font-size: ${FONT_SIZE.md};
  font-weight: ${FONT_WEIGHT.semibold};
  width: 100%;
  border-radius: ${BORDER_RADIUS};

  color: black;

  &:hover {
    color: black !important;
  }
`

export const ResendText = styled.span`
  color: #42e5c6;
  font-size: ${FONT_SIZE.xs};
  transition: all 1.25s ease !important;
  font-weight: ${FONT_WEIGHT.regular};
  cursor: pointer;
`
export const PasswordForm = styled(Auth.FormInputPassword)`
  color: var(--text-main-color);
  background: white;
  border-radius: ${BORDER_RADIUS};
  border: 1px solid #42e5c6;
  & input.ant-input {
    background: white;
  }
`

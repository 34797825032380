import {
  DayOfTheWeekEnum,
  IBusinessHours,
  IPayloadUpdateLocationTime,
} from 'app/api/restaurant/model'
import {
  getOrdinalNumberOfDayOfTheWeek,
  getTextDayOfDayOfTheWeek,
  timeToMilliseconds,
  usePagination,
} from 'app/hook'
import {
  useCreateOpenTime,
  useDeleteOpenTime,
  useGetRestaurantDetail,
  useUpdateOpenTime,
} from 'app/react-query/hook/restaurant'
import { useAppSelector } from 'app/redux/hooks'
import moment from 'moment'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { IOpenTimeItem, ITimeOpenRestaurant } from './type'
import { getRandomUuid } from 'parkway-web-common'

export const useHook = () => {
  const { t } = useTranslation()

  const { flattenDataList } = usePagination()

  const { mutateAsync: mutateAsyncCreate, isLoading: loadingCreate } =
    useCreateOpenTime()
  const { mutateAsync: mutateAsyncDelete, isLoading: loadingDelete } =
    useDeleteOpenTime()
  const { mutateAsync: mutateAsyncUpdate, isLoading: loadingUpdate } =
    useUpdateOpenTime()

  const restaurantDetail = useAppSelector(
    state => state.pathSetting,
  )?.restaurantData

  const { data: restaurantData, isLoading: loadingList } =
    useGetRestaurantDetail(restaurantDetail?.id)

  const days = useMemo(() => {
    const restaurant = flattenDataList(restaurantData)
    const listTime: IBusinessHours[] = restaurant?.businessHours ?? []

    const res: IOpenTimeItem[] = Object.values(DayOfTheWeekEnum)?.map(itm => {
      const listTimeOfDayExist = listTime?.filter(
        time => time.dayOfWeek === itm,
      )
      const checked = !!listTimeOfDayExist?.length

      let times: ITimeOpenRestaurant[] = []

      if (listTimeOfDayExist?.length) {
        times = listTimeOfDayExist?.map(itemTime => {
          return {
            endTime: itemTime?.endLocalTime
              ? timeToMilliseconds(itemTime?.endLocalTime)
              : new Date(),
            startTime: itemTime?.startLocalTime
              ? timeToMilliseconds(itemTime?.startLocalTime)
              : new Date(),
            id: itemTime?.id ?? getRandomUuid(),
          }
        })
      }

      return {
        checked,
        dayId: `${getOrdinalNumberOfDayOfTheWeek(itm)}`,
        title: t(getTextDayOfDayOfTheWeek(itm)),
        key: itm,
        times,
      }
    })
    return res
  }, [restaurantData])

  const onAddTimeRange = async (key: string) => {
    const payload: IPayloadUpdateLocationTime = {
      day_of_week: key,
      end_local_time: '00:00',
      location_fk: restaurantDetail?.id,
      start_local_time: '00:00',
    }

    await mutateAsyncCreate(payload)
  }

  const onDeleteRangeTime = async (id: string) => {
    await mutateAsyncDelete({ id })
  }

  const onChangeRangeTime = async ({
    newItem,
    key,
  }: {
    newItem: ITimeOpenRestaurant
    key: string
  }) => {
    const payload: IPayloadUpdateLocationTime = {
      day_of_week: key,
      end_local_time: moment(newItem.endTime).format('HH:mm'),
      location_fk: restaurantDetail?.id,
      start_local_time: moment(newItem.startTime).format('HH:mm'),
      id: newItem.id,
    }
    console.log({ payload })

    await mutateAsyncUpdate(payload)
  }

  return {
    days,
    onAddTimeRange,
    onDeleteRangeTime,
    onChangeRangeTime,
    isLoading: loadingCreate || loadingDelete || loadingList || loadingUpdate,
  }
}

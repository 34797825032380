/* eslint-disable @typescript-eslint/no-explicit-any */
import { NotificationType } from 'app/components/common/BaseNotification'
import { ADMIN_PATH } from 'app/components/router/route-path'
import { getToken } from 'app/service/storage/storage-service'
import { flatten } from 'lodash'
import { formatMoney } from 'parkway-web-common'
import { MutableRefObject, useEffect, useRef } from 'react'
import { InfiniteData } from 'react-query'
import { useNavigate } from 'react-router'

interface NotificationSeverity {
  id: number
  name: NotificationType
}

export const defineColorBySeverity = (
  severity: NotificationType | undefined,
  rgb = false,
): string => {
  const postfix = rgb ? 'rgb-color' : 'color'
  switch (severity) {
    case 'error':
    case 'warning':
    case 'success':
      return `var(--${severity}-${postfix})`
    case 'info':
    default:
      return `var(--primary-${postfix})`
  }
}

export const notificationsSeverities: NotificationSeverity[] = [
  { id: 0, name: 'info' },
  { id: 1, name: 'success' },
  { id: 2, name: 'warning' },
  { id: 3, name: 'error' },
  { id: 4, name: 'mention' },
]

export const normalizeProp = (
  prop: string | number | [number, number],
): string =>
  typeof prop === 'number'
    ? `${prop}px`
    : (Array.isArray(prop) && `${prop[0]}px ${prop[1]}px`) || prop.toString()

interface UseMountedReturnVal {
  isMounted: MutableRefObject<boolean>
}

export const useMounted = (): UseMountedReturnVal => {
  const isMounted = useRef(false)

  useEffect(() => {
    isMounted.current = true

    return () => {
      isMounted.current = false
    }
  }, [])

  return { isMounted }
}

export const useNavigateHook = () => {
  const navigate = useNavigate()
  const token = getToken()
  const navigatePath = (path: string) => {
    navigate(path)
  }

  const navigateAuthPath = (path: string) => {
    if (token) {
      navigate(ADMIN_PATH.HOME)
      return
    }

    navigate(path)
  }

  return { navigatePath, navigateAuthPath }
}

export const formatMoneyDollar = (money: number | string, currency?: string) => {
  switch (currency) {
    case "USD":
      money = (+money / 100)
      break
    case "VND":
      break
    default:
      break
  }
  return `${formatMoney(money, currency ?? '', undefined, 2)}`
}

export function usePagination() {
  const flattenData = (data?: InfiniteData<any>) => {
    if (!data) {
      return []
    }

    return flatten(data?.pages?.map(page => page))?.filter(
      (obj: any) => obj !== undefined,
    )
  }

  const flattenDataList = (data?: InfiniteData<any>) => {
    if (!data) {
      return []
    }

    return data?.pages?.[0]
  }

  return {
    flattenData,
    flattenDataList,
  }
}

import { BaseImage } from 'app/components/common/BaseImage'
import { DASHBOARD_PATH } from 'app/components/router/route-path'
import { BORDER_RADIUS } from 'parkway-web-common'
import React from 'react'
import * as S from './MainSider/styles'

interface SliderLogoProps {
  isSliderCollapsed: boolean
  toggleSlider: () => void
}
export const SliderLogo: React.FC<SliderLogoProps> = () => {
  return (
    <S.SliderLogoDiv>
      <S.SliderLogoLink to={DASHBOARD_PATH}>
        <BaseImage
          width={40}
          src={
            'https://storage.googleapis.com/kiosify-01/images/ic_logo_kiosify.png'
          }
          style={{ borderRadius: BORDER_RADIUS, cursor: 'pointer' }}
          preview={false}
        />
      </S.SliderLogoLink>
    </S.SliderLogoDiv>
  )
}

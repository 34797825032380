import R from 'app/assets/R'
import { ADMIN_PATH } from 'app/components/router/route-path'
import React from 'react'

export interface SidebarNavigationItem {
  title: string
  key: string
  url?: string
  children?: SidebarNavigationItem[]
  icon?: React.ReactNode
  iconFocus?: React.ReactNode
}

export const sidebarNavigation: SidebarNavigationItem[] = [
  {
    title: R.strings.home,
    key: R.strings.home,
    url: ADMIN_PATH.HOME,
    iconFocus: R.images.ic_home_admin_focus,
    icon: R.images.ic_home_gray,
  },
  {
    title: R.strings.report,
    key: R.strings.report,
    url: ADMIN_PATH.ADMIN_REPORTS,
    icon: R.images.ic_chart_admin_gray,
    iconFocus: R.images.ic_chart_admin_black,
  },
  {
    title: R.strings.settings,
    key: R.strings.settings,
    url: ADMIN_PATH.ADMIN_SETTINGS,
    icon: R.images.ic_setting_gray,
    iconFocus: R.images.ic_setting_admin_black,
  },
]

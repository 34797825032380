import { cyan, generate, green, presetPalettes, red } from '@ant-design/colors'
import { ColorPicker, theme } from 'antd'
import { ColorPickerProps } from 'antd/lib'
import { memo } from 'react'
import * as S from './styles'

interface ColorCustomProps {
  title: string
  currentValue: string
  isLoading?: boolean
  circleColors?: string[]
  onChange?: (colorCode: string) => void
  onTargetChange?: (target: string) => void
}

type Presets = Required<ColorPickerProps>['presets'][number]

const genPresets = (presets = presetPalettes) =>
  Object.entries(presets).map<Presets>(([label, colors]) => ({ label, colors }))

const ColorCustom = (props: ColorCustomProps) => {
  const { token } = theme.useToken()
  const presets = genPresets({
    primary: generate(token.colorPrimary),
    red,
    green,
    cyan,
  })

  return (
    <S.RootWrapper>
      <S.ColorCircleWrapper gutter={[8, 8]}>
        <ColorPicker
          value={props?.currentValue}
          presets={presets}
          onChangeComplete={color => props.onChange?.(color.toHexString())}
        >
          <S.ColorCircleGradientItem>
            <div className="gradient-area">
              <div className="white-area"></div>
            </div>
          </S.ColorCircleGradientItem>
        </ColorPicker>
      </S.ColorCircleWrapper>
    </S.RootWrapper>
  )
}

export default memo(ColorCustom)
//

/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAction, createSlice, PrepareAction } from '@reduxjs/toolkit'
import {
  getCurrentPathReport,
  setCurrentPathReport,
} from 'app/service/storage/storage-service'

export interface PathState {
  path: string
  chartData?: any
}

const initialState: PathState = {
  path: getCurrentPathReport(),
}

export const setPathReport = createAction<PrepareAction<string>>(
  'pathReport/setPathReport',
  newPath => {
    return {
      payload: newPath,
    }
  },
)

export const pathReportSlice = createSlice({
  name: 'pathReport',
  initialState,
  reducers: {
    //
    setDeviceSetting: (state, action) => {
      state.chartData = action.payload
    },
  },
  extraReducers: builder => {
    builder.addCase(setPathReport, (state, action) => {
      state.path = action.payload
      setCurrentPathReport(action.payload)
    })
  },
})

export const { setDeviceSetting } = pathReportSlice.actions
export default pathReportSlice.reducer

import R from 'app/assets/R'
import BaseText from 'app/components/common/BaseText'
import { Loading } from 'app/components/common/Loading'
import { useTranslation } from 'react-i18next'
import { SelectOpenTimeItem } from './SelectOpenTimeItem'
import * as S from './styles'
import { IOpenTimeItem, ITimeOpenRestaurant } from './type'

export const OpenTimeLayout = ({
  days,
  onAddTimeRange,
  onDeleteRangeTime,
  onChangeRangeTime,
  isLoading,
}: {
  days: IOpenTimeItem[]
  onAddTimeRange: (key: string) => Promise<void>
  onDeleteRangeTime: (id: string, key: string) => Promise<void>
  onChangeRangeTime: ({
    newItem,
    key,
  }: {
    newItem: ITimeOpenRestaurant
    key: string
  }) => Promise<void>
  isLoading: boolean
}) => {
  const { t } = useTranslation()

  return (
    <S.RootWrapper>
      <BaseText fontSize="xs" fontWeight="semibold">
        {t(R.strings.kiosk_open_time)}
      </BaseText>
      <S.OpenTimeSelectWrapper direction="vertical" size={8}>
        {days.map((item, index) => {
          return (
            <SelectOpenTimeItem
              key={index}
              item={item}
              index={index}
              onAddTimeRangeOfDay={onAddTimeRange}
              onDeleteRangeTime={onDeleteRangeTime}
              onChangeRangeTime={onChangeRangeTime}
            />
          )
        })}
      </S.OpenTimeSelectWrapper>
      {isLoading && <Loading />}
    </S.RootWrapper>
  )
}

import { memo } from 'react'
import { isEqual } from 'lodash'
import ChangePasswordSuccessContainer from 'app/containers/auth/ChangePasswordSuccess'

const ChangePasswordSuccessPageComp = () => {
  return <ChangePasswordSuccessContainer />
}
export const ChangePasswordSuccessPage = memo(
  ChangePasswordSuccessPageComp,
  isEqual,
)

import { BaseLayout } from 'app/components/common/BaseLayout'
import { PaddingMain } from 'app/constant'
import { colors, media } from 'parkway-web-common'
import styled, { css } from 'styled-components'

interface Header {
  $isTwoColumnsLayoutHeader: boolean
}

export const Header = styled(BaseLayout.Header)<Header>`
  line-height: 1.5;
  background-color: ${colors.white};
  border-bottom: 0.5px solid var(--gray-border-color);
  @media only screen and ${media.md} {
    padding: 10px ${PaddingMain.Mobile.defaultHz} !important;
    padding-left: 0px;
    height: unset;
  }
  @media only screen and ${media.xs} {
    padding: ${PaddingMain.Mobile.defaultHz};
  }

  @media only screen and ${media.md} {
    ${props =>
      props?.$isTwoColumnsLayoutHeader &&
      css`
        padding: 0;
      `}
  }
`

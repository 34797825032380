import { useResponsive } from 'parkway-web-common'
import React from 'react'
import { DesktopLayout } from './components/desktop-layout'
import { MobileLayout } from './components/mobile-layout'
import * as S from './styles'

const DashboardContainer: React.FC = () => {
  const { mobileOnly } = useResponsive()

  return (
    <S.ScrollWrapper>
      {mobileOnly ? <MobileLayout /> : <DesktopLayout />}
    </S.ScrollWrapper>
  )
}

export default DashboardContainer

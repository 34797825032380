import { IResponseDefine } from 'app/common/model'

export enum TypeDateEnum {
  daily = 'daily',
  weekly = 'weekly',
  monthly = 'monthly',
  yearly = 'yearly',
}

export interface IFilterReport {
  type_date: TypeDateEnum
}

// IDishOrdered
export interface IDishOrdered {
  name?: string
  current?: {
    amount?: number
    currency?: string
  }
  yesterday?: {
    amount?: number
    currency?: string
  }
}

export interface IMostOrderedReport {
  date?: string
  dish_ordered?: IDishOrdered[]
}

export type IResponseMostOrderedReport = IResponseDefine<IMostOrderedReport[]>

//IReportRevenue
export interface IReportRevenue {
  date?: string
  current?: {
    total_gross?: number
    total_net?: number
    currency?: string
  }
  yesterday?: {
    total_gross?: number
    total_net?: number
    currency?: string
  }
}

export type IResponseReportRevenue = IResponseDefine<IReportRevenue[]>

//IReportCustomer
export interface IReportCustomer {
  date?: string
  total_customer?: number
}

export type IResponseReportCustomer = IResponseDefine<IReportCustomer[]>

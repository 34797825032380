/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { DeleteOutlined, SearchOutlined } from '@ant-design/icons'
import TextArea from 'antd/es/input/TextArea'
import Dragger from 'antd/es/upload/Dragger'
import {
  ICategory,
  IModifier,
  IPostItems,
  IVariationProductManagement,
} from 'app/api/product-management/model'
import { IRestaurant } from 'app/api/restaurant/model'
import R from 'app/assets/R'
import {
  SvgSquareCheckIcon,
  SvgUnCheckedCreateItemIcon,
} from 'app/assets/svg-assets'
import { BaseButton } from 'app/components/common/BaseButton'
import { ButtonVariant } from 'app/components/common/BaseButtonVariant'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseCollapse } from 'app/components/common/BaseCollapse'
import { BaseImage } from 'app/components/common/BaseImage'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import { BaseSwitch } from 'app/components/common/BaseSwitch'
import BaseText from 'app/components/common/BaseText'
import { CurrencyPicker } from 'app/components/common/CurrencyPicker'
import { CurrencySupportType } from 'app/components/common/CurrencyPicker/type'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { BaseInput } from 'app/components/common/inputs/BaseInput'
import { InputNumber } from 'app/components/common/inputs/InputNumber'
import { Loading } from 'app/components/common/Loading'
import { BaseSelect } from 'app/components/common/selects/BaseSelect'
import { customCurrencyValue, useDebounce, usePagination } from 'app/hook'
import {
  useGetCategoryList,
  useGetModifierList,
} from 'app/react-query/hook/product-management'
import { useGetRestaurantListV2 } from 'app/react-query/hook/restaurant'
import { convertColor } from 'app/styles/themes/themeVariables'
import { t } from 'i18next'
import { isEmpty, isEqual } from 'lodash'
import { BORDER_RADIUS, PADDING } from 'parkway-web-common'
import { useMemo, useState } from 'react'
import styled from 'styled-components'

interface IForm extends IPostItems {
  image?: string
}

type ProductManagementProps = {
  imageUrl?: string
  setImageUrl: React.Dispatch<React.SetStateAction<string | undefined>>
  fileUploaded?: File
  setFileUploaded: React.Dispatch<React.SetStateAction<File | undefined>>
  currency: CurrencySupportType
  setCurrency: React.Dispatch<React.SetStateAction<CurrencySupportType>>
  includeTax: boolean
  setIncludeTax: React.Dispatch<React.SetStateAction<boolean>>
  locationIdsSelected: string[]
  setLocationIdsSelected: React.Dispatch<React.SetStateAction<string[]>>
  modifierIdsSelected: string[]
  setModifierIdsSelected: React.Dispatch<React.SetStateAction<string[]>>
  variationsSelected: IVariationProductManagement[]
  setVariationsSelected: React.Dispatch<
    React.SetStateAction<IVariationProductManagement[]>
  >
}
export const HookFormModal = ({
  imageUrl,
  setImageUrl,
  setFileUploaded,
  currency,
  setCurrency,
  includeTax,
  setIncludeTax,
  locationIdsSelected,
  setLocationIdsSelected,
  modifierIdsSelected,
  setModifierIdsSelected,
  variationsSelected,
  setVariationsSelected,
}: ProductManagementProps) => {
  const [form] = BaseForm.useForm<IForm>()

  const { flattenData, flattenDataList } = usePagination()

  // Xử lý sau khi upload thành công
  const handleUploadChange = (info: any) => {
    const file = info?.file
    if (file) {
      const url = URL.createObjectURL(file)
      setImageUrl(url)
      form.setFieldsValue({ image: url })
      setFileUploaded(file)
    }
  }

  const [searchLocation, setSearchLocation] = useState('')
  const [searchModifier, setSearchModifier] = useState('')

  const searchDebounceLocation = useDebounce(searchLocation, 500)
  const searchDebounceModifier = useDebounce(searchModifier, 500)

  const { data: categoriesData, isLoading: loadingCategories } =
    useGetCategoryList()
  const { data: locationsData, isLoading: loadingLocations } =
    useGetRestaurantListV2({ searchKey: searchDebounceLocation })
  const { data: modifiersData, isLoading: loadingModifiers } =
    useGetModifierList({ searchKey: searchDebounceModifier })

  const categories: ICategory[] = useMemo(() => {
    return flattenData(categoriesData)
  }, [categoriesData])

  const locations: IRestaurant[] = useMemo(() => {
    return locationsData?.filter(itm => isEqual(itm?.currency, currency)) ?? []
  }, [locationsData, currency])

  const modifiers: IModifier[] = useMemo(() => {
    return flattenDataList(modifiersData)?.data ?? []
  }, [modifiersData])

  const onSelectLocation = (id?: string) => {
    if (!id) return

    const itemExist = locationIdsSelected?.find(idSelected =>
      isEqual(idSelected, id),
    )

    if (itemExist) {
      setLocationIdsSelected(prev =>
        prev?.filter(idSelected => !isEqual(idSelected, id)),
      )
    } else {
      setLocationIdsSelected(prev => prev?.concat([id]))
    }
  }

  const onSelectModifier = (id?: string) => {
    if (!id) return

    const itemExist = modifierIdsSelected?.find(idSelected =>
      isEqual(idSelected, id),
    )

    if (itemExist) {
      setModifierIdsSelected(prev =>
        prev?.filter(idSelected => !isEqual(idSelected, id)),
      )
    } else {
      setModifierIdsSelected(prev => prev?.concat([id]))
    }
  }

  const onPressSelectedLocationAll = () => {
    if (locationIdsSelected?.length) {
      setLocationIdsSelected([])
    } else {
      setLocationIdsSelected(locations?.map(itm => itm?.id ?? ''))
    }
  }

  const onPressSelectedModifierAll = () => {
    if (!modifierIdsSelected?.length) {
      setModifierIdsSelected(modifiers?.map(itm => itm?.id ?? ''))
    } else {
      setModifierIdsSelected([])
    }
  }

  const onPressNewRecord = () => {
    setVariationsSelected(prev =>
      prev?.concat([
        {
          name: '',
          price: '',
        },
      ]),
    )
  }

  const onDeleteVariationByIndex = (index: number) => {
    let newVariations = variationsSelected?.filter(
      (_, idx) => !isEqual(idx, index),
    )

    if (!newVariations?.length) {
      newVariations = newVariations?.concat([
        {
          name: '',
          price: '',
        },
      ])
    }

    setVariationsSelected(newVariations)
  }

  const onChangeNameByIndex = (name: string, index: number) => {
    setVariationsSelected(prev =>
      prev?.map((itm, idx) => {
        if (isEqual(idx, index)) {
          return {
            ...itm,
            name,
          }
        }
        return itm
      }),
    )
  }

  const onChangePriceByIndex = (
    price: string | null | number,
    index: number,
  ) => {
    if (!price) return
    setVariationsSelected(prev =>
      prev?.map((itm, idx) => {
        if (isEqual(idx, index)) {
          return {
            ...itm,
            price: `${price}`,
          }
        }
        return itm
      }),
    )
  }

  const onChangeCurrency = (cu: CurrencySupportType) => {
    setCurrency(cu)
    setLocationIdsSelected([])
  }

  return (
    <BaseSpace
      style={{ padding: '0px 26px', maxHeight: 650, overflowY: 'auto' }}
      size={8}
    >
      <BaseText
        children={'Details'}
        fontWeight="bold"
        fontSize="md"
        fontFamily="Raleway"
      />

      <BaseSpace size={8}>
        <BaseSpace size={0}>
          <BaseText children={'Categorization'} opacity="0.7" />

          <BaseForm.Item
            name={'categoryId'}
            required
            rules={[
              {
                required: true,
                message: t(R.strings.require_field, {
                  field: 'Category',
                }),
              },
            ]}
          >
            <BaseSelect
              placeholder={t(R.strings.input_placeholder_select_category)}
              options={categories?.map(item => ({
                label: item?.name,
                value: item?.id,
              }))}
              loading={loadingCategories}
            />
          </BaseForm.Item>
        </BaseSpace>

        <BaseRow justify={'space-between'} align={'top'} gutter={16}>
          <BaseCol xl={12}>
            <BaseSpace size={0}>
              <BaseText children={'Item Name'} opacity="0.7" />

              <BaseForm.Item
                name={'name'}
                required
                rules={[
                  {
                    required: true,
                    message: t(R.strings.require_field, {
                      field: 'Name',
                    }),
                  },
                ]}
              >
                <BaseInput
                  placeholder="Enter item name"
                  style={{ padding: '8px 10px', width: '100%' }}
                />
              </BaseForm.Item>
            </BaseSpace>
          </BaseCol>

          <BaseCol xl={12}>
            <BaseSpace size={0}>
              <BaseText children={'Item Display Name'} opacity="0.7" />

              <BaseForm.Item
                name={'displayName'}
                required
                rules={[
                  {
                    required: true,
                    message: t(R.strings.require_field, {
                      field: 'Display Name',
                    }),
                  },
                ]}
              >
                <BaseInput
                  placeholder="Enter item display name"
                  style={{ padding: '8px 10px', width: '100%' }}
                />
              </BaseForm.Item>
            </BaseSpace>
          </BaseCol>
        </BaseRow>

        <BaseSpace size={0}>
          <BaseText children={'Description'} opacity="0.7" />

          <BaseForm.Item name={'description'}>
            <TextArea
              placeholder="Enter description"
              style={{ padding: '8px 10px', width: '100%' }}
            />
          </BaseForm.Item>
        </BaseSpace>

        <BaseRow align={'middle'} justify={'space-between'} gutter={16}>
          <BaseCol xl={4}>
            <BaseForm.Item label={'Include Tax'}>
              <BaseSwitch checked={includeTax} onChange={setIncludeTax} />
            </BaseForm.Item>
          </BaseCol>
          {includeTax ? (
            <BaseCol xl={20}>
              <BaseSpace size={0}>
                <BaseText children={'Tax Value'} opacity="0.7" />

                <BaseForm.Item
                  name={'taxRate'}
                  required
                  rules={[
                    {
                      required: true,
                      message: t(R.strings.require_field, {
                        field: 'Tax Value',
                      }),
                    },
                  ]}
                >
                  <InputNumber
                    placeholder="Enter tax value"
                    style={{ padding: '8px 10px', width: '100%' }}
                  />
                </BaseForm.Item>
              </BaseSpace>
            </BaseCol>
          ) : null}
        </BaseRow>

        <BaseSpace size={0}>
          <BaseText children={'Currency'} opacity="0.7" />

          <BaseForm.Item>
            <CurrencyPicker onChange={onChangeCurrency} initValue={currency} />
          </BaseForm.Item>
        </BaseSpace>

        <BaseForm.Item
          name={'image'}
          valuePropName="file"
          required
          rules={[
            {
              required: true,
              message: t(R.strings.require_field, {
                field: 'Image',
              }),
            },
          ]}
        >
          <Dragger
            name="file"
            multiple={false}
            onChange={handleUploadChange}
            beforeUpload={() => false}
            style={{
              borderRadius: 10,
              padding: '10px 35px',
              backgroundColor: 'white',
              borderStyle: 'dashed',
            }}
            showUploadList={false}
          >
            <BaseRow gutter={16} align={'middle'} justify={'start'}>
              <BaseImage
                src={imageUrl ?? R.images.ic_logo_kiosk_master}
                style={{ borderRadius: 10 }}
                width={88}
                preview={false}
              />
              <BaseCol xl={10}>
                <p>
                  Drag an image here,{' '}
                  <span style={{ color: '#1890ff' }}>upload</span>, or{' '}
                  <span style={{ color: '#1890ff' }}>browse image library</span>
                  .
                </p>
              </BaseCol>
            </BaseRow>
          </Dragger>
        </BaseForm.Item>
      </BaseSpace>

      <BaseCollapseWrapper
        items={[
          {
            key: 'locations',
            label: <BaseText children={'Location'} fontWeight="semibold" />,
            children: (
              <BaseSpace>
                <BaseForm.Item>
                  <BaseInput
                    placeholder="Search location"
                    prefix={<SearchOutlined rev={undefined} />}
                    style={{ padding: '12px 12px', width: '100%' }}
                    onChange={e => setSearchLocation(e?.target?.value)}
                  />
                </BaseForm.Item>

                <BaseRow gutter={16} align={'middle'} justify={'space-between'}>
                  <BaseCol>
                    <BaseText
                      children={`Selected: ${locationIdsSelected?.length}`}
                      style={{ color: '#818181' }}
                      fontWeight="semibold"
                      fontSize="xxxs"
                    />
                  </BaseCol>

                  <BaseCol>
                    <div
                      style={{ cursor: 'pointer' }}
                      onClick={onPressSelectedLocationAll}
                    >
                      <BaseText
                        children={`Select all`}
                        style={{ color: '#0090FF' }}
                        fontWeight="medium"
                        fontSize="xxxs"
                      />
                    </div>
                  </BaseCol>
                </BaseRow>

                {locations?.map((location, index) => {
                  const isSelected = locationIdsSelected?.find(id =>
                    isEqual(location?.id, id),
                  )
                  return (
                    <BaseRow
                      gutter={16}
                      align={'middle'}
                      justify={'space-between'}
                      key={index}
                      onClick={() => onSelectLocation(location?.id)}
                      style={{ cursor: 'pointer' }}
                    >
                      <BaseCol>
                        <BaseText
                          children={location?.name ?? location?.businessName}
                          fontWeight="semibold"
                        />
                      </BaseCol>

                      <BaseCol
                        xl={2}
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        {isSelected ? (
                          <SvgSquareCheckIcon />
                        ) : (
                          <SvgUnCheckedCreateItemIcon />
                        )}
                      </BaseCol>
                    </BaseRow>
                  )
                })}

                {loadingLocations ? <Loading size="small" /> : null}
              </BaseSpace>
            ),
          },
        ]}
        expandIconPosition="end"
      />

      <BaseCollapseWrapper
        items={[
          {
            key: 'modifier',
            label: <BaseText children={'Modifier'} fontWeight="semibold" />,
            children: (
              <BaseSpace style={{ position: 'relative' }}>
                <BaseForm.Item>
                  <BaseInput
                    placeholder="Search modifier"
                    prefix={<SearchOutlined rev={undefined} />}
                    style={{ padding: '12px 12px', width: '100%' }}
                    onChange={e => setSearchModifier(e?.target?.value)}
                  />
                </BaseForm.Item>

                <BaseRow gutter={16} align={'middle'} justify={'space-between'}>
                  <BaseCol>
                    <BaseText
                      children={`Selected: ${modifierIdsSelected?.length}`}
                      style={{ color: '#818181' }}
                      fontWeight="semibold"
                      fontSize="xxxs"
                    />
                  </BaseCol>

                  <BaseCol>
                    <div
                      style={{ cursor: 'pointer' }}
                      onClick={onPressSelectedModifierAll}
                    >
                      <BaseText
                        children={`Select all`}
                        style={{ color: '#0090FF' }}
                        fontWeight="medium"
                        fontSize="xxxs"
                      />
                    </div>
                  </BaseCol>
                </BaseRow>

                {modifiers?.map((modifier, index) => {
                  const isSelected = modifierIdsSelected?.find(id =>
                    isEqual(modifier?.id, id),
                  )
                  return (
                    <BaseRow
                      gutter={16}
                      align={'middle'}
                      justify={'space-between'}
                      key={index}
                      onClick={() => onSelectModifier(modifier?.id)}
                      style={{ cursor: 'pointer' }}
                    >
                      <BaseCol>
                        <BaseText
                          children={modifier?.name}
                          fontWeight="semibold"
                        />
                      </BaseCol>

                      <BaseCol
                        xl={2}
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        {isSelected ? (
                          <SvgSquareCheckIcon />
                        ) : (
                          <SvgUnCheckedCreateItemIcon />
                        )}
                      </BaseCol>
                    </BaseRow>
                  )
                })}

                {loadingModifiers ? <Loading size="small" /> : null}
              </BaseSpace>
            ),
          },
        ]}
        expandIconPosition="end"
      />

      <BaseCollapseWrapper
        items={[
          {
            key: 'variation',
            label: <BaseText children={'Variations'} fontWeight="semibold" />,
            children: (
              <BaseSpace>
                {variationsSelected?.map((item, index) => {
                  return (
                    <BaseRow
                      gutter={16}
                      align={'middle'}
                      key={index}
                      justify={'space-between'}
                    >
                      <BaseCol xl={10}>
                        <BaseSpace size={0}>
                          <BaseText children={'Name'} opacity="0.7" />

                          <BaseForm.Item>
                            <BaseInput
                              placeholder="Name"
                              value={item?.name}
                              onChange={e =>
                                onChangeNameByIndex(
                                  e?.target?.value ?? '',
                                  index,
                                )
                              }
                            />
                          </BaseForm.Item>
                        </BaseSpace>
                      </BaseCol>
                      <BaseCol xl={10}>
                        <BaseSpace size={0}>
                          <BaseText children={'Price'} opacity="0.7" />

                          <BaseForm.Item>
                            <InputNumber
                              placeholder="Price of variation"
                              min={0}
                              style={{ width: '100%' }}
                              value={item?.price}
                              onChange={e => onChangePriceByIndex(e, index)}
                            />
                          </BaseForm.Item>
                        </BaseSpace>
                      </BaseCol>
                      <BaseCol style={{ marginTop: 20 }}>
                        <BaseText
                          children={customCurrencyValue(currency)?.labelInput}
                        />
                      </BaseCol>
                      <BaseCol>
                        <BaseButton
                          style={{ marginTop: 20 }}
                          type="text"
                          icon={
                            <DeleteOutlined
                              rev={undefined}
                              style={{ color: 'red' }}
                              onClick={() => onDeleteVariationByIndex(index)}
                            />
                          }
                        />
                      </BaseCol>
                    </BaseRow>
                  )
                })}

                <ButtonVariant
                  children={t(R.strings.add_new)}
                  disabled={
                    !!variationsSelected?.find(itm => {
                      if (isEmpty(itm?.name)) return true

                      if (isEmpty(itm?.price)) {
                        if (Number(itm?.price) === 0) return true
                      }

                      return false
                    })
                  }
                  style={{ width: '100%' }}
                  onClick={onPressNewRecord}
                />
              </BaseSpace>
            ),
          },
        ]}
        expandIconPosition="end"
      />
    </BaseSpace>
  )
}

const BaseCollapseWrapper = styled(BaseCollapse)`
  width: 100%;

  .ant-collapse-content {
    border-top: 1px solid ${convertColor.neutralBlack9Color};
    background-color: ${convertColor.backgroundColor};
  }

  .ant-collapse-header {
    background-color: var(--background-color);
    border-radius: ${BORDER_RADIUS}!important;
    align-items: center !important;
  }

  .ant-collapse-header[aria-expanded='true'] {
    border-radius: ${BORDER_RADIUS}!important;
    border-bottom-left-radius: ${BORDER_RADIUS} !important;
    border-bottom-right-radius: ${BORDER_RADIUS} !important;
  }

  .header-content {
    padding: ${PADDING.md};
    background-color: #f9fafb;
  }
`

/* eslint-disable @typescript-eslint/no-explicit-any */
import { motion, MotionValue, useScroll, useTransform } from 'framer-motion'

const VideoLayout = () => {
  const { scrollY } = useScroll() // Lấy thông tin về vị trí cuộn

  const scale: MotionValue = useTransform(scrollY, [0, 250], [0.9, 1]) // Tỷ lệ phóng to từ 1 đến 1.2 khi cuộn từ 0 đến 500px
  const borderRadius: MotionValue = useTransform(scrollY, [0, 250], [16, 0]) // Tỷ lệ phóng to từ 1 đến 1.2 khi cuộn từ 0 đến 500px

  return (
    <motion.video
      src="https://assets.awwwards.com/awards/element/2022/05/627a5f58d8b10261553694.mp4"
      autoPlay
      muted
      loop
      transition={{ duration: 1, ease: 'easeInOut' }}
      style={{
        objectFit: 'cover',
        width: '100vw',
        height: '100vh',
        scale,
        borderRadius,
      }}
    />
  )
}
export default VideoLayout

import R from 'app/assets/R'
import ReactCountryFlag from 'react-country-flag'
import { Currency } from './type'

export const CURRENCY: Currency[] = [
  {
    value: 'USD',
    label: R.strings.currency_option_en,
    flag: <ReactCountryFlag svg countryCode="US" />,
  },
  {
    value: 'VND',
    label: R.strings.currency_option_vi,
    flag: <ReactCountryFlag svg countryCode="VN" />,
  },
  {
    value: 'JPY',
    label: R.strings.currency_option_ja,
    flag: <ReactCountryFlag svg countryCode="JP" />,
  },
  {
    value: 'EUR',
    label: R.strings.currency_option_es,
    flag: <ReactCountryFlag svg countryCode="EU" />,
  },
  {
    value: 'CNY',
    label: R.strings.currency_option_zh,
    flag: <ReactCountryFlag svg countryCode="CN" />,
  },
]

import { memo } from 'react'
import { isEqual } from 'lodash'
import SplashLoginContainerDesktop from 'app/containers/auth/desktop/SplashLogin'
import SplashLoginContainerMobile from 'app/containers/auth/mobile/SplashLogin'
import { useResponsive } from 'parkway-web-common'

const SplashLoginPageComp = () => {
  const { isTablet } = useResponsive()
  return !isTablet ? (
    <SplashLoginContainerMobile />
  ) : (
    <SplashLoginContainerDesktop />
  )
}
export const SplashLoginPage = memo(SplashLoginPageComp, isEqual)

import { TabsProps } from 'antd'
import R from 'app/assets/R'
import { t } from 'i18next'
export enum TabEnum {
  CATEGORY = 'CATEGORY',
  ITEM = 'ITEM',
  UP_SELL = 'UP_SELL',
  DISCOUNT = 'DISCOUNT',
  MODIFIER = 'MODIFIER',
}

export const getItemTabs = () => {
  const ItemsTabs: TabsProps['items'] = [
    {
      key: TabEnum.CATEGORY,
      label: t(R.strings.product_management_category),
    },
    {
      key: TabEnum.ITEM,
      label: t(R.strings.product_management_items),
    },
    {
      key: TabEnum.UP_SELL,
      label: t(R.strings.product_management_up_sell),
    },
    {
      key: TabEnum.MODIFIER,
      label: t(R.strings.product_management_modifiers),
    },
    // {
    //   key: TabEnum.DISCOUNT,
    //   label: t(R.strings.product_management_discounts),
    // },
  ]

  return ItemsTabs
}

import { BORDER_RADIUS, FONT_SIZE, FONT_WEIGHT, PADDING } from "parkway-web-common";
import styled from "styled-components";
import { SearchInput } from "./common/inputs/SearchInput";
import { BaseButton } from "./common/BaseButton";

interface TextProps {
  $color?: string;
  $bgColor?: string;
  $fontSize?: string;
  $fontWeight?: string;
  $opacity?: string;
  $margin?: string;
  $inline?: boolean;
  $capitalize?: boolean;
  $center?: boolean;
}

export const Text = styled.div<TextProps>`
    color: ${props => props.$color || 'var(--background-color)'};
    background-color: ${props => props.$bgColor || 'initial'};
    opacity: ${props => props.$opacity || '1'};
    font-size: ${props => props.$fontSize || FONT_SIZE.xl};
    font-weight: ${props => props.$fontWeight || FONT_WEIGHT.bold};
    margin:  ${props => props.$margin || '0'};
    display:  ${props => props.$inline ? 'inline-block' : 'block'};
    text-transform: ${props => props.$capitalize ? 'capitalize' : 'uset'};
    text-align: ${props => props.$center && 'center'};
`

export const SearchLayout = styled(SearchInput)`
  .ant-input-group-addon {
    display: none;
  }
  .ant-input-group .ant-input-affix-wrapper:not(:last-child) {
    border-radius: ${BORDER_RADIUS};
    border: 0;
    background: transparent !important;
    color: var(--background-color);
    border: 0.7px solid rgba(45, 48, 62, 1);
  }
  input {
    background: transparent !important;
    color: var(--background-color);
  }
`

export const BackButton = styled(BaseButton) <{ $hide?: boolean; }>`
  display: ${props => props.$hide && 'none'};
  background-color: rgba(255, 255, 255, 0.1); // need change
  border-radius: ${BORDER_RADIUS};
  border-color: transparent;
  padding: ${PADDING.md};
  transition: all 1s ease;
  margin-right: 40px;
  color: #FFFFFF;

  & svg {
    fill: var(--background-color);
  }

  &:hover {
    border-color: var(--background-color) !important;
    color: #FFFFFF !important;
    transition: all 1s ease;
  }
`

export const SpaceVertical = styled.div<{ $gap?: number }>`
  height: ${props => `${props.$gap || '5'}px`};
  &::before {
    content: '_';
    font-size: 0px;
    color: transparent;
  }
`

export const Image = styled.img<{ $width?: string; $height?: string; }>`
  width:  ${props => props.$width};
  height:  ${props => props.$height};
`

export const LoadingWrapper = styled.div`
  position: relative;
`
import { SearchOutlined } from '@ant-design/icons'
import { IKiosk } from 'app/api/kiosk/model'
import R from 'app/assets/R'
import { SvgDesktopIcon } from 'app/assets/svg-assets'
import { ButtonVariant } from 'app/components/common/BaseButtonVariant'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseImage } from 'app/components/common/BaseImage'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { BaseInput } from 'app/components/common/inputs/BaseInput'
import { Loading } from 'app/components/common/Loading'
import { NotFound } from 'app/components/common/NotFound'
import { URL_SETTING } from 'app/containers/admin/Settings/constant'
import { usePagination } from 'app/hook'
import { useGetKioskList } from 'app/react-query/hook/kiosk'
import { useAppDispatch } from 'app/redux/hooks'
import {
  setKioskUpdateItem,
  setPathSetting,
} from 'app/redux/slices/pathSettingSlice'
import { isEmpty } from 'lodash'
import { memo, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as S from './styles'

const YourKioskLayout = () => {
  const { t } = useTranslation()
  const [search, setSearch] = useState('')

  const navigateToAddYourKiosk = () => {
    dispatch(setPathSetting(URL_SETTING.AddYourKiosk))
  }

  const dispatch = useAppDispatch()
  const navigateToEditYourKiosk = (item?: IKiosk) => {
    dispatch(setPathSetting(URL_SETTING.UpdateYourKiosk))
    dispatch(setKioskUpdateItem(item))
  }

  const { data, isLoading: loading } = useGetKioskList({
    page_size: 1000,
  })
  const { flattenDataList } = usePagination()

  const kiosks = useMemo(() => {
    const kiosk: IKiosk[] = flattenDataList(data)
    if (!search) return kiosk

    const keyword = search?.toLowerCase()

    const kioskFilter = kiosk.filter(
      contact =>
        contact?.code?.toString()?.toLowerCase().includes(keyword) ||
        contact?.name?.toLowerCase()?.includes(keyword) ||
        contact?.location?.name?.toLowerCase()?.includes(keyword),
    )

    return kioskFilter
  }, [data, search])

  return (
    <S.RootWrapper>
      <BaseInput
        placeholder="Search kiosk"
        prefix={<SearchOutlined rev={undefined} />}
        onChange={e => setSearch?.(e?.target?.value)}
        style={{ padding: '8px 10px' }}
      />

      <BaseRow gutter={5} align={'middle'} justify={'end'}>
        {/* <BaseCol>
          <BaseButton
            icon={<SvgSortDescIcon style={{ width: 26, height: 26 }} />}
            type="text"
          />
        </BaseCol>

        <BaseCol>
          <BaseButton
            icon={<SvgSortAscendingIcon style={{ width: 26, height: 26 }} />}
            type="text"
          />
        </BaseCol> */}

        <BaseCol>
          <ButtonVariant
            children={
              <BaseText
                children={t(R.strings.kiosk_add_new_kiosk)}
                fontWeight="semibold"
              />
            }
            onClick={navigateToAddYourKiosk}
          />
        </BaseCol>
      </BaseRow>

      <BaseSpace size={16}>
        {kiosks.map((item, index) => {
          return (
            <S.ItemRestaurant
              onClick={() => navigateToEditYourKiosk(item)}
              key={index}
              justify={'space-between'}
              align={'middle'}
              gutter={[16, 16]}
            >
              <BaseCol>
                <BaseRow gutter={[16, 16]} align={'middle'}>
                  <BaseCol>
                    <SvgDesktopIcon />
                  </BaseCol>
                  <BaseCol>
                    <BaseSpace size={4}>
                      <BaseText fontSize="xs" fontWeight="semibold">
                        {`${item.name}`}
                      </BaseText>
                      <BaseText
                        children={`${
                          !isEmpty(item?.location?.name)
                            ? item?.location?.name
                            : '-'
                        }`}
                        fontSize="xxxs"
                        fontWeight="semibold"
                        style={{ color: '#818181' }}
                      />
                    </BaseSpace>
                  </BaseCol>
                </BaseRow>
              </BaseCol>

              <BaseCol>
                <BaseImage
                  width={24}
                  height={24}
                  src={R.images.ic_caret_right_restaurant}
                  preview={false}
                />
              </BaseCol>
            </S.ItemRestaurant>
          )
        })}
        {kiosks.length === 0 && <NotFound />}
      </BaseSpace>
      {loading && <Loading />}
    </S.RootWrapper>
  )
}

export default memo(YourKioskLayout)

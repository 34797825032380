import styled from 'styled-components'
import { Select as AntSelect } from 'antd'
import {
  FONT_WEIGHT,
  BORDER_RADIUS,
  FONT_SIZE,
  PADDING,
} from 'parkway-web-common'
import { Dimension } from 'parkway-web-common'
import { normalizeProp } from 'app/hook'

export interface InternalSelectProps {
  $width?: Dimension
  $shadow?: boolean
}

export const Select = styled(AntSelect)<InternalSelectProps>`
  width: ${props => props.$width && normalizeProp(props.$width)};

  font-weight: ${FONT_WEIGHT.medium};

  box-shadow: ${props => props.$shadow && 'var(--box-shadow)'};

  &.ant-select-borderless {
    background: var(--secondary-background-color);

    border-radius: ${BORDER_RADIUS};
  }

  .ant-select-selection-placeholder {
    font-size: ${FONT_SIZE.xs};
    color: #abbbc2;
  }

  .ant-select-arrow {
    color: var(--text-main-color);
  }

  &.ant-select-multiple.ant-select-sm .ant-select-selection-item {
    height: 0.875rem;
    line-height: ${FONT_SIZE.xs};
    font-size: ${FONT_SIZE.xs};
    margin-top: 0.1875rem;
    margin-bottom: 0.1875rem;
  }

  &.ant-select-disabled.ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    color: var(--disabled-color);
  }

  .ant-select-clear {
    color: var(--disabled-color);
  }
  .ant-select-selection-item-remove {
    color: var(--icon-color);
    &:hover {
      color: var(--icon-hover-color);
    }
  }
  .ant-select-item-option-disabled {
    color: var(--disabled-color);
  }

  min-width: 200px !important;

  .ant-select-dropdown
    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: var(--background-color) !important;
    transition: all 0.5s ease;

    .ant-select-item-option-content {
      color: var(--primary-color) !important;
      transition: all 0.5s ease;
    }
  }
  .ant-select-arrow {
    top: 50% !important;

    svg {
      width: 15px;
      height: 13px;
    }
  }

  .ant-select-dropdown .ant-select-item-option {
    margin-bottom: ${PADDING.xxxxs};
  }
`

import { LeftOutlined } from '@ant-design/icons'
import R from 'app/assets/R'
import { BaseButton } from 'app/components/common/BaseButton'
import { ButtonVariant } from 'app/components/common/BaseButtonVariant'
import { BaseCheckbox } from 'app/components/common/BaseCheckbox'
import { BaseImage } from 'app/components/common/BaseImage'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { BaseInput as CommonInput } from 'app/components/common/inputs/BaseInput'
import { InputPassword as CommonInputPassword } from 'app/components/common/inputs/InputPassword'
import { BORDER_RADIUS_2 } from 'app/constant'
import { FONT_SIZE, FONT_WEIGHT, media } from 'parkway-web-common'
import styled from 'styled-components'

export const Wrapper = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
`

export const BackgroundWrapper = styled.div`
  width: 100%;
  height: 100%;
  background-size: cover;
  background-image: url(${R.images.img_bg_auth});
`

export const LoginWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 12%;
  left: 10%;
`

export const ServicePackageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const LoginBgWrapper = styled.div`
  width: 100%;
  height: 100%;
  background-size: cover;
  position: relative;
  background-image: url(${'https://storage.googleapis.com/kiosify-01/images/img_bg_login.png'});
`
export const SignUpBgWrapper = styled.div`
  width: 100%;
  height: 100%;
  background-size: cover;
  position: relative;
  background-image: url(${'https://storage.googleapis.com/kiosify-01/images/img_bg_sign_up.png'});
`

export const PaymentBgWrapper = styled.div`
  width: 100%;
  height: 100%;
  background-size: cover;
  position: relative;
  background-image: url(${'https://storage.googleapis.com/kiosify-01/images/img_bg_payment.png'});
`

export const ResetPasswordBgWrapper = styled.div`
  width: 100%;
  height: 100%;
  background-size: cover;
  position: relative;
  background-image: url(${'https://storage.googleapis.com/kiosify-01/images/img_bg_email_input.png'});
`

export const FormWrapper = styled.div`
  min-width: 200px;
  overflow: none;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media only screen and ${media.xs} {
    min-width: 200px;
  }

  @media only screen and ${media.md} {
    min-width: 200px;
  }
`

export const FormTitle = styled.div`
  color: white;
  text-align: center;
  font-size: 42px;
  @media only screen and ${media.xs} {
    margin-bottom: 0.5rem;
    font-size: ${FONT_SIZE.xxxl};
    font-weight: ${FONT_WEIGHT.black};
    line-height: 1.5625rem;
  }

  @media only screen and ${media.md} {
    margin-bottom: 0.675rem;
    font-size: ${FONT_SIZE.xxxxl};
    font-weight: ${FONT_WEIGHT.black};
    line-height: 1.9375rem;
  }

  @media only screen and ${media.xl} {
    margin-bottom: 0.8375rem;
    font-size: ${FONT_SIZE.xxxxl};
    font-weight: ${FONT_WEIGHT.black};
    line-height: 2.125rem;
  }
`

export const FormCheckbox = styled(BaseCheckbox)`
  display: flex;
  padding-left: 0.15rem;

  .ant-checkbox-checked {
    .ant-checkbox-inner {
      background-color: #25dac5;
    }
  }
  .ant-checkbox-inner {
    border-radius: 3px;
    border: 1px solid #25dac5;
  }

  .ant-checkbox-input {
    transform: scale(1.25rem);
  }
`

export const FormItem = styled(BaseForm.Item)`
  margin-bottom: 0.75rem;
  & .ant-form-item-control-input {
    min-height: 3.25rem;
  }

  & .ant-form-item-explain-error {
    font-size: ${FONT_SIZE.xs};
  }

  & .ant-form-item-label {
    padding: 0px;
  }

  & label {
    color: #959895;
    font-size: ${FONT_SIZE.xs};
    line-height: 1.25rem;
  }

  &.ant-form-item-has-feedback .ant-input-affix-wrapper .ant-input-suffix {
    padding-right: 1.5rem;
  }
`

export const FormInput = styled(CommonInput)`
  color: white;
  background: transparent;
  border-radius: 8px;
  border: 1px solid #335271;
  padding: 12px 12px;
  & input.ant-input {
    background: transparent;
  }

  &::placeholder {
    color: white;
  }
`

export const FormInputPassword = styled(CommonInputPassword)`
  color: white;
  background: transparent;
  border-radius: 8px;
  border: 1px solid #335271;
  padding: 12px 12px;
  & input.ant-input {
    background: transparent;
    color: white;
  }

  & ::placeholder {
    color: white;
  }
`

export const ActionsWrapper = styled(BaseRow)`
  margin-bottom: 2rem;
  min-height: 30px;
`

export const Text = styled.span`
  color: var(--text-main-color);
  font-size: ${FONT_SIZE.xs};
  font-weight: ${FONT_WEIGHT.regular};
`

export const LinkText = styled(Text)`
  text-decoration: underline;
  color: var(--primary-color);
`

export const SubmitButton = styled(ButtonVariant)`
  font-size: ${FONT_SIZE.md};
  font-weight: ${FONT_WEIGHT.semibold};
  width: 30%;
  border-radius: 12px;
  padding: 24px 12px;
  color: black;
`

export const SocialButton = styled(BaseButton)`
  font-size: ${FONT_SIZE.md};
  font-weight: ${FONT_WEIGHT.semibold};
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
  width: 100%;
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
`

export const FooterWrapper = styled.div`
  margin-top: 1.25rem;
  text-align: center;
`

export const BackIcon = styled(LeftOutlined)`
  font-size: 0.75rem;
  margin-right: 0.75rem;
`

export const BackWrapper = styled.div`
  font-size: ${FONT_SIZE.md};
  font-weight: ${FONT_WEIGHT.semibold};
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 1.25rem;
`

export const SocialIconWrapper = styled(BaseImage)`
  cursor: pointer;
  border-radius: ${BORDER_RADIUS_2};
  dislay: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.5s ease !important;
  &:hover {
    transition: all 0.5s ease !important;
    box-shadow: rgba(41, 204, 106, 0.3) 0px 0px 15px 5px,
      rgba(41, 204, 106, 0.2) 0px 5px 5px -2px;
  }
`

export const FormBaseWrapper = styled.div`
  width: 50%;
  padding: 3.75rem;
`

export const BaseFormWrapper = styled.div<{
  minWidth?: '320px' | '400px' | '450px' | '500px' | '600px' | '700px' | '800px'
}>`
  padding-top: 48px;
  width: 100%;
  min-width: ${props => props.minWidth ?? '320px'};
`

export const DescriptionText = styled.div`
  color: white;
  font-size: ${FONT_SIZE.xs};
  font-weight: ${FONT_WEIGHT.regular};
  text-align: center;
`

export const PaddingWrapperVer = styled.div<{ padding?: number }>`
  height: ${props => (props.padding ? `${props.padding}px` : '8px')};
`

export const NormalText = styled.div`
  color: white;
  font-size: 16px;
  font-weight: ${FONT_WEIGHT.regular};
  text-align: center;
`

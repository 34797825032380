/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  SearchOutlined,
} from '@ant-design/icons'
import {
  requestGetModifierById,
  requestPutModifier,
} from 'app/api/product-management/api'
import {
  IModifier,
  IMultipleModifiers,
  IPostModifier,
} from 'app/api/product-management/model'
import { IRestaurant } from 'app/api/restaurant/model'
import R from 'app/assets/R'
import {
  SvgSquareCheckIcon,
  SvgUnCheckedCreateItemIcon,
} from 'app/assets/svg-assets'
import { BaseButton } from 'app/components/common/BaseButton'
import { ButtonVariant } from 'app/components/common/BaseButtonVariant'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseCollapse } from 'app/components/common/BaseCollapse'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { CurrencySupportType } from 'app/components/common/CurrencyPicker/type'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { BaseInput } from 'app/components/common/inputs/BaseInput'
import { InputNumber } from 'app/components/common/inputs/InputNumber'
import { Loading } from 'app/components/common/Loading'
import { IRefModal, ModalComponent } from 'app/components/common/ModalComponent'
import { notificationController } from 'app/controllers/notification-controller'
import { useDebounce, usePagination } from 'app/hook'
import { useGetRestaurantListV2 } from 'app/react-query/hook/restaurant'
import { ProductManagementKeys } from 'app/react-query/query-key/product-management'
import { convertColor } from 'app/styles/themes/themeVariables'
import { t } from 'i18next'
import { isEmpty, isEqual } from 'lodash'
import { BORDER_RADIUS, PADDING } from 'parkway-web-common'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useQueryClient } from 'react-query'
import styled from 'styled-components'
import { HookFormModal } from '../hook'

export const UpdateModal = ({ item }: { item: IModifier }) => {
  const [loading, setLoading] = useState(false)
  const query = useQueryClient()
  const refModal = useRef<IRefModal>(null)
  const [form] = BaseForm.useForm<IPostModifier>()
  const [currency, setCurrency] = useState<CurrencySupportType>('USD')

  const [loadingSave, setLoadingSave] = useState(false)

  const [locationIdsSelected, setLocationIdsSelected] = useState<string[]>([])
  const [priceListsSelected, setPriceListsSelected] = useState<
    IMultipleModifiers[]
  >([{ name: '', price: '' }])

  const openModal = () => {
    refModal.current?.open?.()
  }

  const hideModal = () => {
    refModal.current?.hide?.()
  }

  const getDetail = async () => {
    if (!item?.id) {
      hideModal()
      return
    }

    try {
      setLoading(true)

      const res: IModifier = await requestGetModifierById(item?.id)

      form.setFieldsValue({
        name: res?.name,
        displayName: res?.displayName,
      })

      setPriceListsSelected(
        res?.multipleModifiers ?? item?.multipleModifiers ?? [],
      )
      setLocationIdsSelected(res?.presentAtLocationIds ?? [])
      setCurrency(res?.multipleModifiers?.[0]?.currency ?? 'USD')

      console.log({ res })
    } catch (error) {
      console.log({ error })
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getDetail()
  }, [item])

  const onSave = async (values: IPostModifier) => {
    if (!item?.id) return
    try {
      setLoadingSave(true)
      const res = await requestPutModifier(item?.id, {
        name: values?.name,
        locationIds: locationIdsSelected,
        type: 'Multiple',
        multipleModifiers: priceListsSelected?.map((item, index) => ({
          name: item?.name,
          price: item?.price,
          position: index,
          currency,
        })),
        displayName: values?.displayName,
      })

      if (res) {
        setLocationIdsSelected([])
        setPriceListsSelected([])
        form.resetFields()
        query.invalidateQueries({
          queryKey: ProductManagementKeys.allModifier,
        })

        notificationController.success({
          message: t(R.strings.save_success),
        })

        hideModal()
      }
    } catch (error) {
      console.log({ error })
    } finally {
      setLoadingSave(false)
    }
  }

  return (
    <ModalComponent
      ref={refModal}
      titleModal="Edit modifier"
      renderContent={
        <BaseSpace>
          <BaseForm form={form} onFinish={onSave}>
            <HookFormModal
              currency={currency}
              setCurrency={setCurrency}
              locationIdsSelected={locationIdsSelected}
              setLocationIdsSelected={setLocationIdsSelected}
              priceListsSelected={priceListsSelected}
              setPriceListsSelected={setPriceListsSelected}
            />
          </BaseForm>

          {loading ? <Loading /> : null}
        </BaseSpace>
      }
      footer={
        <div style={{ padding: '0px 20px', width: '100%' }}>
          <ButtonVariant
            onClick={form.submit}
            style={{ width: '100%' }}
            loading={loadingSave}
          >
            <BaseText children={'Edit modifier'} fontWeight="semibold" />
          </ButtonVariant>
        </div>
      }
      rightHeader={
        <BaseCol>
          <CloseOutlined rev={undefined} onClick={hideModal} />
        </BaseCol>
      }
      buttonOpenCustom={
        <BaseButton
          icon={<EditOutlined rev={undefined} />}
          children={'Edit'}
          type="text"
          onClick={openModal}
          style={{ width: '100%', justifyContent: 'flex-start' }}
        />
      }
    />
  )
}

const BaseCollapseWrapper = styled(BaseCollapse)`
  width: 100%;

  .ant-collapse-content {
    border-top: 1px solid ${convertColor.neutralBlack9Color};
    background-color: ${convertColor.backgroundColor};
  }

  .ant-collapse-header {
    background-color: var(--background-color);
    border-radius: ${BORDER_RADIUS}!important;
    align-items: center !important;
  }

  .ant-collapse-header[aria-expanded='true'] {
    border-radius: ${BORDER_RADIUS}!important;
    border-bottom-left-radius: ${BORDER_RADIUS} !important;
    border-bottom-right-radius: ${BORDER_RADIUS} !important;
  }

  .header-content {
    padding: ${PADDING.md};
    background-color: #f9fafb;
  }
`

import { BaseButton } from 'app/components/common/BaseButton'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import BaseText from 'app/components/common/BaseText'
import { isEqual } from 'lodash'
import { PADDING } from 'parkway-web-common'
import { memo, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import * as S from './styles'
import { getItemTabs, TabEnum } from './tabs'
import CategoryTab from './tabs/Category'
import { ItemTabs } from './tabs/Items'
import { Modifiers } from './tabs/Modifier'
import { UpSell } from './tabs/UpSell'

const ProductManagementLayout = () => {
  const { i18n } = useTranslation()
  const [tab, setTab] = useState<string>(TabEnum.CATEGORY)

  const ItemsTabs = useMemo(() => getItemTabs(), [i18n.language])

  const onChange = (key: string) => {
    setTab(key)
  }

  const renderContent = useMemo(() => {
    switch (tab) {
      case TabEnum.CATEGORY:
        return <CategoryTab />
      case TabEnum.ITEM:
        return <ItemTabs />
      case TabEnum.UP_SELL:
        return <UpSell />
      case TabEnum.MODIFIER:
        return <Modifiers />
      default:
        return null
    }
  }, [tab])

  return (
    <S.RootWrapper>
      <BaseRow
        gutter={16}
        align={'middle'}
        justify={'start'}
        wrap={false}
        style={{
          overflowX: 'auto',
        }}
      >
        {ItemsTabs.map(item => {
          const isSelect = isEqual(tab, item.key)
          return (
            <BaseCol key={item.key} onClick={() => onChange(item.key)} xl={4}>
              <ButtonStyled $isSelect={isSelect}>
                <BaseText
                  style={{ color: isSelect ? '#032648' : '#8B8B8B' }}
                  fontWeight="semibold"
                >
                  {item.label}
                </BaseText>
              </ButtonStyled>
            </BaseCol>
          )
        })}
      </BaseRow>

      {renderContent}
    </S.RootWrapper>
  )
}

export default memo(ProductManagementLayout)

export const ButtonStyled = styled(BaseButton)<{
  isVariantOutline?: boolean
  $borderRadius?: string
  $isSelect?: boolean
}>`
  width: 100%;
  border-radius: 6px;
  padding: ${PADDING.xl} ${PADDING.xl};
  background-color: ${props =>
    props.$isSelect ? '#42E5C6' : '#EFEFEF'}; // need change
  transition: all 0.5s ease !important;
  border: 1px solid ${props => (props.$isSelect ? '#42E5C6' : '#EFEFEF')}; // need change
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 36px;
  // shadow #29CC6A40
  ${({ $isSelect }) => {
    if ($isSelect) {
      return 'box-shadow: 0px 4px 10px rgba(41, 204, 106, 0.25);'
    }
  }}
  &:hover {
    transition: all 0.5s ease !important;
    border: 1px solid ${props => (props.$isSelect ? '#42E5C6' : '#EFEFEF')} !important;
  }
`

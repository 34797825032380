export default {
  empowering_kiosk_efficiency: 'Potenciando la Eficiencia del Quiosco',
  streamlined_content_management: 'Gestión de Contenido Optimizada',
  streamlined_content_management_d:
    'Administre y actualice sin esfuerzo el contenido de su quiosco, asegurándose de que esté siempre actualizado y atractivo.',
  remote_kiosk_control: 'Control Remoto del Quiosco',
  remote_kiosk_control_d:
    'Tome el control de sus quioscos desde cualquier lugar, monitoree su rendimiento y realice ajustes sobre la marcha.',
  personalized_recommendations: 'Recomendaciones Personalizadas',
  personalized_recommendations_d:
    'Aproveche los datos del cliente para ofrecer recomendaciones personalizadas de productos y promociones.',

  advanced_reporting: 'Informes Avanzados',
  advanced_reporting_d:
    'Genere informes completos para identificar tendencias, optimizar estrategias y tomar decisiones informadas.',
  personalized_recommendations_and_promotions:
    'Recomendaciones y Promociones Personalizadas',
  personalized_recommendations_and_promotions_d:
    'Desbloqueando Recomendaciones y Promociones Personalizadas en el Sistema de Pedido Rápido de Kiosify',
  revolutionizing_customer_engagement:
    'Revolutionando la Participación del Cliente: Recomendaciones y Promociones Personalizadas de Kiosify',
  revolutionizing_customer_engagement_d:
    'Kiosify aprovecha los datos del cliente para ofrecer recomendaciones personalizadas de productos y promociones. Al analizar el historial de compras, el comportamiento de navegación y la información demográfica, Kiosify puede adaptar sugerencias a las preferencias e intereses de cada cliente individual. Este enfoque personalizado mejora la satisfacción del cliente al mostrar productos relevantes y ofertas que se alinean con sus gustos.',
  sign_up_for_a_free_trial: 'Regístrate para una prueba gratuita',
  start_14_days_trail: 'Comienza la prueba de 14 días',
  home_subcribe:
    'Suscríbete a nuestro boletín y recibe las últimas actualizaciones',
  enter_your_email_address: 'Ingresa tu dirección de correo electrónico',
  footer_kiosify: 'Kiosify: Eleva tu experiencia del cliente con autoservicio',
  contact_us: 'Contáctenos',

  home_slogan: 'Kiosify: El Futuro de la Gestión de Quioscos',
  home_slogan_d: 'El Sistema de AutoPedido Perfecto para Tu Negocio',
  create_your_kiosify_account: 'Crea tu cuenta en Kiosify',
  see_more: 'Ver más',
  find: 'Buscar',
  address: 'Dirección',
  language: 'Idioma',
  vietnamese: 'Tiếng Việt',
  english: 'Inglés',
  foreign_language: 'Idioma Extranjero',
  home: 'Inicio',
  phone: 'Teléfono',
  email: 'Correo Electrónico',
  send: 'Enviar',
  next: 'Siguiente',
  back: 'Atrás',
  send_code: 'Enviar código',
  login: 'Iniciar sesión',
  user_name: 'Nombre de usuario',
  password: 'Contraseña',
  login_success: 'Inicio de sesión exitoso',
  save_success: 'Guardado exitoso',
  forgot_password: 'Olvidé mi contraseña',
  require_field: "Por favor, ingresa '{{field}}', este campo es obligatorio",
  regex_field: "Por favor, ingresa '{{field}}' en el formato correcto",
  minimum_field: 'Por favor, ingresa al menos {{count}} caracteres',
  maximum_field: 'Por favor, ingresa un máximo de {{count}} caracteres',
  register: 'Registrarse',
  confirm_password: 'Confirmar contraseña',
  verify_by_phone: 'Verificar por teléfono',
  verify_by_email: 'Verificar por correo electrónico',
  code_verify: 'Código de verificación',
  message_verify_phone:
    'Por favor, verifica el mensaje de Zalo del número de teléfono {{phone}} para obtener el código de verificación.',
  re_send_verify_code: 'Reenviar código de verificación',
  resend: 'Reenviar',
  time_resend_verify_code: 'Reenviar después de {{timer}} segundos',
  verify: 'Verificar',
  logout: 'Cerrar sesión',
  full_name: 'Nombre completo',
  regex_name: 'El nombre debe estar en el formato correcto',
  regex_user_name:
    'El nombre de usuario debe estar en el formato correcto (sin espacios)',
  using_by: 'Utilizado por',
  require_email: 'Por favor, ingresa el correo electrónico',
  require_contact_name: 'Por favor, ingresa el nombre de contacto',
  regex_email: 'Por favor, ingresa un formato de correo electrónico válido',
  regex_phone: 'Por favor, ingresa un formato de número de teléfono válido',
  send_contact_success:
    'Información de contacto enviada exitosamente, gracias.',
  please_enter_correct_format: 'Por favor, ingresa en el formato correcto',
  medical_examination: 'Examen médico',
  human_resources: 'Recursos humanos',
  finance: 'Finanzas',
  inventory_management: 'Gestión de inventario',
  purchasing_management: 'Gestión de compras',
  customer_care: 'Atención al cliente',
  clinic_management: 'Gestión de clínicas',
  company: 'Empresa',
  organizational_chart: 'Organigrama',
  announcement: 'Anuncio',
  internal_news: 'Noticias internas',
  onboarding: 'OnBoard',
  report: 'Informe',
  marketing: 'Marketing',
  accounting: 'Contabilidad',
  work_schedule: 'Horario de trabajo',
  attendance_record: 'Registro de asistencia',
  mailbox: 'Buzón',
  my_requests: 'Mis solicitudes',
  my_tasks: 'Mis tareas',
  my_income: 'Mis ingresos',
  dashboard: 'Tablero',
  profile: 'Perfil',
  read_all: 'Marcar todo como leído',
  view_all: 'Ver todo',
  remember_me: 'Recuérdame',
  parkway_dental: 'Parkway Dental',
  description_parkway_auth:
    'Parkway Dental es un sistema dental integral y profesional, especialmente en ortodoncia e implantes, que satisface completamente las necesidades dentales de los clientes.',
  development: 'En desarrollo',
  coming_soon: 'Próximamente',
  customers: 'Clientes',
  region: 'Región',
  revenue: 'Ingresos',

  basic_package: {
    title: 'Básico',
    price: '$50/mes',
    benefit_1: 'Hasta 3 Quioscos',
    benefit_2: 'Pedidos Ilimitados',
    benefit_3: 'Un Gerente de Cuenta Dedicado',
    benefit_4: 'Soporte 24/7',
    benefit_5: 'Acceso a nuestro panel en línea',
    benefit_6: 'Informes y Análisis',
  },
  premium_package: {
    title: 'Premium',
    price: '$80/mes',
    benefit_1: 'Hasta 10 Quioscos',
    benefit_2: 'Pedidos Ilimitados',
    benefit_3: 'KDS Gratis',
    benefit_4: 'Soporte 24/7',
    benefit_5: 'Branding Personalizable',
    benefit_6: 'Soporte Multiubicación',
    benefit_7: 'Soporte de Marketing',
  },
  smart_saving_package: {
    title: 'Ahorro Inteligente',
    price: '$30/mes/1 quiosco',
    benefit_1: 'Tarifas de comisión reducidas',
    benefit_2: 'Soporte prioritario al cliente',
    benefit_3: 'Acceso a funciones exclusivas',
  },
  time: {
    today: 'Hoy',
    this_week: 'Esta semana',
    this_month: 'Este mes',
    days: 'Días',
    start_time_kiosk: 'Hora de inicio',
    end_time_kiosk: 'Hora de finalización',
    monday: 'Lunes',
    tuesday: 'Martes',
    wednesday: 'Miércoles',
    thursday: 'Jueves',
    friday: 'Viernes',
    satuday: 'Sábado',
    sunday: 'Domingo',
  },
  error_message: {
    service_package: 'Por favor, selecciona un paquete de servicio',
    select_location: 'Por favor, selecciona una ubicación',
    select_payment_method: 'Por favor, selecciona un método de pago',
    select_kiosk: 'Por favor, selecciona un quiosco',
  },
  success_message: {
    create_kiosk: 'Quiosco creado con éxito',
    update_kiosk: 'Quiosco actualizado con éxito',
  },
  table: {
    customer: 'Cliente',
    time: 'Hora',
    date: 'Fecha',
    order: 'Orden',
    total_payment: 'Pago Total',
    payment_method: 'Método de Pago',
    status: 'Estado',
    type: 'Tipo',
    name: 'Nombre',
    location: 'Ubicación',
    kiosk_code: 'Código del Quiosco',
    product_name: 'Nombre del Producto',
    price: 'Precio',
    item_name: 'Nombre del Ítem',
    category: 'Categoría',
    variation: 'Variación',
    up_sell_item: 'Ítem de Venta Adicional',
    up_sell_at: 'Vender a',
    quantity: 'Cantidad',
    action: 'Acción',
    options: 'Opciones',
    discount_name: 'Nombre del Descuento',
    discount_type: 'Tipo de Descuento',
    discount_apply: 'Aplicar Descuento',
    item_price: 'Precio del Ítem',
    active: 'Activo',
    bestseller: 'Más Vendido',
  },
  payment_method: {
    cash: 'Efectivo',
    credit_card: 'Tarjeta de crédito',
    gift_card: 'Tarjeta de regalo',
  },
  dashboard_common: {
    most_ordered: 'Más Ordenado',
    most_type_order: 'Tipo de Orden Más Común',
    total_revenue: 'Ingresos Totales',
    pending_revenue: 'Ingresos Pendientes',
    actual_revenue: 'Ingresos Reales',
    compared_to_last_month: 'Comparado con el mes pasado',
    today_revenue: 'Ingresos de hoy',
    branch_name: 'Nombre de la Sucursal',
    overall_today_summary: 'Resumen General de Hoy',
    area: 'Área',
    ordinal_number: 'Número Ordinal',
    clinic: 'Clínica',
    dishes_ordered: 'Platos Ordenados',
    view_all: 'Ver Todo',
    order_report: 'Informe de Pedidos',
  },
  reports_common: {
    title: 'Informes',
    total_revenue: 'Ingresos Totales',
    total_dish_ordered: 'Total de Platos Ordenados',
    total_customer: 'Total de Clientes',
    order_report: 'Informe de Pedidos',
    chart_header_realtime: 'Tiempo Real',
    chart_header_daily: 'Diario',
    chart_header_weekly: 'Semanal',
    chart_header_monthly: 'Mensual',
    chart_header_yearly: 'Anual',
    chart_label_monday: 'Lun',
    chart_label_tuesday: 'Mar',
    chart_label_wednesday: 'Mié',
    chart_label_thursday: 'Jue',
    chart_label_friday: 'Vie',
    chart_label_satuday: 'Sáb',
    chart_label_sunday: 'Dom',
    chart_label_january: 'Ene',
    chart_label_february: 'Feb',
    chart_label_march: 'Mar',
    chart_label_april: 'Abr',
    chart_label_may: 'May',
    chart_label_june: 'Jun',
    chart_label_july: 'Jul',
    chart_label_august: 'Ago',
    chart_label_september: 'Sep',
    chart_label_october: 'Oct',
    chart_label_november: 'Nov',
    chart_label_december: 'Dic',
    chart_serrie_total_gross: 'Total Bruto',
    chart_serrie_total_net: 'Total Neto',
  },
  input_placeholder: {
    search: 'Buscar alimentos, café, etc.',
    search_tab: 'Buscar pestaña...',
    select_location: 'Seleccionar ubicación',
    order_note: 'Nota del pedido...',
    search_only: 'Buscar',
    select_category: 'Seleccionar categoría',
    device_name: 'Nombre del dispositivo',
    search_printer: 'Buscar impresora',
    search_profile: 'Buscar perfil',
    search_your_kiosk: 'Buscar tu quiosco',
    write_your_content_here: 'Escribe tu contenido aquí.....',
    select_time: 'Seleccionar hora',
  },
  input_validate: {
    required_kiosk_name: '¡Por favor, ingresa el nombre de tu quiosco!',
  },
  information: {
    branch: 'Sucursal',
    location: 'Ubicación',
    phone_no: 'Número de Teléfono',
    email: 'Correo Electrónico',
  },
  kiosk: {
    add_your_kiosk: 'Agregar Tu Quiosco',
    edit_your_kiosk: 'Editar Tu Quiosco',
    add_new_kiosk: 'Agregar Nuevos Quioscos',
    kiosk_name: 'Nombre del Quiosco',
    kiosk_code: 'Código del Quiosco',
    your_subscription: 'Tu Suscripción',
    payment_method: 'Método de Pago',
    redeem_loyalty: 'Canjear Lealtad',
    redeem_discount: 'Canjear Descuento',
    open_time: 'Horario de Apertura',
    start_popups_when_the_store_is_closed:
      'Iniciar Popups cuando la tienda está cerrada',
    message: 'Mensaje',
  },
  notification_status: {
    success: 'Éxito',
    info: 'Información',
    warning: 'Advertencia',
    error: 'Error',
  },
  product_management: {
    category: 'Categoría',
    items: 'Ítems',
    up_sell: 'Venta Adicional',
    modifiers: 'Modificadores',
    discounts: 'Descuentos',
    delete_item_up_sell: 'Eliminar ítem de venta adicional',
    delete_item_up_sell_description: '¿Deseas eliminar el ítem?',
    create_up_sell: 'Crear Venta Adicional',
    menu_price: 'Precio en el Menú',
    discount_price: 'Precio con Descuento',
    item_level: 'Nivel del Ítem',
    order_level: 'Nivel del Pedido',
  },
  form_item: {
    select_up_sell_at_t: 'Vender en',
    select_up_sell_at_p: 'Seleccionar Venta Adicional en',
    select_item_t: 'Seleccionar Ítem',
    select_item_p: 'Seleccionar Ítem',
    select_item_variation_t: 'Variación',
    select_item_variation_p: 'Seleccionar Variación del Ítem',
    select_price_t: 'Seleccionar Precio',
    select_price_p: 'Seleccionar Precio',
    price_t: 'Precio',
    select_item_apply_t: 'Aplicar Ítem',
    select_item_apply_p: 'Seleccionar Ítem a Aplicar',
  },
  setting_menu: {
    appearance_t: 'Apariencia',
    appearance_d: 'Modo oscuro y claro, Tamaño de fuente',
    your_restaurant_t: 'Tu Restaurante',
    your_restaurant_d: 'Modo oscuro y claro, Tamaño de fuente',
    your_kiosk_t: 'Tus Quioscos',
    your_kiosk_d: 'Verifica la información de tu Quiosco',
    product_management_t: 'Gestión de Productos',
    product_management_d: 'Administra tus productos, precios, etc.',
    kds_t: 'Configuración de KDS',
    kds_d: 'Modo oscuro y claro, Tamaño de fuente',
    backup_t: 'Respaldar',
    backup_d: 'Vuelve a tu actualización anterior',
    your_information_t: 'Tu Información',
    your_information_d:
      'Descubre más sobre tu nombre de marca, impresora, lectores, ...',
    sync_data_from_square_t: 'Sincronizar Datos Desde Square',
    sync_data_from_square_d: 'Sincroniza tus datos desde Square inmediatamente',
  },
  your_infomation: {
    km_stand: 'KM Stand',
    km_printers: 'Impresoras KM',
    km_readers: 'Lectores KM',
    device_id: 'ID del dispositivo',
    get_help: 'Obtener ayuda',
    printers: 'Impresoras',
    profiles: 'Perfiles',
    connect_printer: 'Conectar impresora',
    no_printer_connected: 'No hay impresoras conectadas',
    troubleshoot: 'Solucionar problemas',
    troubleshoot_d: '¿Esperando que se conecte una impresora?',
    create_profile: 'Crear perfil',
    no_profile: 'Sin perfil',
    printer_defaults: 'Configuración predeterminada de la impresora',
    printer_defaults_d:
      'Seleccione los elementos que desea imprimir con este perfil. Todas las impresoras que hereden este perfil solo imprimirán esos elementos.',
    receipts: 'Recibos',
    in_person_order_tickets: 'Tickets de pedidos en persona',
    online_order_tickets: 'Tickets de pedidos en línea',
    order_ticket_stubs: 'Recortes de tickets de pedido',
    void_tickets: 'Tickets anulados',
    use_this_printer_for_receipts: 'Usar esta impresora para recibos',
    use_this_printer_for_receipts_d:
      'Cuando está activado, esta impresora también imprimirá facturas, informes y abrirá cajones de dinero.',
    no_device: 'No hay dispositivo',
  },
  about: 'Acerca de',
  general: 'General',
  connect_and_manage: 'Conectar y gestionar',
  add_new: 'Agregar nuevo',
  add_more_food: 'Agregar más alimentos',
  key_all_label: 'Todos',
  restaurant: 'Restaurante',
  your_restaurant: 'Tu restaurante',
  see_your_restaurant: 'Ver tu restaurante',
  background: 'Fondo',
  text: 'Texto',
  button: 'Botón',
  custom: 'Personalizado',
  see_all: 'Ver todo',
  review: 'Reseña',
  restaurant_name: 'Nombre del restaurante',
  fake_time: 'Sábado, 18 Nov 2024',
  fake_tab: {
    hot_dishes: 'Platos Calientes',
    cold_dishes: 'Platos Fríos',
    soup: 'Sopa',
    grill: 'Parrilla',
    appetizer: 'Aperitivo',
    dessert: 'Postre',
  },
  choose_dishes: 'Elegir Platos',
  bowls_available: 'Boles disponibles',
  item: 'Ítem',
  qty: 'Cantidad',
  price: 'Precio',
  fake_order_note: 'Por favor, solo un poco picante.',
  discount: 'Descuento',
  sub_total: 'Subtotal',
  continue_to_payment: 'Continuar con el Pago',
  save: 'Guardar',
  dark_mode: 'Modo Oscuro',
  light_mode: 'Modo Claro',
  color: {
    black: 'Negro',
    white: 'Blanco',
    light_red: 'Rojo Claro',
    light_green: 'Verde Claro',
    light_blue: 'Azul Claro',
    light_orange: 'Naranja Claro',
    light_gray: 'Gris Claro',
    light_cyan: 'Cian Claro',
  },
  label_common: {
    for_here: 'Para aquí',
    to_go: 'Para llevar',
    pick_up: 'Recoger',
    late: 'Tarde',
    on_time: 'A tiempo',
    caution: 'Precaución',
    small: 'Pequeño',
    medium: 'Mediano',
    large: 'Grande',
    extra_large: 'Extra grande',
  },
  kds: {
    standard_screen_option: 'OPCIÓN DE PANTALLA ESTÁNDAR',
    default_t: 'Predeterminado',
    default_d:
      'De arriba a abajo, de izquierda a derecha. Las órdenes se llenan donde encajan.',
    classic_t: 'Clásico',
    classic_d:
      'De arriba a abajo, de izquierda a derecha. Las órdenes se llenan donde encajan.',
    caution_time: 'Tiempo de precaución',
    late_time: 'Tiempo de retraso',
    order_received_sms: 'SMS de Orden Recibida',
    order_bumped_from_kds_screen_sms: 'SMS de Orden Quitada de la Pantalla KDS',
    order_is_ready_for_pick_up_sms: 'SMS de Orden Lista para Recoger',
    transition_time: 'TIEMPOS DE TRANSICIÓN',
    font_and_color: 'FUENTES Y COLORES',
    font_size: 'Tamaño de fuente',
    status_color: 'Colores de estado',
    order_type: 'Tipo de orden',
    mesaging: 'MENSAJERÍA',
  },
  min: 'min',
  on: 'Activado',
  off: 'Desactivado',
  see_with: 'Ver con',
  import: 'Importar',
  week: 'Semana',
  download_successful: 'Descarga exitosa',
  import_file_successful: 'Importación de archivo exitosa',
  import_file_error: 'Error al importar el archivo',
  choose_week: 'Elegir semana',
  notification: 'Notificación',
  not_done: 'No hecho',
  done: 'Hecho',
  in_progress: 'En progreso',
  see_detail: 'Ver detalle',
  mail: 'Correo',
  new_mail: 'Nuevo correo',
  important: 'Importante',
  show_less: 'Mostrar menos',
  collapse: 'Colapsar',
  last_time_update: 'Última actualización',
  service: 'Servicio',
  month: 'Mes',
  import_file: 'Importar archivo',
  cancel: 'Cancelar',
  export_excel: 'Exportar a Excel',
  download_example_import_file: 'Descargar ejemplo de archivo de importación',
  solutions: 'Soluciones',
  vision: 'Visión',
  programs: 'Programas',
  blog: 'Blog',
  product: 'Producto',
  pricing: 'Precios',
  resource: 'Recursos',
  customer_login: 'Inicio de sesión del cliente',
  sign_up: 'Registrarse',
  watch_free_demo: 'Ver demostración gratuita',
  sign_in_to_stay_connected: 'Inicia sesión para mantenerte conectado.',
  or_sign_in_with_other_accounts: 'o inicia sesión con otras cuentas?',
  dont_have_an_account: '¿No tienes una cuenta?',
  click_here_to_sign_up: 'Haz clic aquí para registrarte.',
  sign_in: 'Iniciar sesión',
  create_your_hope_ui_account: 'Crea tu cuenta Hope UI',
  first_name: 'Nombre',
  last_name: 'Apellido',
  phone_no: 'Número de teléfono',
  confirm_password_incorrect: 'Contraseña de confirmación incorrecta',
  i_agree_with_the_terms_of_use: 'Estoy de acuerdo con los términos de uso',
  or_sign_up_with_other_accounts: 'o regístrate con otras cuentas?',
  already_have_an_account: '¿Ya tienes una cuenta?',
  reset: 'Restablecer',
  reset_password: 'Restablecer contraseña',
  enter_your_email_address_and_we_ll_send_you_an_email_with_instructions_to_reset_your_password:
    'Ingresa tu dirección de correo electrónico y te enviaremos un correo electrónico con instrucciones para restablecer tu contraseña',
  back_to_home: 'Volver a la página de inicio',
  back_to_login: 'Volver a iniciar sesión',
  message_verify_email:
    'Se ha enviado un correo electrónico a tu correo electrónico@dominio.com. Por favor, revisa tu correo electrónico de la empresa y haz clic en el enlace incluido para restablecer tu contraseña.',
  success: 'Éxito',
  subcribe_now: 'Suscríbete ahora',
  error: 'Error',
  admin: 'Administrador',
  settings: 'Configuraciones',
  not_found: 'No encontrado',
  spicy_seasoned_seafood_noodles: 'Fideos de mariscos condimentados picantes',
  salted_pasta_with_mushroom_sauce: 'Pasta salada con salsa de champiñones',
  beef_dumpling_in_hot_and_sour_soup:
    'Empanadas de carne en sopa agria y picante',
  healthy_noodle_with_spinach_leaf: 'Fideos saludables con hojas de espinaca',
  hot_spicy_fried_rice_with_omelet: 'Arroz frito picante con tortilla',
  spicy_instant_noodle_with_special_omelette:
    'Fideos instantáneos picantes con tortilla especial',
  spicy_seasoned_seafood_noodles_short:
    'Fideos de mariscos condimentados picantes...',
  salted_pasta_with_mushroom_sauce_short:
    'Pasta salada con salsa de champiñones...',
  spicy_instant_noodle_with_special_omelette_short:
    'Fideos instantáneos picantes con tortilla...',
  healthy_noodle_with_spinach_leaf_short:
    'Fideos saludables con hojas de espinaca...',
  select_item_for_upsells: 'Seleccionar artículo para ventas adicionales',
  recommended_foods: 'Alimentos recomendados',
  ex: 'Ej.',
  spicy_seasoned_seafood_noodles_strawberry:
    'Fideos de mariscos condimentados picantes, Fresa',
  account_verification: 'Verificación de cuenta',
  email_verification: 'Verificación de correo electrónico',
  account_verification_d1:
    'Te hemos enviado un código temporal de inicio de sesión de 6 dígitos a',
  account_verification_d2:
    'Ingresa este código para iniciar sesión en tu cuenta.',
  didnt_receive_the_code: '¿No recibiste el código?',
  resend_code_in: 'Reenviar código en',
  resend_code: 'Reenviar código',
  account_verified: 'Cuenta verificada',
  didnt_sync_data: '¿No se sincronizaron los datos?',
  click_here_to_connect_square_account:
    'Haz clic aquí para conectar la cuenta de Square',
  change_password: 'Cambiar contraseña',
  card: 'Tarjeta',
  cardholder_name: 'Nombre del titular de la tarjeta',
  please_input_cardholder_name:
    'Por favor, ingresa el nombre del titular de la tarjeta.',
  cardholder_name_must_be_at_least_6_characters:
    'El nombre del titular de la tarjeta debe tener al menos 6 caracteres',
  start_subscription: 'Iniciar suscripción',
  order_summary: 'Resumen del pedido',
  Plane: 'Plano',
  subscription_monthly: 'Suscripción (mensual)',
  fourteen_days_free_trail: '14 días de prueba gratuita',
  subtotal: 'Subtotal',
  taxes_if_applicable: 'Impuestos (si corresponde)',
  total: 'Total',
  save_this_card_for_future_use: 'Guardar esta tarjeta para uso futuro.',
  subcribe_for_free: 'Suscribirse gratis',
  language_option: {
    en: 'Inglés',
    vi: 'Vietnamita',
    ja: 'Japonés',
    es: 'Español',
    fr: 'Francés',
    zh: 'Chino',
  },
  feature: 'Característica',
  before_5_minutes: 'Antes de 5 minutos',
  upload: 'Subir',
  upload_your_image: 'Subir tu imagen',
  confirm: 'Confirmar',
  you_sync_data_success: 'Ha sincronizado los datos correctamente',
  thank_you_for_interest:
    'Gracias por su interés en Kiosify, nos pondremos en contacto pronto.',
}

/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { CloseOutlined } from '@ant-design/icons'
import { requestPostItems } from 'app/api/product-management/api'
import {
  IPostItems,
  IVariationProductManagement,
} from 'app/api/product-management/model'
import R from 'app/assets/R'
import { ButtonVariant } from 'app/components/common/BaseButtonVariant'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { IRefModal, ModalComponent } from 'app/components/common/ModalComponent'
import { notificationController } from 'app/controllers/notification-controller'
import { ProductManagementKeys } from 'app/react-query/query-key/product-management'
import { handleUploadFileGetId } from 'app/service/general/service'
import { t } from 'i18next'
import { useRef, useState } from 'react'
import { useQueryClient } from 'react-query'
import { HookFormModal } from '../hook'
import { CurrencySupportType } from 'app/components/common/CurrencyPicker/type'

interface IForm extends IPostItems {
  image?: string
}

export const CreateModal = () => {
  const query = useQueryClient()
  const refModal = useRef<IRefModal>(null)
  const [form] = BaseForm.useForm<IForm>()

  const [imageUrl, setImageUrl] = useState<string | undefined>(undefined)
  const [fileUploaded, setFileUploaded] = useState<File | undefined>(undefined)
  const [currency, setCurrency] = useState<CurrencySupportType>('USD')

  const [loadingSave, setLoadingSave] = useState(false)
  const [includeTax, setIncludeTax] = useState(false)

  const [locationIdsSelected, setLocationIdsSelected] = useState<string[]>([])
  const [modifierIdsSelected, setModifierIdsSelected] = useState<string[]>([])
  const [variationsSelected, setVariationsSelected] = useState<
    IVariationProductManagement[]
  >([{ name: '', price: '0' }])

  const openModal = () => {
    refModal.current?.open?.()
  }

  const hideModal = () => {
    refModal.current?.hide?.()
  }

  const onSave = async (values?: IForm) => {
    try {
      setLoadingSave(true)

      const imageId = await handleUploadFileGetId(fileUploaded)
      if (imageId) {
        const res = await requestPostItems({
          categoryId: values?.categoryId,
          description: values?.description,
          name: values?.name,
          imageId,
          itemVariations: variationsSelected?.map(item => ({
            name: item?.name,
            price: item?.price,
            currency,
          })),
          locationIds: locationIdsSelected,
          modifierListIds: modifierIdsSelected,
          displayName: values?.displayName,
          taxIncluded: includeTax,
          taxRate: values?.taxRate,
        })

        if (res) {
          setLocationIdsSelected([])
          setModifierIdsSelected([])
          setVariationsSelected([])
          setImageUrl(undefined)
          form.resetFields()
          query.invalidateQueries({
            queryKey: ProductManagementKeys.allItemProductManagement,
          })

          notificationController.success({
            message: t(R.strings.save_success),
          })

          hideModal()
        }
      }
    } catch (error) {
      console.log({ error })
    } finally {
      setLoadingSave(false)
    }
  }

  return (
    <ModalComponent
      ref={refModal}
      titleModal="Add Item"
      renderContent={
        <BaseSpace>
          <BaseForm form={form} onFinish={onSave}>
            <HookFormModal
              imageUrl={imageUrl}
              setImageUrl={setImageUrl}
              fileUploaded={fileUploaded}
              setFileUploaded={setFileUploaded}
              currency={currency}
              setCurrency={setCurrency}
              includeTax={includeTax}
              setIncludeTax={setIncludeTax}
              locationIdsSelected={locationIdsSelected}
              setLocationIdsSelected={setLocationIdsSelected}
              modifierIdsSelected={modifierIdsSelected}
              setModifierIdsSelected={setModifierIdsSelected}
              variationsSelected={variationsSelected}
              setVariationsSelected={setVariationsSelected}
            />
          </BaseForm>
        </BaseSpace>
      }
      footer={
        <div style={{ padding: '0px 20px', width: '100%' }}>
          <ButtonVariant
            onClick={form.submit}
            style={{ width: '100%' }}
            loading={loadingSave}
          >
            <BaseText children={'Add Item'} fontWeight="semibold" />
          </ButtonVariant>
        </div>
      }
      rightHeader={
        <BaseCol>
          <CloseOutlined rev={undefined} onClick={hideModal} />
        </BaseCol>
      }
      buttonOpenCustom={
        <ButtonVariant
          children={<BaseText children={'Add Item'} fontWeight="semibold" />}
          onClick={openModal}
          style={{ width: '100%' }}
        />
      }
    />
  )
}

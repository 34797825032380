import R from 'app/assets/R'
import { BaseButton } from 'app/components/common/BaseButton'
import { ButtonVariant } from 'app/components/common/BaseButtonVariant'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseImage } from 'app/components/common/BaseImage'
import { BaseRow } from 'app/components/common/BaseRow'
import {
  AUTH_SIGN_UP_PATH,
  AUTH_SPLASH_PATH,
  DASHBOARD_PATH,
} from 'app/components/router/route-path'
import { PaddingMain } from 'app/constant'
import { useNavigateHook } from 'app/hook'
import { useAppDispatch, useAppSelector } from 'app/redux/hooks'
import { setCurrentPath } from 'app/redux/slices/pathSlice'
import { isEqual } from 'lodash'
import { BORDER_RADIUS, colors, PADDING } from 'parkway-web-common'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { LanguagePicker } from '../components/SettingsDropdown/settings-overlay/LanguagePicker'
import { DataHeaderMenu } from '../data'
import * as S from '../styles'

interface DesktopHeaderProps {
  isTwoColumnsLayout: boolean
}

export const DesktopHeader: React.FC<DesktopHeaderProps> = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const path = useAppSelector(state => state.path).path

  const { navigateAuthPath } = useNavigateHook()

  const isFocusPath = useCallback(
    (newPath: string) => {
      return isEqual(path, newPath)
    },
    [path],
  )

  return (
    <S.HeaderWrapper
      justify="space-between"
      align="middle"
      style={{ paddingLeft: PaddingMain.Desktop.defaultHz }}
    >
      <BaseCol xl={6}>
        <BaseRow align={'middle'} gutter={15}>
          <BaseCol>
            <BaseImage
              width={120}
              src={R.images.ic_new_logo}
              style={{ borderRadius: BORDER_RADIUS, cursor: 'pointer' }}
              preview={false}
              onClick={() => dispatch(setCurrentPath(DASHBOARD_PATH))}
            />
          </BaseCol>
        </BaseRow>
      </BaseCol>

      <BaseCol xl={10}>
        <BaseRow align={'middle'}>
          {DataHeaderMenu.map((item, index) => {
            return (
              <BaseCol key={`${index} ${item?.id}`} xl={5}>
                <S.ButtonPath
                  onClick={() => {
                    dispatch(setCurrentPath(item?.path))
                  }}
                  isFocus={isFocusPath(item.path)}
                >
                  {t(item.titleKeyI18n)?.toUpperCase()}
                </S.ButtonPath>
              </BaseCol>
            )
          })}
        </BaseRow>
      </BaseCol>
      <BaseCol xl={5}>
        <BaseRow align={'middle'} gutter={10} justify={'space-around'}>
          <BaseCol>
            <ButtonLogin onClick={() => navigateAuthPath(AUTH_SPLASH_PATH)}>
              {t(R.strings.login).toUpperCase()}
            </ButtonLogin>
          </BaseCol>
          <BaseCol>
            <ButtonVariant
              $colorText={colors.black}
              onClick={() => navigateAuthPath(AUTH_SIGN_UP_PATH)}
            >
              {t(R.strings.sign_up).toUpperCase()}
            </ButtonVariant>
          </BaseCol>
          <BaseCol>
            <LanguagePicker />
          </BaseCol>
        </BaseRow>
      </BaseCol>
    </S.HeaderWrapper>
  )
}

export const ButtonLogin = styled(BaseButton)<{
  isVariantOutline?: boolean
  $borderRadius?: string
  $colorText?: string
}>`
  border-radius: ${PADDING.xxs};
  padding: ${PADDING.xl} ${PADDING.xl};
  transition: all 0.5s ease !important;
  color: ${colors.black};
  border: 1px solid transparent;
  &:hover {
    transition: all 0.5s ease !important;
    color: #42e5c6 !important;
    border: 1px solid #42e5c6 !important;
  }
`

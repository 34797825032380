import { ApiClient, ApiClientV2 } from 'app/service/api-service'
import { ProductManagementApiEndpoint } from './contant'
import {
  IParamsGetVariationByItemId,
  IParamsList,
  IParamsListV2,
  IParamsUpdateStatus,
  IPayloadChangePositionCate,
  IPostCategory,
  IPostItems,
  IPostModifier,
  IPostUpSell,
} from './model'

// category start
export const requestGetCategoryList = (body: IParamsListV2) =>
  ApiClientV2.Post({
    url: ProductManagementApiEndpoint.GET_CATEGORY_LIST,
    body,
  })
export const requestPostCategoryList = (body: IPostCategory) =>
  ApiClientV2.Post({
    url: ProductManagementApiEndpoint.CATEGORY_V2,
    body,
  })
export const requestUpdateCategoryList = (id: string, body: IPostCategory) =>
  ApiClientV2.Put({
    url: `${ProductManagementApiEndpoint.CATEGORY_V2}/${id}`,
    body,
  })

export const requestDeleteCategoryList = (id: string) =>
  ApiClientV2.Delete({
    url: `${ProductManagementApiEndpoint.CATEGORY_V2}/${id}`,
    body: {},
  })

export const requestChangePositionCategory = (
  body: IPayloadChangePositionCate,
) =>
  ApiClientV2.Put({
    url: ProductManagementApiEndpoint.RE_ODER_POSITION_CATEGORY,
    body,
  })

// end category

// start items
export const requestGetItemList = (body?: IParamsListV2) =>
  ApiClientV2.Post({
    url: ProductManagementApiEndpoint.GET_ITEM_LIST,
    body,
  })

export const requestUpdateItemById = (params: IParamsUpdateStatus) =>
  ApiClient.Put({
    url: `${ProductManagementApiEndpoint.ITEM}${params.id}/detail/`,
    body: { ...params },
  })

export const requestGetItemById = (id: string) =>
  ApiClientV2.Get({
    url: `${ProductManagementApiEndpoint.ITEMS_V2}/${id}`,
    params: {},
  })

export const requestPostItems = (body: IPostItems) =>
  ApiClientV2.Post({
    url: ProductManagementApiEndpoint.ITEMS_V2,
    body,
  })

export const requestPutItems = (id: string, body: IPostItems) =>
  ApiClientV2.Put({
    url: `${ProductManagementApiEndpoint.ITEMS_V2}/${id}`,
    body,
  })

export const requestDeleteItems = (id: string) =>
  ApiClientV2.Delete({
    url: `${ProductManagementApiEndpoint.ITEMS_V2}/${id}`,
    body: {},
  })
// end items

export const requestGetVariationByItemId = (
  params: IParamsGetVariationByItemId,
) =>
  ApiClient.Get({
    url: `${ProductManagementApiEndpoint.ITEM}${params.id}${ProductManagementApiEndpoint.GET_ITEM_VARIATION}`,
    params,
  })

// start modifier
export const requestGetModifierList = (body: IParamsListV2) =>
  ApiClientV2.Post({
    url: ProductManagementApiEndpoint.GET_MODIFIER_LIST,
    body,
  })

export const requestGetModifierById = (id?: string) =>
  ApiClientV2.Get({
    url: `${ProductManagementApiEndpoint.MODIFIER_V2}/${id}`,
    params: {},
  })

export const requestPostModifier = (body: IPostModifier) =>
  ApiClientV2.Post({
    url: ProductManagementApiEndpoint.MODIFIER_V2,
    body,
  })

export const requestPutModifier = (id: string, body: IPostModifier) =>
  ApiClientV2.Put({
    url: `${ProductManagementApiEndpoint.MODIFIER_V2}/${id}`,
    body,
  })

export const requestDeleteModifier = (id: string) =>
  ApiClientV2.Delete({
    url: `${ProductManagementApiEndpoint.MODIFIER_V2}/${id}`,
    body: {},
  })

export const requestUpdateModifierById = (params: IParamsUpdateStatus) =>
  ApiClientV2.Put({
    url: `${ProductManagementApiEndpoint.MODIFIER}${params.id}/`,
    body: { status: params.status },
  })

// end modifier

// start modifier
export const requestGetUpSellListV2 = (body: IParamsListV2) =>
  ApiClientV2.Post({
    url: ProductManagementApiEndpoint.GET_UP_SELL_LIST,
    body,
  })

export const requestGetUpSellById = (id?: string) =>
  ApiClientV2.Get({
    url: `${ProductManagementApiEndpoint.UP_SELL_V2}/${id}`,
    params: {},
  })

export const requestPostUpSell = (body: IPostUpSell) =>
  ApiClientV2.Post({
    url: ProductManagementApiEndpoint.UP_SELL_V2,
    body,
  })

export const requestPutUpSell = (id: string, body: IPostUpSell) =>
  ApiClientV2.Put({
    url: `${ProductManagementApiEndpoint.UP_SELL_V2}/${id}`,
    body,
  })

export const requestDeleteUpSell = (id: string) =>
  ApiClientV2.Delete({
    url: `${ProductManagementApiEndpoint.UP_SELL_V2}/${id}`,
    body: {},
  })

// end modifier

export const requestGetTaxList = (params: IParamsList) =>
  ApiClient.Get({
    url: ProductManagementApiEndpoint.GET_TAX_LIST,
    params: { params },
  })

export const requestGetDiscountList = (params: IParamsList) =>
  ApiClient.Get({
    url: ProductManagementApiEndpoint.GET_DISCOUNT_LIST,
    params: { params },
  })

export const requestUpdateDiscountById = (params: IParamsUpdateStatus) =>
  ApiClient.Put({
    url: `${ProductManagementApiEndpoint.DISCOUNT}${params.id}/`,
    body: { status: params.status },
  })

export const requestGetUpSellList = (params: IParamsList) =>
  ApiClient.Get({
    url: ProductManagementApiEndpoint.GET_UP_SELL_LIST,
    params: { params },
  })

export const requestGetUpSellListByItem = (params: IParamsList) =>
  ApiClient.Get({
    url: ProductManagementApiEndpoint.GET_UPSELL_LIST_BY_ITEM,
    params: { params },
  })

export const requestUpdateUpSellById = (params: IParamsUpdateStatus) =>
  ApiClient.Put({
    url: `${ProductManagementApiEndpoint.UPSELL}${params.id}/`,
    body: { status: params.status },
  })

export const requestUpdateStatusUpSellById = (params: IParamsUpdateStatus) =>
  ApiClient.Put({
    url: `${ProductManagementApiEndpoint.UPSELL}${params.id}/status`,
    body: { status: params.status },
  })

export const requestDeleteUpSellById = (params: IParamsUpdateStatus) =>
  ApiClient.Delete({
    url: `${ProductManagementApiEndpoint.UPSELL}${params.id}/`,
    body: { status: params.status },
  })

export const requestUploadImageCategory = (body: FormData) =>
  ApiClient.Post({
    url: ProductManagementApiEndpoint.UPLOAD_IMAGE_CATEGORY,
    body,
  })

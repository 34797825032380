/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  requestGetMostOrderedReport,
  requestGetReportCustomer,
  requestGetReportRevenue,
} from 'app/api/report'
import {
  IFilterReport,
  IMostOrderedReport,
  IReportCustomer,
  IReportRevenue,
  IResponseMostOrderedReport,
  IResponseReportCustomer,
  IResponseReportRevenue,
} from 'app/api/report/type'
import { LOGOUT_PATH } from 'app/components/router/route-path'
import { useInfiniteQuery } from 'react-query'
import { useNavigate } from 'react-router'
import { ReportKeys } from '../query-key/report'

export function useGetMostOrderedReport(params: IFilterReport, enabled = true) {
  const navigate = useNavigate()
  return useInfiniteQuery<IMostOrderedReport[], undefined>(
    ReportKeys.reportMostOrdered(params),
    async () => {
      try {
        const response: IResponseMostOrderedReport =
          await requestGetMostOrderedReport(params)

        return response?.data ?? []
      } catch (error: any) {
        if (error?.message === '401') {
          navigate(LOGOUT_PATH, { replace: true })
          return []
        }
        return []
      }
    },
    {
      enabled: !!params && enabled,
      cacheTime: 5000,
    },
  )
}

export function useGetReportRevenue(params: IFilterReport, enabled = true) {
  const navigate = useNavigate()
  return useInfiniteQuery<IReportRevenue[], undefined>(
    ReportKeys.reportTotalRevenue(params),
    async () => {
      try {
        const response: IResponseReportRevenue = await requestGetReportRevenue(
          params,
        )

        return response?.data ?? []
      } catch (error: any) {
        if (error?.message === '401') {
          navigate(LOGOUT_PATH, { replace: true })
          return []
        }
        return []
      }
    },
    {
      enabled: !!params && enabled,
      cacheTime: 5000,
    },
  )
}

export function useGetReportCustomer(params: IFilterReport, enabled = true) {
  const navigate = useNavigate()
  return useInfiniteQuery<IReportCustomer[], undefined>(
    ReportKeys.reportCustomer(params),
    async () => {
      try {
        const response: IResponseReportCustomer =
          await requestGetReportCustomer(params)

        return response?.data ?? []
      } catch (error: any) {
        if (error?.message === '401') {
          navigate(LOGOUT_PATH, { replace: true })
          return []
        }
        return []
      }
    },
    {
      enabled: !!params && enabled,
      cacheTime: 5000,
    },
  )
}

import { memo } from 'react'
import { isEqual } from 'lodash'
import ResetPasswordContainerDe from 'app/containers/auth/desktop/ResetPassword'
import ResetPasswordContainerMo from 'app/containers/auth/mobile/ResetPassword'
import { useResponsive } from 'parkway-web-common'

const ResetPasswordPageComp = () => {
  const { isTablet } = useResponsive()
  return !isTablet ? <ResetPasswordContainerMo /> : <ResetPasswordContainerDe />
}
export const ResetPasswordPage = memo(ResetPasswordPageComp, isEqual)

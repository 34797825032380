import { ButtonVariant } from 'app/components/common/BaseButtonVariant'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { BaseInput } from 'app/components/common/inputs/BaseInput'
import { BORDER_RADIUS, FONT_WEIGHT, PADDING, colors } from 'parkway-web-common'
import styled from 'styled-components'

export const RootWrapper = styled(BaseCol)`
  padding: 4rem 0px 3rem 0rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  clip-path: ellipse(150% 100% at 50% 100%);
  min-height: 700px;
`

export const AreaText = styled.div`
  padding: ${PADDING.xxs} 0px;
  text-align: left;
  font-family: 'Plus Jakarta Sans', sans-serif !important;
`

export const TitleText = styled.span`
  font-size: 25px;
  font-weight: ${FONT_WEIGHT.bold};
  color: #003366;
  text-align: center;
  display: flex;
  justify-content: center;
  font-family: 'Plus Jakarta Sans', sans-serif !important;
`

export const DescriptionText = styled.span`
  color: ${colors.black};
  font-size: 14px;
  font-weight: ${FONT_WEIGHT.semibold};
  display: flex;
  justify-content: center;
  font-family: 'Plus Jakarta Sans', sans-serif !important;
`

export const BaseFormItem = styled(BaseForm.Item)`
  margin-bottom: 0px;
`

export const InputEmail = styled(BaseInput)`
  text-align: center;
  font-size: 14px;
  font-weight: ${FONT_WEIGHT.regular};
  font-family: 'Plus Jakarta Sans', sans-serif !important;
  padding: ${PADDING.xxs} ${PADDING.xxxxl};
  width: calc(100vw - 100px);
`

export const ButtonEmail = styled(ButtonVariant)`
  border-radius: ${BORDER_RADIUS};
  padding: ${PADDING.xxl} ${PADDING.xxl};
  color: ${colors.black};
  font-weight: ${FONT_WEIGHT.bold};
  transition: all 0.5s ease !important;
  background-color: transparent;
  border: 1px solid #003366;
  &:hover {
    color: ${colors.black} !important;
  }
`

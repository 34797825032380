/* eslint-disable @typescript-eslint/no-explicit-any */
import R from 'app/assets/R'
import { BaseImage } from 'app/components/common/BaseImage'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import {
  BaseTemplatePageAuth,
  JupiterComponent,
} from 'app/components/layouts/AuthLayout/Component'
import * as Auth from 'app/components/layouts/AuthLayout/styles'
import { AUTH_LOGIN_PATH } from 'app/components/router/route-path'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import * as S from './styles'
import { BaseSpace } from 'app/components/common/BaseSpace'

const ResetPasswordSuccessContainer: React.FC = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  return (
    <BaseTemplatePageAuth>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flex: 1,
          height: 'calc(100% - 10%)',
        }}
      >
        <div style={{ width: 600, zIndex: 10 }}>
          <S.FormWrapperCustom>
            <BaseForm layout="vertical" requiredMark="optional">
              <BaseSpace size={24}>
                <Auth.FormTitle>{'Password Changed'}</Auth.FormTitle>
                <BaseRow justify={'center'}>
                  <BaseImage
                    src={R.images.ic_success}
                    width={50}
                    preview={false}
                  />
                </BaseRow>
                <Auth.DescriptionText>
                  {
                    'Change password success, you can re-login with new password!'
                  }
                </Auth.DescriptionText>

                <BaseRow justify={'center'}>
                  <BaseForm.Item noStyle>
                    <Auth.SubmitButton
                      onClick={() =>
                        navigate(AUTH_LOGIN_PATH, {
                          replace: true,
                        })
                      }
                    >
                      {t(R.strings.back_to_login)}
                    </Auth.SubmitButton>
                  </BaseForm.Item>
                </BaseRow>
              </BaseSpace>
            </BaseForm>
          </S.FormWrapperCustom>
        </div>
      </div>

      <JupiterComponent />
    </BaseTemplatePageAuth>
  )
}
export default ResetPasswordSuccessContainer

import { ApiClient } from 'app/service/api-service'
import { ReportApiEndpoint } from './constant'
import { IFilterReport } from './type'

export const requestGetMostOrderedReport = (params: IFilterReport) =>
  ApiClient.Get({
    url: ReportApiEndpoint.MostOrdered,
    params: { params },
  })

export const requestGetReportCustomer = (params: IFilterReport) =>
  ApiClient.Get({
    url: ReportApiEndpoint.ReportCustomer,
    params: { params },
  })

export const requestGetReportRevenue = (params: IFilterReport) =>
  ApiClient.Get({
    url: ReportApiEndpoint.ReportRevenue,
    params: { params },
  })

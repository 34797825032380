import { BaseCheckbox } from 'app/components/common/BaseCheckbox'
import { BaseImage } from 'app/components/common/BaseImage'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { BaseInput as CommonInput } from 'app/components/common/inputs/BaseInput'
import * as Auth from 'app/components/layouts/AuthLayout/styles'
import { BORDER_RADIUS_2 } from 'app/constant'
import {
  BORDER_RADIUS,
  FONT_SIZE,
  FONT_WEIGHT,
  PADDING,
} from 'parkway-web-common'
import { CreditCard, PaymentForm } from 'react-square-web-payments-sdk'
import styled from 'styled-components'

export const FormTitle = styled(Auth.FormTitle)`
  color: #ffffff;
`

export const FormWrapper = styled(BaseSpace)`
  width: 100%;
`

export const ItemServicePackage = styled(BaseRow) <{ isSelected?: boolean }>`
  color: ${props => (props.isSelected ? '#29cc6a' : '#959895')};
  font-size: ${FONT_SIZE.xs};
  font-weight: ${FONT_WEIGHT.regular};
  padding: ${PADDING.xxxs} ${PADDING.xxs};
  transition: all 1.25s ease !important;
  cursor: pointer;
  border-radius: ${BORDER_RADIUS};
  border: 1px solid ${props => (props.isSelected ? '#29cc6a' : '#959895')};
  box-shadow: ${props =>
    props.isSelected ? '1px 3px 5px rgba(41, 204, 106, 0.5)' : 'none'};
`

export const TextServicePackage = styled.span<{ isSelected?: boolean }>`
  transition: all 1.25s ease !important;
  color: ${props => (props.isSelected ? '#ffffff' : '#959895')};
  font-size: ${FONT_SIZE.xs};
  font-weight: ${FONT_WEIGHT.regular};
`

export const BaseFormWrapper = styled(Auth.BaseFormWrapper)`
  background-color: #252836;
  padding: ${PADDING.xxxxl};
  border-radius: ${BORDER_RADIUS};
`

export const FormItem = styled(BaseForm.Item)`
  margin-bottom: 0.75rem;
  & .ant-form-item-control-input {
    min-height: 3.25rem;
  }

  input {
    background-color: #ffffff;
    color: #000000;
    border-radius: ${BORDER_RADIUS};
    border-color: #ffffff;
  }

  & .ant-form-item-explain-error {
    margin-top: 0.5rem;
    font-size: ${FONT_SIZE.xs};
  }

  & .ant-form-item-label {
    padding: 0px;
  }

  & label {
    color: #959895;
    font-size: ${FONT_SIZE.xs};
    line-height: 1.25rem;
  }

  &.ant-form-item-has-feedback .ant-input-affix-wrapper .ant-input-suffix {
    padding-right: 1.5rem;
  }
`

export const CircleComponent = styled.div<{ isSelected: boolean }>`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: ${props => (props.isSelected ? '#29cc6a' : '#959895')};
  margin-right: ${PADDING.xs};
  justify-content: center;
  align-items: center;
  display: flex;
  transition: all 1.25s ease !important;
  .small-circle {
    transition: all 1.25s ease !important;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: ${props => (props.isSelected ? 'black' : 'white')};
  }
`

export const FormInput = styled(CommonInput)`
  color: var(--text-main-color);
  background: white;
  border-radius: ${BORDER_RADIUS_2};
  border: 1px solid #29cc6a;
  & input.ant-input {
    background: white;
  }
`

export const ImageCard = styled(BaseImage)``

export const TextCardInfo = styled.div`
  font-size: ${FONT_SIZE.lg};
  font-weight: ${FONT_WEIGHT.bold};
  color: #ffffff;
`

export const ButtonSubmit = styled(Auth.SubmitButton)`
  width: 100%;
  border-radius: ${BORDER_RADIUS};
  font-size: ${FONT_SIZE.md};
  font-weight: ${FONT_WEIGHT.semibold};
`

export const PaymentFormWrapper = styled(PaymentForm)``

export const CreditCardWrapper = styled(CreditCard)``

export const InfoPackageWrapper = styled(BaseSpace)`
  min-width: 320px;
  padding: ${PADDING.xxl};
  width: 100%;
`
export const LayoutMain = styled(BaseRow)`
  width: 100%;
  height: 90vh;
  padding-left: ${PADDING.xxxxl}
`

export const BackButton = styled.button`
  background-color: rgba(37, 40, 54, 0.1); // need change
  border-radius: ${BORDER_RADIUS};
  border-color: transparent;
  padding: ${PADDING.xs};
  transition: all 1s ease;
  cursor: pointer;
  & svg {
    fill: var(--background-color);
    width: 18px;
    height: 18px;
  }
`

export const TextOrderSummary = styled.span`
  font-size: ${FONT_SIZE.xxl};
  font-weight: ${FONT_WEIGHT.bold};
  color: #07143b;
`

export const TextOrderSummaryDescription = styled.span`
  font-size: ${FONT_SIZE.xs};
  font-weight: ${FONT_WEIGHT.regular};
  color: #07143b;
`
export const TextOrderSummaryDescriptionTrial = styled(
  TextOrderSummaryDescription,
)`
  font-size: ${FONT_SIZE.xxs};
`

export const InfoPackageItem = styled(BaseRow)`
  padding: ${PADDING.md};
  border-radius: ${BORDER_RADIUS};
  border: 1px solid #07143b;
  width: 100%;
  background-color: rgba(41, 204, 106, 0.6);

  margin-left: 0px !important;
  margin-right: 0px !important;
`

export const TotalPaymentWrapper = styled(BaseSpace)`
  padding: ${PADDING.md} ${PADDING.xxxl};
  border-radius: ${BORDER_RADIUS};
  border: 1px solid #07143b;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.6);
`

export const ItemWrapperPayment = styled(BaseRow)`
  width: 100%;
  .text {
    color: rgba(148, 148, 148, 1);
    font-size: ${FONT_SIZE.md};
    font-weight: ${FONT_WEIGHT.regular};
  }
  .total {
    font-weight: ${FONT_WEIGHT.semibold};
    color: #000000;
    font-size: ${FONT_SIZE.md};
  }
`

export const CheckBox = styled(BaseCheckbox)`
  .ant-checkbox-inner {
    background-color: #ffffff !important;
    border-color: #959895 !important;
  }
  .ant-checkbox-inner:after {
    border-color: #29cc6a !important;
  }
`

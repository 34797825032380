/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMounted } from 'app/hook'
import { Pagination, formatMoney } from 'parkway-web-common'
import { useCallback, useEffect, useState } from 'react'
import { initialPagination } from './constant'
import * as S from './styles'
import { IColumnTable, IConvertColumnTable, IDataBaseTable } from './type'
import { SvgTableCloseIcon, SvgTableOpenIcon } from 'app/assets/svg-assets'
interface Props {
  getTreeTableData: (pagination: Pagination) => Promise<IDataBaseTable<any>>
  isExpandedWhenStart?: boolean
}

export function useBaseTableReportHook({
  getTreeTableData,
  isExpandedWhenStart,
}: Props) {
  const { isMounted } = useMounted()
  const [tableData, setTableData] = useState<{
    data: any[]
    pagination: Pagination
    loading: boolean
  }>({
    data: [],
    pagination: initialPagination,
    loading: false,
  })
  const [expandedRowKeys, setExpandedRowKeys] = useState<number[]>([])
  const [expandedAreaKeys, setExpandedAreaKeys] = useState<number[]>([])

  const handleExpand = (expanded: boolean, newKey?: number) => {
    if (!newKey) return
    if (expanded) {
      setExpandedRowKeys([...expandedRowKeys, newKey])
    } else {
      setExpandedRowKeys(expandedRowKeys.filter(key => key !== newKey))
    }
  }

  const handleExpandArea = (expanded: boolean, newKey?: number) => {
    if (!newKey) return
    if (expanded) {
      setExpandedAreaKeys([...expandedAreaKeys, newKey])
    } else {
      setExpandedAreaKeys(expandedAreaKeys.filter(key => key !== newKey))
    }
  }

  const fetch = useCallback(
    (pagination: Pagination) => {
      setTableData(tableData => ({ ...tableData, loading: true }))
      getTreeTableData(pagination).then(res => {
        if (isMounted.current) {
          setTableData({
            data: res?.data,
            pagination: res?.pagination,
            loading: false,
          })

          if (isExpandedWhenStart) {
            setExpandedRowKeys(res?.data?.map(item => item?.key ?? 0))
          }
        }
      })
    },
    [isMounted],
  )

  useEffect(() => {
    fetch(initialPagination)
  }, [fetch])

  const handleTableChange = (pagination: Pagination) => {
    fetch(pagination)
  }

  const handleWeekChange = (startDate?: Date | null, endDate?: Date | null) => {
    //
    console.log({ startDate, endDate })
  }

  return {
    handleExpand,
    tableData,
    handleTableChange,
    handleWeekChange,
    expandedRowKeys,
    expandedAreaKeys,
    handleExpandArea,
    fetch,
  }
}

export function RenderValueTableReport({
  prefix,
  record,
  totalValue,
  childValue,
  expandedRowKeys,
  isFillBlueInTotal,
  valueString,
}: {
  prefix?: string
  record: any
  totalValue?: number
  childValue?: number
  expandedRowKeys?: (number | undefined)[]
  isFillBlueInTotal?: boolean
  valueString?: string
}) {
  const isExpanded = expandedRowKeys?.includes(record?.key)
  const valueAfterChecked =
    record?.children && !isExpanded ? totalValue : childValue
  if (valueString) {
    return (
      <S.RevenueTableWrapper
        justify={'start'}
        isBold={record?.isTotalTable}
        isBlueText={isFillBlueInTotal && record?.isTotalTable}
      >
        {valueString}
      </S.RevenueTableWrapper>
    )
  }
  return (
    <S.RevenueTableWrapper
      justify={'start'}
      isBold={record?.isTotalTable}
      isBlueText={isFillBlueInTotal && record?.isTotalTable}
    >
      {formatMoney(valueAfterChecked ?? '-', prefix)}
    </S.RevenueTableWrapper>
  )
}

export function convertColumnTable<T>({
  key,
  className,
  classNameOverwrite,
  onCell,
  render,
  title,
  dataIndex,
  align = 'start',
  width,
  fixed,
}: IConvertColumnTable<T>): IColumnTable<T> {
  return {
    fixed,
    title: title ?? '-',
    dataIndex: dataIndex ?? key,
    key: key,
    className: classNameOverwrite ?? className ?? 'normal-column',
    onCell,
    render,
    align,
    width,
  }
}

export const ExpandedTableValue = ({
  isExpanded,
  handleExpand,
  value = '',
  isTotalTable,
}: {
  isExpanded?: boolean
  handleExpand?: () => void
  value?: string
  isTotalTable?: boolean
}) => {
  if (isTotalTable) {
    return (
      <S.AreaRowWrapper justify={'start'} align={'middle'}>
        <S.AreaText>{value}</S.AreaText>
      </S.AreaRowWrapper>
    )
  }

  return (
    <S.AreaRowWrapper justify={'start'}>
      {isExpanded ? (
        <SvgTableCloseIcon onClick={handleExpand} />
      ) : (
        <SvgTableOpenIcon onClick={handleExpand} />
      )}
      <S.AreaText>{value}</S.AreaText>
    </S.AreaRowWrapper>
  )
}

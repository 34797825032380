import { memo } from 'react'
import { isEqual } from 'lodash'
import VerifyEmailChangePassContainer from 'app/containers/auth/VerifyEmail'

const VerifyEmailChangePassPageComp = () => {
  return <VerifyEmailChangePassContainer />
}
export const VerifyEmailChangePassPage = memo(
  VerifyEmailChangePassPageComp,
  isEqual,
)

import { WithChildrenProps } from 'parkway-web-common'
import { getToken } from 'app/service/storage/storage-service'
import React from 'react'
import { Navigate } from 'react-router-dom'
import { ADMIN_PATH } from './route-path'

const NotAuth: React.FC<WithChildrenProps> = ({ children }) => {
  const token = getToken()
  return !token ? <>{children}</> : <Navigate to={ADMIN_PATH.HOME} replace />
}

export default NotAuth

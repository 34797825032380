import {
  IParamsGetVariationByItemId,
  IParamsList,
  IParamsListV2,
} from 'app/api/product-management/model'

export const ProductManagementKeys = {
  allCategory: ['Category'] as const,
  categoryLists: () =>
    [...ProductManagementKeys.allCategory, 'Category'] as const,
  categoryList: (filters?: IParamsListV2) =>
    [...ProductManagementKeys.categoryLists(), { filters }] as const,

  allItemProductManagement: ['ItemProductManagement'] as const,
  itemProductManagementLists: () =>
    [
      ...ProductManagementKeys.allItemProductManagement,
      'ItemProductManagement',
    ] as const,
  itemProductManagementList: (filters?: IParamsListV2) =>
    [
      ...ProductManagementKeys.itemProductManagementLists(),
      { filters },
    ] as const,

  allDiscount: ['Discount'] as const,
  discountLists: () =>
    [...ProductManagementKeys.allDiscount, 'Discount'] as const,
  discountList: (filters?: IParamsList) =>
    [...ProductManagementKeys.discountLists(), { filters }] as const,

  allUpSell: ['UpSell'] as const,
  upSellLists: () => [...ProductManagementKeys.allUpSell, 'UpSell'] as const,
  upSellList: (filters?: IParamsListV2) =>
    [...ProductManagementKeys.upSellLists(), { filters }] as const,

  allUpSellByItem: ['UpSellByItem'] as const,
  upSellByItemLists: () =>
    [...ProductManagementKeys.allUpSellByItem, 'UpSellByItem'] as const,
  upSellByItemList: (filters?: IParamsList) =>
    [...ProductManagementKeys.upSellLists(), { filters }] as const,

  allModifier: ['Modifier'] as const,
  modifierLists: () =>
    [...ProductManagementKeys.allModifier, 'Modifier'] as const,
  modifierList: (filters?: IParamsListV2) =>
    [...ProductManagementKeys.modifierLists(), { filters }] as const,

  allVariation: ['Variation'] as const,
  variationLists: () =>
    [...ProductManagementKeys.allVariation, 'Variation'] as const,
  variationList: (filters?: IParamsGetVariationByItemId) =>
    [...ProductManagementKeys.variationLists(), { filters }] as const,
}

/* eslint-disable @typescript-eslint/no-explicit-any */
import { BaseCol } from 'app/components/common/BaseCol'
import {
  CONTACT_US_PATH,
  PRICING_PATH,
  PRODUCT_PATH,
} from 'app/components/router/route-path'
import { useAppSelector } from 'app/redux/hooks'
import { useEffect, useRef } from 'react'
import HeaderHome from './Header'
import LayoutFourHome from './LayoutFour'
import LayoutOneHome from './LayoutOne'
import LayoutThreeHome from './LayoutThree'
import LayoutTwoHome from './LayoutTwo'

export const MobileLayout = () => {
  const currentPath = useAppSelector(state => state.path).path
  console.log({ currentPath })

  const refOne = useRef<any>()
  const refTwo = useRef<any>()
  const refThree = useRef<any>()

  useEffect(() => {
    const timer = setTimeout(() => {
      switch (currentPath) {
        case PRODUCT_PATH:
          refOne?.current.scrollIntoView({ behavior: 'smooth' })
          break
        case PRICING_PATH:
          refThree?.current.scrollIntoView({ behavior: 'smooth' })
          break
        case CONTACT_US_PATH:
          refTwo?.current.scrollIntoView({ behavior: 'smooth' })
          break
      }
    }, 500)

    return () => clearTimeout(timer)
  }, [currentPath])
  return (
    <BaseCol>
      <HeaderHome />
      <div ref={refOne}>
        <LayoutOneHome />
      </div>
      <LayoutTwoHome />
      <div ref={refThree}>
        <LayoutThreeHome />
      </div>
      <div ref={refTwo}>
        <LayoutFourHome />
      </div>
    </BaseCol>
  )
}

/* eslint-disable @typescript-eslint/no-explicit-any */
import { Rule } from 'antd/es/form'
import R from 'app/assets/R'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { BaseTemplatePageAuth } from 'app/components/layouts/AuthLayout/Component'
import * as Auth from 'app/components/layouts/AuthLayout/styles'
import { AUTH_CHANGE_PASSWORD_SUCCESS_PATH } from 'app/components/router/route-path'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router'
import * as S from './styles'
import { IBodyChangePassword, requestPostChangePassword } from 'app/api/auth'

interface ChangePasswordFormData {
  password: string
  confirmPassword?: string
}

interface RuleChangePassword {
  password: Rule[]
  confirmPassword: Rule[]
}

export const initValues: ChangePasswordFormData = {
  password: '',
  confirmPassword: '',
}

const ChangePasswordContainer: React.FC = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const params: { email: string } = useLocation().state

  const [isLoading, setLoading] = useState(false)
  const [time, setTime] = useState(60)

  const onGoBack = () => {
    navigate(-1)
  }

  useEffect(() => {
    if (!params) {
      onGoBack()
    }
  }, [params])

  const handleSubmit = async (values: ChangePasswordFormData) => {
    setLoading(true)
    try {
      const payload: IBodyChangePassword = {
        new_password: values.password,
      }

      await requestPostChangePassword(payload)

      navigate(AUTH_CHANGE_PASSWORD_SUCCESS_PATH, {
        replace: true,
      })

      setLoading(false)
    } catch (error: any) {
      console.log({ error })
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (time > 0) {
      const timer = setTimeout(() => {
        setTime(time - 1)
      }, 1000)

      return () => clearTimeout(timer)
    }
  }, [time])

  const rule: RuleChangePassword = useMemo(() => {
    return {
      password: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.password),
          }),
        },
        {
          min: 8,
          message: t(R.strings.minimum_field, {
            field: t(R.strings.password),
            count: 8,
          }),
        },
      ],
      confirmPassword: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.confirm_password),
          }),
        },
        {
          min: 8,
          message: t(R.strings.minimum_field, {
            field: t(R.strings.password),
            count: 8,
          }),
        },
        ({ getFieldValue }) => ({
          validator(_, value) {
            if (!value || getFieldValue('password') === value) {
              return Promise.resolve()
            }
            return Promise.reject(
              new Error(t(R.strings.confirm_password_incorrect)),
            )
          },
        }),
      ],
    }
  }, [t])

  return (
    <BaseTemplatePageAuth>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flex: 1,
          height: 'calc(100% - 10%)',
        }}
      >
        <div style={{ width: 400 }}>
          <BaseForm
            layout="vertical"
            onFinish={handleSubmit}
            requiredMark="optional"
            initialValues={initValues}
          >
            <BaseSpace>
              <S.Title>{'New Password'}</S.Title>
              <BaseSpace size={8}>
                <Auth.FormItem name="password" rules={rule.password}>
                  <Auth.FormInputPassword placeholder="Your new password..." />
                </Auth.FormItem>

                <Auth.FormItem
                  name="confirmPassword"
                  rules={rule.confirmPassword}
                >
                  <Auth.FormInputPassword placeholder="Confirm again here" />
                </Auth.FormItem>
              </BaseSpace>

              <Auth.ActionsWrapper justify={'space-between'} align={'middle'}>
                <BaseForm.Item
                  name="rememberMe"
                  valuePropName="checked"
                  noStyle
                >
                  <Auth.FormCheckbox>
                    <S.RememberMeText>
                      {`${t(R.strings.remember_me)}?`}
                    </S.RememberMeText>
                  </Auth.FormCheckbox>
                </BaseForm.Item>
              </Auth.ActionsWrapper>
              <BaseRow justify={'center'}>
                <Auth.SubmitButton htmlType="submit" loading={isLoading}>
                  {'Done'}
                </Auth.SubmitButton>
              </BaseRow>
            </BaseSpace>
          </BaseForm>
        </div>
      </div>
    </BaseTemplatePageAuth>
  )
}
export default ChangePasswordContainer

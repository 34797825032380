import { BaseCheckbox } from "app/components/common/BaseCheckbox";
import { BaseRow } from "app/components/common/BaseRow";
import styled from "styled-components";

export const RootWrapper = styled(BaseRow)`
  margin-top: 20px;
`

export const CustomCheckBox = styled(BaseCheckbox)`
  min-width: 120px;
  align-items: center;
  margin-right: 40px;
  margin-bottom: 12px;
  color: #FFFFFF;
  font-size: 10px;
  &:hover {
    .ant-checkbox-inner {
      background-color: #D5D9E0 !important;
    }
    .ant-checkbox.ant-checkbox-checked .ant-checkbox-inner {
      background-color: #29CC6A !important;
    }
  }
  .ant-checkbox,
  .ant-checkbox .ant-checkbox-input,
  .ant-checkbox .ant-checkbox-inner {
    width: 13px;
    height: 13px;
  }
  span:nth-child(2) {
    display: flex;
    align-items: center;
  }
  .ant-checkbox .ant-checkbox-inner {
    background-color: #D5D9E0;
    border: unset;
  }
  .ant-checkbox.ant-checkbox-checked .ant-checkbox-inner {
    background-color: #29CC6A;
  }
`

export const CircleColor = styled.div<{ $bgColor: string }>`
  display: inline-block;
  width: 10px;
  height: 10px;
  background-color: ${props => props.$bgColor};
  border-radius: 50%;
  margin-right: 6px;
`
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.delay-animation-1s {
  animation-delay: 1s;
  animation: slideUp 1s forwards;
}

.delay-animation-2s {
  animation-delay: 2s;
  animation: slideUp 2s forwards;
}

.delay-animation-3s {
  animation-delay: 3s;
  animation: slideUp 3s forwards;
}

.delay-animation-4s {
  animation-delay: 4s;
  animation: slideUp 4s forwards;
}

@keyframes slideUp {
  0% {
    transform: translateY(100px); /* Đưa các phần tử xuống */
    opacity: 0; /* Bắt đầu với độ mờ là 0 */
  }
  100% {
    transform: translateY(0); /* Đưa các phần tử về vị trí ban đầu */
    opacity: 1; /* Độ mờ trở về 1 khi hiển thị */
  }
}
`, "",{"version":3,"sources":["webpack://./src/app/containers/landing-page/components/mobile-layout/LayoutTwo/styles.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,8BAA8B;AAChC;;AAEA;EACE,mBAAmB;EACnB,8BAA8B;AAChC;;AAEA;EACE,mBAAmB;EACnB,8BAA8B;AAChC;;AAEA;EACE,mBAAmB;EACnB,8BAA8B;AAChC;;AAEA;EACE;IACE,4BAA4B,EAAE,0BAA0B;IACxD,UAAU,EAAE,2BAA2B;EACzC;EACA;IACE,wBAAwB,EAAE,sCAAsC;IAChE,UAAU,EAAE,gCAAgC;EAC9C;AACF","sourcesContent":[".delay-animation-1s {\n  animation-delay: 1s;\n  animation: slideUp 1s forwards;\n}\n\n.delay-animation-2s {\n  animation-delay: 2s;\n  animation: slideUp 2s forwards;\n}\n\n.delay-animation-3s {\n  animation-delay: 3s;\n  animation: slideUp 3s forwards;\n}\n\n.delay-animation-4s {\n  animation-delay: 4s;\n  animation: slideUp 4s forwards;\n}\n\n@keyframes slideUp {\n  0% {\n    transform: translateY(100px); /* Đưa các phần tử xuống */\n    opacity: 0; /* Bắt đầu với độ mờ là 0 */\n  }\n  100% {\n    transform: translateY(0); /* Đưa các phần tử về vị trí ban đầu */\n    opacity: 1; /* Độ mờ trở về 1 khi hiển thị */\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

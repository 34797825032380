import { BaseSpace } from 'app/components/common/BaseSpace'
import {
  BORDER_RADIUS,
  FONT_SIZE,
  FONT_WEIGHT,
  PADDING,
} from 'parkway-web-common'
import styled from 'styled-components'

export const DescriptionText = styled.div`
  color: #959895;
  font-size: ${FONT_SIZE.xxs};
  font-weight: ${FONT_WEIGHT.regular};
  text-align: left;
`

export const FormWrapperCustom = styled(BaseSpace)`
  width: 100%;
  height: fit-content;
  border-radius: ${BORDER_RADIUS};
  background-color: rgba(255, 255, 255, 0.3);
  padding: ${PADDING.xxxl};
  align-items: center;
  border: 0.5px solid #f7ffe5;
  .ant-space-item {
    width: 100%;
  }

  @media only screen and (max-width: 768px) {
    width: 90%;
  }
`

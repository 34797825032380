import { BORDER_RADIUS, FONT_SIZE, FONT_WEIGHT } from 'parkway-web-common'
import styled from 'styled-components'

export const RootWrapper = styled.div<{ $bgColor: string }>`
  border-radius: ${BORDER_RADIUS};
  // background-color: ${props => props.$bgColor};
  height: 100%;
`
export const TitleSide = styled.div`
  color: var(--background-color);
  font-size: ${FONT_SIZE.xl} !important;
  font-weight: ${FONT_WEIGHT.bold} !important;
`

interface TitleProps {
  $color?: string
  $bgColor?: string
  $fontSize?: string
  $fontWeight?: string
  $opacity?: string
}

export const Text = styled.div<TitleProps>`
  color: ${props => props.$color || 'var(--primary-color)'};
  background-color: ${props => props.$bgColor || 'initial'};
  opacity: ${props => props.$opacity || '1'};
  font-size: ${props => props.$fontSize || FONT_SIZE.xl};
  font-weight: ${props => props.$fontWeight || FONT_WEIGHT.bold};
`

import R from 'app/assets/R'

export const URL_REPORTS = {
  Home: 'home',
  TotalRevenue: 'total-revenue',
  TotalDishOrdered: 'total-dish-ordered',
  TotalCustomer: 'total-customer',
  OrderReport: 'order-report',
}

export const CHART_SERIES_COLORS = [
  '#FA5F10',
  '#FFA564',
  '#868686',
  '#FFFFFF',
  '#0741D6',
  '#0B8AFF',
  '#00517E',
  '#FA5F10',
  '#27AE60',
  '#6CBDA0',
  '#B6EC44',
]

export const BAR_CHART_SERIES_COLORS = ['#6DC24F', '#4A97FF']

export const CHART_LABEL_DAY_OF_WEEK = [
  R.strings.reports_common_chart_label_monday,
  R.strings.reports_common_chart_label_tuesday,
  R.strings.reports_common_chart_label_wednesday,
  R.strings.reports_common_chart_label_thursday,
  R.strings.reports_common_chart_label_friday,
  R.strings.reports_common_chart_label_satuday,
  R.strings.reports_common_chart_label_sunday,
]

export const CHART_LABEL_MONTH = [
  R.strings.reports_common_chart_label_january,
  R.strings.reports_common_chart_label_february,
  R.strings.reports_common_chart_label_march,
  R.strings.reports_common_chart_label_april,
  R.strings.reports_common_chart_label_may,
  R.strings.reports_common_chart_label_june,
  R.strings.reports_common_chart_label_july,
  R.strings.reports_common_chart_label_august,
  R.strings.reports_common_chart_label_september,
  R.strings.reports_common_chart_label_october,
  R.strings.reports_common_chart_label_november,
  R.strings.reports_common_chart_label_december,
]

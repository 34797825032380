import { BaseButton } from 'app/components/common/BaseButton'
import { ButtonVariant } from 'app/components/common/BaseButtonVariant'
import { BaseSpace } from 'app/components/common/BaseSpace'
import {
  BORDER_RADIUS,
  FONT_SIZE,
  FONT_WEIGHT,
  PADDING,
} from 'parkway-web-common'
import styled from 'styled-components'
export const RootWrapper = styled.div`
  width: 100%;
  height: 100%;
  background-size: cover;
  position: relative;
  background-image: url(${'https://storage.googleapis.com/kiosify-01/images/img_bg_login.png'});
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const BackButtonCustom = styled(BaseButton)`
  background-color: rgba(255, 255, 255, 0.1); // need change
  border-radius: ${BORDER_RADIUS};
  border-color: transparent;
  padding: ${PADDING.md};
  transition: all 1s ease;

  & svg {
    fill: var(--background-color);
  }

  &:hover {
    border-color: var(--background-color) !important;
    transition: all 1s ease;
  }
`

export const FormWrapperCustom = styled(BaseSpace)`
  width: 100%;
  height: fit-content;
  border-radius: ${BORDER_RADIUS};
  background-color: rgba(255, 255, 255, 0.25);
  padding: ${PADDING.xxxl};
  align-items: center;
  border: 0.5px solid #f7ffe5;
  .ant-space-item {
    width: 100%;
  }

  @media only screen and (max-width: 768px) {
    width: 90%;
  }
`

export const Title = styled.div`
  font-size: 18px;
  font-weight: ${FONT_WEIGHT.semibold};
  color: #fff;
`

export const Description = styled.div`
  font-size: 14px;
  font-weight: ${FONT_WEIGHT.regular};
  color: #42e5c6;
  text-align: center;
`

export const EmailText = styled.span`
  font-size: ${FONT_SIZE.xxs};
  font-weight: ${FONT_WEIGHT.regular};
  color: #fff;
`

export const SubmitButton = styled(ButtonVariant)`
  font-size: ${FONT_SIZE.md};
  font-weight: ${FONT_WEIGHT.semibold};
  width: 100%;
  border-radius: ${BORDER_RADIUS};
`

export const ResendText = styled.span`
  color: #42e5c6;
  font-size: ${FONT_SIZE.xs};
  transition: all 1.25s ease !important;
  font-weight: ${FONT_WEIGHT.regular};
  cursor: pointer;
`

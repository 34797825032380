import { ConfigProvider } from 'antd'
import enUS from 'antd/lib/locale/en_US'
import deVi from 'antd/lib/locale/vi_VN'
import { AppRouter } from 'app/components/router/app-router'
import { LanguageSupportType } from 'app/i18n/constant'
import { useAppDispatch, useAppSelector } from 'app/redux/hooks'
import { getUserInfo } from 'app/redux/slices/userSlice'
import { getCurrentLanguage } from 'app/service/storage/storage-service'
import GlobalStyle from 'app/styles/GlobalStyle'
import { themeObject } from 'app/styles/themes/themeVariables'
import { isEmpty } from 'lodash'
import { useEffect } from 'react'
import { HelmetProvider } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import { QueryClient, QueryClientProvider } from 'react-query'
import './app/i18n'

const queryClient = new QueryClient()

const App: React.FC = () => {
  const language = getCurrentLanguage() as LanguageSupportType
  const theme = useAppSelector(state => state.theme.theme)
  const user = useAppSelector(state => state.user.user)
  const dataToken = useAppSelector(state => state.auth.token)
  const dispatch = useAppDispatch()
  const { i18n } = useTranslation()

  const getLanguage = () => {
    if (user?.language) {
      i18n.changeLanguage(user?.language)
      return
    }

    if (language) {
      i18n.changeLanguage(language)
      return
    }
  }

  const getUser = async () => {
    if (!isEmpty(dataToken)) {
      dispatch(getUserInfo())
    }
  }

  useEffect(() => {
    getUser()
  }, [dataToken])

  useEffect(() => {
    getLanguage()
  }, [i18n.language, user])

  return (
    <QueryClientProvider client={queryClient}>
      <div data-theme={theme}>
        <meta name="theme-color" content={themeObject[theme].primary} />
        <GlobalStyle />

        <HelmetProvider>
          <ConfigProvider locale={language === 'en' ? enUS : deVi}>
            <AppRouter />
          </ConfigProvider>
        </HelmetProvider>
      </div>
    </QueryClientProvider>
  )
}
export default App

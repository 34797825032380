import { IFilterReport } from 'app/api/report/type'

export const ReportKeys = {
  all: ['All'] as const,
  reportTotalRevenueAll: () =>
    [...ReportKeys.all, 'reportTotalRevenue'] as const,
  reportTotalRevenue: (filters?: IFilterReport) =>
    [...ReportKeys.reportTotalRevenueAll(), { filters }] as const,

  reportMostOrderedAll: () => [...ReportKeys.all, 'reportMostOrdered'] as const,
  reportMostOrdered: (filters?: IFilterReport) =>
    [...ReportKeys.reportMostOrderedAll(), { filters }] as const,

  reportCustomers: () => [...ReportKeys.all, 'reportCustomer'] as const,
  reportCustomer: (filters?: IFilterReport) =>
    [...ReportKeys.reportCustomers(), { filters }] as const,
}

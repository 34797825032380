import R from 'app/assets/R'
import { PageTitle } from 'app/components/common/PageTitle'
import DashboardContainerDes from 'app/containers/admin/desktop/Dashboard'
import DashboardContainerMo from 'app/containers/admin/mobile/Dashboard'
import { useResponsive } from 'parkway-web-common'
import { useTranslation } from 'react-i18next'

const DashboardPage = () => {
  const { t } = useTranslation()
  const { isTablet } = useResponsive()
  return (
    <>
      <PageTitle>{t(R.strings.dashboard)}</PageTitle>
      {!isTablet ? <DashboardContainerMo /> : <DashboardContainerDes />}
    </>
  )
}
export default DashboardPage

/* eslint-disable @typescript-eslint/no-explicit-any */
import { IParamsList, IParamsListV2 } from 'app/api/product-management/model'
import {
  requestCreateRestaurantOpenTime,
  requestDeleteRestaurantOpenTime,
  requestGetRestaurantDetail,
  requestGetRestaurantListV2,
  requestUpdateRestaurantOpenTime,
  requestUploadImageRestaurant,
} from 'app/api/restaurant/api'
import {
  IPayloadUpdateLocationTime,
  IRestaurant,
  ResponseRestaurantList,
} from 'app/api/restaurant/model'
import { IResponseDefine } from 'app/common/model'
import { LOGOUT_PATH } from 'app/components/router/route-path'
import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query'
import { useNavigate } from 'react-router'
import { RestaurantKeys } from '../query-key/restaurant'

export function useGetRestaurantDetail(id?: string, enabled = true) {
  const navigate = useNavigate()
  return useInfiniteQuery<IRestaurant, IParamsList>(
    RestaurantKeys.restaurantDetail({ id }),
    async () => {
      try {
        const response: IResponseDefine<{ about: IRestaurant }> =
          await requestGetRestaurantDetail(id)

        return response?.data.about
      } catch (error: any) {
        console.log({ error }, 'product-management.ts')
        if (error?.message === '401') {
          navigate(LOGOUT_PATH, { replace: true })
          return {}
        }
        return {}
      }
    },
    {
      enabled: !!id && enabled,
    },
  )
}

export const useUpdateImageLocation = () => {
  const navigate = useNavigate()
  const query = useQueryClient()
  return useMutation<IResponseDefine<boolean>, unknown, FormData, unknown>(
    async (body: any) => {
      try {
        const res = await requestUploadImageRestaurant(body)
        return res
      } catch (error: any) {
        if (error?.message === '401') {
          navigate(LOGOUT_PATH, { replace: true })
          return {
            data: [],
            total: 0,
          }
        }
      }
    },
    {
      onSuccess: () => {
        query.invalidateQueries({
          queryKey: RestaurantKeys.all,
        })
      },
    },
  )
}

export const useCreateOpenTime = () => {
  const navigate = useNavigate()
  const query = useQueryClient()
  return useMutation<unknown, unknown, IPayloadUpdateLocationTime, unknown>(
    async (body: IPayloadUpdateLocationTime) => {
      try {
        await requestCreateRestaurantOpenTime(body)
      } catch (error: any) {
        if (error?.message === '401') {
          navigate(LOGOUT_PATH, { replace: true })
          return {
            data: [],
            total: 0,
          }
        }
      }
    },
    {
      onSuccess: () => {
        query.invalidateQueries({
          queryKey: RestaurantKeys.all,
        })
      },
    },
  )
}

export const useUpdateOpenTime = () => {
  const navigate = useNavigate()
  const query = useQueryClient()
  return useMutation<unknown, unknown, IPayloadUpdateLocationTime, unknown>(
    async (body: IPayloadUpdateLocationTime) => {
      try {
        await requestUpdateRestaurantOpenTime(body)
      } catch (error: any) {
        if (error?.message === '401') {
          navigate(LOGOUT_PATH, { replace: true })
          return {
            data: [],
            total: 0,
          }
        }
      }
    },
    {
      onSuccess: () => {
        query.invalidateQueries({
          queryKey: RestaurantKeys.all,
        })
      },
    },
  )
}

export const useDeleteOpenTime = () => {
  const navigate = useNavigate()
  const query = useQueryClient()
  return useMutation<unknown, unknown, { id: string }, unknown>(
    async (body: { id: string }) => {
      try {
        await requestDeleteRestaurantOpenTime(body.id)
      } catch (error: any) {
        if (error?.message === '401') {
          navigate(LOGOUT_PATH, { replace: true })
          return {
            data: [],
            total: 0,
          }
        }
      }
    },
    {
      onSuccess: () => {
        query.invalidateQueries({
          queryKey: RestaurantKeys.all,
        })
      },
    },
  )
}

// v2
export function useGetRestaurantListV2(params?: IParamsListV2, enabled = true) {
  const navigate = useNavigate()
  return useQuery<IRestaurant[], IParamsListV2>(
    RestaurantKeys.restaurantListV2(params),
    async () => {
      try {
        const response: ResponseRestaurantList =
          await requestGetRestaurantListV2({
            page: 1,
            size: 1000,
            ...params,
          })

        return response?.items ?? []
      } catch (error: any) {
        console.log({ error }, 'product-management.ts')
        if (error?.message === '401') {
          navigate(LOGOUT_PATH, { replace: true })
          return []
        }
        return []
      }
    },
    {
      enabled,
      cacheTime: 5000,
    },
  )
}

import { ButtonVariant } from 'app/components/common/BaseButtonVariant'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { BaseInput } from 'app/components/common/inputs/BaseInput'
import { PaddingMain } from 'app/constant'
import {
  BORDER_RADIUS,
  FONT_WEIGHT,
  PADDING,
  colors,
  media,
} from 'parkway-web-common'
import styled from 'styled-components'

export const RootWrapper = styled(BaseCol)`
  padding: ${PaddingMain.Desktop.defaultHz} 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 50px;
  @media only screen and ${media.xs} {
    padding: ${PaddingMain.Mobile.defaultHz} 0px;
  }
`

export const AreaText = styled.div`
  padding: ${PADDING.xxs} 0px;
  text-align: left;
  font-family: 'Plus Jakarta Sans', sans-serif !important;
`

export const TitleText = styled.span`
  font-size: 24px;
  font-weight: ${FONT_WEIGHT.bold};
  color: ${colors.black};
  text-align: center;
  display: flex;
  justify-content: center;
  font-family: 'Plus Jakarta Sans', sans-serif !important;
`

export const DescriptionText = styled.span`
  color: ${colors.black};
  font-size: 20px;
  font-weight: ${FONT_WEIGHT.semibold};
  display: flex;
  justify-content: center;
  font-family: 'Plus Jakarta Sans', sans-serif !important;
`

export const BaseFormItem = styled(BaseForm.Item)`
  margin-bottom: 0px;
`

export const InputEmail = styled(BaseInput)`
  text-align: center;
  font-size: 16px;
  font-weight: ${FONT_WEIGHT.regular};
  font-family: 'Plus Jakarta Sans', sans-serif !important;
  padding: ${PADDING.xxs} ${PADDING.xxxxl};
  width: 344px;
`
export const ButtonEmail = styled(ButtonVariant)`
  border-radius: ${BORDER_RADIUS};
  padding: ${PADDING.xxxl} ${PADDING.xxxxl};
  color: ${colors.black};
  font-weight: ${FONT_WEIGHT.bold};
`

export const BaseColFullWidth = styled(BaseCol)`
  width: 100%;
`

import { BaseMenu } from 'app/components/common/BaseMenu'
import { BaseRow } from 'app/components/common/BaseRow'
import {
  BORDER_RADIUS,
  FONT_SIZE,
  FONT_WEIGHT,
  colors,
} from 'parkway-web-common'
import styled from 'styled-components'

export const Menu = styled(BaseMenu)`
  background: transparent;
  border-right: 0;

  a {
    width: 100%;
    display: block;
  }

  .ant-menu-item,
  .ant-menu-submenu {
    font-size: ${FONT_SIZE.xs};
  }

  .ant-menu-item-icon {
    width: 1.25rem;
  }

  // .ant-menu-submenu-expand-icon,
  // .ant-menu-submenu-arrow,
  // span[role='img'],
  // a,
  // .ant-menu-item,
  // .ant-menu-submenu {
  //   color: var(--text-Slider-secondary-color);
  //   fill: var(--text-Slider-secondary-color);
  // }

  // .ant-menu-item:hover,
  // .ant-menu-submenu-title:hover {
  //   .ant-menu-submenu-expand-icon,
  //   .ant-menu-submenu-arrow,
  //   span[role='img'],
  //   a,
  //   .ant-menu-item-icon,
  //   .ant-menu-title-content {
  //     color: ${colors.secondaryText};
  //     fill: ${colors.secondaryText};
  //     & > svg > path {
  //       stroke: ${colors.secondaryText} !important;
  //     }
  //   }
  // }

  // .ant-menu-submenu-selected {
  //   .ant-menu-submenu-title {
  //     color: var(--text-Slider-primary-color);

  //     .ant-menu-submenu-expand-icon,
  //     .ant-menu-submenu-arrow,
  //     span[role='img'] {
  //       color: ${colors.secondaryText};
  //       fill: ${colors.secondaryText};
  //     }
  //   }
  // }

  .ant-menu-submenu-title {
    padding-left: 0px !important;
    width: 100%;
  }

  // .ant-menu-item-selected {
  //   background-color: ${colors.secondaryBackground} !important;
  //   .ant-menu-submenu-expand-icon,
  //   .ant-menu-submenu-arrow,
  //   span[role='img'],
  //   .ant-menu-item-icon,
  //   a {
  //     color: ${colors.secondaryText};
  //     fill: ${colors.secondaryText};
  //   }
  // }

  // .ant-menu-item-selected:hover {
  //   background-color: ${colors.secondaryBackground} !important;
  // }

  .ant-menu-item-active,
  .ant-menu-submenu-active .ant-menu-submenu-title {
    background-color: transparent !important;
  }
`

export const LeftPoint = styled.div<{ isSelected?: boolean }>`
  transition: 0.5s ease;
  border-top-right-radius: ${BORDER_RADIUS};
  border-bottom-right-radius: ${BORDER_RADIUS};
  height: 17px;
  width: 5px;
  margin-right: 6px;
  background: ${props =>
    props.isSelected ? colors.secondaryText : 'transparent'};
`

export const LeftPointSubMenu = styled.div<{ isSelected?: boolean }>`
  transition: 0.5s ease;
  border-radius: ${BORDER_RADIUS};
  height: 6px;
  width: 6px;
  margin-left: 11px;
  background: ${props =>
    props.isSelected ? colors.secondaryText : 'transparent'};
`

export const ItemMenuCustomWrapper = styled(BaseRow)<{
  isSelected: boolean
}>`
  background-color: ${colors.white};
  border-radius: ${BORDER_RADIUS};
`

export const LabelCustom = styled.span<{
  isSelected: boolean
  isSubMenu?: boolean
}>`
  color: ${props => (props.isSelected ? '#032648' : colors.grayC4)};
  font-weight: ${props =>
    props.isSelected ? FONT_WEIGHT.bold : FONT_WEIGHT.medium};
  margin-left: 8px;
`

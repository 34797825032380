import MainLayout from 'app/components/layouts/main/landing/MainLayout'
import RequireAuth from 'app/components/router/require-auth-router'
import { withLoading } from 'app/hocs/with-loading.hoc'
import DashboardPage from 'app/page/admin/dashboard'
import ReportsPage from 'app/page/admin/reports'
import SettingPage from 'app/page/admin/settings'
import { HomePage } from 'app/page/landing-page'
import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import MainLayoutAdmin from '../layouts/main/admin/MainLayout'
import { AuthRouter } from './auth-router'
import LandingRouter from './landing-router'
import { ADMIN_PATH, DASHBOARD_PATH } from './route-path'

const Home = withLoading(HomePage)
const Dashboard = withLoading(DashboardPage)
const Reports = withLoading(ReportsPage)
const Setting = withLoading(SettingPage)

export const AppRouter: React.FC = () => {
  const protectedLayout = (
    <RequireAuth>
      <MainLayoutAdmin />
    </RequireAuth>
  )

  const landingLayout = (
    <LandingRouter>
      <MainLayout />
    </LandingRouter>
  )

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path={DASHBOARD_PATH} element={landingLayout}>
            <Route index element={<Home />} />
          </Route>
          <Route path={ADMIN_PATH.HOME} element={protectedLayout}>
            <Route index element={<Dashboard />} />
            <Route path={ADMIN_PATH.DASHBOARD} element={<Dashboard />} />
            <Route path={ADMIN_PATH.REPORTS} element={<Reports />} />
            <Route path={ADMIN_PATH.SETTING} element={<Setting />} />
          </Route>
          {AuthRouter()}
        </Routes>
      </BrowserRouter>
    </>
  )
}

/* eslint-disable @typescript-eslint/no-unused-vars */
import { IParamsListGetReportOrder } from 'app/api/dashboard/type'
import { TypeDateEnum } from 'app/api/report/type'
import R from 'app/assets/R'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import {
  FilterReportOrderEnum,
  getMonthRange,
  getTodayRange,
  getWeekRange,
} from 'app/containers/admin/desktop/Dashboard'
import i18n from 'app/i18n'
import moment from 'moment'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import ChartHeaderLayout from '../ChartHeader'
import TableOrderHistory from './layouts/Table/table-order'

const OrderReportLayout = () => {
  const [time, setTime] = useState<FilterReportOrderEnum>(
    FilterReportOrderEnum.Today,
  )
  const [params, setParams] = useState<IParamsListGetReportOrder>({
    page: 1,
    page_size: 10,
    end_date: getMonthRange().endDate,
    start_date: getMonthRange().startDate,
    type_date: TypeDateEnum.daily,
  })

  const onChangePage = (page: number, pageSize?: number) => {
    setParams({
      ...params,
      page,
      page_size: pageSize ?? 10,
    })
  }

  const onChangeTimeType = (value: FilterReportOrderEnum) => {
    setTime(value)

    switch (value) {
      case FilterReportOrderEnum.Today:
        setParams(prev => ({
          ...prev,
          end_date: getTodayRange().endDate,
          start_date: getTodayRange().startDate,
        }))
        break
      case FilterReportOrderEnum.ThisWeek:
        setParams(prev => ({
          ...prev,
          end_date: getWeekRange().endDate,
          start_date: getWeekRange().startDate,
        }))
        break
      case FilterReportOrderEnum.ThisMonth:
        setParams(prev => ({
          ...prev,
          end_date: getMonthRange().endDate,
          start_date: getMonthRange().startDate,
        }))
        break
    }
  }

  const onChange = (type_date: TypeDateEnum) => {
    setParams(prev => ({
      ...prev,
      type_date,
    }))
  }

  const { t } = useTranslation()

  const today = moment().locale(i18n.language)

  return (
    <BaseSpace>
      <BaseRow align={'middle'} justify={'space-between'}>
        <BaseCol>
          <BaseSpace size={4}>
            <BaseText
              children={t(R.strings.reports_common_order_report)}
              fontSize="xxl"
              fontWeight="bold"
              fontFamily="Raleway"
            />

            <BaseText
              children={today.format('dddd, D MMM YYYY')}
              fontSize="xxs"
              style={{ color: '#9A9FA5' }}
            />
          </BaseSpace>
        </BaseCol>
        <BaseCol>
          <ChartHeaderLayout
            hideFilter
            onChange={onChange}
            params={params.type_date}
          />
        </BaseCol>
      </BaseRow>

      <BaseRow align={'middle'} justify={'end'} gutter={[16, 16]}>
        <BaseCol>
          <BaseSpace size={4}>
            <BaseText
              children={'0'}
              fontSize="md"
              fontWeight="semibold"
              textAlign="center"
            />
            <BaseText
              children={'Dine-ins'}
              fontSize="xxxs"
              fontWeight="regular"
              textAlign="center"
            />
          </BaseSpace>
        </BaseCol>
        <BaseCol>
          <BaseSpace size={4}>
            <BaseText
              children={'0'}
              fontSize="md"
              fontWeight="semibold"
              textAlign="center"
            />
            <BaseText
              children={'Out for delivery'}
              fontSize="xxxs"
              fontWeight="regular"
              textAlign="center"
            />
          </BaseSpace>
        </BaseCol>
        <BaseCol>
          <BaseSpace size={4}>
            <BaseText
              children={'0'}
              fontSize="md"
              fontWeight="semibold"
              textAlign="center"
            />
            <BaseText
              children={'To Go'}
              fontSize="xxxs"
              fontWeight="regular"
              textAlign="center"
            />
          </BaseSpace>
        </BaseCol>
        <BaseCol>
          <BaseSpace size={4}>
            <BaseText
              children={'0'}
              fontSize="md"
              fontWeight="semibold"
              textAlign="center"
            />
            <BaseText
              children={'Total Orders'}
              fontSize="xxxs"
              fontWeight="regular"
              textAlign="center"
            />
          </BaseSpace>
        </BaseCol>
      </BaseRow>

      <TableOrderHistory params={params} onChangePage={onChangePage} />
    </BaseSpace>
  )
}

export default OrderReportLayout

import React, { ComponentProps } from 'react'
import { Select as AntSelect } from 'antd'
import { RefSelectProps } from 'antd/lib/select'
import * as S from './styles'
import { Dimension } from 'parkway-web-common'
import { SvgCaretDownIcon } from 'app/assets/svg-assets'

export const { Option } = AntSelect

export interface BaseSelectProps extends ComponentProps<typeof AntSelect> {
  width?: Dimension
  shadow?: boolean
  className?: string
}

export const BaseSelect = React.forwardRef<RefSelectProps, BaseSelectProps>(
  ({ className, width, shadow, children, ...props }, ref) => (
    <S.Select
      getPopupContainer={triggerNode => triggerNode}
      ref={ref}
      className={className}
      $width={width}
      $shadow={shadow}
      suffixIcon={
        <SvgCaretDownIcon style={{ width: 22, height: 22, marginTop: -5 }} />
      }
      {...props}
    >
      {children}
    </S.Select>
  ),
)

import { TimePicker as AntTimePicker } from 'antd'
import { normalizeProp } from 'app/hook'
import { Dimension } from 'parkway-web-common'
import styled from 'styled-components'

export interface InternalTimePickerProps {
  $width?: Dimension
  $okText?: string
}

export const CustomTimePicker = styled(AntTimePicker)<InternalTimePickerProps>`
  width: ${props => props.$width && normalizeProp(props.$width)};
  background: transparent;
  &,
  &:hover,
  &.ant-picker-focused {
    border-color: #ffffff;
  }
  .ant-picker-input {
    input::placeholder {
      color: #d9d9d92b;
    }
    .ant-picker-clear {
      background: transparent;
    }
    &:hover:has(.ant-picker-clear) .ant-picker-suffix {
      opacity: 0;
    }
    .ant-picker-suffix svg {
      fill: none;
    }
    .ant-picker-clear svg {
      fill: #9a9b9f;
    }
  }
  .ant-picker-dropdown {
    .ant-picker-time-panel-column
      .ant-picker-time-panel-cell-selected
      .ant-picker-time-panel-cell-inner {
      background: #d9f7be;
    }
  }
  .ant-picker-footer {
    button span {
      display: none;
    }
    button:not(:disabled),
    button:not(:disabled):hover,
    button:not(:disabled):active {
      background-color: #29cc6a !important;
    }
    button:before {
      content: '${props => props.$okText}';
    }
  }
`

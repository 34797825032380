import { CheckOutlined } from '@ant-design/icons'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import { AUTH_LOGIN_PATH } from 'app/components/router/route-path'
import { useNavigateHook } from 'app/hook'
import { setServicePackage } from 'app/service/storage/storage-service'
import { IPricing, subscriptionPlans } from '../../desktop-layout/LayoutThree'
import * as S from './styles'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import R from 'app/assets/R'

const ItemBenefit = ({ item }: { item: IPricing }) => {
  const { t } = useTranslation()
  const { package: itemPackage, price, benefits } = item

  const { navigateAuthPath } = useNavigateHook()

  const onClick = () => {
    setServicePackage(itemPackage)
    navigateAuthPath(AUTH_LOGIN_PATH)
  }
  return (
    <S.ItemWrapper>
      <S.ContentWrapper onClick={onClick}>
        <S.AreaText>
          <S.IconItem
            className="icon"
            src={
              'https://storage.googleapis.com/kiosify-01/images/ic_rocket.png'
            }
            preview={false}
          />
        </S.AreaText>
        <S.TitleItem className="title">{itemPackage}</S.TitleItem>
        <S.AreaText>
          <S.TextPriceItem>{price}</S.TextPriceItem>
        </S.AreaText>
        <S.ListBenefitOption justify={'start'}>
          <BaseSpace direction="vertical" size={20}>
            {benefits.map((be, index) => {
              return (
                <BaseRow gutter={15} align={'middle'} key={index} wrap={false}>
                  <BaseCol>
                    <CheckOutlined rev={undefined} />
                  </BaseCol>
                  <BaseCol>
                    <S.TextOption>{be}</S.TextOption>
                  </BaseCol>
                </BaseRow>
              )
            })}
          </BaseSpace>
        </S.ListBenefitOption>
        <S.AreaText />
        <S.ButtonTrial className="button-trial" onClick={onClick}>
          {t(R.strings.start_14_days_trail)}
        </S.ButtonTrial>
      </S.ContentWrapper>
    </S.ItemWrapper>
  )
}

const LayoutThreeHome = () => {
  const { t, i18n } = useTranslation()

  const dataPricingResult = useMemo(() => subscriptionPlans(), [i18n.language])

  return (
    <S.RootWrapper>
      <BaseCol xl={24}>
        <S.AreaText>
          <S.TitleText>{t(R.strings.pricing)}</S.TitleText>
        </S.AreaText>
        <S.DescriptionText>{t(R.strings.footer_kiosify)}</S.DescriptionText>
        <S.AreaText />
        <S.AreaText />
      </BaseCol>
      <S.ListWrapper gutter={[10, 50]} justify={'center'} wrap={true}>
        {dataPricingResult.map((item, index) => {
          return (
            <BaseCol xl={24} key={index}>
              <ItemBenefit item={item} />
            </BaseCol>
          )
        })}
      </S.ListWrapper>
    </S.RootWrapper>
  )
}
export default LayoutThreeHome

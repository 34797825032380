/* eslint-disable @typescript-eslint/no-explicit-any */
import { SearchOutlined } from '@ant-design/icons'
import { Empty } from 'antd'
import { IRestaurant } from 'app/api/restaurant/model'
import R from 'app/assets/R'
import { SvgSortAscendingIcon, SvgSortDescIcon } from 'app/assets/svg-assets'
import { BaseButton } from 'app/components/common/BaseButton'
import { ButtonVariant } from 'app/components/common/BaseButtonVariant'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseImage } from 'app/components/common/BaseImage'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { BaseInput } from 'app/components/common/inputs/BaseInput'
import { Loading } from 'app/components/common/Loading'
import { URL_SETTING } from 'app/containers/admin/Settings/constant'
import { convertAddressToString, useDebounce } from 'app/hook'
import { useGetRestaurantListV2 } from 'app/react-query/hook/restaurant'
import { useAppDispatch } from 'app/redux/hooks'
import {
  setPathSetting,
  setRestaurantSetting,
} from 'app/redux/slices/pathSettingSlice'
import { memo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as S from './styles'

const RestaurantLayout = () => {
  const dispatch = useAppDispatch()
  const [search, setSearch] = useState('')
  const { t } = useTranslation()
  // const { flattenData } = usePagination()
  const searchDebounce = useDebounce(search, 500)
  // const { data, isLoading: loading } = useGetRestaurantList({
  //   search: searchDebounce,
  // })
  const { data: restaurants, isLoading: loading } = useGetRestaurantListV2({
    searchKey: searchDebounce,
  })

  const navigateToRestaurantDetail = (data: IRestaurant) => {
    dispatch(setPathSetting(URL_SETTING.RestaurantDetail))
    dispatch(setRestaurantSetting(data))
  }

  const onPressCreate = () => {
    dispatch(setPathSetting(URL_SETTING.RestaurantCreate))
  }

  return (
    <S.RootWrapper>
      <BaseRow align={'middle'} justify={'space-between'} gutter={16}>
        <BaseCol>
          <BaseForm.Item>
            <BaseInput
              placeholder="Search restaurant"
              prefix={<SearchOutlined rev={undefined} />}
              style={{ padding: '8px 10px' }}
              onChange={e => setSearch(e?.target?.value)}
            />
          </BaseForm.Item>
        </BaseCol>

        <BaseCol>
          <BaseRow gutter={5} align={'middle'}>
            {/* <BaseCol>
              <BaseButton
                icon={<SvgSortDescIcon style={{ width: 26, height: 26 }} />}
                type="text"
              />
            </BaseCol>

            <BaseCol>
              <BaseButton
                icon={
                  <SvgSortAscendingIcon style={{ width: 26, height: 26 }} />
                }
                type="text"
              />
            </BaseCol> */}

            <BaseCol>
              <ButtonVariant
                children={
                  <BaseText children={'Add Restaurant'} fontWeight="semibold" />
                }
                onClick={onPressCreate}
              />
            </BaseCol>
          </BaseRow>
        </BaseCol>
      </BaseRow>
      <BaseRow gutter={5} align={'middle'} justify={'end'}>
        <BaseCol>
          <BaseRow gutter={5} align={'middle'}>
            <BaseCol>
              <BaseButton
                icon={<SvgSortDescIcon style={{ width: 26, height: 26 }} />}
                type="text"
              />
            </BaseCol>

            <BaseCol>
              <BaseButton
                icon={
                  <SvgSortAscendingIcon style={{ width: 26, height: 26 }} />
                }
                type="text"
              />
            </BaseCol>
          </BaseRow>
        </BaseCol>

        <BaseCol>
          <ButtonVariant
            children={
              <BaseText children={'Add Restaurant'} fontWeight="semibold" />
            }
          />
        </BaseCol>
      </BaseRow>

      <BaseSpace size={16}>
        {restaurants?.map((item, index) => {
          return (
            <S.ItemRestaurant
              onClick={() => navigateToRestaurantDetail(item)}
              key={index}
              justify={'space-between'}
              align={'middle'}
              gutter={[16, 16]}
            >
              <BaseCol>
                <BaseRow gutter={[16, 16]} align={'middle'}>
                  <BaseCol>
                    <S.ImageHeadItem
                      src={item?.image ?? R.images.ic_restaurant_item}
                      width={32}
                      height={32}
                      preview={false}
                    />
                  </BaseCol>
                  <BaseCol>
                    <BaseSpace size={4}>
                      <BaseText fontSize="xs" fontWeight="semibold">
                        {`${t(R.strings.restaurant)} ${item.businessName}`}
                      </BaseText>
                      <BaseText
                        children={convertAddressToString(item?.address)}
                        fontSize="xxxs"
                        fontWeight="semibold"
                        style={{ color: '#818181' }}
                      />
                    </BaseSpace>
                  </BaseCol>
                </BaseRow>
              </BaseCol>

              <BaseCol>
                <BaseImage
                  width={24}
                  height={24}
                  src={R.images.ic_caret_right_restaurant}
                  preview={false}
                />
              </BaseCol>
            </S.ItemRestaurant>
          )
        })}
        {restaurants?.length === 0 && <Empty />}
      </BaseSpace>
      {loading && <Loading />}
    </S.RootWrapper>
  )
}

export default memo(RestaurantLayout)

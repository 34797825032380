import { isEqual } from 'lodash'
import { useMemo } from 'react'

import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { useAppDispatch, useAppSelector } from 'app/redux/hooks'
import { setPathSetting } from 'app/redux/slices/pathSettingSlice'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

export interface IMenuSetting {
  url: string
  title?: string
  description?: string
  icon?: string
  iconFocus?: string
}

export const ItemMenuCpn = ({
  item,
  type = 'link',
  onPress,
  index,
  total,
}: {
  item: IMenuSetting
  type?: 'link' | 'action'
  onPress?: () => void
  index: number
  total: number
}) => {
  const currentPath = useAppSelector(state => state?.pathSetting)?.path
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const isSelected = useMemo(() => {
    const pathSlice = currentPath
      .split('/')
      .filter(component => component !== '')

    const path = pathSlice?.find(itm => isEqual(itm, item?.url))
    // console.log({ pathSlice, item })

    if (path) {
      return true
    }
    return isEqual(currentPath, item?.url)
  }, [currentPath, item?.url])

  const onClickItem = () => {
    switch (type) {
      case 'link':
        dispatch(setPathSetting(item?.url))
        break
      case 'action':
        onPress?.()
        break
      default:
        dispatch(setPathSetting(item?.url))
        break
    }
  }

  return (
    <CardItem
      key={index}
      onClick={onClickItem}
      $isLastItem={isEqual(index + 1, total)}
      $isFirstItem={isEqual(index, 0)}
      $isSelected={isSelected}
      size={8}
    >
      <BaseText
        children={t(item?.title ?? '')}
        fontSize="xs"
        fontWeight="medium"
      />
      <BaseText
        children={t(item?.description ?? '')}
        fontWeight="regular"
        style={{ color: '#9A9FA5' }}
      />
      <div
        style={{
          width: 9,
          height: '100%',
          position: 'absolute',
          top: 0,
          left: 0,
          backgroundColor: isSelected ? '#54E9CD' : 'transparent',
          borderRadius: (() => {
            switch (index) {
              case 0:
                return '100px 0px 100px 0px'
              case total - 1:
                return '0px 100px 0px 100px'
              default:
                return '0px 100px 100px 0px'
            }
          })(),
        }}
      />
    </CardItem>
  )
}

const CardItem = styled(BaseSpace)<{
  $isSelected?: boolean
  $isLastItem?: boolean
  $isFirstItem?: boolean
}>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  background-color: ${props => (props?.$isSelected ? '#DDFBF5' : 'white')};
  cursor: pointer;
  position: relative;
  ${props => {
    if (props?.$isLastItem) {
      return `border-radius: 0px 0px 12px 12px;`
    }

    if (props?.$isFirstItem) {
      return `border-radius: 12px 12px 0px 0px;`
    }
  }}
  z-index: 1;
`

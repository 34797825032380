import { BaseImage } from 'app/components/common/BaseImage'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import {
  BORDER_RADIUS,
  FONT_SIZE,
  FONT_WEIGHT,
  PADDING,
} from 'parkway-web-common'
import styled from 'styled-components'

export const RootWrapper = styled(BaseSpace)`
  border-radius: 12px;
  position: relative;
`

export const ItemRestaurant = styled(BaseRow)`
  border: 1px solid rgba(0, 0, 0, 0.25);
  cursor: pointer;
  border-radius: ${BORDER_RADIUS};
  transition: all 1s ease;
  padding: 8px;
  margin-left: 0px !important;
  margin-right: 0px !important;
  &:hover {
    border-color: #54e9cd;
    transition: all 1s ease;
  }
`

export const HeadItemRestaurant = styled.div`
  background-color: #393c49; //need change
  border-radius: ${BORDER_RADIUS};
  padding: ${PADDING.xxxl} 2.75rem;
  transition: all 1s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    transition: all 1s ease;
    background-color: var(--admin-background-focus-setting-menu-color);
  }
`

export const ImageHeadItem = styled(BaseImage)`
  border-radius: ${BORDER_RADIUS};
`

export const NameRestaurant = styled(BaseRow)`
  color: rgba(171, 187, 194, 1); //need change
  font-size: ${FONT_SIZE.xs};
  font-weight: ${FONT_WEIGHT.medium};
  padding: ${PADDING.xxxs};
  width: 100%;
  text-align: center;
`

export const ListWrapper = styled(BaseRow)`
  padding: ${PADDING.xxxl} 0px;
`

import { BaseCol } from 'app/components/common/BaseCol'
import * as S from './styles'
import { BaseRow } from 'app/components/common/BaseRow'
import R from 'app/assets/R'
import { useTranslation } from 'react-i18next'

const LayoutTwoHome = () => {
  const { t } = useTranslation()

  return (
    <S.RootWrapper>
      <BaseCol xl={24}>
        <S.AreaText>
          <S.TitleText>
            {t(R.strings.personalized_recommendations_and_promotions)}
          </S.TitleText>
        </S.AreaText>
        <S.DescriptionText>
          {t(R.strings.personalized_recommendations_and_promotions_d)}
        </S.DescriptionText>
        <S.AreaText />
        <S.AreaText />
        <BaseRow>
          <BaseCol xl={17}>
            <S.ImageWrapper
              src={
                'https://storage.googleapis.com/kiosify-01/images/img_landing_page_chart.png'
              }
              preview={false}
            />
          </BaseCol>
          <S.SmallWrapper xl={7}>
            <S.TextTitleSmall>
              {t(R.strings.revolutionizing_customer_engagement)}
            </S.TextTitleSmall>
            <S.AreaText>
              <S.TextDescriptionSmall>
                {t(R.strings.revolutionizing_customer_engagement_d)}
              </S.TextDescriptionSmall>
            </S.AreaText>
          </S.SmallWrapper>
        </BaseRow>
      </BaseCol>
    </S.RootWrapper>
  )
}
export default LayoutTwoHome

import R from 'app/assets/R'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { Loading } from 'app/components/common/Loading'
import ReactECharts from 'echarts-for-react'
import { formatMoney } from 'parkway-web-common'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const StyledCard = styled(BaseSpace)`
  border-radius: 12px;
  background-color: white;
  padding: 16px;
  padding-bottom: 0px;
`

const CustomChart: React.FC<{
  data: {
    togo: number
    for_here: number
    pickup: number
  }
  isLoading?: boolean
}> = ({ data, isLoading }) => {
  const { t } = useTranslation()

  const dataOption = useMemo(() => {
    return [
      {
        type: t(R.strings.label_common_pick_up),
        value: data?.pickup,
        color: '#6DC24F',
      },
      {
        type: t(R.strings.label_common_for_here),
        value: data?.for_here,
        color: '#4A97FF',
      },
      {
        type: t(R.strings.label_common_to_go),
        value: data?.togo,
        color: '#FFBC99',
      },
    ]
  }, [data, t])

  const options = useMemo(() => {
    return {
      xAxis: {
        type: 'category',
        data: dataOption?.map(item => item.type),
        axisLabel: {
          color: '#84878A',
          fontSize: 12,
        },
      },
      yAxis: {
        type: 'value',
        max: 50,
      },
      series: [
        {
          data: dataOption?.map(item => ({
            value: item.value,
            itemStyle: { color: item.color, borderRadius: [5, 5, 2, 2] },
          })),
          type: 'bar',
          barWidth: '30px',
        },
      ],
      tooltip: {
        trigger: 'axis',
        axisPointer: { type: 'shadow' },
      },
      grid: {
        left: '0%',
        right: '2%',
        bottom: '10%',
        top: '5%',
        containLabel: true,
      },
    }
  }, [dataOption])

  return (
    <StyledCard>
      <BaseRow>
        <BaseText
          children={t(R.strings.dashboard_common_most_type_order)}
          fontSize="xl"
          fontWeight="regular"
        />
      </BaseRow>

      <BaseRow justify={'space-between'} align={'middle'} gutter={8}>
        <BaseCol xl={20}>
          <ReactECharts option={options} style={{ height: 240 }} />
        </BaseCol>

        <BaseCol xl={4}>
          <BaseSpace size={12}>
            {dataOption?.map(item => (
              <BaseSpace size={2}>
                <BaseRow key={item.type} gutter={8} wrap={false}>
                  <BaseCol>
                    <div
                      style={{
                        display: 'inline-block',
                        width: 15,
                        height: 15,
                        backgroundColor: item.color,
                        borderRadius: '50%',
                      }}
                    />
                  </BaseCol>
                  <BaseCol>
                    <span style={{ fontWeight: 600 }}>{item.type}</span> <br />
                  </BaseCol>
                </BaseRow>
                <span style={{ color: '#8C8C8C' }}>
                  {formatMoney(item.value, '')} customers
                </span>
              </BaseSpace>
            ))}
          </BaseSpace>
        </BaseCol>
      </BaseRow>

      {isLoading ? <Loading /> : null}
    </StyledCard>
  )
}

export default CustomChart

/* eslint-disable @typescript-eslint/no-explicit-any */
import { Rule } from 'antd/es/form'
import { ISignUpResponse, SignUpRequest, requestPostSignUp } from 'app/api/auth'
import R from 'app/assets/R'
import { IResponseDefine } from 'app/common/model'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { BaseTemplatePageAuth } from 'app/components/layouts/AuthLayout/Component'
import * as Auth from 'app/components/layouts/AuthLayout/styles'
import { AUTH_VERIFY_EMAIL_PATH } from 'app/components/router/route-path'
import { REGEX_PHONE } from 'app/constant'
import { REG_EMAIL } from 'parkway-web-common'
import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'

interface SignUpFormData {
  firstName?: string
  lastName?: string
  phone?: string
  email: string
  password: string
  confirmPassword?: string
}

interface RuleSignUp {
  firstName: Rule[]
  lastName: Rule[]
  phone: Rule[]
  email: Rule[]
  password: Rule[]
  confirmPassword: Rule[]
}

export const initValues: SignUpFormData = {
  email: ``,
  password: '',
  confirmPassword: '',
  phone: '',
  lastName: '',
  firstName: '',
}

const SignUpContainer: React.FC = () => {
  const navigate = useNavigate()
  // const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const [isLoading, setLoading] = useState(false)

  const handleSubmit = async (values: SignUpFormData) => {
    setLoading(true)
    try {
      const payload: SignUpRequest = {
        email: values.email,
        password: values.password,
        phone_number: values.phone,
        first_name: values.firstName,
        last_name: values.lastName,
      }

      const res: IResponseDefine<ISignUpResponse> = await requestPostSignUp(
        payload,
      )

      navigate(AUTH_VERIFY_EMAIL_PATH, {
        state: { email: values.email, code: res.data.activation_code },
      })

      setLoading(false)
    } catch (error: any) {
      console.log({ error })
      setLoading(false)
    }
  }

  const rule: RuleSignUp = useMemo(() => {
    return {
      firstName: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.first_name),
          }),
        },
      ],
      email: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.email),
          }),
        },
        {
          pattern: REG_EMAIL,
          message: t(R.strings.regex_email),
        },
      ],
      password: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.password),
          }),
        },
        {
          min: 8,
          message: t(R.strings.minimum_field, {
            field: t(R.strings.password),
            count: 8,
          }),
        },
      ],
      lastName: [],
      phone: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.phone_no),
          }),
        },
        {
          pattern: REGEX_PHONE,
          message: t(R.strings.regex_phone),
        },
      ],
      confirmPassword: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.confirm_password),
          }),
        },
        {
          min: 8,
          message: t(R.strings.minimum_field, {
            field: t(R.strings.password),
            count: 8,
          }),
        },
        ({ getFieldValue }) => ({
          validator(_, value) {
            if (!value || getFieldValue('password') === value) {
              return Promise.resolve()
            }
            return Promise.reject(
              new Error(t(R.strings.confirm_password_incorrect)),
            )
          },
        }),
      ],
    }
  }, [t])

  return (
    <BaseTemplatePageAuth>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flex: 1,
          height: 'calc(100% - 10%)',
        }}
      >
        <div style={{ width: 450 }}>
          <BaseForm
            layout="vertical"
            onFinish={handleSubmit}
            requiredMark="optional"
            initialValues={initValues}
          >
            <BaseSpace>
              <Auth.FormTitle>{'Sign Up Now'}</Auth.FormTitle>
              <Auth.DescriptionText style={{ color: '#42E5C6' }}>
                {'Create your KIOSIFY account'}
              </Auth.DescriptionText>
              <BaseSpace size={8}>
                <BaseRow gutter={16} align={'top'}>
                  <BaseCol xl={12}>
                    <Auth.FormItem name="firstName" rules={rule.firstName}>
                      <Auth.FormInput placeholder="Your first name *" />
                    </Auth.FormItem>
                  </BaseCol>
                  <BaseCol xl={12}>
                    <Auth.FormItem name="lastName" rules={rule.lastName}>
                      <Auth.FormInput placeholder="Your last name" />
                    </Auth.FormItem>
                  </BaseCol>
                </BaseRow>
                <Auth.FormItem name="email" rules={rule.email}>
                  <Auth.FormInput placeholder="Your email *" />
                </Auth.FormItem>

                <Auth.FormItem name="phone" rules={rule.phone}>
                  <Auth.FormInput placeholder="Your phone" />
                </Auth.FormItem>

                <Auth.FormItem name="password" rules={rule.password}>
                  <Auth.FormInputPassword placeholder="Your password *" />
                </Auth.FormItem>

                <Auth.FormItem
                  name="confirmPassword"
                  rules={rule.confirmPassword}
                >
                  <Auth.FormInputPassword placeholder="Your confirm password *" />
                </Auth.FormItem>
              </BaseSpace>
              <BaseForm.Item name="rememberMe" valuePropName="checked" noStyle>
                <Auth.FormCheckbox>
                  <Auth.DescriptionText
                    style={{ opacity: 0.4, textAlign: 'left' }}
                  >
                    {`By signing up you agree to Our Terms and Privacy Policy`}
                  </Auth.DescriptionText>
                </Auth.FormCheckbox>
              </BaseForm.Item>
              <BaseRow justify={'center'}>
                <BaseForm.Item noStyle>
                  <Auth.SubmitButton htmlType="submit" loading={isLoading}>
                    {t(R.strings.sign_up)}
                  </Auth.SubmitButton>
                </BaseForm.Item>
              </BaseRow>
            </BaseSpace>
          </BaseForm>
        </div>
      </div>
    </BaseTemplatePageAuth>
  )
}
export default SignUpContainer

import styled, { css } from 'styled-components'
import { Form, FormItemProps } from 'antd'
import { FONT_SIZE, FONT_WEIGHT, PADDING, media } from 'parkway-web-common'

interface InternalFormItemProps {
  $isSuccess?: boolean
  $successText?: string
}

export type BaseFormItemProps = FormItemProps

export const BaseFormItem = styled(Form.Item)<InternalFormItemProps>`
  .ant-form-item-label > label {
    color: #959895;
    font-weight: ${FONT_WEIGHT.regular};
    font-size: ${FONT_SIZE.xxs};
  }

  .ant-input-group-addon:first-of-type {
    font-weight: ${FONT_WEIGHT.medium};
    width: 5rem;

    color: #959895;

    .anticon,
    svg {
      font-size: 1.25rem;
    }

    @media only screen and ${media.md} {
      width: 5.5rem;
      font-size: 1.125rem;
    }

    @media only screen and ${media.xl} {
      font-size: 1.5rem;
    }
  }

  .ant-input-suffix .ant-btn {
    padding: 0;
    width: unset;
    height: unset;
    line-height: 1;
  }

  .ant-form-item-optional {
    display: none !important ;
  }

  .ant-form-item-explain-error {
    display: flex;
    margin: 0px;
    padding-bottom: ${PADDING.xxxxs};
    line-height: 1;
    font-weight: ${FONT_WEIGHT.regular};
    font-size: ${FONT_SIZE.xxs};

    &:not(:first-of-type) {
      display: none;
    }

    width: 100%;
  }

  ${props =>
    props.$isSuccess &&
    css`
      .ant-input {
        &,
        &:hover {
          border-color: var(--success-color);
        }
      }

      .ant-form-item-control-input {
        display: block;

        &::after {
          content: '✓ ${props.$successText}';
          color: var(--success-color);
        }
      }
    `}

  &.ant-form-item-has-feedback .ant-form-item-children-icon {
    display: none;
  }

  .ant-picker-suffix {
    font-size: 1rem;
  }

  .ant-select-arrow {
    font-size: 1rem;
    width: unset;
    height: unset;
    top: 50%;
  }

  &.ant-form-item-has-error .ant-input,
  &.ant-form-item-has-error .ant-input-affix-wrapper,
  &.ant-form-item-has-error .ant-input:hover,
  &.ant-form-item-has-error .ant-input-affix-wrapper:hover {
    border-color: var(--error-color);
  }

  &.ant-form-item-has-success.ant-form-item-has-feedback .ant-input,
  &.ant-form-item-has-success.ant-form-item-has-feedback
    .ant-input-affix-wrapper,
  &.ant-form-item-has-success.ant-form-item-has-feedback .ant-input:hover,
  &.ant-form-item-has-success.ant-form-item-has-feedback
    .ant-input-affix-wrapper:hover {
    border-color: var(--success-color);
  }

  margin-bottom: 0px;
  & .ant-form-item-control-input {
    min-height: 3.125rem;
  }

  & .ant-form-item-label {
    padding: 0px;
  }

  & label {
    color: var(--primary-color);
    font-size: ${FONT_SIZE.xs};
    line-height: 1.25rem;
  }

  &.ant-form-item-has-feedback .ant-input-affix-wrapper .ant-input-suffix {
    padding-right: 1.5rem;
  }

  &.ant-form-item .ant-form-item-label > label::after {
    display: none !important;
  }
`

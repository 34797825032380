import { BaseCol } from 'app/components/common/BaseCol'
import { BaseSpace } from 'app/components/common/BaseSpace'
import styled from 'styled-components'

export const RootWrapper = styled.div`
  border-radius: 12px;
`

export const CardItemWrapper = styled(BaseCol)``

export const CardItem = styled(BaseSpace)<{
  $isSelected?: boolean
  $isLastItem?: boolean
  $isFirstItem?: boolean
}>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  background-color: ${props => (props?.$isSelected ? '#DDFBF5' : 'white')};
  cursor: pointer;
  position: relative;
  ${props => {
    if (props?.$isLastItem) {
      return `border-radius: 0px 0px 12px 12px;`
    }

    if (props?.$isFirstItem) {
      return `border-radius: 12px 12px 0px 0px;`
    }
  }}
  z-index: 1;
`

import R from 'app/assets/R'
import ReactCountryFlag from 'react-country-flag'
import { Language } from './type'

export const LANGUAGES: Language[] = [
  {
    value: 'en',
    label: R.strings.language_option_en,
    flag: <ReactCountryFlag svg countryCode="US" />,
  },
  {
    value: 'vi',
    label: R.strings.language_option_vi,
    flag: <ReactCountryFlag svg countryCode="VN" />,
  },
  {
    value: 'ja',
    label: R.strings.language_option_ja,
    flag: <ReactCountryFlag svg countryCode="JP" />,
  },
  {
    value: 'es',
    label: R.strings.language_option_es,
    flag: <ReactCountryFlag svg countryCode="ES" />,
  },
  {
    value: 'fr',
    label: R.strings.language_option_fr,
    flag: <ReactCountryFlag svg countryCode="FR" />,
  },
  {
    value: 'zh',
    label: R.strings.language_option_zh,
    flag: <ReactCountryFlag svg countryCode="CN" />,
  },
]

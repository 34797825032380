import { useAppSelector } from 'app/redux/hooks'
import { useCallback } from 'react'
import {
  AddYourKioskLayout,
  AppearanceLayout,
  BackupLayout,
  KdsSettingLayout,
  ProductManagementLayout,
  RestaurantDetailLayout,
  RestaurantLayout,
  YourInformationLayout,
  YourKioskLayout,
  EditYourKioskLayout,
  CreateRestaurant,
  UpdateRestaurant,
} from './layout'
import * as S from './styles'
import { URL_SETTING } from 'app/containers/admin/Settings/constant'

export const RightSide = () => {
  const currentPath = useAppSelector(state => state?.pathSetting)?.path

  const currentLayout = useCallback(() => {
    switch (currentPath) {
      case URL_SETTING.Appearance:
        return <AppearanceLayout />
      case URL_SETTING.Backup:
        return <BackupLayout />
      case URL_SETTING.Information:
        return <YourInformationLayout />
      case URL_SETTING.KDSSetting:
        return <KdsSettingLayout />
      case URL_SETTING.Restaurant:
        return <RestaurantLayout />
      case URL_SETTING.ProductsManagement:
        return <ProductManagementLayout />
      case URL_SETTING.Kiosks:
        return <YourKioskLayout />
      case URL_SETTING.RestaurantDetail:
        return <RestaurantDetailLayout />

      case URL_SETTING.AddYourKiosk:
        return <AddYourKioskLayout />

      case URL_SETTING.UpdateYourKiosk:
        return <EditYourKioskLayout />

      case URL_SETTING.RestaurantCreate:
        return <CreateRestaurant />
      case URL_SETTING.RestaurantUpdate:
        return <UpdateRestaurant />
    }
  }, [currentPath])()

  return (
    <S.RootWrapper
      $bgColor={
        [URL_SETTING.Appearance].includes(currentPath) ? 'transparent' : 'white'
      }
    >
      {currentLayout}
    </S.RootWrapper>
  )
}

import { BaseCol } from 'app/components/common/BaseCol'
import { BurgerIcon } from 'app/components/common/BurgerIcon'
import React from 'react'
import styled, { css } from 'styled-components'
import * as S from './MainSider/styles'

interface SliderLogoProps {
  isSliderCollapsed: boolean
  toggleSlider: () => void
}
export const SliderLogo: React.FC<SliderLogoProps> = ({
  toggleSlider,
  isSliderCollapsed,
}) => {
  return (
    <S.SliderLogoDiv>
      <BurgerCol>
        <MobileBurger onClick={toggleSlider} isCross={!isSliderCollapsed} />
      </BurgerCol>
    </S.SliderLogoDiv>
  )
}

export const BurgerCol = styled(BaseCol)`
  display: flex;
`

export const MobileBurger = styled(BurgerIcon)`
  width: 1.75rem;
  height: 1.75rem;
  margin-right: -0.5rem;
  color: var(--text-main-color);

  ${props =>
    props?.isCross &&
    css`
      color: var(--text-main-color);
    `};
`

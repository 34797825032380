import { BaseCol } from 'app/components/common/BaseCol'
import { BaseImage } from 'app/components/common/BaseImage'
import { PaddingMain } from 'app/constant'
import { FONT_SIZE, FONT_WEIGHT, PADDING, colors } from 'parkway-web-common'
import styled from 'styled-components'

export const RootWrapper = styled(BaseCol)`
  padding: ${PaddingMain.Mobile.defaultHz} 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`

export const AreaText = styled.div`
  padding: ${PADDING.xxs} 0px;
  text-align: left;
  font-family: 'Plus Jakarta Sans', sans-serif !important;
`

export const TitleText = styled.span`
  font-size: 36px;
  font-weight: ${FONT_WEIGHT.bold};
  color: ${colors.black};
  text-align: center;
  display: flex;
  justify-content: center;
  font-family: 'Plus Jakarta Sans', sans-serif !important;
`

export const DescriptionText = styled.span`
  color: ${colors.black};
  font-size: 20px;
  font-weight: ${FONT_WEIGHT.medium};
  display: flex;
  justify-content: center;
  text-align: center;
  font-family: 'Plus Jakarta Sans', sans-serif !important;
`
export const ImageWrapper = styled(BaseImage)`
  width: 100%;
  height: 100%;
`

export const SmallWrapper = styled(BaseCol)`
  padding: ${PADDING.xs};
  display: flex;
  flex-direction: column;
  justify-content: end;
`

export const TextTitleSmall = styled.span`
  color: ${colors.black};
  font-size: ${FONT_SIZE.xxxl};
  font-weight: ${FONT_WEIGHT.bold};
  font-family: 'Plus Jakarta Sans', sans-serif !important;
`

export const TextDescriptionSmall = styled.span`
  color: ${colors.black};
  font-size: ${FONT_SIZE.md};
  font-weight: ${FONT_WEIGHT.medium};
  font-family: 'Plus Jakarta Sans', sans-serif !important;
`

import AppearanceLayout from './Appearance'
import BackupLayout from './Backup'
import KdsSettingLayout from './KdsSetting'
import ProductManagementLayout from './ProductManagement'
import YourInformationLayout from './YourInformation'

import YourKioskLayout from './YourKiosk'
import EditYourKioskLayout from './YourKiosk/EditYourKiosk'
import AddYourKioskLayout from './YourKiosk/AddYourKiosk'

import RestaurantLayout from './Restaurant'
import CreateRestaurant from './Restaurant/CreateRestaurant'
import UpdateRestaurant from './Restaurant/UpdateRestaurant'
import RestaurantDetailLayout from './Restaurant/RestaurantDetail'

export {
  RestaurantLayout,
  AppearanceLayout,
  BackupLayout,
  KdsSettingLayout,
  ProductManagementLayout,
  YourKioskLayout,
  YourInformationLayout,
  RestaurantDetailLayout,
  EditYourKioskLayout,
  AddYourKioskLayout,
  CreateRestaurant,
  UpdateRestaurant,
}

/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  LoginResponse,
  requestPostLogin,
  requestPostResendCode,
} from 'app/api/auth'
import R from 'app/assets/R'
import { IResponseDefine } from 'app/common/model'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { BaseTemplatePageAuth } from 'app/components/layouts/AuthLayout/Component'
import * as Auth from 'app/components/layouts/AuthLayout/styles'
import {
  ADMIN_PATH,
  AUTH_RESET_PASSWORD_PATH,
  AUTH_SERVICE_PACKAGE_PATH,
  AUTH_SIGN_UP_PATH,
  AUTH_VERIFY_EMAIL_PATH,
} from 'app/components/router/route-path'
import { notificationController } from 'app/controllers/notification-controller'
import { useAppDispatch } from 'app/redux/hooks'
import { setSubscriptionPlan, setUser } from 'app/redux/slices/userSlice'
import {
  setEmailAuth,
  setPassword,
  setServicePackage,
  setToken,
} from 'app/service/storage/storage-service'
import { REG_EMAIL } from 'parkway-web-common'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import * as S from './styles'

interface LoginFormData {
  email: string
  password: string
  rememberMe?: boolean
}

export const initValues: LoginFormData = {
  email: '',
  password: '',
  rememberMe: false,
}

const LoginContainer: React.FC = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const [isLoading, setLoading] = useState(false)

  const onResendCode = async (email: string) => {
    try {
      setLoading(true)
      await requestPostResendCode({
        email,
      })
    } catch (err: any) {
      console.log({ err })
      // notificationController.error(err)
    } finally {
      setLoading(false)
    }
  }

  const handleSubmit = async (values: LoginFormData) => {
    try {
      setLoading(true)
      console.log({
        email: values.email,
        password: values.password,
      })

      const res: IResponseDefine<LoginResponse> = await requestPostLogin({
        email: values.email,
        password: values.password,
      })
      const data = res?.data
      console.log({ res })
      if (
        data?.user_steps?.step_verified_email &&
        data?.subscription_plan?.name
      ) {
        setToken(res.data.access_token)
        dispatch(setUser(res.data.user))
        dispatch(setSubscriptionPlan(res.data.subscription_plan))
        if (data?.subscription_plan?.name) {
          setServicePackage(data?.subscription_plan?.name)
        }
        navigate(ADMIN_PATH.HOME)
        setEmailAuth('')
        setPassword('')
        notificationController.success({
          message: t(R.strings.login_success),
        })
        return
      }

      setEmailAuth(values.email)
      setPassword(values.password)

      if (!data?.user_steps?.step_verified_email) {
        await onResendCode(values.email)
        navigate(AUTH_VERIFY_EMAIL_PATH, {
          state: { email: values.email },
        })
        notificationController.success({
          message: "You haven't verified your email yet",
        })
        return
      }

      // if (!data?.user_steps?.step_sync_from_square) {
      //   navigate(AUTH_VERIFIED_EMAIL_PATH, {
      //     state: { email: values.email },
      //   })
      //   notificationController.success({
      //     message: "You haven't sync your square account yet",
      //   })
      //   return
      // }

      if (!data?.user_steps?.step_payment_info) {
        navigate(AUTH_SERVICE_PACKAGE_PATH)
        notificationController.success({
          message: 'Please choose a package',
        })
        return
      }
    } catch (error: any) {
      console.log({ error })
      // notificationController.error(error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <BaseTemplatePageAuth>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flex: 1,
          height: 'calc(100% - 10%)',
        }}
      >
        <BaseForm
          layout="vertical"
          onFinish={handleSubmit}
          requiredMark="optional"
          initialValues={initValues}
        >
          <BaseSpace direction="vertical" size={18} style={{ width: '100%' }}>
            <div
              style={{
                color: 'white',
                fontSize: 36,
                fontWeight: 'bold',
                textAlign: 'center',
              }}
            >
              {'Sign In'}
            </div>

            <BaseRow
              gutter={24}
              align={'middle'}
              justify={'center'}
              style={{ marginLeft: 0, marginRight: 0 }}
            >
              <Auth.NormalText>{'Already have an account?'}</Auth.NormalText>
              <BaseCol>
                <div
                  style={{
                    color: '#25DAC5',
                    fontSize: 16,
                    textDecorationLine: 'underline',
                    fontWeight: 'normal',
                    cursor: 'pointer',
                  }}
                  onClick={() => navigate(AUTH_SIGN_UP_PATH)}
                >
                  {'Sign Up'}
                </div>
              </BaseCol>
            </BaseRow>

            <BaseSpace
              direction="vertical"
              size={9}
              style={{
                width: '100%',
                alignItems: 'center',
                paddingInline: 48,
              }}
            >
              <Auth.FormItem
                name="email"
                rules={[
                  {
                    required: true,
                    message: t(R.strings.require_field, {
                      field: t(R.strings.email),
                    }),
                  },
                  {
                    pattern: REG_EMAIL,
                    message: t(R.strings.regex_email),
                  },
                ]}
              >
                <Auth.FormInput
                  placeholder="Your email"
                  style={{
                    width: 'calc(100vw - 100px)',
                  }}
                />
              </Auth.FormItem>

              <Auth.FormItem
                name="password"
                rules={[
                  {
                    required: true,
                    message: t(R.strings.require_field, {
                      field: t(R.strings.password),
                    }),
                  },
                  {
                    min: 8,
                    message: t(R.strings.minimum_field, {
                      field: t(R.strings.password),
                      count: 8,
                    }),
                  },
                ]}
                style={{ width: '100%' }}
              >
                <Auth.FormInputPassword
                  placeholder="Your password"
                  style={{
                    width: 'calc(100vw - 100px)',
                  }}
                />
              </Auth.FormItem>
            </BaseSpace>
            <Auth.ActionsWrapper
              justify={'space-between'}
              align={'middle'}
              style={{ paddingInline: 48 }}
            >
              <BaseForm.Item name="rememberMe" valuePropName="checked" noStyle>
                <Auth.FormCheckbox>
                  <S.RememberMeText>
                    {`${t(R.strings.remember_me)}?`}
                  </S.RememberMeText>
                </Auth.FormCheckbox>
              </BaseForm.Item>
              <S.ForgotPasswordText
                onClick={() => navigate(AUTH_RESET_PASSWORD_PATH)}
              >
                {t(R.strings.forgot_password)}
              </S.ForgotPasswordText>
            </Auth.ActionsWrapper>
            <BaseRow justify={'center'}>
              <BaseForm.Item noStyle>
                <Auth.SubmitButton htmlType="submit" loading={isLoading}>
                  {t(R.strings.sign_in)}
                </Auth.SubmitButton>
              </BaseForm.Item>
            </BaseRow>
          </BaseSpace>
        </BaseForm>
      </div>
    </BaseTemplatePageAuth>
  )
}
export default LoginContainer

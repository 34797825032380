export default {
  empowering_kiosk_efficiency: 'Empowering Kiosk Efficiency',
  streamlined_content_management: 'Streamlined Content Management',
  streamlined_content_management_d:
    "Effortlessly manage and update your kiosk content, ensuring it's always up-to-date and engaging.",
  remote_kiosk_control: 'Remote Kiosk Control',
  remote_kiosk_control_d:
    'Take control of your kiosks from anywhere, monitor their performance, and make adjustments on the fly.',
  personalized_recommendations: 'Personalized Recommendations',
  personalized_recommendations_d:
    'Leverage customer data to deliver personalized product recommendations and promotions.',
  advanced_reporting: 'Advanced Reporting',
  advanced_reporting_d:
    'Generate comprehensive reports to identify trends, optimize strategies, and make informed decisions.',
  personalized_recommendations_and_promotions:
    'Personalized Recommendations and Promotions',
  personalized_recommendations_and_promotions_d:
    'Unlocking Personalized Recommendations and Promotions in the Kiosify Fast Order System',

  revolutionizing_customer_engagement:
    "Revolutionizing Customer Engagement: Kiosify's Personalized Recommendations and Promotions",
  revolutionizing_customer_engagement_d:
    "Kiosify leverages customer data to deliver personalized product recommendations and promotions. By analyzing purchase history, browsing behavior, and demographic information, Kiosify can tailor suggestions to each individual customer's preferences and interests. This personalized approach enhances customer satisfaction by showcasing relevant products and offers that align with their tastes.",
  sign_up_for_a_free_trial: 'Sign Up For A Free Trial',
  start_14_days_trail: 'Start 14 Days Trail',
  home_subcribe: 'Subcribe to our newsletter & get the latest updates',
  enter_your_email_address: 'Enter your email address',
  footer_kiosify: 'Kiosify: Elevate Your Customer Experience with Self-Service',
  contact_us: 'Contact US',

  home_slogan: 'Kiosify: The Future of Kiosk Management',
  home_slogan_d: "The Self-Order System That's Perfect for Your Business",
  create_your_kiosify_account: 'Create your Kiosify account',
  see_more: 'See more',
  find: 'Search',
  address: 'Address',
  language: 'Language',
  vietnamese: 'Tiếng Việt',
  english: 'English',
  foreign_language: 'Foreign Language',
  home: 'Home',
  phone: 'Phone',
  email: 'Email',
  send: 'Send',
  next: 'Next',
  back: 'Back',
  send_code: 'Send code',
  login: 'Login',
  user_name: 'Username',
  password: 'Password',
  login_success: 'Login successful',
  save_success: 'Save successful',
  forgot_password: 'Forgot password',
  require_field: 'Please enter "{{field}}", this is a required field',
  regex_field: 'Please enter "{{field}}" correct format',
  minimum_field: 'Please enter at least {{count}} characters',
  maximum_field: 'Please enter a maximum of {{count}} characters',
  register: 'Register',
  confirm_password: 'Confirm password',
  verify_by_phone: 'Verify by phone',
  verify_by_email: 'Verify by email',
  code_verify: 'Verification code',
  message_verify_phone:
    'Please check the Zalo message of the phone number {{phone}} to get the verification code.',
  re_send_verify_code: 'Resend verification code',
  resend: 'Resend',
  time_resend_verify_code: 'Resend after {{timer}} seconds',
  verify: 'Verify',
  logout: 'Logout',
  full_name: 'Full name',
  regex_name: 'Name must be in the correct format',
  regex_user_name: 'Username must be in the correct format (excluding spaces)',
  using_by: 'Used by',
  require_email: 'Please enter email',
  require_contact_name: 'Please enter the contact name',
  regex_email: 'Please enter a valid email format ',
  regex_phone: 'Please enter a valid phone number format',
  send_contact_success: 'Contact information sent successfully, thank you.',
  please_enter_correct_format: 'Please enter in the correct format',
  medical_examination: 'Medical examination',
  human_resources: 'Human resources',
  finance: 'Finance',
  inventory_management: 'Inventory management',
  purchasing_management: 'Purchasing management',
  customer_care: 'Customer care',
  clinic_management: 'Clinic management',
  company: 'Company',
  organizational_chart: 'Organizational chart',
  announcement: 'Announcement',
  internal_news: 'Internal news',
  onboarding: 'OnBoard',
  report: 'Report',
  marketing: 'Marketing',
  accounting: 'Accounting',
  work_schedule: 'Work schedule',
  attendance_record: 'Attendance record',
  mailbox: 'Mailbox',
  my_requests: 'My requests',
  my_tasks: 'My tasks',
  my_income: 'My income',
  dashboard: 'Dashboard',
  profile: 'Profile',
  read_all: 'Mark all as read',
  view_all: 'View all',
  remember_me: 'Remember me',
  parkway_dental: 'Parkway Dental',
  description_parkway_auth:
    'Parkway Dental is a comprehensive and professional dental system, especially in orthodontics and implants, fully meeting the dental needs of customers.',
  development: 'Under development',
  coming_soon: 'Coming soon',
  customers: 'Customers',
  region: 'Region',
  revenue: 'Revenue',
  basic_package: {
    title: 'Basic',
    price: '$50/mo',
    benefit_1: 'Up to 3 Kiosks',
    benefit_2: 'Unlimited orders',
    benefit_3: 'A dedicated account manager',
    benefit_4: '24/7 support',
    benefit_5: 'Access to our online dashboard',
    benefit_6: 'Reporting and analytics',
  },
  premium_package: {
    title: 'Premium',
    price: '$80/mo',
    benefit_1: 'Up to 10 Kiosks',
    benefit_2: 'Unlimited orders',
    benefit_3: 'Free KDS',
    benefit_4: '24/7 support',
    benefit_5: 'Customizable branding',
    benefit_6: 'Multi-location support',
    benefit_7: 'Marketing support',
  },
  smart_saving_package: {
    title: 'Smart Saving',
    price: '$30/mo/1 kiosk',
    benefit_1: 'Reduced commission fees',
    benefit_2: 'Priority customer support',
    benefit_3: 'Access to exclusive features',
  },
  time: {
    today: 'Today',
    this_week: 'This week',
    this_month: 'This month',
    days: 'Days',
    start_time_kiosk: 'Start time',
    end_time_kiosk: 'End time',
    monday: 'Monday',
    tuesday: 'Tuesday',
    wednesday: 'Wednesday',
    thursday: 'Thursday',
    friday: 'Friday',
    satuday: 'Satuday',
    sunday: 'Sunday',
  },
  error_message: {
    service_package: 'Please select service package',
    select_location: 'Please select location',
    select_payment_method: 'Please select payment method',
    select_kiosk: 'Please select kiosk',
  },
  success_message: {
    create_kiosk: 'Create Kiosk success',
    update_kiosk: 'Update Kiosk success',
  },
  table: {
    customer: 'Customer',
    time: 'Time',
    date: 'Date',
    order: 'Order',
    total_payment: 'Total Payment',
    payment_method: 'Payment Method',
    status: 'Status',
    type: 'Type',
    name: 'Name',
    location: 'Location',
    kiosk_code: 'Kiosk Code',
    product_name: 'Product name',
    price: 'Price',
    item_name: 'Item name',
    category: 'Category',
    variation: 'Variation',
    up_sell_item: 'Up sell item',
    up_sell_at: 'Up sell at',
    quantity: 'Quantity',
    action: 'Action',
    options: 'Options',
    discount_name: 'Discount name',
    discount_type: 'Discount type',
    discount_apply: 'Discount apply',
    item_price: 'Item price',
    active: 'Active',
    bestseller: 'Bestseller',
  },
  payment_method: {
    cash: 'Cash',
    credit_card: 'Credit card',
    gift_card: 'Gift card',
  },
  dashboard_common: {
    most_ordered: 'Most Ordered',
    most_type_order: 'Most Type Of Order',
    total_revenue: 'Total revenue',
    pending_revenue: 'Pending revenue',
    actual_revenue: 'Actual revenue',
    compared_to_last_month: 'Compared to last month',
    today_revenue: "Today's revenue",
    branch_name: 'Branch name',
    overall_today_summary: 'Overall summary today',
    area: 'Area',
    ordinal_number: 'Ordinal number',
    clinic: 'Clinic',
    dishes_ordered: 'Dishes Ordered',
    view_all: 'View All',
    order_report: 'Order Report',
  },
  reports_common: {
    title: 'Reports',
    total_revenue: 'Total Revenue',
    total_dish_ordered: 'Total Dish Ordered',
    total_customer: 'Total Customer',
    order_report: 'Order Report',
    chart_header_realtime: 'Realtime',
    chart_header_daily: 'Daily',
    chart_header_today: 'Today',
    chart_header_weekly: 'Weekly',
    chart_header_monthly: 'Monthly',
    chart_header_yearly: 'Yearly',
    chart_label_monday: 'Mon',
    chart_label_tuesday: 'Tue',
    chart_label_wednesday: 'Wed',
    chart_label_thursday: 'Thu',
    chart_label_friday: 'Fri',
    chart_label_satuday: 'Sat',
    chart_label_sunday: 'Sun',
    chart_label_january: 'Jan',
    chart_label_february: 'Feb',
    chart_label_march: 'Mar',
    chart_label_april: 'Apr',
    chart_label_may: 'May',
    chart_label_june: 'Jun',
    chart_label_july: 'Jul',
    chart_label_august: 'Aug',
    chart_label_september: 'Sep',
    chart_label_october: 'Oct',
    chart_label_november: 'Nov',
    chart_label_december: 'Dec',
    chart_serrie_total_gross: 'Total Gross',
    chart_serrie_total_net: 'Total Net',
  },
  input_placeholder: {
    search: 'Search for food, coffe, etc',
    search_tab: 'Search for tab ...',
    select_location: 'Select location',
    order_note: 'Order note...',
    search_only: 'Search',
    select_category: 'Select category',
    device_name: 'Device name',
    search_printer: 'Search printer',
    search_profile: 'Search profile',
    search_your_kiosk: 'Search Your Kiosk',
    write_your_content_here: 'Write your content here.....',
    select_time: 'Select time',
  },
  input_validate: {
    required_kiosk_name: 'Please input your Kiosk Name!',
  },
  information: {
    branch: 'Branch',
    location: 'Location',
    phone_no: 'Phone No',
    email: 'Email',
  },
  kiosk: {
    add_your_kiosk: 'Add Your Kiosk',
    edit_your_kiosk: 'Edit Your Kiosk',
    add_new_kiosk: 'Add New Kiosks',
    kiosk_name: 'Kiosk Name',
    kiosk_code: 'Kiosk Code',
    your_subscription: 'Your Subscription',
    payment_method: 'Payment method',
    redeem_loyalty: 'Reedem Loyalty',
    redeem_discount: 'Reedem Discount',
    open_time: 'Open time',
    start_popups_when_the_store_is_closed:
      'Start Popups when the store is closed',
    message: 'Message',
  },
  notification_status: {
    success: 'Success',
    info: 'Info',
    warning: 'Warning',
    error: 'Error',
  },
  product_management: {
    category: 'Category',
    items: 'Items',
    up_sell: 'Up sell',
    modifiers: 'Modifiers',
    discounts: 'Discounts',
    delete_item_up_sell: 'Delete item up sell',
    delete_item_up_sell_description: 'Are you want delete item',
    create_up_sell: 'Create Up Sell',
    menu_price: 'Menu price',
    discount_price: 'Discount price',
    item_level: 'Item Level',
    order_level: 'Order Level',
  },
  form_item: {
    select_up_sell_at_t: 'Up Sell At',
    select_up_sell_at_p: 'Select Up Sell At',
    select_item_t: 'Select Item',
    select_item_p: 'Select Item',
    select_item_variation_t: 'Variation',
    select_item_variation_p: 'Select Variation Of Item',
    select_price_t: 'Select Price',
    select_price_p: 'Select Price',
    price_t: 'Price',
    select_item_apply_t: 'Select Item Apply',
    select_item_apply_p: 'Select Item Apply',
  },
  setting_menu: {
    appearance_t: 'Appearance',
    appearance_d: 'Dark and Light mode, Font size',
    your_restaurant_t: 'Your Restaurant',
    your_restaurant_d: 'Dark and Light mode, Font size',
    your_kiosk_t: 'Your Kiosks',
    your_kiosk_d: 'Check your Kiosk information',
    product_management_t: 'Products Management',
    product_management_d: 'Manage your product, pricing, etc',
    kds_t: 'KDS Setting',
    kds_d: 'Dark and Light mode, Font size',
    backup_t: 'Backup',
    backup_d: 'Get back to your previous updated',
    your_information_t: 'Your Information',
    your_information_d:
      'Find out more about your brand name, printer, readers,...',
    sync_data_from_square_t: 'Sync Data From Square',
    sync_data_from_square_d: 'Sync your data from Square immediately',
  },
  your_infomation: {
    km_stand: 'KM Stand',
    km_printers: 'KM Printers',
    km_readers: 'KM Readers',
    device_id: 'Device ID',
    get_help: 'Get help',
    printers: 'Printers',
    profiles: 'Profiles',
    connect_printer: 'Connect printer',
    no_printer_connected: 'No printers connected',
    troubleshoot: 'Troubleshoot',
    troubleshoot_d: 'Expecting a printer to be connected?',
    create_profile: 'Create profile',
    no_profile: 'No profile',
    printer_defaults: 'Printer defaults',
    printer_defaults_d:
      'Select the items you want to print with this profile. All printers inheriting this profile will only print those items.',
    receipts: 'Receipts',
    in_person_order_tickets: 'In - person order tickets',
    online_order_tickets: 'Online order tickets',
    order_ticket_stubs: 'Order ticket stubs',
    void_tickets: 'Void tickets',
    use_this_printer_for_receipts: 'Use this printer for receipts',
    use_this_printer_for_receipts_d:
      'When on, this printer will also print bills, reports, and open cash drawers',
    no_device: 'No Device',
  },
  about: 'About',
  general: 'General',
  connect_and_manage: 'Connect and manage',
  add_new: 'Add new',
  add_more_food: 'Add more foods',
  key_all_label: 'All',
  restaurant: 'Restaurant',
  your_restaurant: 'Your restaurant',
  see_your_restaurant: 'See your restaurant',
  background: 'Background',
  text: 'Text',
  button: 'Button',
  custom: 'Custom',
  see_all: 'See all',
  review: 'Review',
  restaurant_name: 'Restaurant name',
  fake_time: 'Saturday, 18 Nov 2024',
  fake_tab: {
    hot_dishes: 'Hot Dishes',
    cold_dishes: 'cold dishes',
    soup: 'Soup',
    grill: 'Grill',
    appetizer: 'Appetizer',
    dessert: 'Dessert',
  },
  choose_dishes: 'Choose Dishes',
  bowls_available: 'Bowls available',
  item: 'Item',
  qty: 'Qty',
  price: 'Price',
  fake_order_note: 'Please, just a little bit spicy only.',
  discount: 'Discount',
  sub_total: 'Sub total',
  continue_to_payment: 'Continue to Payment',
  save: 'Save',
  dark_mode: 'Dark mode',
  light_mode: 'Light mode',
  color: {
    black: 'Black',
    white: 'White',
    light_red: 'Light red',
    light_green: 'Light green',
    light_blue: 'Light blue',
    light_orange: 'Light orange',
    light_gray: 'Light gray',
    light_cyan: 'Light cyan',
  },
  label_common: {
    for_here: 'For here',
    to_go: 'To go',
    pick_up: 'Pick up',
    late: 'Late',
    on_time: 'On time',
    caution: 'Caution',
    small: 'Small',
    medium: 'Medium',
    large: 'Large',
    extra_large: 'Extra large',
  },
  kds: {
    standard_screen_option: 'STANDARD SCREEN OPTION',
    default_t: 'Default',
    default_d: 'Top to bottom, left to right. Tickets fill in where they fit',
    classic_t: 'Classic',
    classic_d: 'Top to bottom, left to right. Tickets fill in where they fit',
    caution_time: 'Caution time',
    late_time: 'Late time',
    order_received_sms: 'Order Received SMS',
    order_bumped_from_kds_screen_sms: 'Order Bumped from KDS Screen SMS',
    order_is_ready_for_pick_up_sms: 'Order is Ready for Pick up SMS',
    transition_time: 'TRANSITION TIMES',
    font_and_color: 'FONTS & COLORS',
    font_size: 'Font size',
    status_color: 'Status colors',
    order_type: 'Order type',
    mesaging: 'MESAGING',
  },
  min: 'min',
  on: 'On',
  off: 'Off',
  see_with: 'See with',
  import: 'Import',
  week: 'Week',
  download_successful: 'Download successful',
  import_file_successful: 'Import file successful',
  import_file_error: 'Import file error',
  choose_week: 'Choose week',
  notification: 'Notification',
  not_done: 'Not done',
  done: 'Done',
  in_progress: 'In progress',
  see_detail: 'See detail',
  mail: 'Mail',
  new_mail: 'New mail',
  important: 'Important',
  show_less: 'Show less',
  collapse: 'Collapse',
  last_time_update: 'Last time update',
  service: 'Service',
  month: 'Month',
  import_file: 'Import file',
  cancel: 'Cancel',
  export_excel: 'Export excel',
  download_example_import_file: '',
  solutions: 'Solutions',
  vision: 'Vision',
  programs: 'Programs',
  blog: 'Blog',
  product: 'Product',
  pricing: 'Pricing',
  resource: 'Resource',
  customer_login: 'Customer Login',
  sign_up: 'Sign up',
  watch_free_demo: 'Watch free demo',
  sign_in_to_stay_connected: 'Sign in to stay connected.',
  or_sign_in_with_other_accounts: 'or sign in with other accounts?',
  dont_have_an_account: 'Don’t have an account?',
  click_here_to_sign_up: 'Click here to sign up.',
  sign_in: 'Sign in',
  create_your_hope_ui_account: 'Create your Hope UI account',
  first_name: 'First name',
  last_name: 'Last name',
  phone_no: 'Phone no.',
  confirm_password_incorrect: 'Confirm password incorrect',
  i_agree_with_the_terms_of_use: 'I agree with the terms of use',
  or_sign_up_with_other_accounts: 'or sign up with other accounts?',
  already_have_an_account: 'Already have an Account',
  reset: 'Reset',
  reset_password: 'Reset password',
  enter_your_email_address_and_we_ll_send_you_an_email_with_instructions_to_reset_your_password:
    'Enter your email address and we’ll send you an email with instructions to reset your password',
  back_to_home: 'Back to home',
  back_to_login: 'Back to login',
  message_verify_email:
    'A email has been send to your email@domain.com. Please check for an email from company and click on the included link to reset your password.',
  success: 'Success',
  subcribe_now: 'Subscribe Now',
  error: 'Error',
  admin: 'Admin',
  settings: 'Settings',
  not_found: 'Not found',
  spicy_seasoned_seafood_noodles: 'Spicy seasoned seafood noodles',
  salted_pasta_with_mushroom_sauce: 'Salted Pasta with mushroom sauce',
  beef_dumpling_in_hot_and_sour_soup: 'Beef dumpling in hot and sour soup',
  healthy_noodle_with_spinach_leaf: 'Healthy noodle with spinach leaf',
  hot_spicy_fried_rice_with_omelet: 'Hot spicy fried rice with omelet',
  spicy_instant_noodle_with_special_omelette:
    'Spicy instant noodle with special omelette',
  spicy_seasoned_seafood_noodles_short: 'Spicy seasoned sea...',
  salted_pasta_with_mushroom_sauce_short: 'Salted Pasta with mu...',
  spicy_instant_noodle_with_special_omelette_short: 'Spicy instant noodle...',
  healthy_noodle_with_spinach_leaf_short: 'Healthy noodle with...',
  select_item_for_upsells: 'Select Item for upsells',
  recommended_foods: 'Recommended foods',
  ex: 'Ex',
  spicy_seasoned_seafood_noodles_strawberry:
    'Spicy seasoned seafood noodles, Strawberry',
  account_verification: 'Account Verification',
  email_verification: 'Email Verification',
  account_verification_d1: 'We`ve sent you a temporary 6-digit login code at',
  account_verification_d2: 'Please enter this code to login to your account.',
  didnt_receive_the_code: 'Didn`t receive the code?',
  resend_code_in: 'Resend code in',
  resend_code: 'Resend code',
  account_verified: 'Account Verified',
  didnt_sync_data: 'Didn`t sync data?',
  click_here_to_connect_square_account: 'Click here to connect square account',
  change_password: 'Change Password',
  card: 'Card',
  cardholder_name: 'Cardholder Name',
  please_input_cardholder_name: 'Please input cardholder name!',
  cardholder_name_must_be_at_least_6_characters:
    'Cardholder name must be at least 6 characters',
  start_subscription: 'Start Subscription',
  order_summary: 'Order Summary',
  Plane: 'Plane',
  subscription_monthly: 'Subscription (monthly)',
  fourteen_days_free_trail: '14 days free trail',
  subtotal: 'Subtotal',
  taxes_if_applicable: 'Taxes (if applicable)',
  total: 'Total',
  save_this_card_for_future_use: 'Save this card for future use.',
  subcribe_for_free: 'Subcribe for free',
  language_option: {
    en: 'English',
    vi: 'Vietnamese',
    ja: 'Japanese',
    es: 'Spanish',
    fr: 'French',
    zh: 'Chinese',
  },
  currency_option: {
    en: 'USD',
    vi: 'VND',
    ja: 'JPY',
    es: 'EUR',
    fr: 'EUR',
    zh: 'CNY',
  },
  feature: 'Feature',
  before_5_minutes: 'Before 5 minutes',
  upload: 'Upload',
  upload_your_image: 'Upload your Image',
  confirm: 'Confirm',
  you_sync_data_success: 'You are sync data successfully!',
  thank_you_for_interest:
    'Thank you for your interest in Kiosify, we will contact you soon.',
}

import R from 'app/assets/R'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseImage } from 'app/components/common/BaseImage'
import { BaseSpace } from 'app/components/common/BaseSpace'
import { FONT_SIZE, FONT_WEIGHT, PADDING, colors } from 'parkway-web-common'
import styled from 'styled-components'

export const RootWrapper = styled(BaseSpace)`
  padding: 1.5rem 0px;
  width: 100%;
`

export const AreaText = styled.div`
  padding: ${PADDING.xxs} 0px;
  text-align: left;
  font-family: 'Plus Jakarta Sans', sans-serif !important;
`

export const ImageWrapper = styled(BaseImage)`
  width: 100% !important;
  height: 100% !important;
  margin: unset !important;
  padding: unset !important;
`

export const TitleText = styled.span`
  font-size: 36px;
  font-weight: ${FONT_WEIGHT.bold};
  color: ${colors.black};
  text-align: center;
  display: flex;
  justify-content: center;
  font-family: 'Plus Jakarta Sans', sans-serif !important;
  width: 100%;
`

export const ItemWrapper = styled.div`
  border-radius: 30px;
  .info {
    position: absolute;
    bottom: 0;
    padding: 16px;
    align-items: center;
    text-align: center;
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    opacity: 0.7;
    width: 100%;
    justify-content: center;
    transition: height 2s ease, background-image 2s ease;
    height: fit-content;
  }
  &:hover {
    .info {
      background-image: linear-gradient(
        to bottom,
        #fcfffd,
        #aaffef,
        #54e9cd,
        #fcfffd
      );
      height: 100%;
      transition: height 2s ease, background-image 2s ease;
    }
    cursor: url('${R.images.ic_cursor}'), auto; /* Đường dẫn đến cursor tùy chỉnh */
  }
`

export const TextTitleItem = styled.span`
  font-size: 30px;
  font-weight: ${FONT_WEIGHT.semibold};
  color: ${colors.black};
  font-family: 'Plus Jakarta Sans', sans-serif !important;
`
export const DescriptionItem = styled.span`
  font-size: ${FONT_SIZE.md};
  font-weight: ${FONT_WEIGHT.regular};
  color: ${colors.black};
  font-family: 'Plus Jakarta Sans', sans-serif !important;
`

export const IconBase = styled(BaseImage)`
  width: 100px !important;
  height: 100px !important;
`

export const ColItem = styled(BaseCol)`
  width: 481px;
  height: 440px;
`

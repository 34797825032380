import { BaseSelect } from 'app/components/common/selects/BaseSelect'
import { FONT_SIZE, FONT_WEIGHT } from 'parkway-web-common'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { CURRENCY } from './data'
import { IPropsCurrencyPicker } from './type'

export const CurrencyPicker: React.FC<IPropsCurrencyPicker> = ({
  initValue,
  onChange,
}) => {
  const { t, i18n } = useTranslation()

  const handleChangeLanguage = value => {
    if (onChange) {
      onChange(value)
      return
    }
  }

  const language = useMemo(() => {
    if (initValue) return initValue

    return i18n.language
  }, [i18n, initValue])

  return (
    <>
      <CustomSelectionLanguage
        value={language}
        options={CURRENCY.map(item => ({
          value: item.value,
          label: (
            <>
              <span className="flag">{item.flag}</span>
              <span className="label">{t(item.label)}</span>
            </>
          ),
        }))}
        popupMatchSelectWidth={false}
        placement="bottomRight"
        onChange={handleChangeLanguage}
      />
    </>
  )
}

const CustomSelectionLanguage = styled(BaseSelect)`
  min-width: 114px !important;
  width: 100%;
  height: 40px;
  .flag {
    margin-right: 8px;
    img {
      width: 20px !important;
      height: 14px !important;
      object-fit: cover;
    }
  }
  .label {
    font-size: ${FONT_SIZE.xs};
    font-weight: ${FONT_WEIGHT.medium};
    color: black;
  }

  .ant-select-arrow {
    color: #475569;
    top: 50% !important;
  }
  .ant-select-selection-item {
    display: flex;
    align-items: center;
    .flag {
      margin-left: 4px;
    }
    .label {
      color: var(--primary-color);
    }
  }
  .ant-select-dropdown {
    background-color: var(--background-color);
    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
      background-color: #29cc6a38 !important;
    }
  }
`

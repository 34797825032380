import R from 'app/assets/R'
import {
  SvgChartDashboardIcon,
  SvgHomeDashboardIcon,
  SvgSettingDashboardIcon,
} from 'app/assets/svg-assets'
import { ADMIN_PATH, LOGOUT_PATH } from 'app/components/router/route-path'
import { ReactNode } from 'react'

export interface SidebarNavigationItem {
  title: string
  key: string
  url?: string
  children?: SidebarNavigationItem[]
  icon?: string
  iconFocus?: string
  svgIcon?: ReactNode
}

export const sidebarNavigation: SidebarNavigationItem[] = [
  {
    title: R.strings.home,
    key: R.strings.home,
    url: ADMIN_PATH.HOME,
    iconFocus: R.images.ic_dashboard_admin,
    icon: R.images.ic_home_admin_focus,
    svgIcon: <SvgHomeDashboardIcon />,
  },
  {
    title: R.strings.reports_common_title,
    key: R.strings.reports_common_title,
    url: ADMIN_PATH.ADMIN_REPORTS,
    icon: R.images.ic_chart_admin,
    iconFocus: R.images.ic_dashboard_admin_white,
    svgIcon: <SvgChartDashboardIcon />,
  },
  {
    title: R.strings.settings,
    key: R.strings.settings,
    url: ADMIN_PATH.ADMIN_SETTINGS,
    icon: R.images.ic_setting_admin,
    iconFocus: R.images.ic_setting_admin_white,
    svgIcon: <SvgSettingDashboardIcon />,
  },
  {
    title: R.strings.logout,
    key: R.strings.logout,
    url: LOGOUT_PATH,
    icon: R.images.ic_logout,
    iconFocus: R.images.ic_logout,
  },
]

import { colors, PADDING, useResponsive } from 'parkway-web-common'
import React, { useMemo } from 'react'
import { Overlay } from '../../../../../common/Overlay'
import { SliderLogo } from '../SiderLogo'
import SliderMenu from '../SiderMenu'
import * as S from './styles'
import styled from 'styled-components'
import { BaseSpace } from 'app/components/common/BaseSpace'
import { useTranslation } from 'react-i18next'
import { ButtonVariant } from 'app/components/common/BaseButtonVariant'
import R from 'app/assets/R'
import { useNavigateHook } from 'app/hook'
import {
  AUTH_SIGN_UP_PATH,
  AUTH_SPLASH_PATH,
} from 'app/components/router/route-path'
import { BaseButton } from 'app/components/common/BaseButton'

interface MainSliderProps {
  isCollapsed: boolean
  setCollapsed: (isCollapsed: boolean) => void
}

const MainSlider: React.FC<MainSliderProps> = ({
  isCollapsed,
  setCollapsed,
  ...props
}) => {
  const { t } = useTranslation()
  const { mobileOnly, tabletOnly } = useResponsive()

  const isCollapsible = useMemo(
    () => mobileOnly && tabletOnly,
    [mobileOnly, tabletOnly],
  )

  const toggleSlider = () => setCollapsed(!isCollapsed)
  const { navigateAuthPath } = useNavigateHook()
  return (
    <>
      <S.Slider
        trigger={null}
        collapsed={isCollapsed}
        collapsedWidth={0}
        collapsible={isCollapsible}
        width={180}
        style={{ backgroundColor: colors.white, position: 'absolute' }}
        {...props}
      >
        <BaseSpace
          size={4}
          split={
            <div
              style={{
                height: 0.8,
                backgroundColor: 'rgba(0, 0, 0, 0.1)',
                width: '100%',
              }}
            />
          }
        >
          <SliderLogo
            isSliderCollapsed={isCollapsed}
            toggleSlider={toggleSlider}
          />
          <S.SliderContent>
            <SliderMenu setCollapsed={setCollapsed} isCollapsed={isCollapsed} />
          </S.SliderContent>
          <BaseSpace style={{ paddingInline: 16 }}>
            <ButtonLogin onClick={() => navigateAuthPath(AUTH_SPLASH_PATH)}>
              {t(R.strings.login).toUpperCase()}
            </ButtonLogin>

            <ButtonVariant
              $colorText={colors.black}
              onClick={() => navigateAuthPath(AUTH_SIGN_UP_PATH)}
              style={{ width: '100%' }}
            >
              {t(R.strings.sign_up).toUpperCase()}
            </ButtonVariant>
          </BaseSpace>
        </BaseSpace>
      </S.Slider>
      {mobileOnly && (
        <OverlayOverwrite onClick={toggleSlider} show={!isCollapsed} />
      )}
    </>
  )
}

export default MainSlider

const OverlayOverwrite = styled(Overlay)`
  background-color: rgba(0, 0, 0, 0.4);
`

export const ButtonLogin = styled(BaseButton)<{
  isVariantOutline?: boolean
  $borderRadius?: string
  $colorText?: string
}>`
  width: 100%;
  border-radius: ${PADDING.xxs};
  padding: ${PADDING.xl} ${PADDING.xl};
  transition: all 0.5s ease !important;
  color: ${colors.black};
  border: 1px solid transparent;
  &:hover {
    transition: all 0.5s ease !important;
    color: #42e5c6 !important;
    border: 1px solid #42e5c6 !important;
  }
`

import R from 'app/assets/R'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import BaseText from 'app/components/common/BaseText'
import { SpaceVertical } from 'app/components/styles'
import { useAppSelector } from 'app/redux/hooks'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import HomeLayout from './components/Home'
import OrderReportLayout from './components/OrderReport'
import TotalCustomerLayout from './components/TotalCustomer'
import TotalDishOrderedLayout from './components/TotalDishOrdered'
import TotalRevenueLayout from './components/TotalRevenue'
import { URL_REPORTS } from './constant'
import * as S from './styles'

const ReportsContainer = () => {
  const { t } = useTranslation()
  const currentPath = useAppSelector(state => state?.pathReport)?.path
  const currentLayout = useCallback(() => {
    switch (currentPath) {
      case URL_REPORTS.TotalRevenue:
        return <TotalRevenueLayout />
      case URL_REPORTS.TotalDishOrdered:
        return <TotalDishOrderedLayout />

      case URL_REPORTS.TotalCustomer:
        return <TotalCustomerLayout />

      case URL_REPORTS.OrderReport:
        return <OrderReportLayout />
    }
  }, [currentPath])()

  return (
    <S.RootWrapper>
      <BaseText
        children={t(R.strings.report)}
        fontSize="xxl"
        fontWeight="semibold"
      />
      <SpaceVertical $gap={16} />
      <BaseRow gutter={[32, 32]} align={'top'}>
        <BaseCol xl={6}>
          <HomeLayout />
        </BaseCol>

        <BaseCol xl={18}>
          <RightSide>{currentLayout}</RightSide>
        </BaseCol>
      </BaseRow>
    </S.RootWrapper>
  )
}

export default ReportsContainer

const RightSide = styled.div`
  background-color: white;
  padding: 16px;
  border-radius: 12px;
`

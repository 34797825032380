import { ApiClient } from 'app/service/api-service'

const LandingEndPoint = {
  Subscriber: 'subscriber/send-email/',
}

export const requestPostSubscriberEmail = (body: { email: string }) =>
  ApiClient.Post({
    url: LandingEndPoint.Subscriber,
    body,
  })

/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  requestDeleteUpSellById,
  requestGetCategoryList,
  requestGetDiscountList,
  requestGetItemList,
  requestGetModifierList,
  requestGetUpSellListByItem,
  requestGetUpSellListV2,
  requestGetVariationByItemId,
  requestUpdateDiscountById,
  requestUpdateItemById,
  requestUpdateModifierById,
  requestUpdateStatusUpSellById,
  requestUploadImageCategory,
} from 'app/api/product-management/api'
import {
  ICategory,
  IDiscountProductManagement,
  IItemProductManagement,
  IModifier,
  IParamsGetVariationByItemId,
  IParamsList,
  IParamsListV2,
  IParamsUpdateStatus,
  IResponseQueryList,
  IUpSellProductManagement,
  IVariationProductManagement,
  ResponseCategory,
  ResponseDiscount,
  ResponseUpSell,
  ResponseVariation,
} from 'app/api/product-management/model'
import { IResponseDefine } from 'app/common/model'
import { LOGOUT_PATH } from 'app/components/router/route-path'
import { useInfiniteQuery, useMutation, useQueryClient } from 'react-query'
import { useNavigate } from 'react-router'
import { ProductManagementKeys } from '../query-key/product-management'

export function useGetCategoryList(body?: IParamsListV2, enabled = true) {
  const navigate = useNavigate()
  return useInfiniteQuery<ICategory[], IParamsListV2>(
    ProductManagementKeys.categoryList(body),
    async () => {
      try {
        const response: ResponseCategory = await requestGetCategoryList({
          page: 1,
          size: 100,
          ...body,
        })

        return response?.items ?? []
      } catch (error: any) {
        console.log({ error }, 'product-management.ts')
        if (error?.message === '401') {
          navigate(LOGOUT_PATH, { replace: true })
          return []
        }
        return []
      }
    },
    {
      enabled,
      cacheTime: 5000,
    },
  )
}

export function useGetItemList(params?: IParamsListV2, enabled = true) {
  const navigate = useNavigate()
  return useInfiniteQuery<
    IResponseQueryList<IItemProductManagement[]>,
    IParamsListV2
  >(
    ProductManagementKeys.itemProductManagementList(params),
    async () => {
      try {
        const response: IItemProductManagement[] = await requestGetItemList({
          page: 1,
          size: 1000,
          ...params,
        })
        return {
          data: response ?? [],
          total: response?.length ?? 0,
        }
      } catch (error: any) {
        console.log({ error }, 'useGetItemList')
        if (error?.message === '401') {
          navigate(LOGOUT_PATH, { replace: true })
          return {
            data: [],
            total: 0,
          }
        }
        return {
          data: [],
          total: 0,
        }
      }
    },
    {
      enabled: !!params && enabled,
      cacheTime: 5000,
    },
  )
}

export const useUpdateItemStatusById = () => {
  const navigate = useNavigate()
  const query = useQueryClient()
  return useMutation<
    IResponseDefine<boolean>,
    unknown,
    IParamsUpdateStatus,
    unknown
  >(
    async (params: any) => {
      try {
        const res = await requestUpdateItemById(params)
        return res
      } catch (error: any) {
        if (error?.message === '401') {
          navigate(LOGOUT_PATH, { replace: true })
          return {
            data: [],
            total: 0,
          }
        }
      }
    },
    {
      onSuccess: () => {
        query.invalidateQueries({
          queryKey: ProductManagementKeys.allItemProductManagement,
        })
      },
    },
  )
}

export function useGetModifierList(params?: IParamsListV2, enabled = true) {
  const navigate = useNavigate()
  return useInfiniteQuery<IResponseQueryList<IModifier[]>, IParamsListV2>(
    ProductManagementKeys.modifierList(params),
    async () => {
      try {
        const response: IModifier[] = await requestGetModifierList({
          page: 1,
          size: 100,
          ...params,
        })
        return {
          data: response,
          total: response?.length ?? 0,
        }
      } catch (error: any) {
        console.log({ error }, 'useGetModifierList')
        if (error?.message === '401') {
          navigate(LOGOUT_PATH, { replace: true })
          return {
            data: [],
            total: 0,
          }
        }
        return {
          data: [],
          total: 0,
        }
      }
    },
    {
      enabled,
      cacheTime: 5000,
    },
  )
}

export const useUpdateModifierStatusById = () => {
  const navigate = useNavigate()
  const query = useQueryClient()
  return useMutation<
    IResponseDefine<boolean>,
    unknown,
    IParamsUpdateStatus,
    unknown
  >(
    async (params: any) => {
      try {
        const res = await requestUpdateModifierById(params)
        return res
      } catch (error: any) {
        if (error?.message === '401') {
          navigate(LOGOUT_PATH, { replace: true })
          return {
            data: [],
            total: 0,
          }
        }
      }
    },
    {
      onSuccess: () => {
        query.invalidateQueries({
          queryKey: ProductManagementKeys.allModifier,
        })
      },
    },
  )
}

export function useGetDiscountList(params?: IParamsList, enabled = true) {
  const navigate = useNavigate()
  return useInfiniteQuery<
    IResponseQueryList<IDiscountProductManagement[]>,
    IParamsList
  >(
    ProductManagementKeys.discountList(params),
    async () => {
      try {
        const response: ResponseDiscount = await requestGetDiscountList({
          ...params,
        })
        return {
          data: response?.results ?? [],
          total: response?.count ?? 0,
        }
      } catch (error: any) {
        console.log({ error }, 'useGetDiscountList')
        if (error?.message === '401') {
          navigate(LOGOUT_PATH, { replace: true })
          return {
            data: [],
            total: 0,
          }
        }
        return {
          data: [],
          total: 0,
        }
      }
    },
    {
      enabled,
      cacheTime: 5000,
    },
  )
}

export const useUpdateDiscountStatusById = () => {
  const navigate = useNavigate()
  const query = useQueryClient()
  return useMutation<
    IResponseDefine<boolean>,
    unknown,
    IParamsUpdateStatus,
    unknown
  >(
    async (params: any) => {
      try {
        const res = await requestUpdateDiscountById(params)
        return res
      } catch (error: any) {
        if (error?.message === '401') {
          navigate(LOGOUT_PATH, { replace: true })
          return {
            data: [],
            total: 0,
          }
        }
      }
    },
    {
      onSuccess: () => {
        query.invalidateQueries({
          queryKey: ProductManagementKeys.allDiscount,
        })
      },
    },
  )
}

export function useGetUpSellList(params?: IParamsListV2, enabled = true) {
  const navigate = useNavigate()
  return useInfiniteQuery<
    IResponseQueryList<IUpSellProductManagement[]>,
    IParamsListV2
  >(
    ProductManagementKeys.upSellList(params),
    async () => {
      try {
        const response: IUpSellProductManagement[] =
          await requestGetUpSellListV2({
            page: 1,
            size: 100,
            ...params,
          })
        return {
          data: response ?? [],
          total: response?.length ?? 0,
        }
      } catch (error: any) {
        console.log({ error }, 'useGetUpSellList')
        if (error?.message === '401') {
          navigate(LOGOUT_PATH, { replace: true })
          return {
            data: [],
            total: 0,
          }
        }
        return {
          data: [],
          total: 0,
        }
      }
    },
    {
      enabled,
      cacheTime: 5000,
    },
  )
}

export function useGetUpSellListByItem(params?: IParamsList, enabled = true) {
  const navigate = useNavigate()
  return useInfiniteQuery<
    IResponseQueryList<IUpSellProductManagement[]>,
    IParamsList
  >(
    ProductManagementKeys.upSellByItemList(params),
    async () => {
      try {
        const response: ResponseUpSell = await requestGetUpSellListByItem({
          ...params,
        })
        return {
          data: response?.results ?? [],
          total: response?.count ?? 0,
        }
      } catch (error: any) {
        console.log({ error }, 'useGetUpSellList')
        if (error?.message === '401') {
          navigate(LOGOUT_PATH, { replace: true })
          return {
            data: [],
            total: 0,
          }
        }
        return {
          data: [],
          total: 0,
        }
      }
    },
    {
      enabled,
      cacheTime: 5000,
    },
  )
}

export const useUpdateUpSellStatusById = () => {
  const navigate = useNavigate()
  const query = useQueryClient()
  return useMutation<
    IResponseDefine<boolean>,
    unknown,
    IParamsUpdateStatus,
    unknown
  >(
    async (params: any) => {
      try {
        const res = await requestUpdateStatusUpSellById(params)
        return res
      } catch (error: any) {
        if (error?.message === '401') {
          navigate(LOGOUT_PATH, { replace: true })
          return {
            data: [],
            total: 0,
          }
        }
      }
    },
    {
      onSuccess: () => {
        query.invalidateQueries({
          queryKey: ProductManagementKeys.allUpSell,
        })

        query.invalidateQueries({
          queryKey: ProductManagementKeys.allUpSellByItem,
        })
      },
    },
  )
}

export const useDeleteUpSellStatusById = () => {
  const navigate = useNavigate()
  const query = useQueryClient()
  return useMutation<
    IResponseDefine<boolean>,
    unknown,
    IParamsUpdateStatus,
    unknown
  >(
    async (params: any) => {
      try {
        const res = await requestDeleteUpSellById(params)
        return res
      } catch (error: any) {
        if (error?.message === '401') {
          navigate(LOGOUT_PATH, { replace: true })
          return {
            data: [],
            total: 0,
          }
        }
      }
    },
    {
      onSuccess: () => {
        query.invalidateQueries({
          queryKey: ProductManagementKeys.allUpSell,
        })
        query.invalidateQueries({
          queryKey: ProductManagementKeys.allUpSellByItem,
        })
      },
    },
  )
}

export function useGetVariationListByItemId(
  params?: IParamsGetVariationByItemId,
  enabled = true,
) {
  const navigate = useNavigate()
  return useInfiniteQuery<
    IResponseQueryList<IVariationProductManagement[]>,
    IParamsList
  >(
    ProductManagementKeys.variationList(params),
    async () => {
      try {
        const response: ResponseVariation = await requestGetVariationByItemId({
          ...params,
        })
        return {
          data: response?.results ?? [],
          total: response?.count ?? 0,
        }
      } catch (error: any) {
        console.log({ error }, 'useGetVariationListByItemId')
        if (error?.message === '401') {
          navigate(LOGOUT_PATH, { replace: true })
          return {
            data: [],
            total: 0,
          }
        }
        return {
          data: [],
          total: 0,
        }
      }
    },
    {
      enabled,
      cacheTime: 5000,
    },
  )
}

export const useUploadImageCategory = () => {
  const navigate = useNavigate()
  const query = useQueryClient()
  return useMutation<IResponseDefine<boolean>, unknown, FormData, unknown>(
    async (body: FormData) => {
      try {
        const res = await requestUploadImageCategory(body)
        return res
      } catch (error: any) {
        if (error?.message === '401') {
          navigate(LOGOUT_PATH, { replace: true })
          return {
            data: [],
            total: 0,
          }
        }
      }
    },
    {
      onSuccess: () => {
        query.invalidateQueries({
          queryKey: ProductManagementKeys.allCategory,
        })
      },
    },
  )
}

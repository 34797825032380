import { ArrowLeftOutlined } from '@ant-design/icons'
import R from 'app/assets/R'
import { ButtonVariant } from 'app/components/common/BaseButtonVariant'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { BaseInput } from 'app/components/common/inputs/BaseInput'
import { Loading } from 'app/components/common/Loading'
import { REGEX_PHONE } from 'app/constant'
import { PADDING, REG_EMAIL } from 'parkway-web-common'
import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { OpenTimeLayout } from './components'
import { useHook } from './hook'
import * as S from './styles'
import { LanguagePicker } from 'app/containers/admin/mobile/Settings/components/RightSide/layout/YourInformation/LanguagePicker'
import { CurrencyPicker } from 'app/components/common/CurrencyPicker'

const CreateRestaurantLayout = () => {
  const { t } = useTranslation()
  const {
    days,
    onAddTimeRange,
    onDeleteRangeTime,
    onChangeRangeTime,
    goBack,
    form,
    onSave,
    isLoading,
    language,
    onChangeLanguage,
    setCurrency,
    currency,
  } = useHook()

  return (
    <S.RootWrapper>
      <S.HeaderWrapper justify={'space-between'} align={'middle'}>
        <BaseCol>
          <BaseRow gutter={8} align={'middle'}>
            <BaseCol>
              <S.BackButton onClick={goBack}>
                <ArrowLeftOutlined rev={undefined} />
              </S.BackButton>
            </BaseCol>
            <BaseCol>
              <BaseText
                fontWeight="semibold"
                fontSize="xl"
                fontFamily="Raleway"
              >
                {'Add restaurant'}
              </BaseText>
            </BaseCol>
          </BaseRow>
        </BaseCol>
        <BaseCol>
          <ButtonVariant
            children={<BaseText children={'Save'} fontWeight="semibold" />}
            onClick={form.submit}
          />
        </BaseCol>
      </S.HeaderWrapper>
      <BaseForm
        form={form}
        onFinish={onSave}
        initialValues={{
          businessName: 'ABC Store',
          phoneNumber: '+84123456789',
          email: 'contact@abcstore.com',
          timeZone: 'Asia/Ho_Chi_Minh',
          addressLine1: '123 Đường Nguyễn Trãi',
          locality: 'Quận 1',
          country: 'Vietnam',
          postalCode: '700000',
        }}
      >
        <BaseSpace style={{ padding: `0 ${PADDING.xxs}` }}>
          <S.InfoContentWrapper direction="vertical" size={5}>
            <BaseForm.Item
              name={'businessName'}
              label={'Branch name'}
              required={false}
              rules={[
                {
                  required: true,
                  message: t(R.strings.require_field, { field: 'Branch name' }),
                },
              ]}
            >
              <BaseInput
                style={{ padding: 8, borderRadius: 10 }}
                placeholder="Branch name"
              />
            </BaseForm.Item>
            <BaseForm.Item
              name={'phoneNumber'}
              label={'Phone Number'}
              required={false}
              rules={[
                {
                  required: true,
                  message: t(R.strings.require_field, {
                    field: 'Phone Number',
                  }),
                },
                {
                  pattern: REGEX_PHONE,
                  message: t(R.strings.regex_phone),
                },
              ]}
            >
              <BaseInput
                style={{ padding: 8, borderRadius: 10 }}
                placeholder="Enter phone name"
              />
            </BaseForm.Item>
            <BaseForm.Item
              name={'email'}
              label={'Email Address'}
              required={false}
              rules={[
                {
                  required: true,
                  message: t(R.strings.require_field, {
                    field: t(R.strings.email),
                  }),
                },
                {
                  pattern: REG_EMAIL,
                  message: t(R.strings.regex_email),
                },
              ]}
            >
              <BaseInput
                style={{ padding: 8, borderRadius: 10 }}
                placeholder="Enter email address"
              />
            </BaseForm.Item>
            <BaseForm.Item label={t(R.strings.language)}>
              <LanguagePicker
                onChange={onChangeLanguage}
                initValue={language}
              />
            </BaseForm.Item>

            <BaseForm.Item label={'Currency'}>
              <CurrencyPicker onChange={setCurrency} initValue={currency} />
            </BaseForm.Item>
            <BaseSpace size={5}>
              <BaseText children={'Location Info'} />
              <BaseSpace style={{ paddingLeft: 16 }} size={5}>
                <BaseForm.Item name={'addressLine1'} label={'Address Line'}>
                  <BaseInput
                    style={{ padding: 8, borderRadius: 10 }}
                    placeholder="Enter address line"
                  />
                </BaseForm.Item>
                <BaseForm.Item
                  name={'locality'}
                  label={'Locality'}
                  required={false}
                  rules={[
                    {
                      required: true,
                      message: t(R.strings.require_field, {
                        field: 'Locality',
                      }),
                    },
                  ]}
                >
                  <BaseInput
                    style={{ padding: 8, borderRadius: 10 }}
                    placeholder="Enter locality"
                  />
                </BaseForm.Item>
                <BaseForm.Item
                  name={'country'}
                  label={'Country'}
                  required={false}
                  rules={[
                    {
                      required: true,
                      message: t(R.strings.require_field, {
                        field: 'Country',
                      }),
                    },
                  ]}
                >
                  <BaseInput
                    style={{ padding: 8, borderRadius: 10 }}
                    placeholder="Enter country"
                  />
                </BaseForm.Item>
                <BaseForm.Item
                  name={'postalCode'}
                  label={'Postal Code'}
                  required={false}
                  rules={[
                    {
                      required: true,
                      message: t(R.strings.require_field, {
                        field: 'Postal Code',
                      }),
                    },
                  ]}
                >
                  <BaseInput
                    style={{ padding: 8, borderRadius: 10 }}
                    placeholder="Enter postal code"
                  />
                </BaseForm.Item>
              </BaseSpace>
            </BaseSpace>
          </S.InfoContentWrapper>

          <OpenTimeLayout
            isLoading={isLoading}
            days={days}
            onAddTimeRange={onAddTimeRange}
            onChangeRangeTime={onChangeRangeTime}
            onDeleteRangeTime={onDeleteRangeTime}
          />
        </BaseSpace>
      </BaseForm>

      {isLoading ? <Loading /> : null}
    </S.RootWrapper>
  )
}

export default memo(CreateRestaurantLayout)

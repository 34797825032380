import { BaseSpace } from 'app/components/common/BaseSpace'
import {
  BaseTemplatePageAuth,
  JupiterComponent,
} from 'app/components/layouts/AuthLayout/Component'
import { AUTH_LOGIN_PATH } from 'app/components/router/route-path'
import { useEffect } from 'react'
import { useNavigate } from 'react-router'

const SplashLoginContainer = () => {
  const navigate = useNavigate()

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate(AUTH_LOGIN_PATH, { replace: true })
    }, 4000)

    return () => clearTimeout(timer)
  }, [])

  return (
    <BaseTemplatePageAuth>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flex: 1,
          height: 'calc(100% - 10%)',
        }}
      >
        <BaseSpace direction="vertical" size={16} style={{ zIndex: 10 }}>
          <div
            style={{
              textAlign: 'center',
              color: 'white',
              fontSize: 100,
              fontWeight: 'bold',
            }}
            className="text-opacity-left-to"
          >
            HELLO
          </div>

          <div
            style={{
              textAlign: 'center',
              color: 'white',
              fontSize: 35,
              fontWeight: 'normal',
            }}
            className="text-opacity-right-to"
          >
            Get Started, Verify Your Details
          </div>
          <div
            style={{
              textAlign: 'center',
              color: 'white',
              fontSize: 16,
              fontWeight: 'normal',
            }}
            className="text-opacity-right-to"
          >
            Let’s get you started quickly with a quick verification and start
            make your perfect gift.
          </div>
        </BaseSpace>

        <JupiterComponent />
      </div>
    </BaseTemplatePageAuth>
  )
}

export default SplashLoginContainer

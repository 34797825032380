import R from 'app/assets/R'
import { BaseImage } from 'app/components/common/BaseImage'
import { ADMIN_PATH } from 'app/components/router/route-path'
import { isEqual } from 'lodash'
import { BORDER_RADIUS } from 'parkway-web-common'
import React, { useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router'
import * as S from './MainSider/styles'

interface SliderLogoProps {
  isSliderCollapsed: boolean
  toggleSlider: () => void
}
export const SliderLogo: React.FC<SliderLogoProps> = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const isHomeSelected = useMemo(() => {
    return isEqual(location?.pathname, ADMIN_PATH.HOME)
  }, [location?.pathname])

  return (
    <S.SliderLogoDiv
      isHome={isHomeSelected}
      onClick={() => navigate(ADMIN_PATH.HOME)}
    >
      <S.SliderLogoLink to={ADMIN_PATH.DASHBOARD}>
        <BaseImage
          src={R.images.ic_new_logo}
          width={65}
          style={{ borderRadius: BORDER_RADIUS }}
          preview={false}
        />
      </S.SliderLogoLink>
    </S.SliderLogoDiv>
  )
}

import { BurgerIcon } from 'app/components/common/BurgerIcon'
import { PaddingMain } from 'app/constant'
import {
  FONT_SIZE,
  FONT_WEIGHT,
  LAYOUT,
  PADDING,
  colors,
  media,
} from 'parkway-web-common'
import styled, { css } from 'styled-components'
import { BaseCol } from '../../common/BaseCol'
import { BaseCollapse } from '../../common/BaseCollapse'
import { BaseRow } from '../../common/BaseRow'

export const HeaderActionWrapper = styled.div`
  cursor: pointer;

  & > .ant-btn > span[role='img'],
  .ant-badge {
    font-size: 1.25rem;

    @media only screen and ${media.md} {
      font-size: 1.625rem;
    }
  }

  & .ant-badge {
    display: inline-block;
  }
`

export const DropdownCollapse = styled(BaseCollapse)`
  & > .ant-collapse-item > .ant-collapse-header {
    font-weight: 600;
    font-size: 0.875rem;

    color: var(--primary-color);

    @media only screen and ${media.md} {
      font-size: 1rem;
    }
  }

  & > .ant-collapse-item-disabled > .ant-collapse-header {
    cursor: default;

    & > span[role='img'] {
      display: none;
    }
  }
`

export const BurgerCol = styled(BaseCol)`
  // z-index: 999;
  display: flex;
`

export const MobileBurger = styled(BurgerIcon)`
  width: 1.75rem;
  height: 1.75rem;
  margin-right: -0.5rem;
  color: var(--text-main-color);

  ${props =>
    props?.isCross &&
    css`
      color: var(--text-main-color);
    `};
`

export const HeaderWrapper = styled(BaseRow)`
  height: 100%;
  @media only screen and ${media.xs} {
    padding: ${PADDING.xxxl} ${PaddingMain.Mobile.defaultHz};
    height: 100%;
    width: 100%;
  }
  @media only screen and ${media.md} {
    padding: 0px;
    height: 100%;
  }
`

export const SearchColumn = styled(BaseCol)`
  padding: ${LAYOUT.mobile.paddingVertical} ${LAYOUT.desktop.paddingHorizontal};
`

interface ProfileColumn {
  $isTwoColumnsLayout: boolean
}

export const ProfileColumn = styled(BaseCol)<ProfileColumn>``

export const CurrentPath = styled.span`
  color: var(--primary-color);
  font-size: ${FONT_SIZE.xs};
  font-weight: ${FONT_WEIGHT.semibold};
`

export const ButtonPath = styled.button<{ isFocus?: boolean }>`
  border: none;
  background-color: transparent;
  color: ${props => (props?.isFocus ? colors.black : colors.black)};
  font-weight: ${props =>
    props?.isFocus ? FONT_WEIGHT.bold : FONT_WEIGHT.medium};
  padding: ${PADDING.xxxs} ${PADDING.xs};
  cursor: pointer;
  transition: color 0.3s ease, font-weight 0.3s ease;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  &:hover {
    color: ${colors.black};
    font-weight: ${FONT_WEIGHT?.bold};
    transition: color 0.3s ease, font-weight 0.3s ease;
  }
`

export const HomeText = styled.span`
  color: var(--main-color-main-optional-primary-3-color);
  font-size: ${FONT_SIZE.xs};
  font-weight: ${FONT_WEIGHT.regular};
  cursor: pointer;
`

import R from 'app/assets/R'
import { PageTitle } from 'app/components/common/PageTitle'
import { SettingContainer } from 'app/containers/admin/Settings'
import { useTranslation } from 'react-i18next'

const SettingPage = () => {
  const { t } = useTranslation()
  return (
    <>
      <PageTitle>{t(R.strings.settings)}</PageTitle>
      <SettingContainer />
    </>
  )
}
export default SettingPage

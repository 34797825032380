/* eslint-disable @typescript-eslint/no-explicit-any */
import { BaseCol } from 'app/components/common/BaseCol'
import HeaderHome from './Header'
import './style.css'
import * as S from './styles'

const LayoutOneHome = () => {
  return (
    <S.RootWrapper direction="vertical" size={0}>
      <BaseCol xl={24}>
        <HeaderHome />
      </BaseCol>
    </S.RootWrapper>
  )
}
export default LayoutOneHome

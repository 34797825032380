import { Space } from 'antd'
import type { SpaceProps } from 'antd'

import styled from 'styled-components'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type BaseSpaceProps = SpaceProps & { ref?: any }
export const BaseSpace: React.FC<BaseSpaceProps> = props => {
  return <SpaceStyled direction="vertical" size={16} {...props} />
}

const SpaceStyled = styled(Space)`
  width: 100%;
`

import R from 'app/assets/R'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import * as S from './styles'
import { useTranslation } from 'react-i18next'

const LayoutOneHome = () => {
  const { t } = useTranslation()

  return (
    <S.RootWrapper>
      <BaseCol xl={24}>
        <S.ImageWrapper />
        <S.AreaText />
        <S.AreaText />
        <S.AreaText>
          <S.TitleText>{t(R.strings.empowering_kiosk_efficiency)}</S.TitleText>
        </S.AreaText>
        <S.AreaText />
        <S.AreaText />
        <BaseRow gutter={[40, 40]} justify={'space-between'}>
          <S.ColItem xl={12}>
            <S.ItemWrapper>
              <S.IconBase
                src={R.images.ic_content_management}
                preview={false}
              />
              <S.AreaText />
              <S.AreaText>
                <S.TextTitleItem>
                  {t(R.strings.streamlined_content_management)}
                </S.TextTitleItem>
              </S.AreaText>
              <S.DescriptionItem>
                {t(R.strings.streamlined_content_management_d)}
              </S.DescriptionItem>
            </S.ItemWrapper>
          </S.ColItem>
          <S.ColItem xl={12}>
            <S.ItemWrapper>
              <S.IconBase
                src={R.images.ic_remote_kiosk_control}
                preview={false}
              />
              <S.AreaText />
              <S.AreaText>
                <S.TextTitleItem>
                  {t(R.strings.remote_kiosk_control)}
                </S.TextTitleItem>
              </S.AreaText>
              <S.DescriptionItem>
                {t(R.strings.remote_kiosk_control_d)}
              </S.DescriptionItem>
            </S.ItemWrapper>
          </S.ColItem>
          <S.ColItem xl={12}>
            <S.ItemWrapper>
              <S.IconBase
                src={R.images.ic_personal_recommendations}
                preview={false}
              />
              <S.AreaText />
              <S.AreaText>
                <S.TextTitleItem>
                  {t(R.strings.personalized_recommendations)}
                </S.TextTitleItem>
              </S.AreaText>
              <S.DescriptionItem>
                {t(R.strings.personalized_recommendations_d)}
              </S.DescriptionItem>
            </S.ItemWrapper>
          </S.ColItem>
          <S.ColItem xl={12}>
            <S.ItemWrapper>
              <S.IconBase
                src={
                  'https://storage.googleapis.com/kiosify-01/images/ic_advanced_reporting.png'
                }
                preview={false}
              />
              <S.AreaText />
              <S.AreaText>
                <S.TextTitleItem>
                  {t(R.strings.advanced_reporting)}
                </S.TextTitleItem>
              </S.AreaText>
              <S.DescriptionItem>
                {t(R.strings.advanced_reporting_d)}
              </S.DescriptionItem>
            </S.ItemWrapper>
          </S.ColItem>
        </BaseRow>
      </BaseCol>
    </S.RootWrapper>
  )
}
export default LayoutOneHome

import R from 'app/assets/R'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseImage } from 'app/components/common/BaseImage'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useInView } from 'react-intersection-observer'
import * as S from './styles'

const HeaderHome = () => {
  const { t } = useTranslation()

  const { ref, inView } = useInView({ triggerOnce: false })

  const classNameView = useMemo(() => {
    if (inView) {
      return {
        second: 'container-animation second-text',
        three: 'container-animation three-text',
        slogan: 'text-animation',
      }
    }
    return {
      second: '',
      three: '',
    }
  }, [inView])

  return (
    <S.RootWrapper ref={ref}>
      <BaseSpace className="content" direction="vertical" size={36}>
        <BaseSpace direction="vertical" size={36}>
          <BaseRow gutter={48} justify={'center'}>
            <BaseCol style={{ fontSize: 80, fontWeight: 'normal' }}>
              THE
            </BaseCol>

            <BaseCol className={classNameView?.second}>
              <BaseImage
                src={R.images.ic_left_to_right_home}
                width={257}
                height={100}
                preview={false}
              />
            </BaseCol>

            <BaseCol
              className={classNameView?.second}
              style={{ fontSize: 80, fontWeight: 'bold' }}
            >
              FEATURE OF
            </BaseCol>
          </BaseRow>

          <BaseRow gutter={48} justify={'center'}>
            <BaseCol style={{ fontSize: 80, fontWeight: 'bold' }}>
              KIOSK
            </BaseCol>

            <BaseCol className={classNameView?.second}>
              <BaseImage
                src={R.images.ic_star_02}
                width={147}
                height={100}
                preview={false}
              />
            </BaseCol>

            <BaseCol
              className={classNameView?.three}
              style={{ fontSize: 80, fontWeight: 'normal' }}
            >
              MANAGEMENT
            </BaseCol>
          </BaseRow>
        </BaseSpace>

        <div
          style={{
            color: '#033639',
            fontSize: 20,
            fontWeight: 'normal',
            opacity: 0.8,
            marginTop: 50,
          }}
          className={classNameView.slogan}
        >
          {t(R.strings.home_slogan_d)}
        </div>
      </BaseSpace>

      <S.RoteBackground />
    </S.RootWrapper>
  )
}
export default HeaderHome

import styled from 'styled-components'
import { Table as AntdTable } from 'antd'
import { BORDER_RADIUS, FONT_SIZE } from 'parkway-web-common'
import { convertColor } from 'app/styles/themes/themeVariables'

export const Table = styled(AntdTable)`
  .ant-table {
    background: transparent !important;
  }

  & thead .ant-table-cell {
    color: ${convertColor.primaryColor};
    font-size: ${FONT_SIZE.xs};
    line-height: 1.25rem;

    background: transparent !important;

    & .anticon {
      color: ${convertColor.primaryColor};
    }
  }

  & thead .ant-table-cell-scrollbar {
    display: none !important;
  }

  & tbody .ant-table-cell {
    color: ${convertColor.primaryColor};
    font-size: ${FONT_SIZE.xs};
    line-height: 1.25rem;
  }

  & tbody .ant-table-row-expand-icon {
    min-height: 1.25rem;
    min-width: 1.25rem;
    border-radius: 0.1875rem;
    margin-top: 0;
  }

  // Override default antd selector
  &
    .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(
      .ant-table-row-expand-icon-cell
    ):not([colspan])::before {
    background-color: transparent;
  }

  & .ant-table-thead .ant-table-cell {
    color: ${convertColor.primaryColor};
  }

  & .ant-pagination-item {
    height: 2.0625rem;
    border-radius: ${BORDER_RADIUS} !important;
    line-height: 2.0625rem;
    font-size: ${FONT_SIZE.xs};
    a {
      color: ${convertColor.primaryColor} !important;
    }
  }

  & .ant-pagination-prev,
  .ant-pagination-next,
  .ant-pagination-jump-prev,
  .ant-pagination-jump-next {
    min-width: 2.0625rem;
    height: 2.0625rem;
    border-radius: ${BORDER_RADIUS} !important;
    line-height: 2.0625rem;
    font-size: ${FONT_SIZE.xs};
    background-color: rgba(255, 255, 255, 0.1);
    svg {
      fill: var(--background-color) !important;
    }
    a {
      color: var(--background-color) !important;
    }
  }

  & .ant-pagination-disabled {
    background-color: rgba(255, 255, 255, 0.05);
    svg {
      fill: var(--background-color) !important;
      opacity: 0.5;
    }
  }

  .ant-pagination .ant-pagination-item-active {
    background-color: #54e9cd;
    border: none;
    a {
      color: var(--background-color) !important;
    }
  }

  & .ant-pagination-prev .ant-pagination-item-link,
  .ant-pagination-next .ant-pagination-item-link {
    border-radius: 0;
  }

  & .ant-checkbox-inner {
    border-radius: 0.1875rem;
    height: 1.25rem;
    width: 1.25rem;
    border: 1px solid var(--background-color);
  }

  & .editable-row .ant-form-item-explain {
    position: absolute;
    top: 100%;
    font-size: 0.75rem;
  }

  .ant-table-column-sort {
    background-color: transparent;
  }

  .ant-pagination-item-container .ant-pagination-item-ellipsis {
    color: var(--disabled-color);
  }

  .ant-pagination-disabled {
    .ant-pagination-item-link,
    .ant-pagination-item a {
      color: var(--disabled-color);
    }
  }

  .ant-pagination.ant-pagination-disabled {
    .ant-pagination-item-link,
    .ant-pagination-item a {
      color: var(--disabled-color);
    }
  }
`

import { CurrencySupportType } from 'app/components/common/CurrencyPicker/type'
/* eslint-disable @typescript-eslint/no-explicit-any */
import { DayOfTheWeekEnum, IAddress } from 'app/api/restaurant/model'
import R from 'app/assets/R'
import { NotificationType } from 'app/components/common/BaseNotification'
import { ADMIN_PATH } from 'app/components/router/route-path'
import { getToken } from 'app/service/storage/storage-service'
import { flatten } from 'lodash'
import { formatMoney } from 'parkway-web-common'
import { MutableRefObject, useEffect, useRef, useState } from 'react'
import { InfiniteData } from 'react-query'
import { useNavigate } from 'react-router'

interface NotificationSeverity {
  id: number
  name: NotificationType
}

export const defineColorBySeverity = (
  severity: NotificationType | undefined,
  rgb = false,
): string => {
  const postfix = rgb ? 'rgb-color' : 'color'
  switch (severity) {
    case 'error':
    case 'warning':
    case 'success':
      return `var(--${severity}-${postfix})`
    case 'info':
    default:
      return `var(--primary-${postfix})`
  }
}

export const notificationsSeverities: NotificationSeverity[] = [
  { id: 0, name: 'info' },
  { id: 1, name: 'success' },
  { id: 2, name: 'warning' },
  { id: 3, name: 'error' },
  { id: 4, name: 'mention' },
]

export const normalizeProp = (
  prop: string | number | [number, number],
): string =>
  typeof prop === 'number'
    ? `${prop}px`
    : (Array.isArray(prop) && `${prop[0]}px ${prop[1]}px`) || prop.toString()

interface UseMountedReturnVal {
  isMounted: MutableRefObject<boolean>
}

export const useMounted = (): UseMountedReturnVal => {
  const isMounted = useRef(false)

  useEffect(() => {
    isMounted.current = true

    return () => {
      isMounted.current = false
    }
  }, [])

  return { isMounted }
}

export const useNavigateHook = () => {
  const navigate = useNavigate()
  const token = getToken()
  const navigatePath = (path: string) => {
    navigate(path)
  }

  const navigateAuthPath = (path: string) => {
    if (token) {
      navigate(ADMIN_PATH.HOME)
      return
    }

    navigate(path)
  }

  return { navigatePath, navigateAuthPath }
}

export const formatMoneyDollar = (
  money: number | string,
  currency?: string,
) => {
  switch (currency) {
    case 'USD':
      money = +money / 100
      break
    case 'VND':
      break
    default:
      break
  }
  return `${formatMoney(money, currency ?? '', undefined, 2)}`
}

export function usePagination() {
  const flattenData = (data?: InfiniteData<any>) => {
    if (!data) {
      return []
    }

    return flatten(data?.pages?.map(page => page))?.filter(
      (obj: any) => obj !== undefined,
    )
  }

  const flattenDataList = (data?: InfiniteData<any>) => {
    if (!data) {
      return []
    }

    return data?.pages?.[0]
  }

  return {
    flattenData,
    flattenDataList,
  }
}

export function useDebounce<T>(value: T, delay = 500): T {
  const [debounceValue, setDebounceValue] = useState<T>(value)

  useEffect(() => {
    const handler = setTimeout(() => setDebounceValue(value), delay)

    return () => clearTimeout(handler)
  }, [value, delay])

  return debounceValue
}

export const paginateList = <T>(data: T[], page: number, size: number) => {
  const startIndex = (page - 1) * size
  return data.slice(startIndex, startIndex + size)
}

export const convertAddressToString = (add?: IAddress) => {
  let address = ''
  if (add?.addressLine1) {
    address = add?.addressLine1
  }

  if (add?.locality) {
    address = `${address}, ${add?.locality}`
  }

  if (add?.country) {
    address = `${address}, ${add?.country}`
  }

  return address
}

export function millisecondsToTimeString(milliseconds: number): string {
  const totalSeconds = Math.floor(milliseconds / 1000)
  const hours = Math.floor(totalSeconds / 3600)
  const minutes = Math.floor((totalSeconds % 3600) / 60)

  const formattedHours = String(hours).padStart(2, '0')
  const formattedMinutes = String(minutes).padStart(2, '0')

  return `${formattedHours}:${formattedMinutes}`
}

export function timeToMilliseconds(time: string): Date {
  const [hours, minutes] = time.split(':').map(Number)
  const date = new Date()
  date.setHours(hours)
  date.setMinutes(minutes)
  return date
}

export const getOrdinalNumberOfDayOfTheWeek = (day: DayOfTheWeekEnum) => {
  switch (day) {
    case DayOfTheWeekEnum.MON:
      return 1
    case DayOfTheWeekEnum.TUE:
      return 2
    case DayOfTheWeekEnum.WED:
      return 3
    case DayOfTheWeekEnum.THU:
      return 4
    case DayOfTheWeekEnum.FRI:
      return 5
    case DayOfTheWeekEnum.SAT:
      return 6
    case DayOfTheWeekEnum.SUN:
      return 7
  }
}

export const getTextDayOfDayOfTheWeek = (day: DayOfTheWeekEnum) => {
  switch (day) {
    case DayOfTheWeekEnum.MON:
      return R.strings.time_monday
    case DayOfTheWeekEnum.TUE:
      return R.strings.time_tuesday
    case DayOfTheWeekEnum.WED:
      return R.strings.time_wednesday
    case DayOfTheWeekEnum.THU:
      return R.strings.time_thursday
    case DayOfTheWeekEnum.FRI:
      return R.strings.time_friday
    case DayOfTheWeekEnum.SAT:
      return R.strings.time_satuday
    case DayOfTheWeekEnum.SUN:
      return R.strings.time_sunday
  }
}

export const customCurrencyValue = (
  currency: CurrencySupportType,
  price?: number,
) => {
  switch (currency) {
    case 'USD':
      return {
        labelInput: 'USD (cent)',
        price: formatMoney((price ?? 0) / 100, 'USD'),
      }
    case 'VND':
    case 'JPY':
    case 'EUR':
    case 'CNY':
      return {
        labelInput: currency,
        price: formatMoney(price ?? 0, currency),
      }
  }
}

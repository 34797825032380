import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { useAppDispatch, useAppSelector } from 'app/redux/hooks'
import { useTranslation } from 'react-i18next'
import { RightSide } from './components/RightSide'
import { dataMenu } from './constant'
import * as S from './styles'
import { setPathSetting } from 'app/redux/slices/pathSettingSlice'
import { BaseSelect } from 'app/components/common/selects/BaseSelect'
import styled from 'styled-components'
export const SettingContainer = () => {
  const currentPath = useAppSelector(state => state?.pathSetting)?.path
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const onClickItem = (url?: string) => {
    dispatch(setPathSetting(url))
  }

  return (
    <S.Root>
      <BaseSpace>
        <BaseRow justify={'end'} align={'middle'}>
          <BaseForm>
            <BaseForm.Item>
              <BaseSelectOverwrite
                options={dataMenu?.map(item => ({
                  label: t(item.title ?? ''),
                  value: item.url,
                }))}
                defaultValue={currentPath}
                onChange={value => onClickItem(value as string)}
              />
            </BaseForm.Item>
          </BaseForm>
        </BaseRow>
        <RightSide />
      </BaseSpace>
    </S.Root>
  )
}

const BaseSelectOverwrite = styled(BaseSelect)`
  width: calc(100vw - 32px) !important;
`

import { BaseButton } from 'app/components/common/BaseButton'
import { BaseDivider } from 'app/components/common/BaseDivider'
import { BaseImage } from 'app/components/common/BaseImage'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import { BaseSelect } from 'app/components/common/selects/BaseSelect'
import { BORDER_RADIUS_2 } from 'app/constant'
import {
  BORDER_RADIUS,
  FONT_SIZE,
  FONT_WEIGHT,
  PADDING,
} from 'parkway-web-common'
import styled from 'styled-components'

export const RootWrapper = styled(BaseSpace)`
  padding: ${PADDING.xl};
  padding-left: 3.5rem;
  padding-right: 3.5rem;
  width: 100%;
`

export const LeftSide = styled(BaseSpace)`
  width: 65%;
`

export const HomeText = styled.span`
  color: #032648;
  font-size: ${FONT_SIZE.xxl};
  font-weight: ${FONT_WEIGHT.semibold};
  cursor: pointer;
  font-family: 'Inter' !important;
`

export const TextDate = styled.span`
  color: #032648;
  font-size: ${FONT_SIZE.xxs};
  font-weight: ${FONT_WEIGHT.regular};
  text-transform: capitalize;
`

export const RightSide = styled(BaseSpace)`
  width: 34%;
  padding: 0px ${PADDING.xxl};
`

export const SpaceVertical = styled.div`
  height: 5px;
`

export const RevenueWrapper = styled(BaseRow)`
  padding: ${PADDING.xxs};
`
export const RevenueDataWrapper = styled(BaseSpace)`
  width: 39%;
`

export const RevenueDataItemWrapper = styled(BaseSpace)`
  padding: ${PADDING.xs};
  border-radius: ${BORDER_RADIUS};
  width: 100%;
  background-color: #1f1d2b;
`

export const RevenueDataItemIcon = styled.div`
  padding: ${PADDING.xxs};
  border-radius: ${BORDER_RADIUS};
  background-color: #252836;
`

export const RevenueDataItemTextPercent = styled.div<{ isUp?: boolean }>`
  color: ${props =>
    props?.isUp
      ? 'var(--admin-text-focus-setting-menu-color)'
      : '#FF7CA3'}; // need change
  font-size: ${FONT_SIZE.xxs};
  font-weight: ${FONT_WEIGHT.regular};
`

export const RevenueDataItemTextValue = styled.span`
  color: var(--background-color);
  font-size: ${FONT_SIZE.xl};
  font-weight: ${FONT_WEIGHT.bold};
`

export const RevenueDataItemTextDescription = styled.span`
  color: #abbbc2; // need change
  font-size: ${FONT_SIZE.xs};
  font-weight: ${FONT_WEIGHT.regular};
`

export const ChartWrapper = styled.div`
  width: 100%;
  background-color: #1f1d2b;
  border-radius: ${BORDER_RADIUS};
  padding: ${PADDING.md} 0px;
`

export const ChartHeaderWrapper = styled(BaseRow)`
  width: 100%;
  padding: 0px ${PADDING.md};
`

export const ChartHeaderTitle = styled.div`
  color: var(--background-color);
  font-size: ${FONT_SIZE.xxl};
  font-weight: ${FONT_WEIGHT.bold};
`

export const ChartHeaderSelectTime = styled(BaseSelect)`
  min-width: 150px;
`

export const SplitDivider = styled(BaseDivider)`
  background-color: #abbbc2; // need change
`

export const MostOrderWrapper = styled.div`
  padding: ${PADDING.md} 0px;
  width: 100%;
  background-color: #1f1d2b;
  border-radius: ${BORDER_RADIUS};
  position: relative;
`

export const MostOrderItem = styled(BaseRow)`
  padding: 0px ${PADDING.md};
`

export const MostOrderItemImage = styled(BaseImage)`
  border-radius: ${BORDER_RADIUS_2};
`

export const MostOrderItemTitle = styled.div`
  color: #e0e6e9;
  font-size: ${FONT_SIZE.xxs};
  font-weight: ${FONT_WEIGHT.medium};
`

export const MostOrderItemDescription = styled.div`
  color: #abbbc2;
  font-size: ${FONT_SIZE.xxxs};
  font-weight: ${FONT_WEIGHT.regular};
`

export const MostOrderItemButtonViewAll = styled(BaseButton)`
  width: 100%;
  border-color: #29cc6a;
  color: #29cc6a;
  font-weight: ${FONT_WEIGHT.semibold};
  font-size: ${FONT_SIZE.xxs};
  background-color: transparent;
  padding: ${PADDING.md};
`

export const MostOrderItemButtonWrapper = styled.div`
  padding: ${PADDING.md};
  padding-bottom: 0px;
`

export const TableWrapper = styled(BaseSpace)`
  padding: ${PADDING.md};
  background-color: #1f1d2b;
  border-radius: ${BORDER_RADIUS};
  width: 100%;
`

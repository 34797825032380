import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { AuthActionKey, requestGetUserInfo } from 'app/api/auth'
import { LanguageSupportType } from 'app/i18n/constant'
import { UserModel } from 'app/model/user.model'
import { setCurrentLanguage } from 'app/service/storage/storage-service'
import { ResponseType } from 'parkway-web-common'

export interface UserState {
  user: UserModel | null
}

const initialState: UserState = {
  user: null,
}

export const getUserInfo = createAsyncThunk(
  AuthActionKey.USER_INFO_ACTION_KEY,
  async () => {
    const response: ResponseType<{ user: UserModel }> =
      await requestGetUserInfo()
    const language =
      response.data?.user?.language?.toLowerCase() as unknown as LanguageSupportType
    setCurrentLanguage(language)

    return {
      ...response.data?.user,
      language,
    } as UserModel
  },
)

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, payload) => {
      state.user = payload.payload
    },
    changeLanguageUser: (state, action) => {
      if (state?.user) {
        state.user.language = action.payload
      }
    },
    setSubscriptionPlan: (state, payload) => {
      if (state?.user) {
        state.user.subscription_plan = payload.payload
      }
    },
  },
  extraReducers: builder => {
    builder.addCase(getUserInfo.fulfilled, (state, action) => {
      state.user = action.payload
    })
  },
})

export const { setUser, changeLanguageUser, setSubscriptionPlan } =
  userSlice.actions

export default userSlice.reducer

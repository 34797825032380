import React, { useState } from 'react'
import { Outlet } from 'react-router-dom'
import MainContent from '../MainContent'
import MainSliderDes from '../sider/desktop/MainSider'
import MainSliderMo from '../sider/mobile/MainSider'
import * as S from './styles'
import { useResponsive } from 'parkway-web-common'
import { MainHeader } from '../MainHeader'
import { HeaderAdmin } from 'app/components/header/admin'

const MainLayoutAdmin: React.FC = () => {
  // const [isTwoColumnsLayout, setIsTwoColumnsLayout] = useState(true)
  const [siderCollapsed, setSiderCollapsed] = useState(true)
  const { isDesktop } = useResponsive()

  const toggleSider = () => {
    setSiderCollapsed(!siderCollapsed)
  }

  return (
    <S.LayoutMaster>
      {isDesktop ? (
        <MainSliderDes
          isCollapsed={siderCollapsed}
          setCollapsed={setSiderCollapsed}
        />
      ) : (
        <MainSliderMo
          isCollapsed={siderCollapsed}
          setCollapsed={setSiderCollapsed}
        />
      )}
      <S.LayoutMain>
        {!isDesktop ? (
          <MainHeader isTwoColumnsLayout={false}>
            <HeaderAdmin
              toggleSlider={toggleSider}
              isSliderOpened={!siderCollapsed}
              isTwoColumnsLayout={false}
            />
          </MainHeader>
        ) : null}
        <MainContent id="main-content" $isTwoColumnsLayout={false}>
          <div style={{ height: '100%' }}>
            <Outlet />
          </div>
        </MainContent>
      </S.LayoutMain>
    </S.LayoutMaster>
  )
}

export default MainLayoutAdmin

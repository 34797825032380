export const SettingContainer = () => {
  // const [search, setSearch] = useState('')
  // const { t } = useTranslation()

  return (
    <div>
      {/* <S.TitleWrapper justify={'space-between'}>
        <S.Title>{t(R.strings.settings)}</S.Title>
        <BaseCol>
          <SearchComponent
            initValue={search}
            onChange={setSearch}
            placeholder={t(R.strings.input_placeholder_search_tab)}
          />
        </BaseCol>
      </S.TitleWrapper>
      <S.RootWrapper gutter={[15, 15]}>
        <S.LeftSide xl={6}>
          {dataMenu?.map((item, index) => {
            return <ItemMenuCpn item={item} key={index} />
          })}
          <ModalSyncSquare />
        </S.LeftSide>
        <BaseCol xl={18}>
          <RightSide />
        </BaseCol>
      </S.RootWrapper> */}
    </div>
  )
}

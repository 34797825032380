import { memo } from 'react'
import { isEqual } from 'lodash'
import SignUpContainerDes from 'app/containers/auth/desktop/SignUp'
import SignUpContainerMo from 'app/containers/auth/mobile/SignUp'
import { useResponsive } from 'parkway-web-common'

const SignUpPageComp = () => {
  const { isTablet } = useResponsive()
  return !isTablet ? <SignUpContainerMo /> : <SignUpContainerDes />
}
export const SignUpPage = memo(SignUpPageComp, isEqual)

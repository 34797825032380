export default {
  empowering_kiosk_efficiency: 'Tăng Cường Hiệu Quả Của Kiosk',
  streamlined_content_management: 'Quản Lý Nội Dung Hợp Lý',
  streamlined_content_management_d:
    'Dễ dàng quản lý và cập nhật nội dung kiosk của bạn, đảm bảo nội dung đó luôn cập nhật và hấp dẫn.',
  remote_kiosk_control: 'Điều Khiển Kiosk Từ Xa',
  remote_kiosk_control_d:
    'Kiểm soát các kiosk của bạn từ mọi nơi, theo dõi hiệu suất của chúng và thực hiện các điều chỉnh nhanh chóng.',
  personalized_recommendations: 'Đề Xuất Được Cá Nhân Hóa',
  personalized_recommendations_d:
    'Tận dụng dữ liệu khách hàng để đưa ra khuyến nghị và khuyến mãi sản phẩm được cá nhân hóa.',
  advanced_reporting: 'Báo Cáo Nâng Cao',
  advanced_reporting_d:
    'Tạo báo cáo toàn diện để xác định xu hướng, tối ưu hóa chiến lược và đưa ra quyết định sáng suốt.',
  personalized_recommendations_and_promotions:
    'Đề Xuất Và Khuyến Mãi Được Cá Nhân Hóa',
  personalized_recommendations_and_promotions_d:
    'Mở khóa các đề xuất và khuyến mãi được cá nhân hóa trong hệ thống đặt hàng nhanh Kiosify',

  revolutionizing_customer_engagement:
    'Cách Mạng Hóa Sự Tương Tác Của Khách Hàng: Khuyến Nghị Và Khuyến Mãi Được Cá Nhân Hóa Của Kiosify',
  revolutionizing_customer_engagement_d:
    'Kiosify tận dụng dữ liệu khách hàng để đưa ra các đề xuất và khuyến mãi sản phẩm được cá nhân hóa. Bằng cách phân tích lịch sử mua hàng, hành vi duyệt web và thông tin nhân khẩu học, Kiosify có thể điều chỉnh các đề xuất cho phù hợp với sở thích và mối quan tâm của từng khách hàng. Cách tiếp cận được cá nhân hóa này nâng cao sự hài lòng của khách hàng bằng cách giới thiệu các sản phẩm và ưu đãi phù hợp với sở thích của họ.',
  sign_up_for_a_free_trial: 'Đăng Ký Dùng Thử Miễn Phí',
  start_14_days_trail: 'Dùng Thử 14 Ngày',
  home_subcribe:
    'Đăng ký nhận bản tin của chúng tôi và nhận thông tin cập nhật mới nhất',
  enter_your_email_address: 'Nhập địa chỉ email của bạn',
  footer_kiosify:
    'Kiosify: Nâng Cao Trải Nghiệm Khách Hàng Của Bạn Với Dịch Vụ Tự Phục Vụ',
  contact_us: 'Liên Hệ Chúng Tôi',

  home_slogan: 'Kiosify: Tương Lai Của Quản Lý Kiosk',
  home_slogan_d: 'Hệ thống tự đặt hàng hoàn hảo cho doanh nghiệp của bạn',
  create_your_kiosify_account: 'Tạo tài khoản Kiosify của bạn',
  see_more: 'Xem thêm',
  find: 'Tìm kiếm',
  address: 'Địa chỉ',
  language: 'Ngôn ngữ',
  vietnamese: 'Tiếng việt',
  english: 'English',
  foreign_language: 'Ngoại ngữ',
  home: 'Trang chủ',
  phone: 'Số điện thoại',
  email: 'Email',
  send: 'Gửi',
  next: 'Tiếp theo',
  back: 'Quay lại',
  send_code: 'Gửi mã',
  login: 'Đăng nhập',
  user_name: 'Tên đăng nhập',
  password: 'Mật khẩu',
  login_success: 'Đăng nhập thành công',
  save_success: 'Lưu thành công',
  forgot_password: 'Quên mật khẩu',
  require_field: 'Vui lòng nhập "{{field}}", đây là trường bắt buộc',
  regex_field: 'Please enter "{{field}}" correct format',
  minimum_field: 'Vui lòng nhập tối thiểu {{count}} kí tự',
  maximum_field: 'Vui lòng nhập tối đa {{count}} kí tự',
  register: 'Đăng kí',
  confirm_password: 'Xác nhận mật khẩu',
  verify_by_phone: 'Xác thực bằng số điện thoại',
  verify_by_email: 'Xác thực bằng số email',
  code_verify: 'Mã xác thực',
  message_verify_phone:
    'Bạn vui lòng kiểm tra trong tin nhắn Zalo của số điện thoại {{phone}} để lấy mã xác thực.',
  re_send_verify_code: 'Gửi lại mã xác thực',
  resend: 'Gửi lại',
  time_resend_verify_code: 'Gửi lại sau {{timer}} giây',
  verify: 'Xác thực',
  logout: 'Đăng xuất',
  full_name: 'Họ và tên',
  regex_name: 'Tên cần đúng định dạng',
  regex_user_name: 'User name cần đúng định dạng (Không bao gồm dấu "cách")',
  using_by: 'Được sử dụng bởi',
  require_email: 'Vui lòng nhập email',
  require_contact_name: 'Vui lòng nhập tên người liên hệ',
  regex_email: 'Vui lòng nhập đúng định dạng email',
  regex_phone: 'Vui lòng nhập đúng định dạng số điện thoại',
  send_contact_success:
    'Đã gửi thông tin liên hệ thành công, cảm ơn quý khách.',
  please_enter_correct_format: 'Vui lòng  định dạng',
  medical_examination: 'Khám bệnh',
  human_resources: 'Nhân sự',
  finance: 'Tài chính',
  inventory_management: 'Quản lý kho',
  purchasing_management: 'Quản lý mua hàng',
  customer_care: 'Chăm sóc khách hàng',
  clinic_management: 'Quản lý phòng khám',
  company: 'Công ty',
  organizational_chart: 'Sơ đồ tổ chức',
  announcement: 'Thông báo',
  internal_news: 'Tin nội bộ',
  onboarding: 'OnBoard',
  report: 'Báo cáo',
  marketing: 'Marketing',
  accounting: 'Kế toán',
  work_schedule: 'Lịch làm việc',
  attendance_record: 'Chấm công',
  mailbox: 'Hộp thư',
  my_requests: 'Yêu cầu của tôi',
  my_tasks: 'Công việc của tôi',
  my_income: 'Thu nhập của tôi',
  dashboard: 'Tổng quan',
  profile: 'Thông tin cá nhân',
  read_all: 'Đánh dấu đọc tất cả',
  view_all: 'Xem tất cả',
  remember_me: 'Ghi nhớ',
  parkway_dental: 'Nha khoa Parkway',
  description_parkway_auth:
    'Nha khoa Parkway là hệ thống nha khoa toàn diện và chuyên nghiệp, đặc biệt về chỉnh nha và implant, đáp ứng trọn vẹn nhu cầu nha khoa của khách hàng.',
  development: 'Đang phát triển',
  coming_soon: 'Sắp ra mắt',

  customers: 'Khách hàng',
  region: 'Khu vực',
  revenue: 'Doanh thu',
  basic_package: {
    title: 'Cơ Bản',
    price: '$50/thg',
    benefit_1: 'Lên đến 3 Kiosk',
    benefit_2: 'Đơn hàng không giới hạn',
    benefit_3: 'Người quản lý tài khoản chuyên dụng',
    benefit_4: 'Hỗ trợ 24/7',
    benefit_5: 'Truy cập vào bảng điều khiển trực tuyến của chúng tôi',
    benefit_6: 'Báo cáo và phân tích',
  },
  premium_package: {
    title: 'Cao Cấp',
    price: '$80/thg',
    benefit_1: 'Lên đến 10 Kiosk',
    benefit_2: 'Đơn hàng không giới hạn',
    benefit_3: 'Miễn phí KDS',
    benefit_4: 'Hỗ trợ 24/7',
    benefit_5: 'Xây dựng thương hiệu có thể tùy chỉnh',
    benefit_6: 'Hỗ trợ đa địa điểm',
    benefit_7: 'Hỗ trợ tiếp thị',
  },
  smart_saving_package: {
    title: 'Tiết Kiệm Thông Minh',
    price: '$30/thg/1 kiosk',
    benefit_1: 'Giảm phí hoa hồng',
    benefit_2: 'Hỗ trợ khách hàng ưu tiên',
    benefit_3: 'Truy cập vào tính năng độc quyền',
  },
  time: {
    today: 'Hôm nay',
    this_week: 'Tuần này',
    this_month: 'Tháng này',
    days: 'Ngày',
    start_time_kiosk: 'Thời gian mở cửa',
    end_time_kiosk: 'Thời gian đóng cửa',
    monday: 'Thứ hai',
    tuesday: 'Thứ ba',
    wednesday: 'Thứ tư',
    thursday: 'Thứ năm',
    friday: 'Thứ sáu',
    satuday: 'Thứ bảy',
    sunday: 'Chủ nhật',
  },
  error_message: {
    service_package: 'Vui lòng chọn gói dịch vụ',
    select_location: 'Vui lòng chọn vị trí',
    select_payment_method: 'Vui lòng chọn phương thức thanh toán',
    select_kiosk: 'Please select kiosk',
  },
  success_message: {
    create_kiosk: 'Tạo Kiosk thành công',
    update_kiosk: 'Cập nhật Kiosk thành công',
  },
  table: {
    customer: 'Khách Hàng',
    time: 'Thời Gian',
    date: 'Thời Gian',
    order: 'Đơn Hàng',
    total_payment: 'Tổng Tiền',
    payment_method: 'Thanh Toán',
    status: 'Trạng Thái',
    type: 'Loại',
    name: 'Tên',
    location: 'Địa điểm',
    kiosk_code: 'Mã Kiosk',
    product_name: 'Tên sản phẩm',
    price: 'Giá',
    item_name: 'Tên mạt hàng',
    category: 'Danh mục',
    variation: 'Biến thể',
    up_sell_item: 'Mặt hàng bán thêm',
    up_sell_at: 'Bán thêm tại',
    quantity: 'Số lượng',
    action: 'Hành động',
    options: 'Tùy chọn',
    discount_name: 'Tên giảm giá',
    discount_type: 'Loại giảm giá',
    discount_apply: 'Áp dụng giảm giá',
    item_price: 'Giá mặt hàng',
    active: 'Kích hoạt',
    bestseller: 'Người bán hàng giỏi nhất',
  },
  payment_method: {
    cash: 'Tiền mặt',
    credit_card: 'Thẻ tín dụng',
    gift_card: 'Thẻ quá tặng',
  },
  dashboard_common: {
    most_ordered: 'Đặt Hàng Nhiều Nhất',
    most_type_order: 'Các Loại Đơn Hàng Phổ Biến',
    total_revenue: 'Tổng doanh thu',
    pending_revenue: 'Doanh thu chưa thực hiện',
    actual_revenue: 'Thực thu',
    compared_to_last_month: 'So với tháng trước',
    today_revenue: 'Doanh thu hôm nay',
    branch_name: 'Tên chi nhánh',
    overall_today_summary: 'Tổng quan hôm nay',
    area: 'Khu vực',
    ordinal_number: 'STT',
    clinic: 'Phòng khám',
    dishes_ordered: 'món đã đặt',
    view_all: 'Xem Tất Cả',
    order_report: 'Thống kê đơn hàng',
  },
  reports_common: {
    title: 'Báo cáo',
    total_revenue: 'Tổng doanh thu',
    total_dish_ordered: 'Tổng số món đã đặt',
    total_customer: 'Tổng số khách hàng',
    order_report: 'Báo cáo đơn hàng',
    chart_header_realtime: 'Thời gian thực',
    chart_header_daily: 'Hàng ngày',
    chart_header_today: 'Hôm nay',
    chart_header_weekly: 'Hàng tuần',
    chart_header_monthly: 'Hàng tháng',
    chart_header_yearly: 'Hàng năm',
    chart_label_monday: 'T2',
    chart_label_tuesday: 'T3',
    chart_label_wednesday: 'T4',
    chart_label_thursday: 'T5',
    chart_label_friday: 'T6',
    chart_label_satuday: 'T7',
    chart_label_sunday: 'CN',
    chart_label_january: 'T1',
    chart_label_february: 'T2',
    chart_label_march: 'T3',
    chart_label_april: 'T4',
    chart_label_may: 'T5',
    chart_label_june: 'T6',
    chart_label_july: 'T7',
    chart_label_august: 'T8',
    chart_label_september: 'T9',
    chart_label_october: 'T10',
    chart_label_november: 'T11',
    chart_label_december: 'T12',
    chart_serrie_total_gross: 'Tổng doanh thu',
    chart_serrie_total_net: 'Tổng doanh thu ròng',
  },
  input_placeholder: {
    search: 'Tìm thức ăn, cà phê, ...',
    search_tab: 'Tìm kiếm thẻ ...',
    select_location: 'Chọn địa điểm',
    order_note: 'Ghi chú đơn hàng...',
    search_only: 'Tìm kiếm',
    select_category: 'Chọn danh mục',
    device_name: 'Tên thiết bị',
    search_printer: 'Tìm máy in',
    search_profile: 'Tìm hồ sơ',
    search_your_kiosk: 'Tìm Kiosk Của Bạn',
    write_your_content_here: 'Viết nội dung bạn muốn ở đây.....',
    select_time: 'Chọn thời gian',
  },
  input_validate: {
    required_kiosk_name: 'Vui lòng nhập Tên Kiosk của bạn!',
  },
  information: {
    branch: 'Chi nhánh',
    location: 'Địa chỉ',
    phone_no: 'Số điện thoại',
    email: 'Email',
  },
  kiosk: {
    add_your_kiosk: 'Thêm Kiosk Của Bạn',
    edit_your_kiosk: 'Chỉnh Sửa Kiosk Của Bạn',
    add_new_kiosk: 'Thêm Kiosk',
    kiosk_name: 'Tên Kiosk',
    kiosk_code: 'Mã Kiosk',
    your_subscription: 'Đăng ký của bạn',
    payment_method: 'Phương thức thanh toán',
    redeem_loyalty: 'Đổi Điểm Thưởng Thành Viên',
    redeem_discount: 'Sử Dụng Ưu Đãi Giảm Giá',
    open_time: 'Thời gian mở cửa',
    start_popups_when_the_store_is_closed:
      'Hiện cửa sổ bật lên khi cửa hàng đóng cửa',
    message: 'Tin nhắn',
  },
  notification_status: {
    success: 'Thành công',
    info: 'Thông tin',
    warning: 'Cảnh báo',
    error: 'Lỗi',
  },
  product_management: {
    category: 'Loại',
    items: 'Mặt hàng',
    up_sell: 'Bán thêm',
    modifiers: 'Chỉnh sửa',
    discounts: 'Giảm giá',
    delete_item_up_sell: 'Xóa mặt hàng bán thêm',
    delete_item_up_sell_description: 'Bạn có chắc chắn muốn xóa mặt hàng',
    create_up_sell: 'Tạo Món Bán Thêm',
    menu_price: 'Giá thực đơn',
    discount_price: 'Giảm giá',
    item_level: 'Cấp Độ Vật Phẩm',
    order_level: 'Cấp Độ Đặt Hàng',
  },
  form_item: {
    select_up_sell_at_t: 'Bán Thêm Tại',
    select_up_sell_at_p: 'Chọn Bán Thêm Tại',
    select_item_t: 'Chọn Mặt Hàng',
    select_item_p: 'Chọn Mặt Hàng',
    select_item_variation_t: 'Biến thể',
    select_item_variation_p: 'Chọn Biến Thể Của Mặt Hàng',
    select_price_t: 'Chọn Giá',
    select_price_p: 'Chọn Giá',
    price_t: 'Giá',
    select_item_apply_t: 'Chọn Mục Áp Dụng',
    select_item_apply_p: 'Chọn Mục Áp Dụng',
  },
  setting_menu: {
    appearance_t: 'Giao Diện',
    appearance_d: 'Chế độ tối và sáng, cỡ chữ',
    your_restaurant_t: 'Nhà Hàng Của Bạn',
    your_restaurant_d: 'Chế độ tối và sáng, cỡ chữ',
    your_kiosk_t: 'Kiosks Của bạn',
    your_kiosk_d: 'Kiểm tra thông tin Kiosk của bạn',
    product_management_t: 'Quản Lý Sản Phẩm',
    product_management_d: 'Quản lý sản phẩm, giá cả,...',
    kds_t: 'Cài Đặt KDS',
    kds_d: 'Chế độ tối và sáng, cỡ chữ',
    backup_t: 'Sao Lưu',
    backup_d: 'Quay lại bản cập nhật trước đó của bạn',
    your_information_t: 'Thông Tin Của Bạn',
    your_information_d: 'Tìm hiểu thêm về tên thương hiệu, máy in, đầu đọc,...',
    sync_data_from_square_t: 'Đồng Bộ Dữ Liệu',
    sync_data_from_square_d:
      'Đồng bộ hóa dữ liệu của bạn từ Square ngay lập tức',
  },
  your_infomation: {
    km_stand: 'Khán Đài KM',
    km_printers: 'Máy In KM',
    km_readers: 'Độc Giả KM',
    device_id: 'Mã thiết bị',
    get_help: 'Trợ giúp',
    printers: 'Máy in',
    profiles: 'Hồ sơ',
    connect_printer: 'Kết nối máy in',
    no_printer_connected: 'Không có máy in nào được kết nối',
    troubleshoot: 'Khắc phục sự cố',
    troubleshoot_d: 'Không thấy máy in được kết nối?',
    create_profile: 'Tạo hồ sơ',
    no_profile: 'Không có hồ sơ',
    printer_defaults: 'Mặc định của máy in',
    printer_defaults_d:
      'Chọn các mục bạn muốn in với hồ sơ này. Tất cả các máy in kế thừa hồ sơ này sẽ chỉ in những mục đó.',
    receipts: 'Biên lai',
    in_person_order_tickets: 'Vé đặt hàng trực tiếp',
    online_order_tickets: 'Đặt vé trực tuyến',
    order_ticket_stubs: 'Đặt cuống vé',
    void_tickets: 'Vé vô hiệu',
    use_this_printer_for_receipts: 'Sử dụng máy in này để nhận biên lai',
    use_this_printer_for_receipts_d:
      'Khi bật, máy in này cũng sẽ in hóa đơn, báo cáo và mở ngăn đựng tiền',
    no_device: 'Không Có Thiết Bị',
  },
  about: 'Thông tin',
  general: 'Tổng quan',
  connect_and_manage: 'Kết nối và quản lý',
  add_new: 'Thêm mới',
  add_more_food: 'Thêm món ăn',
  key_all_label: 'Tất cả',
  restaurant: 'Nhà hàng',
  your_restaurant: 'Nhà hàng của bạn',
  see_your_restaurant: 'Xem nhà hàng của bạn',
  background: 'Nền',
  text: 'Chữ',
  button: 'Nút',
  custom: 'Tùy chỉnh',
  see_all: 'Xem tất cả',
  review: 'Xem lại',
  restaurant_name: 'Tên nhà hàng',
  fake_time: 'Thứ Bảy, 18 Thg 11 2024',
  fake_tab: {
    hot_dishes: 'Món ăn nóng',
    cold_dishes: 'Món ăn lạnh',
    soup: 'Súp',
    grill: 'Nướng',
    appetizer: 'Món khai vị',
    dessert: 'Món tráng miệng',
  },
  choose_dishes: 'Chọn món',
  bowls_available: 'phần có sẵn',
  item: 'Món',
  qty: 'Số lượng',
  price: 'Giá',
  fake_order_note: 'Làm ơn, làm ít cay.',
  discount: 'Giảm giá',
  sub_total: 'Tổng',
  continue_to_payment: 'Tiếp tục thanh toán',
  save: 'Lưu',
  dark_mode: 'Chế độ tối',
  light_mode: 'Chế độ sáng',
  color: {
    black: 'Đen',
    white: 'Trắng',
    light_red: 'Đỏ nhạt',
    light_green: 'Xanh lá nhạt',
    light_blue: 'Xanh dương nhạt',
    light_orange: 'Cam nhạt',
    light_gray: 'Xám nhạt',
    light_cyan: 'Lục lam nhạt',
  },
  label_common: {
    for_here: 'Ở đây',
    to_go: 'Mang đi',
    pick_up: 'Đến lấy',
    late: 'Trễ',
    on_time: 'Đúng giờ',
    caution: 'Thận trọng',
    small: 'Nhỏ',
    medium: 'Vừa',
    large: 'Lớn',
    extra_large: 'Cực lớn',
  },
  kds: {
    standard_screen_option: 'TÙY CHỌN MÀN HÌNH TIÊU CHUẨN',
    default_t: 'Mặc định',
    default_d:
      'Từ trên xuống dưới, từ trái sang phải. Vé được điền vào nơi phù hợp',
    classic_t: 'Cổ điển',
    classic_d:
      'Từ trên xuống dưới, từ trái sang phải. Vé được điền vào nơi phù hợp',
    caution_time: 'Thời gian thận trọng',
    late_time: 'Thời gian trễ',
    order_received_sms: 'Đặt hàng đã nhận được SMS',
    order_bumped_from_kds_screen_sms: 'Đơn đặt hàng bị lỗi từ SMS màn hình KDS',
    order_is_ready_for_pick_up_sms: 'Đơn hàng đã sẵn sàng để nhận SMS',
    transition_time: 'THỜI GIAN CHUYỂN TIẾP',
    font_and_color: 'PHÔNG CHỮ & MÀU SẮC',
    font_size: 'Cỡ chữ',
    status_color: 'Màu trạng thái',
    order_type: 'Kiểu đơn hàng',
    mesaging: 'TIN NHẮN',
  },
  min: 'phút',
  on: 'Bật',
  off: 'Tắt',
  see_with: 'Xem theo',
  import: 'Import',
  week: 'Tuần',
  download_successful: 'Download thành công',
  import_file_successful: 'Import file thành công',
  import_file_error: 'Import file lỗi',
  choose_week: 'Chọn tuần',
  notification: 'Thông báo',
  not_done: 'Chưa làm',
  done: 'Hoàn thành',
  in_progress: 'Đang làm',
  see_detail: 'Xem chi tiết',
  mail: 'Thư',
  new_mail: 'Thư mới',
  important: 'Quan trọng',
  show_less: 'Đọc tiếp',
  collapse: 'Thu gọn',
  last_time_update: 'Cập nhật gần nhất',
  service: 'Dịch vụ',
  month: 'Tháng',
  import_file: 'Import file',
  cancel: 'Hủy',
  export_excel: 'Export excel',
  download_example_import_file: '',
  solutions: 'Solutions',
  vision: 'Vision',
  programs: 'Programs',
  blog: 'Blog',

  product: 'Sản phẩm',
  pricing: 'Mức giá',
  resource: 'Tài nguyên',

  customer_login: 'Đăng nhập',
  sign_up: 'Đăng ký',
  watch_free_demo: 'Xem bản demo miễn phí',
  sign_in_to_stay_connected: 'Đăng nhập để duy trì kết nối.',
  or_sign_in_with_other_accounts: 'hoặc đăng nhập bằng tài khoản khác?',
  dont_have_an_account: 'Bạn chưa có tài khoản?',
  click_here_to_sign_up: 'Nhấp chuột vào đây để đăng ký.',
  sign_in: 'Đăng nhập',
  create_your_hope_ui_account: 'Tạo tài khoản UI Hope của bạn',
  first_name: 'Họ',
  last_name: 'Tên',
  phone_no: 'Số điện thoại',
  confirm_password_incorrect: 'Mật khẩu được nhập lại không hợp lệ',
  i_agree_with_the_terms_of_use: 'Tôi đồng ý với các điều khoản sử dụng',
  or_sign_up_with_other_accounts: 'hoặc đăng ký bằng tài khoản khác?',
  already_have_an_account: 'Bạn đã có tài khoản',
  reset: 'Cài lại',
  reset_password: 'Cài lại mật khẩu',
  enter_your_email_address_and_we_ll_send_you_an_email_with_instructions_to_reset_your_password:
    'Nhập địa chỉ email của bạn và chúng tôi sẽ gửi cho bạn một email kèm theo hướng dẫn để đặt lại mật khẩu của bạn',
  back_to_home: 'Back to home',
  back_to_login: 'Back to login',
  message_verify_email:
    'Một email đã được gửi đến email@domain.com của bạn. Vui lòng kiểm tra email từ công ty và nhấp vào liên kết đi kèm để đặt lại mật khẩu của bạn.',
  success: 'Thành công',
  subcribe_now: 'Đăng Ký Ngay',
  error: 'Lỗi',
  admin: 'Quản trị viên',
  settings: 'Cài đặt',
  not_found: 'Không tìm thấy',
  spicy_seasoned_seafood_noodles: 'Mỳ hải sản cay',
  salted_pasta_with_mushroom_sauce: 'Pasta muối sốt nấm',
  beef_dumpling_in_hot_and_sour_soup: 'Bánh bao bò sốt chua cay',
  healthy_noodle_with_spinach_leaf: 'Mỳ dùng kèm với rau bina tốt cho sức khỏe',
  hot_spicy_fried_rice_with_omelet: 'Cơm chiên cay nóng với trứng tráng',
  spicy_instant_noodle_with_special_omelette:
    'Mì ăn liền cay trứng tráng đặc biệt',
  spicy_seasoned_seafood_noodles_short: 'Mì hải sản...',
  salted_pasta_with_mushroom_sauce_short: 'Pasta muối sốt...',
  spicy_instant_noodle_with_special_omelette_short: 'Mì ăn liền cay trứng...',
  healthy_noodle_with_spinach_leaf_short: 'Mỳ dùng kèm với rau bina...',
  select_item_for_upsells: 'Chọn mặt hàng để bán thêm',
  recommended_foods: 'Thực phẩm khuyên dùng',
  ex: 'Vd',
  spicy_seasoned_seafood_noodles_strawberry: 'Mì hải sản cay, dâu tây',
  account_verification: 'Xác minh tài khoản',
  email_verification: 'Xác minh Email',
  account_verification_d1:
    'Chúng tôi đã gửi cho bạn mã đăng nhập tạm thời gồm 6 chữ số tại',
  account_verification_d2:
    'Vui lòng nhập mã này để đăng nhập vào tài khoản của bạn.',
  didnt_receive_the_code: 'Bạn chưa nhận được mã?',
  resend_code_in: 'Gửi lại mã trong',
  resend_code: 'Gửi lại mã',
  account_verified: 'Tài khoản đã được xác minh',
  didnt_sync_data: 'Không đồng bộ hóa dữ liệu?',
  click_here_to_connect_square_account:
    'Nhấn vào đây để kết nối tài khoản square',
  change_password: 'Đổi Mật Khẩu',
  card: 'Thẻ',
  cardholder_name: 'Tên Chủ Thẻ',
  please_input_cardholder_name: 'Vui lòng nhập tên chủ thẻ!',
  cardholder_name_must_be_at_least_6_characters:
    'Tên chủ thẻ phải dài ít nhất 6 ký tự',
  start_subscription: 'Bắt Đầu Đăng Ký',
  order_summary: 'Tóm Tắt Theo Thứ Tự',
  Plane: 'Máy bay',
  subscription_monthly: 'Đăng ký (hàng tháng)',
  fourteen_days_free_trail: '14 ngày miễn phí',
  subtotal: 'Tổng phụ',
  taxes_if_applicable: 'Thuế (nếu có)',
  total: 'Tổng',
  save_this_card_for_future_use: 'Lưu thẻ này để sử dụng sau này.',
  subcribe_for_free: 'Đăng ký miễn phí',
  language_option: {
    en: 'Tiếng Anh',
    vi: 'Tiếng Việt Nam',
    ja: 'Tiếng Nhật Bản',
    es: 'Tiếng Tây Ban Nha',
    fr: 'Tiếng Pháp',
    zh: 'Tiếng Trung Quốc',
  },
  currency_option: {
    en: 'USD',
    vi: 'VND',
    ja: 'JPY',
    es: 'EUR',
    fr: 'EUR',
    zh: 'CNY',
  },
  feature: 'Tính năng',
  before_5_minutes: 'Trước 5 phút',
  upload: 'Tải lên',
  upload_your_image: 'Tải lên ảnh của bạn',
  confirm: 'Xác nhận',
  you_sync_data_success: 'Bạn đã đồng bộ dữ liệu thành công',
  thank_you_for_interest:
    'Cảm ơn bạn đã quan tâm tới kiosify, chúng tôi sẽ sớm liên hệ lại với bạn',
}

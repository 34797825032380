import { PADDING, colors } from 'parkway-web-common'
import { BaseButton } from '../BaseButton'
import styled from 'styled-components'

export const ButtonVariant = styled(BaseButton)<{
  isVariantOutline?: boolean
  $borderRadius?: string
  $colorText?: string
}>`
  border-radius: ${props => props.$borderRadius ?? '6px'};
  padding: ${PADDING.xl} ${PADDING.xl};
  background-color: ${props =>
    props.isVariantOutline ? colors.white : '#42E5C6'}; // need change
  transition: all 0.5s ease !important;
  border: 1px solid
    ${props => (props.isVariantOutline ? colors.black : '#42E5C6')}; // need change
  color: ${props =>
    props.isVariantOutline
      ? props?.$colorText ?? colors.black
      : props?.$colorText ?? colors.white};
  &:hover {
    transition: all 0.5s ease !important;
    color: ${props =>
      props.isVariantOutline
        ? props?.$colorText ?? colors.black
        : props?.$colorText ?? colors.white} !important;
    border: 1px solid
      ${props => (props.isVariantOutline ? colors.black : '#42E5C6')} !important;
  }
`

import R from 'app/assets/R'
import { PageTitle } from 'app/components/common/PageTitle'
import ReportContainerDe from 'app/containers/admin/desktop/Reports'
import ReportContainerMo from 'app/containers/admin/mobile/Reports'
import { useResponsive } from 'parkway-web-common'
import { useTranslation } from 'react-i18next'

const ReportsPage = () => {
  const { t } = useTranslation()
  const { isTablet } = useResponsive()
  return (
    <>
      <PageTitle>{t(R.strings.report)}</PageTitle>
      {!isTablet ? <ReportContainerMo /> : <ReportContainerDe />}
    </>
  )
}
export default ReportsPage

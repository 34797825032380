import { DeleteOutlined, PlusCircleOutlined } from '@ant-design/icons'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { BaseTimePicker } from 'app/components/common/pickers/BaseTimePicker'
import { notificationController } from 'app/controllers/notification-controller'
import dayjs from 'dayjs'
import { isEmpty, isEqual } from 'lodash'
import { FONT_SIZE, FONT_WEIGHT, moment } from 'parkway-web-common'
import { useMemo } from 'react'
import styled from 'styled-components'
import { ITimeOpenRestaurant, SelectOpenTimeItemProps } from './type'
import { convertColor } from 'app/styles/themes/themeVariables'
import { useTranslation } from 'react-i18next'
import R from 'app/assets/R'

export const SelectOpenTimeItem = ({
  item,
  onAddTimeRangeOfDay,
  onDeleteRangeTime,
  onChangeRangeTime,
}: SelectOpenTimeItemProps) => {
  const { t } = useTranslation()
  const handleChangeTimePicker = (
    selectedTime: dayjs.Dayjs | null,
    key: keyof ITimeOpenRestaurant,
    indexRange: number,
    range: ITimeOpenRestaurant,
  ) => {
    const newDate = moment(selectedTime?.toDate()).toDate()
    if (key === 'startTime') {
      const endDate = item?.times?.[indexRange]?.endTime

      if (endDate <= newDate) {
        notificationController.error({
          message:
            'You must choose a start time that is lower than the end time',
        })
        return
      }
    }
    const newItem = {
      ...range,
      [key]: newDate,
    }

    onChangeRangeTime?.({
      newItem,
      key: item?.key,
    })
  }

  const _onAddTimeRangeOfDay = () => {
    onAddTimeRangeOfDay?.(item?.key)
  }

  const _onDeleteRangeTime = (id?: string, key?: string) => {
    if (!id || !key) return
    onDeleteRangeTime?.(id, key)
  }

  const isDisableAddButton = useMemo(() => {
    return !item?.times?.find(itm => isEmpty(itm?.id) || isEqual(itm?.id, '-'))
  }, [item])

  return (
    <BaseSpace size={8}>
      <BaseRow gutter={[16, 16]} wrap={false}>
        <BaseCol xl={21}>
          <BaseRow gutter={[16, 16]} wrap={false}>
            <BaseCol xl={8}>
              <BaseText fontWeight="medium" style={{ color: '#818181' }}>
                {t(R.strings.time_days)}
              </BaseText>
            </BaseCol>
            <BaseCol xl={8}>
              <BaseText fontWeight="medium" style={{ color: '#818181' }}>
                {t(R.strings.time_start_time_kiosk)}
              </BaseText>
            </BaseCol>
            <BaseCol xl={8}>
              <BaseText fontWeight="medium" style={{ color: '#818181' }}>
                {t(R.strings.time_end_time_kiosk)}
              </BaseText>
            </BaseCol>
          </BaseRow>
        </BaseCol>
        <BaseCol />
      </BaseRow>

      <BaseRow gutter={[16, 16]} wrap={false} align={'middle'}>
        <BaseCol xl={21}>
          <BaseRow gutter={[16, 16]} wrap={false} align={'top'}>
            <BaseCol xl={8} style={{ alignItems: 'flex-start' }}>
              <DayWrapper>
                <BaseText fontWeight="semibold">{item?.title ?? '-'}</BaseText>
              </DayWrapper>
            </BaseCol>
            <BaseCol xl={8}>
              <BaseSpace
                direction="vertical"
                size={10}
                style={{ width: '100%' }}
              >
                {item?.times?.length ? (
                  item?.times?.map((itm, idx) => {
                    return (
                      <CustomTimePicker
                        size="small"
                        onChange={selectedTime =>
                          handleChangeTimePicker(
                            selectedTime,
                            'startTime',
                            idx,
                            itm,
                          )
                        }
                        defaultValue={dayjs(itm?.startTime)}
                        value={dayjs(itm?.startTime)}
                        format={'HH:mm'}
                        key={idx}
                      />
                    )
                  })
                ) : (
                  <DayWrapper>
                    <TextNotFound>{'-- --'}</TextNotFound>
                  </DayWrapper>
                )}
              </BaseSpace>
            </BaseCol>

            <BaseCol xl={8}>
              <BaseSpace
                direction="vertical"
                size={10}
                style={{ width: '100%' }}
              >
                {item?.times?.length ? (
                  item?.times?.map((itm, idx) => {
                    return (
                      <BaseRow key={idx} align={'middle'} gutter={16}>
                        <BaseCol xl={20}>
                          <CustomTimePicker
                            size="small"
                            onChange={selectedTime =>
                              handleChangeTimePicker(
                                selectedTime,
                                'endTime',
                                idx,
                                itm,
                              )
                            }
                            defaultValue={dayjs(itm.endTime)}
                            value={dayjs(itm?.endTime)}
                            format={'HH:mm'}
                          />
                        </BaseCol>
                        <BaseCol xl={2}>
                          <DeleteOutlinedIcon
                            rev={undefined}
                            onClick={() =>
                              _onDeleteRangeTime(itm?.id, item.key)
                            }
                          />
                        </BaseCol>
                      </BaseRow>
                    )
                  })
                ) : (
                  <BaseRow>
                    <BaseCol xl={20}>
                      <DayWrapper>
                        <TextNotFound>{'-- --'}</TextNotFound>
                      </DayWrapper>
                    </BaseCol>
                  </BaseRow>
                )}
              </BaseSpace>
            </BaseCol>
          </BaseRow>
        </BaseCol>

        <BaseCol xl={3}>
          <BaseRow justify={'end'}>
            <PlusCircleOutlinedIcon
              rev={undefined}
              onClick={_onAddTimeRangeOfDay}
              disabled={isDisableAddButton}
            />
          </BaseRow>
        </BaseCol>
      </BaseRow>
    </BaseSpace>
  )
}

const DayWrapper = styled.div`
  border-radius: 6px;
  padding: 10px 10px !important;
  border: 1px solid #efefef;
  min-height: 40px;
`

const CustomTimePicker = styled(BaseTimePicker)`
  width: 100%;
  border-radius: 6px;
  padding: 10px 10px !important;
  border: 1px solid #efefef;
  border-color: #efefef !important;
  .ant-picker-input {
    padding: 0px 5px;
    color: ${convertColor.primaryColor};
    input {
      color: ${convertColor.primaryColor} !important;
      font-size: ${FONT_SIZE.xxs};
      font-weight: ${FONT_WEIGHT.semibold};
    }
  }
`

const PlusCircleOutlinedIcon = styled(PlusCircleOutlined)`
  cursor: pointer;
  svg {
    color: ${convertColor.primaryColor};
    fill: ${convertColor.primaryColor};
  }
`

const DeleteOutlinedIcon = styled(DeleteOutlined)`
  cursor: pointer;
  svg {
    color: red;
    fill: red;
  }
`

export const TextNotFound = styled.div`
  color: ${convertColor.primaryColor};
  font-weight: ${FONT_WEIGHT.regular};
  opacity: 0.7;
`

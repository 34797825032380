export default {
  empowering_kiosk_efficiency: 'キオスクの効率向上',
  streamlined_content_management: '効率化されたコンテンツ管理',
  streamlined_content_management_d:
    'キオスクのコンテンツを簡単に管理および更新し、常に最新で魅力的に保ちます。',
  remote_kiosk_control: 'リモートキオスクコントロール',
  remote_kiosk_control_d:
    'どこからでもキオスクを制御し、そのパフォーマンスをモニターし、即座に調整できます。',
  personalized_recommendations: '個別のおすすめ',
  personalized_recommendations_d:
    '顧客データを活用して製品の個別のおすすめとプロモーションを提供します。',
  advanced_reporting: '高度なレポート',
  advanced_reporting_d:
    '傾向を特定し、戦略を最適化し、情報に基づいた意思決定を行うための包括的なレポートを生成します。',
  personalized_recommendations_and_promotions: '個別のおすすめとプロモーション',
  personalized_recommendations_and_promotions_d:
    'Kiosify Fast Order Systemでの個別のおすすめとプロモーションの解除',

  revolutionizing_customer_engagement:
    '顧客エンゲージメントの革新：Kiosifyの個別のおすすめとプロモーション',
  revolutionizing_customer_engagement_d:
    'Kiosifyは顧客データを活用して製品の個別のおすすめとプロモーションを提供します。購入履歴、ブラウジングの動向、および人口統計情報を分析することで、Kiosifyは各顧客の好みと興味に合わせて提案を調整できます。この個別のアプローチにより、関連する製品や好みに合ったオファーを強調することで、顧客満足度が向上します。',
  sign_up_for_a_free_trial: '無料トライアルにサインアップ',
  start_14_days_trail: '14日間のトライアルを開始',
  home_subcribe: 'ニュースレターに登録して最新情報を入手',
  enter_your_email_address: 'メールアドレスを入力',
  footer_kiosify: 'Kiosify: セルフサービスで顧客エクスペリエンスを向上させる',
  contact_us: 'お問い合わせ',

  home_slogan: 'Kiosify: キオスク管理の未来',
  home_slogan_d: 'あなたのビジネスにぴったりのセルフオーダーシステム',
  create_your_kiosify_account: 'Kiosifyアカウントを作成',
  see_more: 'もっと見る',
  find: '検索',
  address: '住所',
  language: '言語',
  vietnamese: 'Tiếng Việt',
  english: 'English',
  foreign_language: '外国語',
  home: 'ホーム',
  phone: '電話',
  email: 'メール',
  send: '送信',
  next: '次へ',
  back: '戻る',
  send_code: 'コードを送信',
  login: 'ログイン',
  user_name: 'ユーザー名',
  password: 'パスワード',
  login_success: 'ログインに成功しました',
  save_success: '保存に成功しました',
  forgot_password: 'パスワードをお忘れですか',
  require_field: '{{field}}を入力してください、これは必須フィールドです',
  regex_field: '{{field}}を正しい形式で入力してください',
  minimum_field: '{{count}}文字以上を入力してください',
  maximum_field: '{{count}}文字以下で入力してください',
  register: '登録',
  confirm_password: 'パスワードの確認',
  verify_by_phone: '電話で確認',
  verify_by_email: 'メールで確認',
  code_verify: '検証コード',
  message_verify_phone:
    '携帯電話番号 {{phone}} のZaloメッセージを確認して、検証コードを取得してください。',
  re_send_verify_code: '検証コードを再送信',
  resend: '再送信',
  time_resend_verify_code: '{{timer}}秒後に再送信',
  verify: '確認',
  logout: 'ログアウト',
  full_name: 'フルネーム',
  regex_name: '名前は正しい形式である必要があります',
  regex_user_name:
    'ユーザー名は正しい形式である必要があります（スペースを除く）',
  using_by: '使用中',
  require_email: 'メールアドレスを入力してください',
  require_contact_name: '連絡先名を入力してください',
  regex_email: '有効なメールアドレス形式で入力してください',
  regex_phone: '有効な電話番号形式で入力してください',
  send_contact_success:
    '連絡先情報が正常に送信されました、ありがとうございます。',
  please_enter_correct_format: '正しい形式で入力してください',
  medical_examination: '健康診断',
  human_resources: '人事',
  finance: '財務',
  inventory_management: '在庫管理',
  purchasing_management: '購買管理',
  customer_care: '顧客ケア',
  clinic_management: 'クリニック管理',
  company: '会社',
  organizational_chart: '組織図',
  announcement: 'お知らせ',
  internal_news: '内部ニュース',
  onboarding: 'オンボーディング',
  report: 'レポート',
  marketing: 'マーケティング',
  accounting: '会計',
  work_schedule: '勤務スケジュール',
  attendance_record: '出席記録',
  mailbox: 'メールボックス',
  my_requests: '私のリクエスト',
  my_tasks: '私のタスク',
  my_income: '私の収入',
  dashboard: 'ダッシュボード',
  profile: 'プロファイル',
  read_all: '全てを既読にする',
  view_all: '全てを見る',
  remember_me: 'ログイン情報を保存する',
  parkway_dental: 'Parkway Dental',
  description_parkway_auth:
    'Parkway Dentalは、特に矯正とインプラントにおいて包括的で専門的な歯科システムで、顧客の歯科ニーズを完全に満たします。',
  development: '開発中',
  coming_soon: '近日公開',
  customers: '顧客',
  region: '地域',
  revenue: '収益',
  basic_package: {
    title: '基本',
    price: '$50/月',
    benefit_1: '最大3台のキオスク',
    benefit_2: '無制限の注文',
    benefit_3: '専任のアカウントマネージャー',
    benefit_4: '24/7サポート',
    benefit_5: 'オンラインダッシュボードへのアクセス',
    benefit_6: 'レポートと分析',
  },
  premium_package: {
    title: 'プレミアム',
    price: '$80/月',
    benefit_1: '最大10台のキオスク',
    benefit_2: '無制限の注文',
    benefit_3: '無料のKDS',
    benefit_4: '24/7サポート',
    benefit_5: 'カスタマイズ可能なブランディング',
    benefit_6: '複数拠点サポート',
    benefit_7: 'マーケティングサポート',
  },
  smart_saving_package: {
    title: 'スマートセービング',
    price: '$30/月/1キオスク',
    benefit_1: '手数料割引',
    benefit_2: '優先顧客サポート',
    benefit_3: '専用機能へのアクセス',
  },
  time: {
    today: '今日',
    this_week: '今週',
    this_month: '今月',
    days: '日',
    start_time_kiosk: '開始時間',
    end_time_kiosk: '終了時間',
    monday: '月曜日',
    tuesday: '火曜日',
    wednesday: '水曜日',
    thursday: '木曜日',
    friday: '金曜日',
    satuday: '土曜日',
    sunday: '日曜日',
  },
  error_message: {
    service_package: 'サービスパッケージを選択してください',
    select_location: '場所を選択してください',
    select_payment_method: '支払い方法を選択してください',
    select_kiosk: 'キオスクを選択してください',
  },
  success_message: {
    create_kiosk: 'キオスクの作成に成功しました',
    update_kiosk: 'キオスクの更新に成功しました',
  },
  table: {
    customer: '顧客',
    time: '時間',
    date: '日付',
    order: '注文',
    total_payment: '合計支払い額',
    payment_method: '支払い方法',
    status: 'ステータス',
    type: 'タイプ',
    name: '名前',
    location: '場所',
    kiosk_code: 'キオスクコード',
    product_name: '商品名',
    price: '価格',
    item_name: 'アイテム名',
    category: 'カテゴリー',
    variation: 'バリエーション',
    up_sell_item: 'アップセルアイテム',
    up_sell_at: 'アップセル時刻',
    quantity: '数量',
    action: 'アクション',
    options: 'オプション',
    discount_name: 'ディスカウント名',
    discount_type: 'ディスカウントタイプ',
    discount_apply: 'ディスカウント適用',
    item_price: 'アイテム価格',
    active: 'アクティブ',
    bestseller: 'ベストセラー',
  },
  payment_method: {
    cash: '現金',
    credit_card: 'クレジットカード',
    gift_card: 'ギフトカード',
  },
  dashboard_common: {
    most_ordered: '最も注文された',
    most_type_order: '最も注文されたタイプ',
    total_revenue: '総収益',
    pending_revenue: '保留中の収益',
    actual_revenue: '実際の収益',
    compared_to_last_month: '前月比',
    today_revenue: '今日の収益',
    branch_name: '支店名',
    overall_today_summary: '今日の総括',
    area: 'エリア',
    ordinal_number: '序数',
    clinic: 'クリニック',
    dishes_ordered: '注文された料理',
    view_all: 'すべて表示',
    order_report: '注文レポート',
  },
  reports_common: {
    title: 'レポート',
    total_revenue: '総収益',
    total_dish_ordered: '合計注文料理',
    total_customer: '総顧客数',
    order_report: '注文レポート',
    chart_header_realtime: 'リアルタイム',
    chart_header_daily: '毎日',
    chart_header_weekly: '毎週',
    chart_header_monthly: '毎月',
    chart_header_yearly: '毎年',
    chart_label_monday: '月曜日',
    chart_label_tuesday: '火曜日',
    chart_label_wednesday: '水曜日',
    chart_label_thursday: '木曜日',
    chart_label_friday: '金曜日',
    chart_label_satuday: '土曜日',
    chart_label_sunday: '日曜日',
    chart_label_january: '1月',
    chart_label_february: '2月',
    chart_label_march: '3月',
    chart_label_april: '4月',
    chart_label_may: '5月',
    chart_label_june: '6月',
    chart_label_july: '7月',
    chart_label_august: '8月',
    chart_label_september: '9月',
    chart_label_october: '10月',
    chart_label_november: '11月',
    chart_label_december: '12月',
    chart_serrie_total_gross: '総グロス',
    chart_serrie_total_net: '総純',
  },
  input_placeholder: {
    search: '食べ物、コーヒーなどを検索',
    search_tab: 'タブを検索...',
    select_location: '場所を選択',
    order_note: '注文メモ...',
    search_only: '検索',
    select_category: 'カテゴリを選択',
    device_name: 'デバイス名',
    search_printer: 'プリンタを検索',
    search_profile: 'プロファイルを検索',
    search_your_kiosk: 'キオスクを検索',
    write_your_content_here: 'ここにコンテンツを書いてください.....',
    select_time: '時間を選択',
  },
  input_validate: {
    required_kiosk_name: 'キオスク名を入力してください！',
  },
  information: {
    branch: '支店',
    location: '場所',
    phone_no: '電話番号',
    email: 'メール',
  },
  kiosk: {
    add_your_kiosk: 'キオスクを追加',
    edit_your_kiosk: 'キオスクを編集',
    add_new_kiosk: '新しいキオスクを追加',
    kiosk_name: 'キオスク名',
    kiosk_code: 'キオスクコード',
    your_subscription: 'あなたのサブスクリプション',
    payment_method: '支払い方法',
    redeem_loyalty: '忠誠度を利用する',
    redeem_discount: '割引を利用する',
    open_time: '開店時間',
    start_popups_when_the_store_is_closed:
      '店が閉まっているときにポップアップを開始',
    message: 'メッセージ',
  },
  notification_status: {
    success: '成功',
    info: '情報',
    warning: '警告',
    error: 'エラー',
  },
  product_management: {
    category: 'カテゴリ',
    items: 'アイテム',
    up_sell: 'アップセル',
    modifiers: '修飾子',
    discounts: '割引',
    delete_item_up_sell: 'アイテムアップセルを削除',
    delete_item_up_sell_description: 'アイテムを削除しますか',
    create_up_sell: 'アップセルを作成',
    menu_price: 'メニュー価格',
    discount_price: '割引価格',
    item_level: 'アイテムレベル',
    order_level: '注文レベル',
  },
  form_item: {
    select_up_sell_at_t: 'アップセル対象',
    select_up_sell_at_p: 'アップセル対象を選択',
    select_item_t: 'アイテムを選択',
    select_item_p: 'アイテムを選択',
    select_item_variation_t: '変種',
    select_item_variation_p: 'アイテムの変種を選択',
    select_price_t: '価格を選択',
    select_price_p: '価格を選択',
    price_t: '価格',
    select_item_apply_t: 'アイテムの適用を選択',
    select_item_apply_p: 'アイテムの適用を選択',
  },
  setting_menu: {
    appearance_t: '外観',
    appearance_d: 'ダークモードとライトモード、フォントサイズ',
    your_restaurant_t: 'あなたのレストラン',
    your_restaurant_d: 'ダークモードとライトモード、フォントサイズ',
    your_kiosk_t: 'あなたのキオスク',
    your_kiosk_d: 'キオスク情報を確認',
    product_management_t: '製品管理',
    product_management_d: '製品、価格などの管理',
    kds_t: 'KDS設定',
    kds_d: 'ダークモードとライトモード、フォントサイズ',
    backup_t: 'バックアップ',
    backup_d: '前回の更新に戻る',
    your_information_t: 'あなたの情報',
    your_information_d:
      'ブランド名、プリンタ、リーダーなどについて詳しく調べる',
    sync_data_from_square_t: 'Squareからデータを同期',
    sync_data_from_square_d: 'Squareからデータを即時に同期',
  },
  your_infomation: {
    km_stand: 'KMスタンド',
    km_printers: 'KMプリンター',
    km_readers: 'KMリーダー',
    device_id: 'デバイスID',
    get_help: 'ヘルプを取得',
    printers: 'プリンター',
    profiles: 'プロファイル',
    connect_printer: 'プリンターに接続',
    no_printer_connected: '接続されたプリンターはありません',
    troubleshoot: 'トラブルシューティング',
    troubleshoot_d: '接続されたプリンターを期待していますか？',
    create_profile: 'プロファイルを作成',
    no_profile: 'プロファイルがありません',
    printer_defaults: 'プリンターのデフォルト',
    printer_defaults_d:
      'このプロファイルで印刷するアイテムを選択します。このプロファイルを継承するすべてのプリンターは、それらのアイテムのみを印刷します。',
    receipts: '領収書',
    in_person_order_tickets: '対面注文のチケット',
    online_order_tickets: 'オンライン注文のチケット',
    order_ticket_stubs: '注文チケットスタブ',
    void_tickets: '無効なチケット',
    use_this_printer_for_receipts: 'このプリンターを領収書に使用',
    use_this_printer_for_receipts_d:
      'オンの場合、このプリンターは請求書、レポート、キャッシュドロワーを開くときにも印刷されます',
    no_device: 'デバイスなし',
  },
  about: '約',
  general: '一般',
  connect_and_manage: '接続と管理',
  add_new: '新規追加',
  add_more_food: '食べ物を追加',
  key_all_label: '全て',
  restaurant: 'レストラン',
  your_restaurant: 'あなたのレストラン',
  see_your_restaurant: 'あなたのレストランを見る',
  background: '背景',
  text: 'テキスト',
  button: 'ボタン',
  custom: 'カスタム',
  see_all: 'すべて見る',
  review: 'レビュー',
  restaurant_name: 'レストラン名',
  fake_time: '2024年11月18日(土曜日)',
  fake_tab: {
    hot_dishes: 'ホットディッシュ',
    cold_dishes: '冷たい料理',
    soup: 'スープ',
    grill: 'グリル',
    appetizer: '前菜',
    dessert: 'デザート',
  },
  choose_dishes: '料理を選ぶ',
  bowls_available: '使用可能なボウル',
  item: 'アイテム',
  qty: '数量',
  price: '価格',
  fake_order_note: 'お願いします、ちょっとだけ辛いです。',
  discount: '割引',
  sub_total: '小計',
  continue_to_payment: '支払いを続ける',
  save: '保存',
  dark_mode: 'ダークモード',
  light_mode: 'ライトモード',
  color: {
    black: 'ブラック',
    white: 'ホワイト',
    light_red: 'ライトレッド',
    light_green: 'ライトグリーン',
    light_blue: 'ライトブルー',
    light_orange: 'ライトオレンジ',
    light_gray: 'ライトグレー',
    light_cyan: 'ライトシアン',
  },
  label_common: {
    for_here: '店内用',
    to_go: '持ち帰り',
    pick_up: '引き取り',
    late: '遅い',
    on_time: '時間通り',
    caution: '注意',
    small: '小',
    medium: '中',
    large: '大',
    extra_large: '特大',
  },
  kds: {
    standard_screen_option: '標準画面オプション',
    default_t: 'デフォルト',
    default_d: '上から下へ、左から右へ。チケットは収まる場所に表示されます',
    classic_t: 'クラシック',
    classic_d: '上から下へ、左から右へ。チケットは収まる場所に表示されます',
    caution_time: '注意時間',
    late_time: '遅刻時間',
    order_received_sms: '受注受信SMS',
    order_bumped_from_kds_screen_sms: 'KDSスクリーンからの注文Bumped SMS',
    order_is_ready_for_pick_up_sms: '注文が引き取り可能SMS',
    transition_time: 'トランジションタイム',
    font_and_color: 'フォントと色',
    font_size: 'フォントサイズ',
    status_color: 'ステータスの色',
    order_type: '注文のタイプ',
    mesaging: 'メッセージング',
  },
  min: '分',
  on: 'オン',
  off: 'オフ',
  see_with: 'で見る',
  import: 'インポート',
  week: '週',
  download_successful: 'ダウンロードが成功しました',
  import_file_successful: 'ファイルのインポートが成功しました',
  import_file_error: 'ファイルのインポートエラー',
  choose_week: '週を選択',
  notification: '通知',
  not_done: '未完了',
  done: '完了',
  in_progress: '進行中',
  see_detail: '詳細を見る',
  mail: 'メール',
  new_mail: '新しいメール',
  important: '重要',
  show_less: '簡略化',
  collapse: '折り畳む',
  last_time_update: '最終更新時刻',
  service: 'サービス',
  month: '月',
  import_file: 'ファイルのインポート',
  cancel: 'キャンセル',
  export_excel: 'Excelへのエクスポート',
  download_example_import_file: '例のインポートファイルをダウンロード',
  solutions: 'ソリューション',
  vision: 'ビジョン',
  programs: 'プログラム',
  blog: 'ブログ',
  product: '製品',
  pricing: '価格',
  resource: 'リソース',
  customer_login: '顧客ログイン',
  sign_up: 'サインアップ',
  watch_free_demo: '無料デモを見る',
  sign_in_to_stay_connected: '接続したままサインインします。',
  or_sign_in_with_other_accounts: 'または他のアカウントでサインインしますか？',
  dont_have_an_account: 'アカウントをお持ちでないですか？',
  click_here_to_sign_up: 'こちらをクリックしてサインアップしてください。',
  sign_in: 'サインイン',
  create_your_hope_ui_account: 'Hope UIアカウントを作成',
  first_name: '名',
  last_name: '姓',
  phone_no: '電話番号',
  confirm_password_incorrect: 'パスワードが一致しません',
  i_agree_with_the_terms_of_use: '利用規約に同意します',
  or_sign_up_with_other_accounts:
    'または他のアカウントでサインアップしますか？',
  already_have_an_account: 'すでにアカウントをお持ちですか',
  reset: 'リセット',
  reset_password: 'パスワードをリセット',
  enter_your_email_address_and_we_ll_send_you_an_email_with_instructions_to_reset_your_password:
    'メールアドレスを入力して、パスワードをリセットするための手順をメールでお知らせします',
  back_to_home: 'ホームに戻る',
  back_to_login: 'ログインに戻る',
  message_verify_email:
    'メールがemail@domain.comに送信されました。会社からのメールを確認して、含まれているリンクをクリックしてパスワードをリセットしてください。',
  success: '成功',
  subcribe_now: '今すぐ購読',
  error: 'エラー',
  admin: '管理者',
  settings: '設定',
  not_found: '見つかりません',
  spicy_seasoned_seafood_noodles: '辛い味付けのシーフードヌードル',
  salted_pasta_with_mushroom_sauce: 'キノコソースの塩味パスタ',
  beef_dumpling_in_hot_and_sour_soup: '酸辣湯のビーフダンプリング',
  healthy_noodle_with_spinach_leaf: 'ホウレン草の入ったヘルシーヌードル',
  hot_spicy_fried_rice_with_omelet: 'オムレツの乗った辛いスパイシーチャーハン',
  spicy_instant_noodle_with_special_omelette:
    '特製オムレツの入った辛いインスタントヌードル',
  spicy_seasoned_seafood_noodles_short: '辛い味付けのシーフードヌードル...',
  salted_pasta_with_mushroom_sauce_short: 'キノコソースの塩味パスタ...',
  spicy_instant_noodle_with_special_omelette_short:
    '特製オムレツの入った辛いインスタントヌードル...',
  healthy_noodle_with_spinach_leaf_short:
    'ホウレン草の入ったヘルシーヌードル...',
  select_item_for_upsells: 'アップセルのアイテムを選択',
  recommended_foods: 'おすすめの食べ物',
  ex: '例',
  spicy_seasoned_seafood_noodles_strawberry:
    '辛い味付けのシーフードヌードル、ストロベリー',
  account_verification: 'アカウント確認',
  email_verification: 'Eメール確認',
  account_verification_d1: '6桁の一時的なログインコードが送信されました',
  account_verification_d2:
    'このコードを入力してアカウントにログインしてください。',
  didnt_receive_the_code: 'コードが届かない場合',
  resend_code_in: 'でコードを再送信',
  resend_code: 'コードを再送信',
  account_verified: 'アカウントが確認されました',
  didnt_sync_data: 'データが同期されていません',
  click_here_to_connect_square_account:
    'ここをクリックしてSquareアカウントを接続してください',
  change_password: 'パスワードを変更',
  card: 'カード',
  cardholder_name: 'カードホルダー名',
  please_input_cardholder_name: 'カードホルダー名を入力してください',
  cardholder_name_must_be_at_least_6_characters:
    'カードホルダー名は少なくとも6文字でなければなりません',
  start_subscription: 'サブスクリプションを開始',
  order_summary: '注文の概要',
  Plane: '飛行機',
  subscription_monthly: 'サブスクリプション（月額）',
  fourteen_days_free_trail: '14日間の無料トライアル',
  subtotal: '小計',
  taxes_if_applicable: '税金（該当する場合）',
  total: '合計',
  save_this_card_for_future_use: '今後の使用のためにこのカードを保存',
  subcribe_for_free: '無料で購読',
  language_option: {
    en: '英語',
    vi: 'ベトナム語',
    ja: '日本語',
    es: 'スペイン語',
    fr: 'フランス語',
    zh: '中国語',
  },
  feature: '機能',
  before_5_minutes: '5分前',
  upload: 'アップロード',
  upload_your_image: '画像をアップロード',
  confirm: '確認',
  you_sync_data_success: 'データを同期しました',
  thank_you_for_interest:
    'Kiosifyへのご関心ありがとうございます、近日中にご連絡いたします。',
}

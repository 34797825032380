import R from 'app/assets/R'
import { PageTitle } from 'app/components/common/PageTitle'
import DashboardContainer from 'app/containers/admin/Dashboard'
import { useTranslation } from 'react-i18next'

const DashboardPage = () => {
  const { t } = useTranslation()
  return (
    <>
      <PageTitle>{t(R.strings.dashboard)}</PageTitle>
      <DashboardContainer />
    </>
  )
}
export default DashboardPage

/* eslint-disable @typescript-eslint/no-explicit-any */
import { BaseCol } from 'app/components/common/BaseCol'
import {
  CONTACT_US_PATH,
  DASHBOARD_PATH,
  PRICING_PATH,
  PRODUCT_PATH,
  SERVICE_PATH,
} from 'app/components/router/route-path'
import { useAppSelector } from 'app/redux/hooks'
import { useEffect, useRef } from 'react'
import LayoutFourHome from './LayoutFour'
import LayoutOneHome from './LayoutOne'
import LayoutThreeHome from './LayoutThree'
import LayoutTwoHome from './LayoutTwo'
import VideoLayout from './LayoutOne/Video'

export const DesktopLayout = () => {
  const currentPath = useAppSelector(state => state.path).path
  const refHead = useRef<any>()
  const refOne = useRef<any>()
  const refTwo = useRef<any>()
  const refThree = useRef<any>()
  const refService = useRef<any>()

  useEffect(() => {
    const timer = setTimeout(() => {
      switch (currentPath) {
        case DASHBOARD_PATH:
          refHead?.current.scrollIntoView({ behavior: 'smooth' })
          break
        case PRODUCT_PATH:
          refOne?.current.scrollIntoView({ behavior: 'smooth' })
          break
        case CONTACT_US_PATH:
          refTwo?.current.scrollIntoView({ behavior: 'smooth' })
          break
        case SERVICE_PATH:
          refService?.current.scrollIntoView({ behavior: 'smooth' })
          break
        case PRICING_PATH:
          refThree?.current.scrollIntoView({ behavior: 'smooth' })
          break
      }
    }, 300)

    return () => clearTimeout(timer)
  }, [currentPath])

  return (
    <BaseCol ref={refHead}>
      <div className="content-body">
        <div ref={refOne}>
          <LayoutOneHome />
        </div>

        <VideoLayout />

        <div ref={refService}>
          <LayoutTwoHome />
        </div>

        <div ref={refThree}>
          <LayoutThreeHome />
        </div>
      </div>
      <div ref={refTwo}>
        <LayoutFourHome />
      </div>
    </BaseCol>
  )
}

export const KEY_STORAGE = {
  LANGUAGE: 'LANGUAGE',
  DRAWER: 'DRAWER',
  PATH: 'PATH',
  PATH_SETTING: 'PATH_SETTING',
  PATH_REPORT: 'PATH_REPORT',
  SERVICE_PACKAGE: 'SERVICE_PACKAGE',
  EMAIL_AUTH: 'EMAIL_AUTH',
  PASSWORD: 'PASSWORD',
  TOKEN: 'TOKEN',
}

import { LanguageSupportType } from 'app/i18n/constant'
import React, { useMemo } from 'react'
import ReactCountryFlag from 'react-country-flag'
import { BaseSelect } from 'app/components/common/selects/BaseSelect'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import R from 'app/assets/R'

interface Language {
  value: LanguageSupportType
  label: string
  flag: JSX.Element
}

const LANGUAGES: Language[] = [
  {
    value: 'en',
    label: R.strings.language_option_en,
    flag: <ReactCountryFlag svg countryCode="US" />,
  },
  {
    value: 'vi',
    label: R.strings.language_option_vi,
    flag: <ReactCountryFlag svg countryCode="VN" />,
  },
  {
    value: 'ja',
    label: R.strings.language_option_ja,
    flag: <ReactCountryFlag svg countryCode="JP" />,
  },
  {
    value: 'es',
    label: R.strings.language_option_es,
    flag: <ReactCountryFlag svg countryCode="ES" />,
  },
  {
    value: 'fr',
    label: R.strings.language_option_fr,
    flag: <ReactCountryFlag svg countryCode="FR" />,
  },
  {
    value: 'zh',
    label: R.strings.language_option_zh,
    flag: <ReactCountryFlag svg countryCode="CN" />,
  },
]

export const LanguagePicker: React.FC = () => {
  const { t, i18n } = useTranslation()

  const options = useMemo(
    () =>
      LANGUAGES.map(item => ({
        value: item.value,
        label: (
          <>
            <span className="label">{t(item.label)}</span>
            <span className="abbr-label">{item.value}</span>
          </>
        ),
      })),
    [i18n.language],
  )

  const handleChangeLanguage = value => {
    i18n.changeLanguage(value)
  }

  return (
    <CustomSelectionLanguage
      value={i18n.language}
      options={options}
      popupMatchSelectWidth={false}
      placement="bottomRight"
      onChange={handleChangeLanguage}
    />
  )
}

const CustomSelectionLanguage = styled(BaseSelect)`
  height: 48px;
  min-width: 20px !important;
  border-radius: 12px;
  .abbr-label {
    text-transform: uppercase;
  }
  .label,
  .abbr-label {
    font-size: 16px;
    font-weight: 500;
    color: black;
  }
  .ant-select-selector {
    background-color: transparent !important;
    border: unset !important;
    border-radius: 12px;
    box-shadow: unset !important;
  }
  .ant-select-arrow {
    display: none;
  }
  .ant-select-selection-item {
    display: flex;
    align-items: center;
    .flag {
      margin-left: 4px;
    }
    .label {
      display: none;
    }
    .abbr-label {
      display: inline-block;
    }
  }
  .ant-select-dropdown {
    background-color: #ffffff;
    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
      background-color: #29cc6a38 !important;
    }
  }
  .ant-select-item-option-content {
    .label {
      display: inline-block;
    }
    .abbr-label {
      display: none;
    }
  }
`

/* eslint-disable @typescript-eslint/no-explicit-any */
import { ICategory } from 'app/api/product-management/model'
import R from 'app/assets/R'
import { Loading } from 'app/components/common/Loading'
import { BaseButtonsForm } from 'app/components/common/forms/BaseButtonsForm'
import { BaseSelect, Option } from 'app/components/common/selects/BaseSelect'
import { usePagination } from 'app/hook'
import { useGetCategoryList } from 'app/react-query/hook/product-management'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

interface IProps {
  onChange?: (value: string) => void
  value?: string
  formItemName?: boolean
}

export const KEY_ALL = R.strings.key_all_label

function SelectionCategory({ onChange, value, formItemName = true }: IProps) {
  const { t, i18n } = useTranslation()
  const { data, isLoading: loading } = useGetCategoryList()
  const { flattenData } = usePagination()

  const dataConvert = useMemo(() => {
    const list: ICategory[] = [
      {
        id: '',
        name: t(KEY_ALL),
      },
    ]
    return list.concat(flattenData(data))
  }, [data, i18n.language])

  const onChangeSelect = (value: any) => {
    onChange && onChange(value)
  }

  return (
    <BaseButtonsForm.Item name={formItemName ? 'select-multiple' : undefined}>
      <SelectWrapper
        placeholder={t(R.strings.input_placeholder_select_category)}
        onChange={onChangeSelect}
        value={value}
      >
        {dataConvert?.map((item, index) => {
          return (
            <Option key={index} value={item.id}>
              {item.name}
            </Option>
          )
        })}
      </SelectWrapper>
      {loading && <Loading />}
    </BaseButtonsForm.Item>
  )
}

export default SelectionCategory

const SelectWrapper = styled(BaseSelect)`
  min-width: 200px !important;
  height: 40px;
  &.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: white;
    color: var(--primary-color);
    border: 0.7px solid #cfd3d7;
  }

  svg {
    fill: var(--primary-color) !important;
  }
`

import { Text } from 'app/components/styles'
import { CHART_SERIES_COLORS } from '../../constant'
import * as S from './styles'
import { useEffect } from 'react'

interface ChartLegendLayoutProps {
  serieNames: string[]
  onChange?: (value: string[]) => void
}

const ChartLegendLayout = (props: ChartLegendLayoutProps) => {
  useEffect(() => {
    props.onChange?.(props.serieNames)
  }, [])

  return (
    <S.RootWrapper>
      <S.CustomCheckBox.Group
        defaultValue={props.serieNames}
        onChange={value => props.onChange?.(value as string[])}
      >
        {props.serieNames.map((item, index) => {
          return (
            <S.CustomCheckBox value={item} key={index}>
              <S.CircleColor $bgColor={CHART_SERIES_COLORS[index]} />
              <Text $fontSize="10px" $fontWeight="510" $inline>
                {item}
              </Text>
            </S.CustomCheckBox>
          )
        })}
      </S.CustomCheckBox.Group>
    </S.RootWrapper>
  )
}

export default ChartLegendLayout

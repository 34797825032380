/* eslint-disable @typescript-eslint/no-explicit-any */
import { TypeDateEnum } from 'app/api/report/type'
import R from 'app/assets/R'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { BaseSelect } from 'app/components/common/selects/BaseSelect'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

interface ChartHeaderLayoutProps {
  hideFilter?: boolean
  onChange?: (value: TypeDateEnum) => void
  params?: TypeDateEnum
}

const DEFINE_TYPE_DATE = {
  [TypeDateEnum.daily]: {
    keyI18n: R.strings.reports_common_chart_header_today,
  },
  [TypeDateEnum.weekly]: {
    keyI18n: R.strings.reports_common_chart_header_weekly,
  },
  [TypeDateEnum.monthly]: {
    keyI18n: R.strings.reports_common_chart_header_monthly,
  },
  [TypeDateEnum.yearly]: {
    keyI18n: R.strings.reports_common_chart_header_yearly,
  },
}

const ChartHeaderLayout = (props: ChartHeaderLayoutProps) => {
  const { t } = useTranslation()

  const handleClick = (value: TypeDateEnum) => {
    props.onChange && props.onChange(value)
  }

  return (
    <BaseForm>
      <BaseForm.Item initialValue={TypeDateEnum.daily}>
        <SelectStyled
          options={[
            {
              label: t(DEFINE_TYPE_DATE[TypeDateEnum.daily].keyI18n),
              value: TypeDateEnum.daily,
            },
            {
              label: t(DEFINE_TYPE_DATE[TypeDateEnum.weekly].keyI18n),
              value: TypeDateEnum.weekly,
            },
            {
              label: t(DEFINE_TYPE_DATE[TypeDateEnum.monthly].keyI18n),
              value: TypeDateEnum.monthly,
            },
            {
              label: t(DEFINE_TYPE_DATE[TypeDateEnum.yearly].keyI18n),
              value: TypeDateEnum.yearly,
            },
          ]}
          onSelect={type => handleClick(type as TypeDateEnum)}
          defaultValue={TypeDateEnum.daily}
        />
      </BaseForm.Item>
    </BaseForm>
  )
}

export default ChartHeaderLayout

const SelectStyled = styled(BaseSelect)`
  min-width: 100px !important;
`

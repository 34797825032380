export default {
  empowering_kiosk_efficiency: "Optimisation de l'efficacité du kiosque",
  streamlined_content_management: 'Gestion de contenu rationalisée',
  streamlined_content_management_d:
    "Gérez et mettez à jour facilement le contenu de votre kiosque, en veillant à ce qu'il soit toujours à jour et attractif.",
  remote_kiosk_control: 'Contrôle à distance du kiosque',
  remote_kiosk_control_d:
    "Prenez le contrôle de vos kiosques de n'importe où, surveillez leur performance et apportez des ajustements en temps réel.",
  personalized_recommendations: 'Recommandations personnalisées',
  personalized_recommendations_d:
    'Exploitez les données des clients pour fournir des recommandations et des promotions de produits personnalisées.',

  advanced_reporting: 'Rapports avancés',
  advanced_reporting_d:
    'Générez des rapports complets pour identifier les tendances, optimiser les stratégies et prendre des décisions éclairées.',
  personalized_recommendations_and_promotions:
    'Recommandations et promotions personnalisées',
  personalized_recommendations_and_promotions_d:
    'Débloquer des recommandations et des promotions personnalisées dans le système de commande rapide Kiosify',
  revolutionizing_customer_engagement:
    "Révolution de l'engagement client : Recommandations et promotions personnalisées de Kiosify",
  revolutionizing_customer_engagement_d:
    "Kiosify exploite les données des clients pour fournir des recommandations et des promotions de produits personnalisées. En analysant l'historique des achats, le comportement de navigation et les informations démographiques, Kiosify peut personnaliser les suggestions en fonction des préférences et des intérêts de chaque client. Cette approche personnalisée améliore la satisfaction client en présentant des produits pertinents et des offres qui correspondent à leurs goûts.",
  sign_up_for_a_free_trial: 'Inscrivez-vous pour un essai gratuit',
  start_14_days_trail: "Commencez l'essai de 14 jours",
  home_subcribe:
    'Abonnez-vous à notre newsletter et obtenez les dernières mises à jour',
  enter_your_email_address: 'Entrez votre adresse e-mail',
  footer_kiosify:
    'Kiosify : Élevez votre expérience client avec le libre-service',
  contact_us: 'Contactez-nous',

  home_slogan: "Kiosify : L'Avenir de la Gestion des Kiosques",
  home_slogan_d:
    'Le Système de Commande Automatique Parfait pour Votre Entreprise',
  create_your_kiosify_account: 'Créez votre compte Kiosify',
  see_more: 'Voir plus',
  find: 'Rechercher',
  address: 'Adresse',
  language: 'Langue',
  vietnamese: 'Tiếng Việt',
  english: 'Anglais',
  foreign_language: 'Langue Étrangère',
  home: 'Accueil',
  phone: 'Téléphone',
  email: 'E-mail',
  send: 'Envoyer',
  next: 'Suivant',
  back: 'Retour',
  send_code: 'Envoyer le code',
  login: 'Connexion',
  user_name: "Nom d'utilisateur",
  password: 'Mot de passe',
  login_success: 'Connexion réussie',
  save_success: 'Enregistrement réussi',
  forgot_password: 'Mot de passe oublié',
  require_field: 'Veuillez entrer "{{field}}", c\'est un champ obligatoire',
  regex_field: 'Veuillez entrer "{{field}}" dans le bon format',
  minimum_field: 'Veuillez entrer au moins {{count}} caractères',
  maximum_field: 'Veuillez entrer un maximum de {{count}} caractères',
  register: "S'inscrire",
  confirm_password: 'Confirmer le mot de passe',
  verify_by_phone: 'Vérifier par téléphone',
  verify_by_email: 'Vérifier par e-mail',
  code_verify: 'Code de vérification',
  message_verify_phone:
    'Veuillez vérifier le message Zalo du numéro de téléphone {{phone}} pour obtenir le code de vérification.',
  re_send_verify_code: 'Renvoyer le code de vérification',
  resend: 'Renvoyer',
  time_resend_verify_code: 'Renvoyer après {{timer}} secondes',
  verify: 'Vérifier',
  logout: 'Déconnexion',
  full_name: 'Nom complet',
  regex_name: 'Le nom doit être dans le bon format',
  regex_user_name:
    "Le nom d'utilisateur doit être dans le bon format (sans espaces)",
  using_by: 'Utilisé par',
  require_email: "Veuillez entrer l'adresse e-mail",
  require_contact_name: 'Veuillez entrer le nom du contact',
  regex_email: 'Veuillez entrer une adresse e-mail valide',
  regex_phone: 'Veuillez entrer un numéro de téléphone valide',
  send_contact_success: 'Informations de contact envoyées avec succès, merci.',
  please_enter_correct_format: 'Veuillez entrer dans le bon format',
  medical_examination: 'Examen médical',
  human_resources: 'Ressources humaines',
  finance: 'Finance',
  inventory_management: 'Gestion des stocks',
  purchasing_management: 'Gestion des achats',
  customer_care: 'Service client',
  clinic_management: 'Gestion de la clinique',
  company: 'Entreprise',
  organizational_chart: 'Organigramme',
  announcement: 'Annonce',
  internal_news: 'Actualités internes',
  onboarding: 'OnBoard',
  report: 'Rapport',
  marketing: 'Marketing',
  accounting: 'Comptabilité',
  work_schedule: 'Planning',
  attendance_record: "Enregistrement de l'assiduité",
  mailbox: 'Boîte aux lettres',
  my_requests: 'Mes demandes',
  my_tasks: 'Mes tâches',
  my_income: 'Mes revenus',
  dashboard: 'Tableau de bord',
  profile: 'Profil',
  read_all: 'Marquer tout comme lu',
  view_all: 'Voir tout',
  remember_me: 'Se souvenir de moi',
  parkway_dental: 'Parkway Dental',
  description_parkway_auth:
    'Parkway Dental est un système dentaire complet et professionnel, spécialisé en orthodontie et implants, répondant pleinement aux besoins dentaires des clients.',
  development: 'En cours de développement',
  coming_soon: 'Prochainement',
  customers: 'Clients',
  region: 'Région',
  revenue: 'Revenu',
  basic_package: {
    title: 'Basique',
    price: '50 $/mois',
    benefit_1: "Jusqu'à 3 Kiosques",
    benefit_2: 'Commandes illimitées',
    benefit_3: 'Un gestionnaire de compte dédié',
    benefit_4: 'Support 24/7',
    benefit_5: 'Accès à notre tableau de bord en ligne',
    benefit_6: 'Rapports et analyses',
  },
  premium_package: {
    title: 'Premium',
    price: '80 $/mois',
    benefit_1: "Jusqu'à 10 Kiosques",
    benefit_2: 'Commandes illimitées',
    benefit_3: 'KDS gratuit',
    benefit_4: 'Support 24/7',
    benefit_5: 'Personnalisation de la marque',
    benefit_6: 'Support multi-emplacements',
    benefit_7: 'Support marketing',
  },
  smart_saving_package: {
    title: 'Économie Intelligente',
    price: '30 $/mois/1 kiosque',
    benefit_1: 'Frais de commission réduits',
    benefit_2: 'Support client prioritaire',
    benefit_3: 'Accès à des fonctionnalités exclusives',
  },
  time: {
    today: "Aujourd'hui",
    this_week: 'Cette semaine',
    this_month: 'Ce mois-ci',
    days: 'Jours',
    start_time_kiosk: 'Heure de début',
    end_time_kiosk: 'Heure de fin',
    monday: 'Lundi',
    tuesday: 'Mardi',
    wednesday: 'Mercredi',
    thursday: 'Jeudi',
    friday: 'Vendredi',
    satuday: 'Samedi',
    sunday: 'Dimanche',
  },
  error_message: {
    service_package: 'Veuillez sélectionner le forfait de service',
    select_location: "Veuillez sélectionner l'emplacement",
    select_payment_method: 'Veuillez sélectionner le mode de paiement',
    select_kiosk: 'Veuillez sélectionner le kiosque',
  },
  success_message: {
    create_kiosk: 'Création du kiosque réussie',
    update_kiosk: 'Mise à jour du kiosque réussie',
  },
  table: {
    customer: 'Client',
    time: 'Heure',
    date: 'Date',
    order: 'Commande',
    total_payment: 'Paiement total',
    payment_method: 'Méthode de paiement',
    status: 'Statut',
    type: 'Type',
    name: 'Nom',
    location: 'Emplacement',
    kiosk_code: 'Code du kiosque',
    product_name: 'Nom du produit',
    price: 'Prix',
    item_name: "Nom de l'article",
    category: 'Catégorie',
    variation: 'Variation',
    up_sell_item: 'Article de vente incitative',
    up_sell_at: 'Vendre à',
    quantity: 'Quantité',
    action: 'Action',
    options: 'Options',
    discount_name: 'Nom de la remise',
    discount_type: 'Type de remise',
    discount_apply: 'Application de la remise',
    item_price: "Prix de l'article",
    active: 'Actif',
    bestseller: 'Meilleure vente',
  },
  payment_method: {
    cash: 'Espèces',
    credit_card: 'Carte de crédit',
    gift_card: 'Carte-cadeau',
  },
  dashboard_common: {
    most_ordered: 'Le plus commandé',
    most_type_order: 'Type de commande le plus fréquent',
    total_revenue: 'Revenu total',
    pending_revenue: 'Revenu en attente',
    actual_revenue: 'Revenu réel',
    compared_to_last_month: 'Comparé au mois dernier',
    today_revenue: "Revenu d'aujourd'hui",
    branch_name: 'Nom de la succursale',
    overall_today_summary: "Résumé global aujourd'hui",
    area: 'Zone',
    ordinal_number: 'Numéro ordinal',
    clinic: 'Clinique',
    dishes_ordered: 'Plats commandés',
    view_all: 'Voir tout',
    order_report: 'Rapport de commande',
  },
  reports_common: {
    title: 'Rapports',
    total_revenue: 'Revenu total',
    total_dish_ordered: 'Total des plats commandés',
    total_customer: 'Total des clients',
    order_report: 'Rapport de commande',
    chart_header_realtime: 'Temps réel',
    chart_header_daily: 'Quotidien',
    chart_header_weekly: 'Hebdomadaire',
    chart_header_monthly: 'Mensuel',
    chart_header_yearly: 'Annuel',
    chart_label_monday: 'Lun',
    chart_label_tuesday: 'Mar',
    chart_label_wednesday: 'Mer',
    chart_label_thursday: 'Jeu',
    chart_label_friday: 'Ven',
    chart_label_satuday: 'Sam',
    chart_label_sunday: 'Dim',
    chart_label_january: 'Jan',
    chart_label_february: 'Fév',
    chart_label_march: 'Mar',
    chart_label_april: 'Avr',
    chart_label_may: 'Mai',
    chart_label_june: 'Juin',
    chart_label_july: 'Juil',
    chart_label_august: 'Août',
    chart_label_september: 'Sep',
    chart_label_october: 'Oct',
    chart_label_november: 'Nov',
    chart_label_december: 'Déc',
    chart_serrie_total_gross: 'Total brut',
    chart_serrie_total_net: 'Total net',
  },
  input_placeholder: {
    search: 'Recherche de nourriture, café, etc.',
    search_tab: "Recherche d'onglet ...",
    select_location: "Sélectionnez l'emplacement",
    order_note: 'Note de commande...',
    search_only: 'Recherche',
    select_category: 'Sélectionnez la catégorie',
    device_name: "Nom de l'appareil",
    search_printer: "Recherche d'imprimante",
    search_profile: 'Recherche de profil',
    search_your_kiosk: 'Recherchez votre kiosque',
    write_your_content_here: 'Écrivez votre contenu ici.....',
    select_time: "Sélectionnez l'heure",
  },
  input_validate: {
    required_kiosk_name: 'Veuillez saisir le nom de votre kiosque !',
  },
  information: {
    branch: 'Succursale',
    location: 'Emplacement',
    phone_no: 'Numéro de téléphone',
    email: 'E-mail',
  },
  kiosk: {
    add_your_kiosk: 'Ajoutez votre kiosque',
    edit_your_kiosk: 'Modifiez votre kiosque',
    add_new_kiosk: 'Ajouter de nouveaux kiosques',
    kiosk_name: 'Nom du kiosque',
    kiosk_code: 'Code du kiosque',
    your_subscription: 'Votre abonnement',
    payment_method: 'Méthode de paiement',
    redeem_loyalty: 'Échangez la fidélité',
    redeem_discount: 'Échangez la remise',
    open_time: "Heure d'ouverture",
    start_popups_when_the_store_is_closed:
      'Démarrer les popups lorsque le magasin est fermé',
    message: 'Message',
  },
  notification_status: {
    success: 'Succès',
    info: 'Info',
    warning: 'Avertissement',
    error: 'Erreur',
  },
  product_management: {
    category: 'Catégorie',
    items: 'Articles',
    up_sell: 'Vente incitative',
    modifiers: 'Modificateurs',
    discounts: 'Remises',
    delete_item_up_sell: "Supprimer l'article en vente incitative",
    delete_item_up_sell_description: 'Voulez-vous supprimer cet article',
    create_up_sell: 'Créer une vente incitative',
    menu_price: 'Prix du menu',
    discount_price: 'Prix de la remise',
    item_level: "Niveau de l'article",
    order_level: 'Niveau de commande',
  },
  form_item: {
    select_up_sell_at_t: 'Vente incitative à',
    select_up_sell_at_p: 'Sélectionnez la vente incitative à',
    select_item_t: "Sélectionnez l'article",
    select_item_p: "Sélectionnez l'article",
    select_item_variation_t: 'Variation',
    select_item_variation_p: "Sélectionnez la variation de l'article",
    select_price_t: 'Sélectionnez le prix',
    select_price_p: 'Sélectionnez le prix',
    price_t: 'Prix',
    select_item_apply_t: "Sélectionnez l'application de l'article",
    select_item_apply_p: "Sélectionnez l'application de l'article",
  },
  setting_menu: {
    appearance_t: 'Apparence',
    appearance_d: 'Mode sombre et clair, Taille de la police',
    your_restaurant_t: 'Votre restaurant',
    your_restaurant_d: 'Mode sombre et clair, Taille de la police',
    your_kiosk_t: 'Vos kiosques',
    your_kiosk_d: 'Vérifiez les informations de votre kiosque',
    product_management_t: 'Gestion des produits',
    product_management_d: 'Gérez vos produits, tarification, etc.',
    kds_t: 'Paramètres KDS',
    kds_d: 'Mode sombre et clair, Taille de la police',
    backup_t: 'Sauvegarde',
    backup_d: 'Revenez à votre mise à jour précédente',
    your_information_t: 'Vos informations',
    your_information_d:
      "En savoir plus sur le nom de votre marque, l'imprimante, les lecteurs,...",
    sync_data_from_square_t: 'Synchroniser les données depuis Square',
    sync_data_from_square_d:
      'Synchronisez vos données depuis Square immédiatement',
  },
  your_infomation: {
    km_stand: 'KM Stand',
    km_printers: 'KM Imprimantes',
    km_readers: 'KM Lecteurs',
    device_id: "Identifiant de l'appareil",
    get_help: "Obtenir de l'aide",
    printers: 'Imprimantes',
    profiles: 'Profils',
    connect_printer: "Connecter l'imprimante",
    no_printer_connected: 'Aucune imprimante connectée',
    troubleshoot: 'Dépanner',
    troubleshoot_d: "Vous attendez qu'une imprimante soit connectée?",
    create_profile: 'Créer un profil',
    no_profile: 'Aucun profil',
    printer_defaults: "Paramètres par défaut de l'imprimante",
    printer_defaults_d:
      "Sélectionnez les éléments que vous souhaitez imprimer avec ce profil. Toutes les imprimantes héritant de ce profil n'imprimeront que ces éléments.",
    receipts: 'Reçus',
    in_person_order_tickets: 'Tickets de commande en personne',
    online_order_tickets: 'Tickets de commande en ligne',
    order_ticket_stubs: 'Billets de commande',
    void_tickets: 'Tickets annulés',
    use_this_printer_for_receipts: 'Utiliser cette imprimante pour les reçus',
    use_this_printer_for_receipts_d:
      'Lorsque activé, cette imprimante imprimer également les factures, les rapports et ouvrira les tiroirs-caisses',
    no_device: 'Aucun appareil',
  },
  about: 'À propos',
  general: 'Général',
  connect_and_manage: 'Connecter et gérer',
  add_new: 'Ajouter nouveau',
  add_more_food: 'Ajouter plus de plats',
  key_all_label: 'Tous',
  restaurant: 'Restaurant',
  your_restaurant: 'Votre restaurant',
  see_your_restaurant: 'Voir votre restaurant',
  background: 'Arrière-plan',
  text: 'Texte',
  button: 'Bouton',
  custom: 'Personnalisé',
  see_all: 'Voir tout',
  review: 'Révision',
  restaurant_name: 'Nom du restaurant',
  fake_time: 'Samedi, 18 Nov 2024',
  fake_tab: {
    hot_dishes: 'Plats chauds',
    cold_dishes: 'Plats froids',
    soup: 'Soupe',
    grill: 'Grill',
    appetizer: 'Apéritif',
    dessert: 'Dessert',
  },
  choose_dishes: 'Choisissez des plats',
  bowls_available: 'Bols disponibles',
  item: 'Article',
  qty: 'Qté',
  price: 'Prix',
  fake_order_note: "S'il vous plaît, juste un peu épicé seulement.",
  discount: 'Remise',
  sub_total: 'Sous-total',
  continue_to_payment: 'Continuer vers le paiement',
  save: 'Enregistrer',
  dark_mode: 'Mode sombre',
  light_mode: 'Mode clair',
  color: {
    black: 'Noir',
    white: 'Blanc',
    light_red: 'Rouge clair',
    light_green: 'Vert clair',
    light_blue: 'Bleu clair',
    light_orange: 'Orange clair',
    light_gray: 'Gris clair',
    light_cyan: 'Cyan clair',
  },
  label_common: {
    for_here: 'Ici',
    to_go: 'À emporter',
    pick_up: 'Ramasser',
    late: 'En retard',
    on_time: "À l'heure",
    caution: 'Attention',
    small: 'Petit',
    medium: 'Moyen',
    large: 'Grand',
    extra_large: 'Très grand',
  },
  kds: {
    standard_screen_option: "OPTION D'ÉCRAN STANDARD",
    default_t: 'Défaut',
    default_d:
      'De haut en bas, de gauche à droite. Les tickets se remplissent là où ils rentrent',
    classic_t: 'Classique',
    classic_d:
      'De haut en bas, de gauche à droite. Les tickets se remplissent là où ils rentrent',
    caution_time: 'Temps de prudence',
    late_time: 'Temps de retard',
    order_received_sms: 'Commande reçue SMS',
    order_bumped_from_kds_screen_sms: "Commande retirée de l'écran KDS SMS",
    order_is_ready_for_pick_up_sms:
      'La commande est prête à être récupérée SMS',
    transition_time: 'TEMPS DE TRANSITION',
    font_and_color: 'POLICES ET COULEURS',
    font_size: 'Taille de la police',
    status_color: 'Couleurs de statut',
    order_type: 'Type de commande',
    mesaging: 'MESSAGERIE',
  },
  min: 'min',
  on: 'Activé',
  off: 'Désactivé',
  see_with: 'Voir avec',
  import: 'Importer',
  week: 'Semaine',
  download_successful: 'Téléchargement réussi',
  import_file_successful: 'Importation du fichier réussie',
  import_file_error: "Erreur d'importation du fichier",
  choose_week: 'Choisir la semaine',
  notification: 'Notification',
  not_done: 'Non fait',
  done: 'Fait',
  in_progress: 'En cours',
  see_detail: 'Voir les détails',
  mail: 'Courrier',
  new_mail: 'Nouveau courrier',
  important: 'Important',
  show_less: 'Voir moins',
  collapse: 'Réduire',
  last_time_update: 'Dernière mise à jour',
  service: 'Service',
  month: 'Mois',
  import_file: 'Importer le fichier',
  cancel: 'Annuler',
  export_excel: 'Exporter en Excel',
  download_example_import_file: '',
  solutions: 'Solutions',
  vision: 'Vision',
  programs: 'Programmes',
  blog: 'Blog',
  product: 'Produit',
  pricing: 'Tarification',
  resource: 'Ressource',
  customer_login: 'Connexion client',
  sign_up: "S'inscrire",
  watch_free_demo: 'Regarder la démo gratuite',
  sign_in_to_stay_connected: 'Connectez-vous pour rester connecté.',
  or_sign_in_with_other_accounts: "ou connectez-vous avec d'autres comptes?",
  dont_have_an_account: "Vous n'avez pas de compte?",
  click_here_to_sign_up: 'Cliquez ici pour vous inscrire.',
  sign_in: 'Se connecter',
  create_your_hope_ui_account: 'Créez votre compte Hope UI',
  first_name: 'Prénom',
  last_name: 'Nom de famille',
  phone_no: 'Numéro de téléphone',
  confirm_password_incorrect: 'Confirmation du mot de passe incorrecte',
  i_agree_with_the_terms_of_use: "J'accepte les conditions d'utilisation",
  or_sign_up_with_other_accounts: "ou inscrivez-vous avec d'autres comptes?",
  already_have_an_account: 'Vous avez déjà un compte',
  reset: 'Réinitialiser',
  reset_password: 'Réinitialiser le mot de passe',
  enter_your_email_address_and_we_ll_send_you_an_email_with_instructions_to_reset_your_password:
    'Entrez votre adresse e-mail et nous vous enverrons un e-mail avec des instructions pour réinitialiser votre mot de passe',
  back_to_home: "Retour à la page d'accueil",
  back_to_login: 'Retour à la connexion',
  message_verify_email:
    'Un e-mail a été envoyé à votre adresse e-mail@domain.com. Veuillez vérifier un e-mail de la société et cliquez sur le lien inclus pour réinitialiser votre mot de passe.',
  success: 'Succès',
  subcribe_now: 'Souscrire maintenant',
  error: 'Erreur',
  admin: 'Admin',
  settings: 'Paramètres',
  not_found: 'Non trouvé',
  spicy_seasoned_seafood_noodles:
    'Nouilles épicées aux fruits de mer assaisonnés',
  salted_pasta_with_mushroom_sauce: 'Pâtes salées à la sauce aux champignons',
  beef_dumpling_in_hot_and_sour_soup:
    'Raviolis au bœuf dans une soupe aigre et épicée',
  healthy_noodle_with_spinach_leaf: "Nouilles saines aux feuilles d'épinards",
  hot_spicy_fried_rice_with_omelet: 'Riz frit épicé avec omelette',
  spicy_instant_noodle_with_special_omelette:
    'Nouilles instantanées épicées avec une omelette spéciale',
  spicy_seasoned_seafood_noodles_short: 'Nouilles épicées aux fruits de mer...',
  salted_pasta_with_mushroom_sauce_short:
    'Pâtes salées à la sauce aux champignons...',
  spicy_instant_noodle_with_special_omelette_short:
    'Nouilles instantanées épicées avec une omelette spéciale...',
  healthy_noodle_with_spinach_leaf_short:
    "Nouilles saines aux feuilles d'épinards...",
  select_item_for_upsells: "Sélectionnez l'article pour les ventes incitatives",
  recommended_foods: 'Plats recommandés',
  ex: 'Ex',
  spicy_seasoned_seafood_noodles_strawberry:
    'Nouilles épicées aux fruits de mer assaisonnés, fraise',
  account_verification: 'Vérification du compte',
  email_verification: "Vérification de l'e-mail",
  account_verification_d1:
    "Nous vous avons envoyé un code d'accès temporaire à 6 chiffres à",
  account_verification_d2:
    'Veuillez saisir ce code pour vous connecter à votre compte.',
  didnt_receive_the_code: "Vous n'avez pas reçu le code?",
  resend_code_in: 'Renvoyer le code dans',
  resend_code: 'Renvoyer le code',
  account_verified: 'Compte vérifié',
  didnt_sync_data: "Les données n'ont pas été synchronisées?",
  click_here_to_connect_square_account:
    'Cliquez ici pour connecter le compte Square',
  change_password: 'Changer le mot de passe',
  card: 'Carte',
  cardholder_name: 'Nom du titulaire de la carte',
  please_input_cardholder_name:
    'Veuillez entrer le nom du titulaire de la carte!',
  cardholder_name_must_be_at_least_6_characters:
    'Le nom du titulaire de la carte doit comporter au moins 6 caractères',
  start_subscription: "Commencer l'abonnement",
  order_summary: 'Résumé de la commande',
  Plane: 'Avion',
  subscription_monthly: 'Abonnement (mensuel)',
  fourteen_days_free_trail: 'Essai gratuit de 14 jours',
  subtotal: 'Sous-total',
  taxes_if_applicable: 'Taxes (le cas échéant)',
  total: 'Total',
  save_this_card_for_future_use:
    'Enregistrez cette carte pour une utilisation future.',
  subcribe_for_free: 'Souscrire gratuitement',
  language_option: {
    en: 'Anglais',
    vi: 'Vietnamien',
    ja: 'Japonais',
    es: 'Espagnol',
    fr: 'Français',
    zh: 'Chinois',
  },
  feature: 'Fonctionnalité',
  before_5_minutes: 'Avant 5 minutes',
  upload: 'Télécharger',
  upload_your_image: 'Téléchargez votre image',
  confirm: 'Confirmer',
  you_sync_data_success: 'Vous avez synchronisé les données avec succès',
  thank_you_for_interest:
    'Merci pour votre intérêt pour Kiosify, nous vous contacterons bientôt.',
}

import { CollapseProps, Collapse as AntdCollapse } from 'antd'
import {
  SvgCaretRightCollapseIcon,
  SvgCaretUpCollapseIcon,
} from 'app/assets/svg-assets'

export type BaseCollapseProps = CollapseProps

interface BaseCollapseInterface extends React.FC<BaseCollapseProps> {
  Panel: typeof AntdCollapse.Panel
}

export const BaseCollapse: BaseCollapseInterface = props => {
  return (
    <AntdCollapse
      expandIcon={({ isActive }) =>
        !isActive ? <SvgCaretRightCollapseIcon /> : <SvgCaretUpCollapseIcon />
      }
      {...props}
    />
  )
}

BaseCollapse.Panel = AntdCollapse.Panel

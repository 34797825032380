/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAction, createSlice, PrepareAction } from '@reduxjs/toolkit'
import { IKiosk } from 'app/api/kiosk/model'
import { IItemProductManagement } from 'app/api/product-management/model'
import { IRestaurant } from 'app/api/restaurant/model'
import {
  getCurrentPathSetting,
  setCurrentPathSetting,
} from 'app/service/storage/storage-service'

export interface PathState {
  path: string
  restaurantData?: IRestaurant
  deviceData?: any
  productInProductManagement?: IItemProductManagement
  upSellSelected?: IItemProductManagement[]
  kioskUpdateItem?: IKiosk
}

const initialState: PathState = {
  path: getCurrentPathSetting(),
}

export const setPathSetting = createAction<PrepareAction<string>>(
  'pathSetting/setPathSetting',
  newPath => {
    return {
      payload: newPath,
    }
  },
)

export const pathSettingSlice = createSlice({
  name: 'pathSetting',
  initialState,
  reducers: {
    setRestaurantSetting: (state, action) => {
      state.restaurantData = action.payload
    },
    setDeviceSetting: (state, action) => {
      state.deviceData = action.payload
    },
    setProductInProductManagement: (state, action) => {
      state.productInProductManagement = action.payload
    },
    setUpSellSelected: (state, action) => {
      state.upSellSelected = action.payload
    },
    deleteUpSellSelectedById: (state, action) => {
      const newData = state.upSellSelected?.filter(
        item => item.item_id !== action.payload,
      )
      state.upSellSelected = newData
    },
    updateStatusProduct: (state, action) => {
      const oldData = state.productInProductManagement
      state.productInProductManagement = {
        ...oldData,
        status: action.payload,
      }
    },
    updateBestSellerProduct: (state, action) => {
      const oldData = state.productInProductManagement
      state.productInProductManagement = {
        ...oldData,
        bestseller: action.payload,
      }
    },
    // kioskUpdateItem
    setKioskUpdateItem: (state, action) => {
      state.kioskUpdateItem = action.payload
    },
  },
  extraReducers: builder => {
    builder.addCase(setPathSetting, (state, action) => {
      state.path = action.payload
      setCurrentPathSetting(action.payload)
    })
  },
})

export const {
  setRestaurantSetting,
  setDeviceSetting,
  setProductInProductManagement,
  setUpSellSelected,
  deleteUpSellSelectedById,
  updateStatusProduct,
  updateBestSellerProduct,
  setKioskUpdateItem,
} = pathSettingSlice.actions
export default pathSettingSlice.reducer

import { ApexOptions } from 'apexcharts'
import { PackageEnum } from 'app/common/enum'
import { Priority } from 'parkway-web-common'

export type CategoryType = 'apps'

interface Category {
  name: CategoryType
  title: string
}

export const categoriesList: Category[] = [
  {
    name: 'apps',
    title: 'common.apps',
  },
]

export interface Component {
  name: string
  title: string
  url: string
  categories: CategoryType[]
  keywords: string[]
}

export const configComponents: Component[] = [
  {
    name: 'Home',
    title: 'medical-dashboard.protein',
    url: `/home`,
    categories: ['apps'],
    keywords: ['home', 'apps', 'statistics'],
  },
]

export const defineColorByPriority = (priority: Priority): string => {
  switch (priority) {
    case Priority.INFO:
      return 'var(--primary-color)'
    case Priority.LOW:
      return 'var(--success-color)'
    case Priority.MEDIUM:
      return 'var(--warning-color)'
    case Priority.HIGH:
      return 'var(--error-color)'
    default:
      return 'var(--success-color)'
  }
}

export const PaddingMain = {
  Desktop: {
    defaultHz: '5rem',
  },
  Mobile: {
    defaultHz: '16px',
  },
}

export const TextData = {
  description: 'Lorem ipsum dolor sit amet consectetur.',
  title: 'Lorem ipsum dolor sit amet consectetur.',
}

export const BORDER_RADIUS_2 = '30px'

export const getArray = (length = 10) => {
  return Array.from({ length }, (_, i) => i + 1)
}

export interface ChartOption {
  series?: ApexOptions['series']
  width?: string | number
  height?: string | number
  options?: ApexOptions
}

export const dataChart: ChartOption = {
  series: [44, 55, 67, 83],
  options: {
    chart: {
      height: 350,
      type: 'radialBar',
    },
    plotOptions: {
      radialBar: {
        dataLabels: {
          name: {
            fontSize: '22px',
          },
          value: {
            fontSize: '16px',
          },
          total: {
            show: true,
            label: 'Total',
          },
        },
      },
    },
    labels: ['Apples', 'Oranges', 'Bananas', 'Berries'],
  },
}

export const REGEX_PHONE =
  /\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\d{1,14}$/

export const SubscriptionPlanData = {
  [PackageEnum.Basic]: {
    name: 'Basic',
    item_id: "JAYFF4OYL2LRJ2MQWJGADWWX",
    plan_id: "YMY34CHW6NEKWCE65KVPL3V3"
  },
  [PackageEnum.Premium]: {
    name: 'Premium',
    item_id: "BRFUI7XF77V2K2H7RM6WZV3K",
    plan_id: "PWHUIBXQZX45SLMRT7ML7RLJ"
  },
  [PackageEnum.SmartSaving]: {
    name: 'Smart Saving',
    item_id: "SGR4EUE65D277WTEESJGXDJR",
    plan_id: "AUDTHGUSXLM555QZEXMHHORT"
  },
}

import { BaseCol } from 'app/components/common/BaseCol'
import { PaddingMain } from 'app/constant'
import {
  FONT_SIZE,
  FONT_WEIGHT,
  PADDING,
  colors,
  media,
} from 'parkway-web-common'
import styled from 'styled-components'

export const RootWrapper = styled(BaseCol)`
  padding: ${PADDING.xxxxl} ${PaddingMain.Desktop.defaultHz};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  @media only screen and ${media.xs} {
    padding: ${PaddingMain.Mobile.defaultHz} ${PaddingMain.Mobile.defaultHz};
  }
`

export const AreaText = styled.div`
  padding: ${PADDING.xs} 0px;
  text-align: center;
  font-family: 'Plus Jakarta Sans', sans-serif !important;
  &.with-bg-img {
    background: url('${'https://storage.googleapis.com/kiosify-01/images/img_gradient_background.png'}')
      no-repeat;
    background-size: 100% 100%;
  }
`
export const DescriptionText = styled.span`
  color: ${colors.black};
  font-weight: ${FONT_WEIGHT.medium};
  font-size: ${FONT_SIZE.md};
  text-align: center;
  font-family: 'Plus Jakarta Sans', sans-serif !important;
`

export const TitleText = styled.span`
  font-size: 48px;
  font-weight: ${FONT_WEIGHT.bold};
  color: ${colors.black};
  text-align: center;
  font-family: 'Plus Jakarta Sans', sans-serif !important;
`
export const DescriptionFooter = styled.span`
  color: #64748b;
  font-size: ${FONT_SIZE.md};
  font-weight: ${FONT_WEIGHT.regular};
`

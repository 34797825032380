import { Empty } from 'antd'
import { IDataMostOrdered } from 'app/api/dashboard/type'
import R from 'app/assets/R'
import { BaseButton } from 'app/components/common/BaseButton'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseImage } from 'app/components/common/BaseImage'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { Loading } from 'app/components/common/Loading'
import { BORDER_RADIUS_2 } from 'app/constant'
import { FONT_SIZE, FONT_WEIGHT, PADDING } from 'parkway-web-common'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const ItemMostOrder = ({ item }: { item: IDataMostOrdered }) => {
  const { t } = useTranslation()
  return (
    <MostOrderItem gutter={[15, 15]} align={'middle'}>
      <BaseCol>
        <MostOrderItemImage
          src={
            item?.image ??
            'https://storage.googleapis.com/kiosify-01/images/ic_logo_kiosify.png'
          }
          width={45}
          height={45}
          preview={false}
        />
      </BaseCol>
      <BaseCol>
        <MostOrderItemTitle>{item?.name ?? '-'}</MostOrderItemTitle>
        <MostOrderItemDescription>
          {item?.total_quantity ?? 0}{' '}
          {t(R.strings.dashboard_common_dishes_ordered)}
        </MostOrderItemDescription>
      </BaseCol>
    </MostOrderItem>
  )
}

export const MostOrderLayout: React.FC<{
  mostOrdered: IDataMostOrdered[]
  isLoading?: boolean
}> = ({ mostOrdered, isLoading }) => {
  const { t } = useTranslation()
  return (
    <RootWrapper>
      <BaseRow justify={'space-between'} align={'middle'} gutter={[16, 16]}>
        <BaseCol>
          <BaseText
            children={t(R.strings.dashboard_common_most_ordered)}
            fontSize="xl"
            fontWeight="regular"
          />
        </BaseCol>

        <BaseCol>
          <BaseButton
            type="text"
            children={
              <BaseText
                children={t(R.strings.view_all)}
                fontWeight="medium"
                colorText="collapseBackgroundColor"
              />
            }
          />
        </BaseCol>
      </BaseRow>

      <BaseSpace direction="vertical" size={10} style={{ width: '100%' }}>
        {mostOrdered?.length ? (
          mostOrdered?.map((item, index) => {
            return <ItemMostOrder key={index} item={item} />
          })
        ) : (
          <BaseRow justify={'center'}>
            <Empty description={''} />
          </BaseRow>
        )}
      </BaseSpace>

      {isLoading && <Loading />}
    </RootWrapper>
  )
}

const RootWrapper = styled(BaseSpace)`
  background-color: white;
  padding: 16px;
  border-radius: 12px;
`

const MostOrderItem = styled(BaseRow)`
  padding: 0px ${PADDING.md};
`

const MostOrderItemImage = styled(BaseImage)`
  border-radius: ${BORDER_RADIUS_2};
`

const MostOrderItemTitle = styled.div`
  color: #e0e6e9;
  font-size: ${FONT_SIZE.xxs};
  font-weight: ${FONT_WEIGHT.medium};
`

const MostOrderItemDescription = styled.div`
  color: #abbbc2;
  font-size: ${FONT_SIZE.xxxs};
  font-weight: ${FONT_WEIGHT.regular};
`

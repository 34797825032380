/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  requestCreateSubscriptionPlanByEmail,
  requestGetSubscriptionPlanList,
} from 'app/api/subscription-plan/api'
import {
  ICreateSubscriptionPlan,
  ISubscriptionPlan,
  ResponseSubscriptionPlanList,
} from 'app/api/subscription-plan/model'
import { IParamsList } from 'app/api/product-management/model'
import { IResponseDefine } from 'app/common/model'
import { LOGOUT_PATH } from 'app/components/router/route-path'
import { useInfiniteQuery, useMutation, useQueryClient } from 'react-query'
import { useNavigate } from 'react-router'
import { SubscriptionPlanKeys } from '../query-key/subscription-plan'

export function useGetSubscriptionPlanList(
  params?: IParamsList,
  enabled = true,
) {
  const navigate = useNavigate()
  return useInfiniteQuery<ISubscriptionPlan[], IParamsList>(
    SubscriptionPlanKeys.subscriptionPlanList(params),
    async () => {
      try {
        const response: ResponseSubscriptionPlanList =
          await requestGetSubscriptionPlanList({
            ...params,
          })

        return response?.results ?? []
      } catch (error: any) {
        console.log({ error }, 'product-management.ts')
        if (error?.message === '401') {
          navigate(LOGOUT_PATH, { replace: true })
          return []
        }
        return []
      }
    },
    {
      enabled,
      cacheTime: 5000,
    },
  )
}

export const useCreateSubscriptionPlansByEmail = () => {
  const navigate = useNavigate()
  const query = useQueryClient()
  return useMutation<
    IResponseDefine<boolean>,
    unknown,
    ICreateSubscriptionPlan,
    unknown
  >(
    async (body: any) => {
      try {
        const res = await requestCreateSubscriptionPlanByEmail(body)
        return res
      } catch (error: any) {
        if (error?.message === '401') {
          navigate(LOGOUT_PATH, { replace: true })
          return {
            data: [],
            total: 0,
          }
        }
      }
    },
    {
      onSuccess: () => {
        query.invalidateQueries({
          queryKey: SubscriptionPlanKeys.all,
        })
      },
    },
  )
}

import { BaseSpace } from 'app/components/common/BaseSpace'
import { useAppSelector } from 'app/redux/hooks'
import { useCallback } from 'react'
import styled from 'styled-components'
import HomeLayout from './components/Home'
import OrderReportLayout from './components/OrderReport'
import TotalCustomerLayout from './components/TotalCustomer'
import TotalDishOrderedLayout from './components/TotalDishOrdered'
import TotalRevenueLayout from './components/TotalRevenue'
import { URL_REPORTS } from './constant'
import * as S from './styles'

const ReportsContainer = () => {
  const currentPath = useAppSelector(state => state?.pathReport)?.path
  const currentLayout = useCallback(() => {
    switch (currentPath) {
      case URL_REPORTS.TotalRevenue:
        return <TotalRevenueLayout />
      case URL_REPORTS.TotalDishOrdered:
        return <TotalDishOrderedLayout />

      case URL_REPORTS.TotalCustomer:
        return <TotalCustomerLayout />

      case URL_REPORTS.OrderReport:
        return <OrderReportLayout />
    }
  }, [currentPath])()

  return (
    <S.RootWrapper>
      <BaseSpace>
        <HomeLayout />
        <RightSide>{currentLayout}</RightSide>
      </BaseSpace>
    </S.RootWrapper>
  )
}

export default ReportsContainer

const RightSide = styled.div`
  background-color: white;
  padding: 16px;
  border-radius: 12px;
`

import { getToken } from 'app/service/storage/storage-service'
import { WithChildrenProps } from 'parkway-web-common'
import React from 'react'
import { Navigate } from 'react-router'
import { AUTH_LOGIN_PATH } from './route-path'

const RequireAuth: React.FC<WithChildrenProps> = ({ children }) => {
  const token = getToken()
  return token ? <>{children}</> : <Navigate to={AUTH_LOGIN_PATH} replace />
}

export default RequireAuth

import { createAction, createSlice, PrepareAction } from '@reduxjs/toolkit'
import { DASHBOARD_PATH } from 'app/components/router/route-path'

export interface PathState {
  path: string
}

const initialState: PathState = {
  path: DASHBOARD_PATH,
}

export const setCurrentPath = createAction<PrepareAction<string>>(
  'path/setPath',
  newPath => {
    // persistPath(newPath);
    console.log({ newPath })

    return {
      payload: newPath,
    }
  },
)

export const pathSlice = createSlice({
  name: 'path',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(setCurrentPath, (state, action) => {
      console.log({ action })

      state.path = action.payload
    })
  },
})

export default pathSlice.reducer

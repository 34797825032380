import R from 'app/assets/R'
import { IMenuSetting } from './components/ItemMenu'

export const APPERANCE_COLOR_TARGETS = {
  Background: 'background',
  Text: 'text',
  Button: 'button',
}

export const URL_SETTING = {
  Appearance: 'appearance',
  Restaurant: 'restaurant',
  RestaurantDetail: 'restaurant/detail',
  AddYourKiosk: 'kiosks/create',
  UpdateYourKiosk: 'kiosks/update',
  Kiosks: 'kiosks',
  ProductsManagement: 'products-management',
  ProductsDetail: 'products-management/detail',
  ProductsSelectUpSell: 'products-management/select-upsell',
  KDSSetting: 'kds_setting',
  Backup: 'backup',
  Information: 'information',
  DeviceDetail: 'information/device-detail',
  PrinterConfig: 'information/printer-config',
  SyncDataFromSquare: 'sync-data-from-square',
}

export const dataMenu: IMenuSetting[] = [
  {
    icon: R.images.ic_appearance,
    iconFocus: R.images.ic_appearance,
    title: R.strings.setting_menu_appearance_t,
    url: URL_SETTING.Appearance,
    description: R.strings.setting_menu_appearance_d,
  },
  {
    icon: R.images.ic_restaurant,
    iconFocus: R.images.ic_restaurant_focus,
    title: R.strings.setting_menu_your_restaurant_t,
    url: URL_SETTING.Restaurant,
    description: R.strings.setting_menu_your_restaurant_d,
  },
  {
    icon: R.images.ic_your_kiosk,
    iconFocus: R.images.ic_your_kiosk_focus,
    title: R.strings.setting_menu_your_kiosk_t,
    url: URL_SETTING.Kiosks,
    description: R.strings.setting_menu_your_kiosk_d,
  },
  {
    icon: R.images.ic_product_management,
    iconFocus: R.images.ic_product_management_focus,
    title: R.strings.setting_menu_product_management_t,
    url: URL_SETTING.ProductsManagement,
    description: R.strings.setting_menu_product_management_d,
  },
  {
    icon: R.images.ic_kds_setting,
    iconFocus: R.images.ic_kds_setting_focus,
    title: R.strings.setting_menu_kds_t,
    url: URL_SETTING.KDSSetting,
    description: R.strings.setting_menu_kds_d,
  },
  {
    icon: R.images.ic_backup,
    iconFocus: R.images.ic_backup_focus,
    title: R.strings.setting_menu_backup_t,
    url: URL_SETTING.Backup,
    description: R.strings.setting_menu_backup_d,
  },
  {
    icon: R.images.ic_your_information,
    iconFocus: R.images.ic_infomation_focus,
    title: R.strings.setting_menu_your_information_t,
    url: URL_SETTING.Information,
    description: R.strings.setting_menu_your_information_d,
  },
  // {
  //   icon: R.images.ic_cloud_sync,
  //   iconFocus: R.images.ic_cloud_sync,
  //   title: R.strings.setting_menu_sync_data_from_square_t,
  //   url: URL_SETTING.SyncDataFromSquare,
  //   description: R.strings.setting_menu_sync_data_from_square_d,
  // },
]

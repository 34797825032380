/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  DeleteOutlined,
  EllipsisOutlined,
  HolderOutlined,
  SearchOutlined,
} from '@ant-design/icons'
import { Empty } from 'antd'
import {
  requestChangePositionCategory,
  requestDeleteCategoryList,
} from 'app/api/product-management/api'
import { ICategory } from 'app/api/product-management/model'
import R from 'app/assets/R'
import { BaseButton } from 'app/components/common/BaseButton'
import { ButtonVariant } from 'app/components/common/BaseButtonVariant'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseImage } from 'app/components/common/BaseImage'
import { BasePopconfirm } from 'app/components/common/BasePopconfirm'
import { BasePopover } from 'app/components/common/BasePopover'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { BaseInput } from 'app/components/common/inputs/BaseInput'
import { Loading } from 'app/components/common/Loading'
import { notificationController } from 'app/controllers/notification-controller'
import { useDebounce, usePagination } from 'app/hook'
import { useGetCategoryList } from 'app/react-query/hook/product-management'
import { ProductManagementKeys } from 'app/react-query/query-key/product-management'
import { t } from 'i18next'
import { isEqual } from 'lodash'
import { PADDING } from 'parkway-web-common'
import { useEffect, useMemo, useState } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { useQueryClient } from 'react-query'
import styled from 'styled-components'
import { CreateModal, UpdateModal } from './modals'
const CategoryTab = () => {
  const [search, setSearch] = useState('')
  const searchKey = useDebounce(search, 500)
  const query = useQueryClient()
  const {
    data,
    isLoading: loading,
    refetch,
  } = useGetCategoryList({ searchKey })
  const { flattenData } = usePagination()
  const [listCategory, setListCategory] = useState<ICategory[]>([])
  const [initList, setInitList] = useState<(number | string)[]>([])

  const [loadingPosition, setLoadingPosition] = useState(false)
  const [isLoadingDelete, setIsLoadingDelete] = useState(false)

  const categories = useMemo(() => {
    const dataRs = flattenData(data)
    return dataRs
  }, [data])

  useEffect(() => {
    setListCategory(categories)
    setInitList(categories?.map(itm => itm?.id))
  }, [categories])

  const onDragEnd = result => {
    const { destination, source } = result

    // Nếu không có nơi đích (destination) hoặc không di chuyển, thì không làm gì cả
    if (!destination || destination.index === source.index) {
      return
    }

    // Thay đổi vị trí item trong mảng
    const newItems = Array.from(listCategory)
    const [removed] = newItems.splice(source.index, 1)
    newItems.splice(destination.index, 0, removed)

    // Cập nhật lại state
    setListCategory(newItems)
  }

  const currentIdList = useMemo(() => {
    return listCategory?.map(item => item?.id)
  }, [listCategory])

  const isChangedPosition = useMemo(() => {
    return !isEqual(currentIdList, initList)
  }, [currentIdList, initList])

  const onSaveChangePosition = async () => {
    try {
      setLoadingPosition(true)
      await Promise.all(
        listCategory.map((item, index) => {
          return requestChangePositionCategory({
            movedCategoryId: item?.id ?? '',
            newPosition: index,
          })
        }),
      )

      notificationController.success({
        message: t(R.strings.save_success),
      })
    } catch (error) {
      console.log({ error })
    } finally {
      refetch()
      setLoadingPosition(false)
    }
  }

  const onPressDelete = async (id?: string) => {
    if (!id) return
    try {
      setIsLoadingDelete(true)
      const res = await requestDeleteCategoryList(id)
      if (res) {
        query.invalidateQueries({
          queryKey: ProductManagementKeys.allCategory,
        })

        notificationController.success({
          message: t(R.strings.success),
        })
      }
    } catch (error) {
      console.log({ error })
    } finally {
      setIsLoadingDelete(false)
    }
  }

  return (
    <BaseSpace>
      <RootWrapper
        gutter={[15, 15]}
        justify={'start'}
        align={'middle'}
        wrap={false}
      >
        <BaseSpace size={8} style={{ paddingInline: 8 }}>
          <BaseRow gutter={16} align={'middle'} justify={'space-between'}>
            <BaseCol xl={8}>
              <BaseForm.Item>
                <BaseInput
                  placeholder="Search restaurant"
                  prefix={<SearchOutlined rev={undefined} />}
                  style={{ padding: '8px 10px' }}
                  onChange={e => setSearch(e?.target?.value ?? '')}
                />
              </BaseForm.Item>
            </BaseCol>

            <BaseCol>
              <CreateModal />
            </BaseCol>
          </BaseRow>
          <div style={{ maxHeight: 500, overflowY: 'auto', minHeight: 400 }}>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable">
                {provided => (
                  <ul
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={{ listStyleType: 'none', padding: 0, width: '100%' }}
                  >
                    {listCategory.map((item, index) => (
                      <Draggable
                        key={item.id}
                        draggableId={item?.id ?? ''}
                        index={index}
                      >
                        {provided => (
                          <li
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            style={{
                              margin: '10px 0',
                              padding: '10px',
                              backgroundColor: '#FFFFFF',
                              borderRadius: '6px',
                              display: 'flex',
                              boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                              width: '100%',
                              alignItems: 'center',
                              ...provided.draggableProps.style,
                            }}
                          >
                            <HolderOutlined
                              {...provided.dragHandleProps}
                              style={{
                                cursor: 'move',
                                fontSize: '18px',
                                padding: '5px',
                              }}
                              rev={undefined}
                            />
                            <BaseRow
                              gutter={16}
                              align={'middle'}
                              justify={'space-between'}
                              style={{
                                marginLeft: '8px',
                                display: 'flex',
                                flex: 1,
                              }}
                            >
                              <BaseCol
                                style={{
                                  display: 'flex',
                                  flex: 1,
                                  cursor: 'pointer',
                                }}
                              >
                                <BaseRow gutter={16} align={'middle'}>
                                  <BaseCol>
                                    <BaseImage
                                      src={
                                        item?.image ??
                                        R.images.ic_logo_kiosk_master
                                      }
                                      preview={false}
                                      width={44}
                                      height={44}
                                      style={{ borderRadius: 6 }}
                                    />
                                  </BaseCol>

                                  <BaseCol>
                                    <BaseSpace size={2}>
                                      <BaseText
                                        fontWeight="semibold"
                                        children={`${item?.name} ${
                                          item?.displayName
                                            ? `(${item?.displayName})`
                                            : ''
                                        }`}
                                      />
                                      <BaseText
                                        style={{ color: '#818181' }}
                                        fontSize="xxxs"
                                        children={item?.description ?? ''}
                                      />
                                      <BaseText
                                        style={{ color: '#818181' }}
                                        fontSize="xxxs"
                                      >
                                        {`${item?.itemCount ?? 0} items`}
                                      </BaseText>
                                    </BaseSpace>
                                  </BaseCol>
                                </BaseRow>
                              </BaseCol>

                              <BaseCol>
                                <BasePopover
                                  trigger={'click'}
                                  content={
                                    <BaseSpace size={8}>
                                      <UpdateModal item={item} />
                                      <BasePopconfirm
                                        title="Delete the task"
                                        description="Are you sure to delete this task?"
                                        onConfirm={() =>
                                          onPressDelete(item?.id)
                                        }
                                        onCancel={undefined}
                                        okText="Yes"
                                        cancelText="No"
                                      >
                                        <BaseButton
                                          icon={
                                            <DeleteOutlined rev={undefined} />
                                          }
                                          children={'Delete'}
                                          type="text"
                                          style={{ color: 'red' }}
                                          loading={isLoadingDelete}
                                        />
                                      </BasePopconfirm>
                                    </BaseSpace>
                                  }
                                  placement="bottomRight"
                                >
                                  <BaseButton
                                    icon={<EllipsisOutlined rev={undefined} />}
                                    type="text"
                                  />
                                </BasePopover>
                              </BaseCol>
                            </BaseRow>
                          </li>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </ul>
                )}
              </Droppable>
            </DragDropContext>
          </div>
          {listCategory.length === 0 && <Empty />}
        </BaseSpace>

        {(loading || loadingPosition) && <Loading />}
      </RootWrapper>
      {isChangedPosition ? (
        <BaseRow justify={'end'} align={'middle'}>
          <ButtonVariant
            onClick={onSaveChangePosition}
            children={t(R.strings.save)}
          />
        </BaseRow>
      ) : null}
    </BaseSpace>
  )
}

export default CategoryTab

const RootWrapper = styled(BaseRow)`
  padding: ${PADDING.xxs} 0px;
`

import {
  IFilterReport,
  IReportCustomer,
  TypeDateEnum,
} from 'app/api/report/type'
import R from 'app/assets/R'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { BaseChart } from 'app/components/common/charts/BaseChart'
import { usePagination } from 'app/hook'
import { useGetReportCustomer } from 'app/react-query/hook/report'
import moment from 'moment'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BAR_CHART_SERIES_COLORS } from '../../constant'
import ChartHeaderLayout from '../ChartHeader'
import * as S from './styles'

const TotalCustomerLayout = () => {
  const { i18n } = useTranslation()

  const [params, setParams] = useState<IFilterReport>({
    type_date: TypeDateEnum.daily,
  })

  const { data: dataReport } = useGetReportCustomer(params)

  const { flattenDataList } = usePagination()

  const chartData = useMemo(() => {
    const data: IReportCustomer[] = flattenDataList(dataReport)

    const customers = data?.map(item => item?.total_customer ?? 0)
    const labels = data?.map(item => item?.date ?? '-')

    console.log({ customers })

    return {
      data: [1, 10, 25, 15, 0, 1, 2, 6, 8, 46, 4, 5],
      labels,
    }
  }, [dataReport])

  const xAxisLabel = useMemo(() => {
    switch (params?.type_date) {
      case TypeDateEnum.daily:
        return chartData?.labels?.map(item => {
          const hour = +item
          return hour > 9
            ? `${hour}:00 - ${hour}:59`
            : `0${hour}:00 - 0${hour}:59`
        })

      case TypeDateEnum.weekly:
        return chartData?.labels?.map(item => `${item}`)
      case TypeDateEnum.monthly:
        return chartData?.labels?.map(item => `${item}`)
      case TypeDateEnum.yearly:
        return chartData?.labels?.map(item => `Month ${item}`)
    }
  }, [chartData, params])

  const dataZoom = useMemo(() => {
    switch (params?.type_date) {
      case TypeDateEnum.daily:
        return [
          {
            type: 'inside',
            start: 0,
            end: 35,
            show: false,
            zoomLock: true,
          },
        ]

      case TypeDateEnum.weekly:
        return [
          {
            type: 'inside',
            start: 0,
            end: 100,
            show: false,
            zoomLock: true,
          },
        ]
      case TypeDateEnum.monthly:
        return [
          {
            type: 'inside',
            start: 0,
            end: 35,
            show: false,
            zoomLock: true,
          },
        ]
      case TypeDateEnum.yearly:
        return [
          {
            type: 'inside',
            start: 0,
            end: 60,
            show: false,
            zoomLock: true,
          },
        ]
    }
  }, [params])

  const option = useMemo(() => {
    return {
      backgroundColor: 'transparent',
      tooltip: {},
      grid: {
        top: 20,
        left: 0,
        right: 0,
        bottom: 0,
        containLabel: true,
      },
      xAxis: {
        data: xAxisLabel,
        type: 'category',
        axisLine: {
          lineStyle: {
            color: '#DBDBDB',
            type: 'line',
          },
        },
        axisTick: {
          show: false,
        },
      },
      yAxis: {
        axisLabel: {
          formatter: params => `${params / 1000}k`,
        },
        splitLine: {
          lineStyle: {
            color: '#DBDBDB',
            type: 'line',
          },
        },
      },
      series: [
        {
          data: chartData?.data ?? [],
          type: 'bar',
          barWidth: '22px',
          itemStyle: {
            color: BAR_CHART_SERIES_COLORS[1],
            borderRadius: [5, 5, 0, 0],
          },
        },
      ],
      dataZoom,
    }
  }, [chartData, i18n.language, dataZoom, xAxisLabel])

  const onChange = (type_date: TypeDateEnum) => {
    setParams({
      type_date,
    })
  }

  const { t } = useTranslation()

  const today = moment().locale(i18n.language)

  return (
    <S.RootWrapper>
      <BaseRow align={'middle'} justify={'space-between'}>
        <BaseCol>
          <BaseSpace size={4}>
            <BaseText
              children={t(R.strings.reports_common_total_customer)}
              fontSize="xxl"
              fontWeight="medium"
            />

            <BaseText
              children={today.format('dddd, D MMM YYYY')}
              fontSize="xxs"
              style={{ color: '#9A9FA5' }}
            />
          </BaseSpace>
        </BaseCol>
        <BaseCol>
          <ChartHeaderLayout
            hideFilter
            onChange={onChange}
            params={params.type_date}
          />
        </BaseCol>
      </BaseRow>

      <BaseChart
        option={{ ...option }}
        theme={'dark'}
        width={'100%'}
        height={500}
      />
    </S.RootWrapper>
  )
}

export default TotalCustomerLayout

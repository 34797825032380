import R from 'app/assets/R'
import { BaseCol } from 'app/components/common/BaseCol'
import BaseText from 'app/components/common/BaseText'
import { useTranslation } from 'react-i18next'
import { ItemMenuCpn } from './components/ItemMenu'
import { RightSide } from './components/RightSide'
import { dataMenu } from './constant'
import * as S from './styles'
export const SettingContainer = () => {
  const { t } = useTranslation()

  return (
    <S.Root>
      <S.TitleWrapper justify={'space-between'}>
        <BaseText
          children={t(R.strings.settings)}
          fontSize="xxl"
          fontWeight="semibold"
        />
      </S.TitleWrapper>

      <S.RootWrapper gutter={[15, 15]}>
        <S.RootWrapperLeftSide xl={6}>
          {dataMenu?.map((item, index) => {
            return (
              <ItemMenuCpn
                index={index}
                total={dataMenu?.length + 1}
                item={item}
                key={index}
              />
            )
          })}
        </S.RootWrapperLeftSide>
        <BaseCol xl={18}>
          <RightSide />
        </BaseCol>
      </S.RootWrapper>
    </S.Root>
  )
}

import R from 'app/assets/R'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseImage } from 'app/components/common/BaseImage'
import { BaseRow } from 'app/components/common/BaseRow'
import { DASHBOARD_PATH } from 'app/components/router/route-path'
import { BORDER_RADIUS, useResponsive } from 'parkway-web-common'
import { ReactNode } from 'react'
import { useNavigate } from 'react-router'

export const BaseTemplatePageAuth = ({ children }: { children: ReactNode }) => {
  const navigate = useNavigate()
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'block',
      }}
    >
      <BaseRow
        style={{
          backgroundColor: 'white',
          padding: '20px 16px',
          marginLeft: 0,
          marginRight: 0,
        }}
        gutter={[16, 16]}
        align={'middle'}
        justify={'space-between'}
      >
        <BaseCol>
          <BaseImage
            width={120}
            src={R.images.ic_new_logo}
            style={{ borderRadius: BORDER_RADIUS, cursor: 'pointer' }}
            preview={false}
            onClick={() => navigate?.(DASHBOARD_PATH)}
          />
        </BaseCol>

        <BaseCol>
          <div style={{ color: '#003366', fontSize: 18, fontWeight: 'bold' }}>
            EN
          </div>
        </BaseCol>
      </BaseRow>

      {children}
    </div>
  )
}

export const JupiterComponent = () => {
  const { isTablet } = useResponsive()
  return (
    <div
      className={isTablet ? 'planet' : 'planet-mobile'}
      style={{
        background: `url(${R.images.ic_rupiter}) no-repeat`,
      }}
    />
  )
}

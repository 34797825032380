// Message code define
export const AuthMessageCode = {
  AUTH_SUCCESS: '10001',
}

// Action key
export const AuthActionKey = {
  AUTH_ACTION_KEY: 'auth/doLogin',
  USER_INFO_ACTION_KEY: 'user/info',
}

// Endpoint
export const AuthEndPoint = {
  LOGIN: 'accounts/login/',
  SIGN_UP: 'accounts/register/',
  FORGOT_PASSWORD: 'accounts/forgot-password/',
  VERITY_CODE: 'accounts/activate-code/',
  NEW_PASSWORD: 'accounts/reset-password/',
  SQUARE_CODE: 'accounts/authorize/square-code/',
  RESEND_CODE: 'accounts/activate-code/resend/',
  CARD_ON_FILE: 'accounts/card-on-file/',
  USER_INFO: 'accounts/user-info/',
  USER_INFO_LANGUAGE: 'accounts/user-info/language/',
  USER_INFO_UPDATE: 'accounts/user-info/update/',
}

import { ApiClient } from 'app/service/api-service'
import {
  IBodyChangePassword,
  IBodySendPaymentInfo,
  IBodySendSquareCode,
  LoginRequest,
  NewPasswordData,
  ResetPasswordRequest,
  SecurityCodePayload,
  SignUpRequest,
} from './model'
import { AuthEndPoint } from './constant'
import { LanguageSupportType } from 'app/i18n/constant'

export const requestPostLogin = (body: LoginRequest) =>
  ApiClient.Post({ url: AuthEndPoint.LOGIN, body })

export const requestPostSignUp = (body: SignUpRequest) =>
  ApiClient.Post({ url: AuthEndPoint.SIGN_UP, body })

export const requestPostResetPassword = (body: ResetPasswordRequest) =>
  ApiClient.Post({ url: AuthEndPoint.FORGOT_PASSWORD, body })

export const requestPostVerifySecurityCode = (body: SecurityCodePayload) =>
  ApiClient.Post({
    url: AuthEndPoint.VERITY_CODE,
    body,
  })

export const requestPostResendCode = (body: ResetPasswordRequest) =>
  ApiClient.Post({
    url: AuthEndPoint.VERITY_CODE,
    body,
  })

export const requestPostSetNewPassword = (body: NewPasswordData) =>
  ApiClient.Post({ url: AuthEndPoint.NEW_PASSWORD, body })

export const requestPostSquareCode = (body: IBodySendSquareCode) =>
  ApiClient.Post({ url: AuthEndPoint.SQUARE_CODE, body })

export const requestPostCardOnFile = (body: IBodySendPaymentInfo) =>
  ApiClient.Post({ url: AuthEndPoint.CARD_ON_FILE, body })

export const requestPostChangePassword = (body: IBodyChangePassword) =>
  ApiClient.Post({ url: AuthEndPoint.NEW_PASSWORD, body })

export const requestGetUserInfo = () =>
  ApiClient.Get({ url: AuthEndPoint.USER_INFO, params: {} })

export const requestChangeLanguageUser = (language: LanguageSupportType) =>
  ApiClient.Put({ url: AuthEndPoint.USER_INFO_LANGUAGE, body: { language } })

export const requestChangeLogoUser = (logo: string) =>
  ApiClient.Put({ url: AuthEndPoint.USER_INFO_UPDATE, body: { logo } })

/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { SearchOutlined } from '@ant-design/icons'
import { FormInstance } from 'antd'
import Dragger from 'antd/es/upload/Dragger'
import {
  IItemProductManagement,
  IPostUpSell,
} from 'app/api/product-management/model'
import { IRestaurant } from 'app/api/restaurant/model'
import R from 'app/assets/R'
import {
  SvgPictureIcon,
  SvgSquareCheckIcon,
  SvgUnCheckedCreateItemIcon,
} from 'app/assets/svg-assets'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseCollapse } from 'app/components/common/BaseCollapse'
import { BaseImage } from 'app/components/common/BaseImage'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { CurrencyPicker } from 'app/components/common/CurrencyPicker'
import { CurrencySupportType } from 'app/components/common/CurrencyPicker/type'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { BaseInput } from 'app/components/common/inputs/BaseInput'
import { InputNumber } from 'app/components/common/inputs/InputNumber'
import { Loading } from 'app/components/common/Loading'
import { BaseSelect } from 'app/components/common/selects/BaseSelect'
import { customCurrencyValue, useDebounce, usePagination } from 'app/hook'
import { useGetItemList } from 'app/react-query/hook/product-management'
import { useGetRestaurantListV2 } from 'app/react-query/hook/restaurant'
import { convertColor } from 'app/styles/themes/themeVariables'
import { t } from 'i18next'
import { isEqual } from 'lodash'
import { BORDER_RADIUS, PADDING } from 'parkway-web-common'
import { useMemo, useState } from 'react'
import styled from 'styled-components'

interface IForm extends IPostUpSell {
  image?: string
  upSellAt?: string
}

interface IProps {
  form: FormInstance<IForm>
  locationIdsSelected: string[]
  setLocationIdsSelected: React.Dispatch<React.SetStateAction<string[]>>
  itemIdsSelected: string[]
  setItemIdsSelected: React.Dispatch<React.SetStateAction<string[]>>
  imageUrl?: string
  setImageUrl: React.Dispatch<React.SetStateAction<string | undefined>>
  fileUpload?: File
  setFile: React.Dispatch<React.SetStateAction<File | undefined>>
  currency: CurrencySupportType
  setCurrency: React.Dispatch<React.SetStateAction<CurrencySupportType>>
}

export const HookFormModal = ({
  form,
  locationIdsSelected,
  setLocationIdsSelected,
  itemIdsSelected,
  setItemIdsSelected,
  imageUrl,
  setImageUrl,
  setFile,
  currency,
  setCurrency,
}: IProps) => {
  const handleUploadChange = (info: any) => {
    const file = info?.file
    if (file) {
      const url = URL.createObjectURL(file)
      setImageUrl(url)
      form.setFieldsValue({ image: url })
      setFile(file)
    }
  }
  const { flattenDataList } = usePagination()

  const [searchLocation, setSearchLocation] = useState('')

  const searchDebounceLocation = useDebounce(searchLocation, 500)

  const { data: locationsData, isLoading: loadingLocations } =
    useGetRestaurantListV2({ searchKey: searchDebounceLocation })

  const locations: IRestaurant[] = useMemo(() => {
    return locationsData?.filter(itm => isEqual(itm?.currency, currency)) ?? []
  }, [locationsData, currency])

  const [searchItem, setSearchItem] = useState('')

  const searchDebounceItem = useDebounce(searchItem, 500)

  const { data: itemsData, isLoading: loadingItems } = useGetItemList({
    searchKey: searchDebounceItem,
  })

  const items: IItemProductManagement[] = useMemo(() => {
    const itemsBf: IItemProductManagement[] =
      flattenDataList(itemsData)?.data ?? []
    return itemsBf?.filter(itm => {
      const currencyItem = itm?.itemVariations?.[0]?.currency
      console.log({ currencyItem })

      return isEqual(currencyItem, currency)
    })
  }, [itemsData, currency])

  const onSelectLocation = (id?: string) => {
    if (!id) return

    const itemExist = locationIdsSelected?.find(idSelected =>
      isEqual(idSelected, id),
    )

    if (itemExist) {
      setLocationIdsSelected(prev =>
        prev?.filter(idSelected => !isEqual(idSelected, id)),
      )
    } else {
      setLocationIdsSelected(prev => prev?.concat([id]))
    }
  }

  const onSelectItem = (id?: string) => {
    if (!id) return

    const itemExist = itemIdsSelected?.find(idSelected =>
      isEqual(idSelected, id),
    )

    if (itemExist) {
      setItemIdsSelected(prev =>
        prev?.filter(idSelected => !isEqual(idSelected, id)),
      )
    } else {
      setItemIdsSelected(prev => prev?.concat([id]))
    }
  }

  const onPressSelectedLocationAll = () => {
    if (locationIdsSelected?.length) {
      setLocationIdsSelected([])
    } else {
      setLocationIdsSelected(locations?.map(itm => itm?.id ?? ''))
    }
  }

  const onPressSelectedItemAll = () => {
    if (itemIdsSelected?.length) {
      setItemIdsSelected([])
    } else {
      setItemIdsSelected(items?.map(itm => itm?.id ?? ''))
    }
  }

  const onChangeCurrency = (currency: CurrencySupportType) => {
    setCurrency(currency)
    setLocationIdsSelected([])
    setItemIdsSelected([])
    form?.setFieldsValue({ upSellAt: undefined, upsellAt: undefined })
  }

  return (
    <BaseSpace
      style={{ padding: '0px 26px', maxHeight: 650, overflowY: 'auto' }}
      size={8}
    >
      <BaseSpace size={8}>
        <BaseSpace size={0}>
          <BaseText children={'Name'} opacity="0.7" />

          <BaseForm.Item
            name={'name'}
            required
            rules={[
              {
                required: true,
                message: t(R.strings.require_field, {
                  field: 'Name',
                }),
              },
            ]}
          >
            <BaseInput
              placeholder="Enter name"
              style={{ padding: '8px 10px', width: '100%' }}
            />
          </BaseForm.Item>
        </BaseSpace>

        <BaseSpace size={0}>
          <BaseText children={'Display Name'} opacity="0.7" />

          <BaseForm.Item
            name={'displayName'}
            required
            rules={[
              {
                required: true,
                message: t(R.strings.require_field, {
                  field: 'Display Name',
                }),
              },
            ]}
          >
            <BaseInput
              placeholder="Enter display name"
              style={{ padding: '8px 10px', width: '100%' }}
            />
          </BaseForm.Item>
        </BaseSpace>

        <BaseSpace size={0}>
          <BaseText children={'Up Sell At'} opacity="0.7" />

          <BaseForm.Item
            name={'upSellAt'}
            required
            rules={[
              {
                required: true,
                message: t(R.strings.require_field, {
                  field: 'Up Sell At',
                }),
              },
            ]}
          >
            <BaseSelect
              placeholder="Select item"
              options={items?.map(item => ({
                label: item?.name,
                value: item?.id,
              }))}
              style={{ height: 44, width: '100%' }}
            />
          </BaseForm.Item>
        </BaseSpace>
      </BaseSpace>
      <BaseSpace size={0}>
        <BaseText children={'Price'} opacity="0.7" />

        <BaseForm.Item
          name={'price'}
          required
          rules={[
            {
              required: true,
              message: t(R.strings.require_field, {
                field: 'Price',
              }),
            },
          ]}
        >
          <InputNumber
            placeholder="Enter price"
            min={0}
            style={{ width: '100%' }}
            prefix={customCurrencyValue(currency)?.labelInput}
          />
        </BaseForm.Item>
      </BaseSpace>

      <BaseSpace size={0}>
        <BaseText children={'Currency'} opacity="0.7" />

        <BaseForm.Item>
          <CurrencyPicker onChange={onChangeCurrency} initValue={currency} />
        </BaseForm.Item>
      </BaseSpace>

      <BaseRow justify={'start'} gutter={16} align={'middle'}>
        <BaseCol xl={2}>
          <BaseText children={'Upload'} fontWeight="semibold" />
        </BaseCol>

        <BaseCol xl={22}>
          <BaseForm.Item
            name={'image'}
            valuePropName="file"
            required
            rules={[
              {
                required: true,
                message: t(R.strings.require_field, {
                  field: 'Image',
                }),
              },
            ]}
          >
            <Dragger
              name="file"
              multiple={false}
              onChange={handleUploadChange}
              beforeUpload={() => false}
              style={{
                borderRadius: 10,
                padding: '10px 20px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'white',
                borderStyle: 'solid',
              }}
              showUploadList={false}
            >
              <BaseRow gutter={16} align={'middle'} justify={'space-between'}>
                <BaseCol xl={8}>
                  <BaseImage
                    src={imageUrl ?? R.images.ic_logo_kiosk_master}
                    style={{ borderRadius: 10 }}
                    width="100%"
                    preview={false}
                  />
                </BaseCol>
                <BaseCol xl={14}>
                  <p>
                    Drag an image here,{' '}
                    <span style={{ color: '#1890ff' }}>upload</span>, or{' '}
                    <span style={{ color: '#1890ff' }}>
                      browse image library
                    </span>
                    .
                  </p>
                </BaseCol>

                <BaseCol xl={2}>
                  <SvgPictureIcon />
                </BaseCol>
              </BaseRow>
            </Dragger>
          </BaseForm.Item>
        </BaseCol>
      </BaseRow>

      <BaseCollapseWrapper
        items={[
          {
            key: 'locations',
            label: <BaseText children={'Location'} fontWeight="semibold" />,
            children: (
              <BaseSpace>
                <BaseForm.Item>
                  <BaseInput
                    placeholder="Search location"
                    prefix={<SearchOutlined rev={undefined} />}
                    style={{ padding: '12px 12px', width: '100%' }}
                    onChange={e => setSearchLocation(e?.target?.value)}
                  />
                </BaseForm.Item>

                <BaseRow gutter={16} align={'middle'} justify={'space-between'}>
                  <BaseCol>
                    <BaseText
                      children={`Selected: ${locationIdsSelected?.length}`}
                      style={{ color: '#818181' }}
                      fontWeight="semibold"
                      fontSize="xxxs"
                    />
                  </BaseCol>

                  <BaseCol>
                    <div
                      style={{ cursor: 'pointer' }}
                      onClick={onPressSelectedLocationAll}
                    >
                      <BaseText
                        children={`Select all`}
                        style={{ color: '#0090FF' }}
                        fontWeight="medium"
                        fontSize="xxxs"
                      />
                    </div>
                  </BaseCol>
                </BaseRow>

                {locations?.map((location, index) => {
                  const isSelected = locationIdsSelected?.find(id =>
                    isEqual(location?.id, id),
                  )
                  return (
                    <BaseRow
                      gutter={16}
                      align={'middle'}
                      justify={'space-between'}
                      key={index}
                      onClick={() => onSelectLocation(location?.id)}
                      style={{ cursor: 'pointer' }}
                    >
                      <BaseCol>
                        <BaseText
                          children={location?.name ?? location?.businessName}
                          fontWeight="semibold"
                        />
                      </BaseCol>

                      <BaseCol
                        xl={2}
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        {isSelected ? (
                          <SvgSquareCheckIcon />
                        ) : (
                          <SvgUnCheckedCreateItemIcon />
                        )}
                      </BaseCol>
                    </BaseRow>
                  )
                })}

                {loadingLocations ? <Loading size="small" /> : null}
              </BaseSpace>
            ),
          },
        ]}
        expandIconPosition="end"
        defaultActiveKey={['locations']}
      />

      <BaseCollapseWrapper
        items={[
          {
            key: 'items',
            label: <BaseText children={'Item Up Sell'} fontWeight="semibold" />,
            children: (
              <BaseSpace>
                <BaseForm.Item>
                  <BaseInput
                    placeholder="Search item"
                    prefix={<SearchOutlined rev={undefined} />}
                    style={{ padding: '12px 12px', width: '100%' }}
                    onChange={e => setSearchItem(e?.target?.value)}
                  />
                </BaseForm.Item>

                <BaseRow gutter={16} align={'middle'} justify={'space-between'}>
                  <BaseCol>
                    <BaseText
                      children={`Selected: ${itemIdsSelected?.length}`}
                      style={{ color: '#818181' }}
                      fontWeight="semibold"
                      fontSize="xxxs"
                    />
                  </BaseCol>

                  <BaseCol>
                    <div
                      style={{ cursor: 'pointer' }}
                      onClick={onPressSelectedItemAll}
                    >
                      <BaseText
                        children={`Select all`}
                        style={{ color: '#0090FF' }}
                        fontWeight="medium"
                        fontSize="xxxs"
                      />
                    </div>
                  </BaseCol>
                </BaseRow>

                {items?.map((location, index) => {
                  const isSelected = itemIdsSelected?.find(id =>
                    isEqual(location?.id, id),
                  )
                  return (
                    <BaseRow
                      gutter={16}
                      align={'middle'}
                      justify={'space-between'}
                      key={index}
                      onClick={() => onSelectItem(location?.id)}
                      style={{ cursor: 'pointer' }}
                    >
                      <BaseCol>
                        <BaseText
                          children={location?.name}
                          fontWeight="semibold"
                        />
                      </BaseCol>

                      <BaseCol
                        xl={2}
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        {isSelected ? (
                          <SvgSquareCheckIcon />
                        ) : (
                          <SvgUnCheckedCreateItemIcon />
                        )}
                      </BaseCol>
                    </BaseRow>
                  )
                })}

                {loadingItems ? <Loading size="small" /> : null}
              </BaseSpace>
            ),
          },
        ]}
        expandIconPosition="end"
        defaultActiveKey={['items']}
      />
    </BaseSpace>
  )
}

const BaseCollapseWrapper = styled(BaseCollapse)`
  width: 100%;

  .ant-collapse-content {
    border-top: 1px solid ${convertColor.neutralBlack9Color};
    background-color: ${convertColor.backgroundColor};
  }

  .ant-collapse-header {
    background-color: var(--background-color);
    border-radius: ${BORDER_RADIUS}!important;
    align-items: center !important;
  }

  .ant-collapse-header[aria-expanded='true'] {
    border-radius: ${BORDER_RADIUS}!important;
    border-bottom-left-radius: ${BORDER_RADIUS} !important;
    border-bottom-right-radius: ${BORDER_RADIUS} !important;
  }

  .header-content {
    padding: ${PADDING.md};
    background-color: #f9fafb;
  }
`

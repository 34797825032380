/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { CloseOutlined, EditOutlined } from '@ant-design/icons'
import {
  requestGetItemById,
  requestPutItems,
} from 'app/api/product-management/api'
import {
  IItemProductManagement,
  IPostItems,
  IVariationProductManagement,
} from 'app/api/product-management/model'
import R from 'app/assets/R'
import { BaseButton } from 'app/components/common/BaseButton'
import { ButtonVariant } from 'app/components/common/BaseButtonVariant'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseCollapse } from 'app/components/common/BaseCollapse'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { Loading } from 'app/components/common/Loading'
import { IRefModal, ModalComponent } from 'app/components/common/ModalComponent'
import { notificationController } from 'app/controllers/notification-controller'
import { ProductManagementKeys } from 'app/react-query/query-key/product-management'
import { handleUploadFileGetId } from 'app/service/general/service'
import { convertColor } from 'app/styles/themes/themeVariables'
import { t } from 'i18next'
import { BORDER_RADIUS, PADDING } from 'parkway-web-common'
import { useEffect, useRef, useState } from 'react'
import { useQueryClient } from 'react-query'
import styled from 'styled-components'
import { HookFormModal } from '../hook'
import { CurrencySupportType } from 'app/components/common/CurrencyPicker/type'
interface IForm extends IPostItems {
  image?: string
}

export const UpdateModal = ({
  item,
}: {
  item: IItemProductManagement
  refetchCode?: number
}) => {
  const query = useQueryClient()
  const refModal = useRef<IRefModal>(null)
  const [form] = BaseForm.useForm<IForm>()

  const [imageUrl, setImageUrl] = useState<string | undefined>(undefined)
  const [fileUploaded, setFileUploaded] = useState<File | undefined>(undefined)
  const [currency, setCurrency] = useState<CurrencySupportType>('USD')

  const [loadingSave, setLoadingSave] = useState(false)
  const [loading, setLoading] = useState(false)
  const [includeTax, setIncludeTax] = useState(false)

  const [locationIdsSelected, setLocationIdsSelected] = useState<string[]>([])
  const [modifierIdsSelected, setModifierIdsSelected] = useState<string[]>([])
  const [variationsSelected, setVariationsSelected] = useState<
    IVariationProductManagement[]
  >([{ name: '', price: '0' }])

  const openModal = () => {
    refModal.current?.open?.()
  }

  const hideModal = () => {
    refModal.current?.hide?.()
  }

  const getDetail = async () => {
    if (!item?.id) {
      hideModal()
      return
    }

    try {
      setLoading(true)

      const res: IItemProductManagement = await requestGetItemById(item?.id)

      form.setFieldsValue({
        name: res?.name,
        description: '',
        image: res?.image,
        displayName: res?.displayName,
        taxRate: res?.taxRate,
        categoryId: res?.categoryId,
      })

      setVariationsSelected(res?.itemVariations ?? [])
      setLocationIdsSelected(res?.presentAtLocationIds ?? [])
      setImageUrl(res?.image)
      setIncludeTax(!!res?.taxIncluded)
      setCurrency((res?.itemVariations?.[0]?.currency ?? 'USD') as any)

      console.log({ res })
    } catch (error) {
      console.log({ error })
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getDetail()
  }, [item])
  const onSave = async (values?: IForm) => {
    if (!item?.id) return
    try {
      setLoadingSave(true)
      if (fileUploaded) {
        const imageId = await handleUploadFileGetId(fileUploaded)
        if (imageId) {
          const res = await requestPutItems(item?.id, {
            categoryId: values?.categoryId,
            description: values?.description,
            name: values?.name,
            imageId,
            itemVariations: variationsSelected?.map(item => ({
              name: item?.name,
              price: item?.price,
              currency,
            })),
            locationIds: locationIdsSelected,
            modifierListIds: modifierIdsSelected,
            displayName: values?.displayName,
            taxIncluded: includeTax,
            taxRate: values?.taxRate,
          })

          if (res) {
            setLocationIdsSelected([])
            setModifierIdsSelected([])
            setVariationsSelected([])
            form.resetFields()
            query.invalidateQueries({
              queryKey: ProductManagementKeys.allItemProductManagement,
            })

            notificationController.success({
              message: t(R.strings.save_success),
            })

            hideModal()
          }
        }
      } else {
        const res = await requestPutItems(item?.id, {
          categoryId: values?.categoryId,
          description: values?.description,
          name: values?.name,
          itemVariations: variationsSelected?.map(item => ({
            name: item?.name,
            price: item?.price,
            currency,
          })),
          locationIds: locationIdsSelected,
          modifierListIds: modifierIdsSelected,
          displayName: values?.displayName,
          taxIncluded: includeTax,
          taxRate: values?.taxRate,
        })

        if (res) {
          setLocationIdsSelected([])
          setModifierIdsSelected([])
          setVariationsSelected([])
          form.resetFields()
          query.invalidateQueries({
            queryKey: ProductManagementKeys.allItemProductManagement,
          })

          notificationController.success({
            message: t(R.strings.save_success),
          })

          hideModal()
        }
      }
    } catch (error) {
      console.log({ error })
    } finally {
      setLoadingSave(false)
    }
  }
  return (
    <ModalComponent
      ref={refModal}
      titleModal="Save"
      renderContent={
        <BaseSpace>
          <BaseForm form={form} onFinish={onSave}>
            <HookFormModal
              imageUrl={imageUrl}
              setImageUrl={setImageUrl}
              fileUploaded={fileUploaded}
              setFileUploaded={setFileUploaded}
              currency={currency}
              setCurrency={setCurrency}
              includeTax={includeTax}
              setIncludeTax={setIncludeTax}
              locationIdsSelected={locationIdsSelected}
              setLocationIdsSelected={setLocationIdsSelected}
              modifierIdsSelected={modifierIdsSelected}
              setModifierIdsSelected={setModifierIdsSelected}
              variationsSelected={variationsSelected}
              setVariationsSelected={setVariationsSelected}
            />
          </BaseForm>

          {loading ? <Loading size="small" /> : null}
        </BaseSpace>
      }
      footer={
        <div style={{ padding: '0px 20px', width: '100%' }}>
          <ButtonVariant
            onClick={form.submit}
            style={{ width: '100%' }}
            loading={loadingSave}
          >
            <BaseText children={'Save'} fontWeight="semibold" />
          </ButtonVariant>
        </div>
      }
      rightHeader={
        <BaseCol>
          <CloseOutlined rev={undefined} onClick={hideModal} />
        </BaseCol>
      }
      buttonOpenCustom={
        <BaseButton
          icon={<EditOutlined rev={undefined} />}
          children={'Edit'}
          type="text"
          onClick={openModal}
        />
      }
    />
  )
}

const BaseCollapseWrapper = styled(BaseCollapse)`
  width: 100%;

  .ant-collapse-content {
    border-top: 1px solid ${convertColor.neutralBlack9Color};
    background-color: ${convertColor.backgroundColor};
  }

  .ant-collapse-header {
    background-color: var(--background-color);
    border-radius: ${BORDER_RADIUS}!important;
    align-items: center !important;
  }

  .ant-collapse-header[aria-expanded='true'] {
    border-radius: ${BORDER_RADIUS}!important;
    border-bottom-left-radius: ${BORDER_RADIUS} !important;
    border-bottom-right-radius: ${BORDER_RADIUS} !important;
  }

  .header-content {
    padding: ${PADDING.md};
    background-color: #f9fafb;
  }
`

import { BaseCol } from 'app/components/common/BaseCol'
import { BaseImage } from 'app/components/common/BaseImage'
import { BaseRow } from 'app/components/common/BaseRow'
import { DASHBOARD_PATH } from 'app/components/router/route-path'
import { useAppDispatch } from 'app/redux/hooks'
import { setCurrentPath } from 'app/redux/slices/pathSlice'
import { BORDER_RADIUS } from 'parkway-web-common'
import React from 'react'
import * as S from '../styles'

interface MobileHeaderProps {
  toggleSlider: () => void
  isSliderOpened: boolean
}

export const MobileHeader: React.FC<MobileHeaderProps> = ({
  toggleSlider,
  isSliderOpened,
}) => {
  const dispatch = useAppDispatch()

  return (
    <BaseRow justify="space-between" align="middle" style={{ height: '100%' }}>
      <BaseCol>
        <BaseImage
          width={40}
          src={
            'https://storage.googleapis.com/kiosify-01/images/ic_logo_kiosify.png'
          }
          style={{ borderRadius: BORDER_RADIUS, cursor: 'pointer' }}
          preview={false}
          onClick={() => dispatch(setCurrentPath(DASHBOARD_PATH))}
        />
      </BaseCol>

      <S.BurgerCol>
        <S.MobileBurger onClick={toggleSlider} isCross={isSliderOpened} />
      </S.BurgerCol>
    </BaseRow>
  )
}

import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import { SearchInput } from 'app/components/common/inputs/SearchInput'
import { BaseSelect } from 'app/components/common/selects/BaseSelect'
import {
  BORDER_RADIUS,
  FONT_SIZE,
  FONT_WEIGHT,
  PADDING,
} from 'parkway-web-common'
import styled from 'styled-components'

export const RootWrapper = styled(BaseSpace)`
  width: 100%;
  position: relative;
`

export const FilterWrapper = styled(BaseRow)`
  overflow-x: auto;
`

export const SearchLayout = styled(SearchInput)`
  .ant-input-group-addon {
    display: none;
  }
  .ant-input-group .ant-input-affix-wrapper:not(:last-child) {
    border-radius: ${BORDER_RADIUS};
    border: 0;
    background: transparent !important;
    color: var(--background-color);
    border: 0.7px solid rgba(45, 48, 62, 1);
  }
  input {
    background: transparent !important;
    color: var(--background-color);
  }
`

export const ChartHeaderSelectTime = styled(BaseSelect)`
  min-width: 200px !important;
  height: 39px;
  &.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: transparent;
    color: var(--background-color);
    border: 0.7px solid rgba(45, 48, 62, 1);
  }
`

export const TotalItemLayout = styled(BaseRow)`
  padding: ${PADDING.xxxs};
  border-radius: ${BORDER_RADIUS};
  background-color: rgba(45, 48, 62, 1);
  svg {
    fill: var(--background-color);
    width: 24px;
    height: 24px;
  }
  .text {
    color: var(--background-color);
    font-weight: ${FONT_WEIGHT.medium};
    font-size: ${FONT_SIZE.lg};
  }
`

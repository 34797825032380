const icons = {
    
    Images: require("./images/Images.png"),
    empty_list: require("./images/empty_list.png"),
    ic_JCB: require("./images/ic_JCB.png"),
    ic_american_express: require("./images/ic_american_express.png"),
    ic_appearance: require("./images/ic_appearance.png"),
    ic_avatar: require("./images/ic_avatar.png"),
    ic_backup: require("./images/ic_backup.png"),
    ic_backup_focus: require("./images/ic_backup_focus.png"),
    ic_bg_auth_01: require("./images/ic_bg_auth_01.png"),
    ic_bg_auth_02: require("./images/ic_bg_auth_02.png"),
    ic_chart_admin: require("./images/ic_chart_admin.png"),
    ic_checked_black: require("./images/ic_checked_black.png"),
    ic_cloud_sync: require("./images/ic_cloud_sync.png"),
    ic_connecttion: require("./images/ic_connecttion.png"),
    ic_content_management: require("./images/ic_content_management.png"),
    ic_contract: require("./images/ic_contract.png"),
    ic_cursor: require("./images/ic_cursor.png"),
    ic_customer: require("./images/ic_customer.png"),
    ic_customer_dashboard: require("./images/ic_customer_dashboard.png"),
    ic_dashboard_admin: require("./images/ic_dashboard_admin.png"),
    ic_dashboard_admin_white: require("./images/ic_dashboard_admin_white.png"),
    ic_fb: require("./images/ic_fb.png"),
    ic_forcus_chart: require("./images/ic_forcus_chart.png"),
    ic_gg: require("./images/ic_gg.png"),
    ic_home_admin_focus: require("./images/ic_home_admin_focus.png"),
    ic_img_hero: require("./images/ic_img_hero.png"),
    ic_infomation_focus: require("./images/ic_infomation_focus.png"),
    ic_instagram: require("./images/ic_instagram.png"),
    ic_kds_setting: require("./images/ic_kds_setting.png"),
    ic_kds_setting_focus: require("./images/ic_kds_setting_focus.png"),
    ic_leading: require("./images/ic_leading.png"),
    ic_left_to_right_home: require("./images/ic_left_to_right_home.png"),
    ic_linkedin: require("./images/ic_linkedin.png"),
    ic_logo_kiosk_master: require("./images/ic_logo_kiosk_master.png"),
    ic_logout: require("./images/ic_logout.png"),
    ic_master_card: require("./images/ic_master_card.png"),
    ic_new_logo: require("./images/ic_new_logo.png"),
    ic_notification: require("./images/ic_notification.png"),
    ic_option_kds_classic: require("./images/ic_option_kds_classic.png"),
    ic_option_kds_default: require("./images/ic_option_kds_default.png"),
    ic_order_dashboard: require("./images/ic_order_dashboard.png"),
    ic_personal_recommendations: require("./images/ic_personal_recommendations.png"),
    ic_product_management: require("./images/ic_product_management.png"),
    ic_product_management_focus: require("./images/ic_product_management_focus.png"),
    ic_remote_kiosk_control: require("./images/ic_remote_kiosk_control.png"),
    ic_restaurant: require("./images/ic_restaurant.png"),
    ic_restaurant_focus: require("./images/ic_restaurant_focus.png"),
    ic_rupiter: require("./images/ic_rupiter.png"),
    ic_setting_admin: require("./images/ic_setting_admin.png"),
    ic_setting_admin_white: require("./images/ic_setting_admin_white.png"),
    ic_star_01: require("./images/ic_star_01.png"),
    ic_star_02: require("./images/ic_star_02.png"),
    ic_success: require("./images/ic_success.png"),
    ic_tablet_land: require("./images/ic_tablet_land.png"),
    ic_total_customer: require("./images/ic_total_customer.png"),
    ic_total_dish_order: require("./images/ic_total_dish_order.png"),
    ic_total_revenue_restaurant_setting: require("./images/ic_total_revenue_restaurant_setting.png"),
    ic_united_kingdom_flag: require("./images/ic_united_kingdom_flag.png"),
    ic_verified: require("./images/ic_verified.png"),
    ic_vietnam_flag: require("./images/ic_vietnam_flag.png"),
    ic_visa: require("./images/ic_visa.png"),
    ic_your_information: require("./images/ic_your_information.png"),
    ic_your_kiosk: require("./images/ic_your_kiosk.png"),
    ic_your_kiosk_focus: require("./images/ic_your_kiosk_focus.png"),
    img_bg_auth: require("./images/img_bg_auth.png"),
    img_card_footer: require("./images/img_card_footer.png")
    }
export default icons
import images from './img-assets'
import strings from './strings'
import videos from './video-assets'

const R = {
  images,
  strings: strings(),
  videos,
}

export default R

import { BaseCol } from 'app/components/common/BaseCol'
import { BaseImage } from 'app/components/common/BaseImage'
import { BaseRow } from 'app/components/common/BaseRow'
import { DASHBOARD_PATH } from 'app/components/router/route-path'
import { useAppDispatch } from 'app/redux/hooks'
import { setCurrentPath } from 'app/redux/slices/pathSlice'
import { BORDER_RADIUS } from 'parkway-web-common'
import React from 'react'
import * as S from '../styles'
import { LanguagePicker } from '../components/SettingsDropdown/settings-overlay/LanguagePicker'
import R from 'app/assets/R'

interface MobileHeaderProps {
  toggleSlider: () => void
  isSliderOpened: boolean
}

export const MobileHeader: React.FC<MobileHeaderProps> = ({
  toggleSlider,
  isSliderOpened,
}) => {
  const dispatch = useAppDispatch()

  return (
    <BaseRow
      justify="space-between"
      align="middle"
      style={{ height: '100%' }}
      wrap={false}
    >
      <BaseCol>
        <LanguagePicker />
      </BaseCol>

      <BaseCol>
        <BaseImage
          width={120}
          src={R.images.ic_new_logo}
          style={{ borderRadius: BORDER_RADIUS, cursor: 'pointer' }}
          preview={false}
          onClick={() => dispatch(setCurrentPath(DASHBOARD_PATH))}
        />
      </BaseCol>
      {!isSliderOpened ? (
        <S.BurgerCol>
          <S.MobileBurger onClick={toggleSlider} isCross={isSliderOpened} />
        </S.BurgerCol>
      ) : null}
    </BaseRow>
  )
}

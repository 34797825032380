import { CheckCircleOutlined } from '@ant-design/icons'
import { BaseButton } from 'app/components/common/BaseButton'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import { BaseSwitch } from 'app/components/common/BaseSwitch'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { BaseInput } from 'app/components/common/inputs/BaseInput'
import { BORDER_RADIUS_2 } from 'app/constant'
import {
  BORDER_RADIUS,
  FONT_SIZE,
  FONT_WEIGHT,
  PADDING,
} from 'parkway-web-common'
import styled from 'styled-components'

export const RootWrapper = styled(BaseSpace)`
  width: 100%;
  padding: ${PADDING.xs} 0px;
`

export const HeaderWrapper = styled(BaseRow)``

export const BackButton = styled(BaseButton)`
  background-color: rgba(255, 255, 255, 0.1); // need change
  border-radius: ${BORDER_RADIUS};
  border-color: transparent;
  padding: ${PADDING.md};
  transition: all 1s ease;

  & svg {
    fill: var(--primary-color) !important;
  }

  &:hover {
    border-color: var(--primary-color) !important;
    transition: all 1s ease;
  }
`

export const ButtonSeeYourRestaurant = styled(BaseButton)`
  background-color: var(--admin-text-focus-setting-menu-color);
  color: var(--primary-color);
  font-weight: ${FONT_WEIGHT.medium};
  border-color: transparent;
  transition: all 1s ease;
  padding: ${PADDING.xxl} ${PADDING.xs};

  &:hover {
    border-color: var(--primary-color) !important;
    color: var(--primary-color) !important;
    transition: all 1s ease;
  }
`
// FormDataWrapper
export const FormDataWrapper = styled(BaseForm)``

export const InputCustom = styled(BaseInput)`
  font-size: 16px;
  font-weight: ${FONT_WEIGHT.regular};
  font-family: 'Plus Jakarta Sans', sans-serif !important;
  width: 100%;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  color: var(--primary-color);
  border-radius: 0px;
  &:hover {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
  &:focus {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
`
export const ItemPaymentMethod = styled(BaseButton)<{ isSelected?: boolean }>`
  border-style: ${props => (props?.isSelected ? 'dashed' : 'none')};
  border-color: var(--admin-text-focus-setting-menu-color);
  padding: ${PADDING.md};
  background-color: ${props =>
    props?.isSelected ? 'transparent' : 'rgba(255, 255, 255, 0.1)'} !important;
  color: var(--primary-color);
  font-weight: ${props =>
    props?.isSelected ? FONT_WEIGHT.semibold : FONT_WEIGHT.regular};
  &:hover {
    border-style: ${props =>
      props?.isSelected ? 'dashed' : 'none'} !important;
    border-color: var(--admin-text-focus-setting-menu-color) !important;
    background-color: ${props =>
      props?.isSelected
        ? 'transparent'
        : 'rgba(255, 255, 255, 0.1)'} !important ;
    color: var(--primary-color) !important;
    font-weight: ${props =>
      props?.isSelected
        ? FONT_WEIGHT.semibold
        : FONT_WEIGHT.regular} !important;
  }
`

export const LabelInput = styled.div`
  color: #959895;
  font-size: ${FONT_SIZE.xxs};
  font-weight: ${FONT_WEIGHT.regular};
`

export const IconChecked = styled(CheckCircleOutlined)`
  svg {
    fill: var(--admin-text-focus-setting-menu-color);
  }
`
export const SwitchWrapper = styled(BaseRow)`
  padding: ${PADDING.xxxxs} ${PADDING.md};
  border-radius: ${BORDER_RADIUS_2};
  background-color: rgba(255, 255, 255, 0.1);
`

export const SwitchButton = styled(BaseSwitch)`
  &.ant-switch.ant-switch-checked {
    background: #43e4c6; // need change
  }

  &.ant-switch.ant-switch-checked:hover {
    background: #43e4c6; // need change
  }
`

export const TextSubTitle = styled.div`
  font-size: ${FONT_SIZE.xs};
  font-weight: ${FONT_WEIGHT.regular};
  color: var(--admin-text-focus-setting-menu-color);
`

export const SubWrapper = styled(BaseSpace)`
  width: 100%;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  padding: ${PADDING.xxxs} 0px;
`

export const TextCodeTitle = styled.div`
  font-size: ${FONT_SIZE.xs};
  font-weight: ${FONT_WEIGHT.regular};
  color: var(--primary-color);
`

export const OpenTimeWrapper = styled.div`
  padding: 16px 8px;
`

export const CommonWrapper = styled.div`
  padding: 0 8px;
`

export const MessageInput = styled(BaseInput)`
  background: transparent;
  color: #ffffff;
  font-size: 10px;
  width: 100%;
  padding-left: 0;
  &,
  &:focus,
  &:focus-within,
  &:focus-visible {
    border: unset;
    box-shadow: unset;
    outline: none;
  }
  &::placeholder {
    color: #6b7280;
  }
`

import { SearchOutlined } from '@ant-design/icons'
import R from 'app/assets/R'
import {
  Svg1AppeaIcon,
  Svg2AppeaIcon,
  Svg3AppeaIcon,
  Svg4AppeaIcon,
  Svg5AppeaIcon,
} from 'app/assets/svg-assets'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseImage } from 'app/components/common/BaseImage'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { BaseInput } from 'app/components/common/inputs/BaseInput'
import { BORDER_RADIUS } from 'parkway-web-common'
import { ReactNode } from 'react'
import styled from 'styled-components'

// Container for the whole layout
const Layout = styled.div`
  display: flex;
  width: 100%;
  height: 50vh;
  background-color: #f9f9f9;
  font-family: 'Arial', sans-serif;
`

// Sidebar styling
const Sidebar = styled(BaseSpace)`
  width: 80px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
`

// Main content container
const Content = styled.div`
  display: flex;
  width: 100%;
  padding: 20px;
`

const ProductSection = styled(BaseSpace)`
  flex: 3;
  padding-right: 20px;
`

const CartSection = styled.div`
  flex: 1;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`

const SectionTitle = styled.h2`
  font-size: 24px;
  margin-bottom: 10px;
`

const ProductGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 15px;
  overflow: auto;
  height: 300px;
`

const ProductCard = styled.div`
  background-color: #fff;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  text-align: center;
`

const ProductImage = styled.div`
  width: 100%;
  height: 100px;
  background-color: #eee;
  border-radius: 8px;
  margin-bottom: 10px;
`

const ProductName = styled.h4`
  font-size: 16px;
  margin-bottom: 5px;
`

const ProductPrice = styled.p`
  color: #333;
  font-weight: bold;
  margin-bottom: 10px;
`

const CartItem = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  font-size: 14px;
`

const Total = styled.div`
  font-size: 18px;
  font-weight: bold;
  text-align: right;
  margin-top: 20px;
`

const CheckoutButton = styled.button`
  width: 100%;
  background-color: #00d084;
  color: #fff;
  border: none;
  padding: 10px;
  font-size: 16px;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #00a96e;
  }
`

// Mock Data for Products
const products = [
  { id: 1, name: 'Grilled Chicken Pan Soup', price: '$10.50' },
  { id: 2, name: 'Asian Chicken Noodles', price: '$12.00' },
  { id: 3, name: 'BBQ Beef Ramen', price: '$9.50' },
  { id: 4, name: 'Vegetarian Bowl', price: '$11.00' },
  { id: 5, name: 'Spicy Tuna Sandwich', price: '$8.50' },
  { id: 6, name: 'Caesar Salad Bowl', price: '$9.50' },
]

const ItemCategory = ({ title, icon }: { title: string; icon: ReactNode }) => {
  return (
    <BaseSpaceItem size={4} style={{ alignItems: 'center' }}>
      {icon}
      <BaseText children={title} fontSize="xxxs" style={{ color: '#A8A8A8' }} />
    </BaseSpaceItem>
  )
}

const BaseSpaceItem = styled(BaseSpace)`
  svg {
    width: 16px;
    height: 16px;
  }
`

const PreviewLayout = () => {
  return (
    <Layout>
      {/* Sidebar */}
      <Sidebar>
        <BaseImage
          width={50}
          src={R.images.ic_new_logo}
          style={{ borderRadius: BORDER_RADIUS, cursor: 'pointer' }}
          preview={false}
        />
        <BaseSpace>
          <ItemCategory icon={<Svg1AppeaIcon />} title={'Best seller'} />
          <ItemCategory icon={<Svg2AppeaIcon />} title={'Hot Dish'} />
          <ItemCategory icon={<Svg3AppeaIcon />} title={'Vegan'} />
          <ItemCategory icon={<Svg4AppeaIcon />} title={'Drinks'} />
          <ItemCategory icon={<Svg5AppeaIcon />} title={'Dessert'} />
        </BaseSpace>
      </Sidebar>

      {/* Main Content */}
      <Content>
        {/* Product Section */}
        <ProductSection>
          <BaseRow gutter={16} align={'middle'} justify={'space-between'}>
            <BaseCol>
              <BaseRow gutter={4} align={'middle'}>
                <BaseCol>
                  <BaseText
                    children={'Menu'}
                    fontSize="md"
                    fontWeight="semibold"
                  />
                </BaseCol>
                <BaseCol>
                  <BaseText children={'Category'} fontSize="md" />
                </BaseCol>
              </BaseRow>
            </BaseCol>

            <BaseCol>
              <BaseInput
                prefix={<SearchOutlined rev={undefined} />}
                placeholder="Search"
              />
            </BaseCol>
          </BaseRow>
          <ProductGrid>
            {products.map(product => (
              <ProductCard key={product.id}>
                <ProductImage />
                <ProductName>{product.name}</ProductName>
                <ProductPrice>{product.price}</ProductPrice>
              </ProductCard>
            ))}
          </ProductGrid>
        </ProductSection>

        {/* Cart Section */}
        <CartSection>
          <SectionTitle>Your Cart</SectionTitle>
          <CartItem>
            <span>Grilled Chicken Pan Soup</span>
            <span>$10.50</span>
          </CartItem>
          <CartItem>
            <span>BBQ Beef Ramen</span>
            <span>$9.50</span>
          </CartItem>
          <Total>Total: $20.00</Total>
          <CheckoutButton>Proceed to Checkout</CheckoutButton>
        </CartSection>
      </Content>
    </Layout>
  )
}

export default PreviewLayout

/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { CloseOutlined, EditOutlined } from '@ant-design/icons'
import {
  requestGetUpSellById,
  requestPutUpSell,
} from 'app/api/product-management/api'
import {
  IPostUpSell,
  IUpSellProductManagement,
} from 'app/api/product-management/model'
import R from 'app/assets/R'
import { BaseButton } from 'app/components/common/BaseButton'
import { ButtonVariant } from 'app/components/common/BaseButtonVariant'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { CurrencySupportType } from 'app/components/common/CurrencyPicker/type'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { Loading } from 'app/components/common/Loading'
import { IRefModal, ModalComponent } from 'app/components/common/ModalComponent'
import { notificationController } from 'app/controllers/notification-controller'
import { ProductManagementKeys } from 'app/react-query/query-key/product-management'
import { handleUploadFileGetId } from 'app/service/general/service'
import { t } from 'i18next'
import { useEffect, useRef, useState } from 'react'
import { useQueryClient } from 'react-query'
import { HookFormModal } from '../hook'
interface IForm extends IPostUpSell {
  image?: string
  upSellAt?: string
}

export const UpdateModal = ({ item }: { item: IUpSellProductManagement }) => {
  const query = useQueryClient()
  const refModal = useRef<IRefModal>(null)
  const [form] = BaseForm.useForm<IForm>()

  const [loadingSave, setLoadingSave] = useState(false)
  const [loading, setLoading] = useState(false)

  const [locationIdsSelected, setLocationIdsSelected] = useState<string[]>([])
  const [itemIdsSelected, setItemIdsSelected] = useState<string[]>([])
  const [imageUrl, setImageUrl] = useState<string | undefined>(undefined)
  const [fileUpload, setFile] = useState<File | undefined>(undefined)
  const [currency, setCurrency] = useState<CurrencySupportType>('USD')

  const openModal = () => {
    refModal.current?.open?.()
  }

  const hideModal = () => {
    refModal.current?.hide?.()
  }

  const getDetail = async () => {
    if (!item?.id) {
      hideModal()
      return
    }

    try {
      setLoading(true)

      const res: IUpSellProductManagement = await requestGetUpSellById(item?.id)

      form.setFieldsValue({
        name: res?.name,
        image: res?.image,
        price: +(res?.price ?? 0),
        upSellAt: res?.upsellAt,
        displayName: res?.displayName,
      })

      setLocationIdsSelected(res?.presentAtLocationIds ?? [])
      setItemIdsSelected(res?.itemIds ?? [])
      setImageUrl(res?.image)

      setCurrency(res?.currency ?? 'USD')
    } catch (error) {
      console.log({ error })
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getDetail()
  }, [item])

  const onSave = async (values?: IForm) => {
    if (!item?.id) return
    try {
      setLoadingSave(true)
      if (fileUpload) {
        const imageId = await handleUploadFileGetId(fileUpload)
        if (imageId) {
          const res = await requestPutUpSell(item?.id, {
            imageId,
            itemIds: itemIdsSelected,
            locationIds: locationIdsSelected,
            name: values?.name,
            price: values?.price,
            upsellAt: values?.upSellAt,
            displayName: values?.displayName,
            currency,
          })

          if (res) {
            setLocationIdsSelected([])
            setItemIdsSelected([])
            form.resetFields()
            query.invalidateQueries({
              queryKey: ProductManagementKeys.allUpSell,
            })

            notificationController.success({
              message: t(R.strings.save_success),
            })

            hideModal()
          }
        }
      } else {
        const res = await requestPutUpSell(item?.id, {
          itemIds: itemIdsSelected,
          locationIds: locationIdsSelected,
          name: values?.name,
          price: values?.price,
          upsellAt: values?.upSellAt,
          displayName: values?.displayName,
          currency,
        })
        if (res) {
          setLocationIdsSelected([])
          setItemIdsSelected([])
          form.resetFields()
          query.invalidateQueries({
            queryKey: ProductManagementKeys.allUpSell,
          })

          notificationController.success({
            message: t(R.strings.save_success),
          })

          hideModal()
        }
      }
    } catch (error) {
      console.log({ error })
    } finally {
      setLoadingSave(false)
    }
  }

  return (
    <ModalComponent
      ref={refModal}
      titleModal="Update Up Sell"
      renderContent={
        <BaseSpace>
          <BaseForm form={form} onFinish={onSave}>
            <HookFormModal
              form={form}
              locationIdsSelected={locationIdsSelected}
              setLocationIdsSelected={setLocationIdsSelected}
              itemIdsSelected={itemIdsSelected}
              setItemIdsSelected={setItemIdsSelected}
              imageUrl={imageUrl}
              setImageUrl={setImageUrl}
              fileUpload={fileUpload}
              setFile={setFile}
              currency={currency}
              setCurrency={setCurrency}
            />
          </BaseForm>

          {loading ? <Loading /> : null}
        </BaseSpace>
      }
      footer={
        <div style={{ padding: '0px 20px', width: '100%' }}>
          <ButtonVariant
            onClick={form.submit}
            style={{ width: '100%' }}
            loading={loadingSave}
            disabled={!itemIdsSelected?.length}
          >
            <BaseText children={'Update Up Sell'} fontWeight="semibold" />
          </ButtonVariant>
        </div>
      }
      rightHeader={
        <BaseCol>
          <CloseOutlined rev={undefined} onClick={hideModal} />
        </BaseCol>
      }
      buttonOpenCustom={
        <BaseButton
          icon={<EditOutlined rev={undefined} />}
          children={'Edit'}
          type="text"
          onClick={openModal}
        />
      }
    />
  )
}

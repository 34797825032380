import {
  DeleteOutlined,
  EditOutlined,
  MoreOutlined,
  SearchOutlined,
} from '@ant-design/icons'
import { requestChangeLogoUser } from 'app/api/auth'
import R from 'app/assets/R'
import {
  SvgMapPinIcon,
  SvgPrinterIcon,
  SvgSortAscendingIcon,
  SvgSortDescIcon,
} from 'app/assets/svg-assets'
import { BaseButton } from 'app/components/common/BaseButton'
import { ButtonVariant } from 'app/components/common/BaseButtonVariant'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseCollapse } from 'app/components/common/BaseCollapse'
import { BasePopover } from 'app/components/common/BasePopover'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import UploadImage from 'app/components/common/BaseUpload/components/UploadImage'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { BaseInput } from 'app/components/common/inputs/BaseInput'
import { useAppDispatch, useAppSelector } from 'app/redux/hooks'
import { getUserInfo } from 'app/redux/slices/userSlice'
import { requestUploadImage } from 'app/service/general/api'
import { ResponseUploadFile } from 'app/service/general/model'
import { convertColor } from 'app/styles/themes/themeVariables'
import { isNil } from 'lodash'
import { BORDER_RADIUS, PADDING } from 'parkway-web-common'
import { memo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { LanguagePicker } from './LanguagePicker'
import * as S from './styles'
import { BaseSwitch } from 'app/components/common/BaseSwitch'

const ItemInfo = ({ label, value }: { label: string; value?: string }) => {
  return (
    <BaseRow justify={'space-between'} gutter={16} align={'middle'}>
      <BaseCol>
        <BaseText
          style={{ color: '#818181', fontFamily: 'Raleway' }}
          fontWeight="medium"
          fontSize="xxxs"
        >
          {label}
        </BaseText>
      </BaseCol>

      <BaseCol>
        <BaseText fontWeight="medium" children={isNil(value) ? '-' : value} />
      </BaseCol>
    </BaseRow>
  )
}

const ItemInfoGeneral = ({
  label,
  value,
}: {
  label: string
  value: string
}) => {
  return (
    <BaseSpace size={4}>
      <BaseText children={label} fontWeight="semibold" />
      <BaseText
        children={value}
        fontWeight="medium"
        opacity="0.7"
        fontSize="xxxs"
      />
    </BaseSpace>
  )
}

const YourInformationLayout = () => {
  const dispatch = useAppDispatch()
  const user = useAppSelector(state => state.user)
  const { t } = useTranslation()
  const [restaurantLocation, setRestaurantLocation] = useState('')
  const [loading, setLoading] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [data, setData] = useState({ branch: 'Kiosify', phone: '', email: '' })
  const [dataTemp, setDataTemp] = useState({ branch: '', phone: '', email: '' })
  const handleChangeImage = async (imageFile: File) => {
    try {
      if (!imageFile) return
      setLoading(true)
      const body = new FormData()
      body.append('image', imageFile)

      const res: ResponseUploadFile = await requestUploadImage(body)
      if (res?.data && res.data?.image) {
        await requestChangeLogoUser(res.data?.image)
        dispatch(getUserInfo())
      }
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const onCancel = () => {
    setIsEdit(false)
    setDataTemp(data)
  }

  const onPressSave = () => {
    setData(dataTemp)
    setIsEdit(false)
  }

  const onPressOpenEdit = () => {
    setDataTemp(data)
    setIsEdit(true)
  }

  return (
    <S.RootWrapper>
      {/* info */}
      <S.InfoWrapper
        gutter={[16, 16]}
        align={'middle'}
        justify={'space-between'}
      >
        <BaseCol xl={5}>
          <S.InfoImageWrapper>
            <UploadImage
              circleLayout
              src={user?.user?.logo ?? R.images.ic_logo_kiosk_master}
              isLoading={loading}
              onChange={handleChangeImage}
              width="150px"
              height="150px"
              textUpload={'Upload logo'}
            />
          </S.InfoImageWrapper>
        </BaseCol>
        <BaseCol xl={19}>
          <BaseSpace>
            <BaseRow justify={'space-between'} align={'middle'} gutter={16}>
              <BaseCol>
                <BaseSpace size={4}>
                  <BaseText
                    children={data?.branch}
                    fontSize="md"
                    fontWeight="bold"
                    fontFamily="Raleway"
                  />

                  <BaseText children={'76 San Pablo Ave'} fontWeight="medium" />
                </BaseSpace>
              </BaseCol>

              {!isEdit ? (
                <BaseCol>
                  <BaseButton onClick={onPressOpenEdit} children={'Edit'} />
                </BaseCol>
              ) : null}
            </BaseRow>

            <ItemInfo label={t(R.strings.language)} value={'English'} />
            <ItemInfo label={t(R.strings.phone)} value={data?.phone} />
            <ItemInfo label={t(R.strings.email)} value={data?.email} />
          </BaseSpace>
        </BaseCol>
      </S.InfoWrapper>

      {isEdit ? (
        <BaseSpace>
          <S.InfoContentItemWrapper>
            <S.InfoContentItemTitle>
              {t(R.strings.dashboard_common_branch_name)}
            </S.InfoContentItemTitle>
            <S.SpaceVertical />
            <BaseInput
              value={dataTemp?.branch}
              onChange={e =>
                setDataTemp(prev => ({ ...prev, branch: e?.target?.value }))
              }
              style={{ padding: 10 }}
            />
          </S.InfoContentItemWrapper>
          <BaseRow gutter={[16, 16]} align={'middle'}>
            <BaseCol xl={12}>
              <S.InfoContentItemWrapper>
                <S.InfoContentItemTitle>
                  {t(R.strings.information_location)}
                </S.InfoContentItemTitle>
                <S.SpaceVertical />
                <S.InfoContentItemDescription>
                  <S.CustomSelectionRestaurant
                    onChange={setRestaurantLocation}
                    value={restaurantLocation}
                    formItemName={false}
                    allowSelectAll={false}
                    selectFirstValue
                  />
                </S.InfoContentItemDescription>
              </S.InfoContentItemWrapper>
            </BaseCol>

            <BaseCol xl={12}>
              <S.InfoContentItemWrapper>
                <S.InfoContentItemTitle>
                  {t(R.strings.language)}
                </S.InfoContentItemTitle>
                <S.SpaceVertical />
                <S.InfoContentItemDescription>
                  <LanguagePicker
                    initValue={user?.user?.language}
                    type="user"
                  />
                </S.InfoContentItemDescription>
              </S.InfoContentItemWrapper>
            </BaseCol>
          </BaseRow>
          <BaseRow gutter={[16, 16]} align={'middle'}>
            <BaseCol xl={12}>
              <S.InfoContentItemWrapper>
                <S.InfoContentItemTitle>
                  {t(R.strings.information_phone_no)}
                </S.InfoContentItemTitle>
                <S.SpaceVertical />
                <BaseInput
                  value={dataTemp?.phone}
                  onChange={e =>
                    setDataTemp(prev => ({ ...prev, phone: e?.target?.value }))
                  }
                  style={{ padding: 10 }}
                  placeholder="Enter phone"
                />
              </S.InfoContentItemWrapper>
            </BaseCol>
            <BaseCol xl={12}>
              <S.InfoContentItemWrapper>
                <S.InfoContentItemTitle>
                  {t(R.strings.information_email)}
                </S.InfoContentItemTitle>
                <S.SpaceVertical />
                <BaseInput
                  value={dataTemp?.email}
                  onChange={e =>
                    setDataTemp(prev => ({ ...prev, email: e?.target?.value }))
                  }
                  style={{ padding: 10 }}
                  placeholder="Enter email"
                />
              </S.InfoContentItemWrapper>
            </BaseCol>
          </BaseRow>

          <BaseRow justify={'end'}>
            <BaseCol>
              <BaseRow gutter={16} align={'middle'}>
                <BaseCol>
                  <BaseButton
                    style={{ height: 40 }}
                    onClick={onCancel}
                    children={'Cancel'}
                  />
                </BaseCol>

                <BaseCol>
                  <ButtonVariant
                    onClick={onPressSave}
                    children={
                      <BaseText children={'Save Changes'} fontWeight="medium" />
                    }
                  />
                </BaseCol>
              </BaseRow>
            </BaseCol>
          </BaseRow>
        </BaseSpace>
      ) : null}

      {/* genera */}
      <BaseCollapseWrapper
        items={[
          {
            label: (
              <BaseText children={'Manage Locations'} fontWeight="semibold" />
            ),

            children: (
              <BaseSpace>
                <BaseRow align={'middle'} justify={'space-between'} gutter={16}>
                  <BaseCol xl={16}>
                    <BaseForm.Item>
                      <BaseInput
                        placeholder="Search Location"
                        prefix={<SearchOutlined rev={undefined} />}
                        style={{ padding: '8px 10px', width: '100%' }}
                      />
                    </BaseForm.Item>
                  </BaseCol>

                  <BaseCol>
                    <BaseRow gutter={5} align={'middle'}>
                      <BaseCol>
                        <BaseButton
                          icon={
                            <SvgSortDescIcon
                              style={{ width: 26, height: 26 }}
                            />
                          }
                          type="text"
                        />
                      </BaseCol>

                      <BaseCol>
                        <BaseButton
                          icon={
                            <SvgSortAscendingIcon
                              style={{ width: 26, height: 26 }}
                            />
                          }
                          type="text"
                        />
                      </BaseCol>

                      <BaseCol>
                        <ButtonVariant
                          children={
                            <BaseText
                              children={'Add Location'}
                              fontWeight="semibold"
                            />
                          }
                        />
                      </BaseCol>
                    </BaseRow>
                  </BaseCol>
                </BaseRow>
                {[1, 2, 3].map((_, index) => {
                  return (
                    <BaseCollapseWrapper
                      items={[
                        {
                          key: index + 1,
                          label: (
                            <BaseRow gutter={16} align={'middle'}>
                              <BaseCol>
                                <SvgMapPinIcon />
                              </BaseCol>
                              <BaseCol>
                                <BaseText
                                  fontWeight="semibold"
                                  children={`Location ${index + 1}`}
                                />
                              </BaseCol>
                            </BaseRow>
                          ),

                          children: (
                            <BaseRow justify={'space-between'} gutter={16}>
                              <BaseCol xl={22}>
                                <BaseSpace>
                                  <BaseRow gutter={16} align={'top'}>
                                    <BaseCol xl={12}>
                                      <ItemInfoGeneral
                                        label="Location Name"
                                        value={`Location ${index + 1}`}
                                      />
                                    </BaseCol>
                                    <BaseCol xl={12}>
                                      <ItemInfoGeneral
                                        label="Time Zone"
                                        value="4:00am"
                                      />
                                    </BaseCol>
                                  </BaseRow>
                                  <BaseRow gutter={16} align={'top'}>
                                    <BaseCol xl={12}>
                                      <ItemInfoGeneral
                                        label="Store Hours"
                                        value="8:00am - 9:00pm"
                                      />
                                    </BaseCol>
                                    <BaseCol xl={12}>
                                      <ItemInfoGeneral
                                        label="Subscription"
                                        value="Annual"
                                      />
                                    </BaseCol>
                                  </BaseRow>
                                  <BaseRow gutter={16} align={'top'}>
                                    <BaseCol xl={12}>
                                      <ItemInfoGeneral
                                        label="Launch Code"
                                        value="4eZ6yth"
                                      />
                                    </BaseCol>
                                    <BaseCol xl={12}>
                                      <ItemInfoGeneral
                                        label="Products"
                                        value="Kiosk, KDS"
                                      />
                                    </BaseCol>
                                  </BaseRow>
                                </BaseSpace>
                              </BaseCol>

                              <BaseCol>
                                <BasePopover
                                  trigger={'click'}
                                  content={
                                    <BaseSpace>
                                      <BaseButton
                                        icon={<EditOutlined rev={undefined} />}
                                        children={'Edit'}
                                        type="text"
                                      />

                                      <BaseButton
                                        style={{ color: 'red' }}
                                        icon={
                                          <DeleteOutlined rev={undefined} />
                                        }
                                        children={'Delete'}
                                        type="text"
                                      />
                                    </BaseSpace>
                                  }
                                >
                                  <BaseButton
                                    type="text"
                                    icon={<MoreOutlined rev={undefined} />}
                                  />
                                </BasePopover>
                              </BaseCol>
                            </BaseRow>
                          ),
                        },
                      ]}
                      expandIconPosition="right"
                      defaultActiveKey={1}
                    />
                  )
                })}
              </BaseSpace>
            ),
          },
        ]}
        expandIconPosition="right"
      />

      <BaseCollapseWrapper
        items={[
          {
            label: (
              <BaseText
                children={'Printer Information'}
                fontWeight="semibold"
              />
            ),

            children: (
              <BaseSpace>
                <BaseRow align={'middle'} justify={'space-between'} gutter={16}>
                  <BaseCol xl={16}>
                    <BaseForm.Item>
                      <BaseInput
                        placeholder="Search Printer"
                        prefix={<SearchOutlined rev={undefined} />}
                        style={{ padding: '8px 10px', width: '100%' }}
                      />
                    </BaseForm.Item>
                  </BaseCol>

                  <BaseCol>
                    <BaseRow gutter={5} align={'middle'}>
                      <BaseCol>
                        <BaseButton
                          icon={
                            <SvgSortDescIcon
                              style={{ width: 26, height: 26 }}
                            />
                          }
                          type="text"
                        />
                      </BaseCol>

                      <BaseCol>
                        <BaseButton
                          icon={
                            <SvgSortAscendingIcon
                              style={{ width: 26, height: 26 }}
                            />
                          }
                          type="text"
                        />
                      </BaseCol>

                      <BaseCol>
                        <ButtonVariant
                          children={
                            <BaseText
                              children={'Add Printer'}
                              fontWeight="semibold"
                            />
                          }
                        />
                      </BaseCol>
                    </BaseRow>
                  </BaseCol>
                </BaseRow>
                {[1, 2, 3].map((_, index) => {
                  return (
                    <BaseCollapseWrapper
                      items={[
                        {
                          key: index + 1,
                          label: (
                            <BaseRow gutter={16} align={'middle'}>
                              <BaseCol>
                                <SvgPrinterIcon />
                              </BaseCol>
                              <BaseCol>
                                <BaseText
                                  fontWeight="semibold"
                                  children={`Printer ${index + 1}`}
                                />
                              </BaseCol>
                            </BaseRow>
                          ),
                          extra: (
                            <BaseText
                              children={'Ready'}
                              style={{ color: '#0090FF' }}
                              fontWeight="medium"
                            />
                          ),
                          children: (
                            <BaseSpace>
                              <BaseRow justify={'space-between'} gutter={16}>
                                <BaseCol xl={22}>
                                  <BaseSpace>
                                    <ItemInfoGeneral
                                      label="Printer Name"
                                      value={`Printer ${index + 1}`}
                                    />
                                    <ItemInfoGeneral
                                      label="Printer Type"
                                      value="Laser Printer"
                                    />
                                  </BaseSpace>
                                </BaseCol>

                                <BaseCol>
                                  <BasePopover
                                    trigger={'click'}
                                    content={
                                      <BaseSpace>
                                        <BaseButton
                                          icon={
                                            <EditOutlined rev={undefined} />
                                          }
                                          children={'Edit'}
                                          type="text"
                                        />

                                        <BaseButton
                                          style={{ color: 'red' }}
                                          icon={
                                            <DeleteOutlined rev={undefined} />
                                          }
                                          children={'Delete'}
                                          type="text"
                                        />
                                      </BaseSpace>
                                    }
                                  >
                                    <BaseButton
                                      type="text"
                                      icon={<MoreOutlined rev={undefined} />}
                                    />
                                  </BasePopover>
                                </BaseCol>
                              </BaseRow>

                              <BaseRow
                                justify={'space-between'}
                                gutter={16}
                                align={'middle'}
                              >
                                <BaseCol>
                                  <ItemInfoGeneral
                                    label="Use this printer for receipts"
                                    value="When on, this printer will also print bills, and open cash drawers."
                                  />
                                </BaseCol>
                                <BaseCol>
                                  <SwitchButton />
                                </BaseCol>
                              </BaseRow>
                            </BaseSpace>
                          ),
                        },
                      ]}
                      expandIconPosition="right"
                      defaultActiveKey={1}
                    />
                  )
                })}
              </BaseSpace>
            ),
          },
        ]}
        expandIconPosition="right"
      />
    </S.RootWrapper>
  )
}

export default memo(YourInformationLayout)

const BaseCollapseWrapper = styled(BaseCollapse)`
  width: 100%;

  .ant-collapse-content {
    border-top: 1px solid ${convertColor.neutralBlack9Color};
    background-color: ${convertColor.backgroundColor};
  }

  .ant-collapse-header {
    background-color: var(--background-color);
    border-radius: ${BORDER_RADIUS}!important;
    align-items: center !important;
  }

  .ant-collapse-header[aria-expanded='true'] {
    border-radius: ${BORDER_RADIUS}!important;
    border-bottom-left-radius: ${BORDER_RADIUS} !important;
    border-bottom-right-radius: ${BORDER_RADIUS} !important;
  }

  .header-content {
    padding: ${PADDING.md};
    background-color: #f9fafb;
  }
`

export const SwitchButton = styled(BaseSwitch)`
  &.ant-switch.ant-switch-checked {
    background: #43e4c6; // need change
  }

  &.ant-switch.ant-switch-checked:hover {
    background: #43e4c6; // need change
  }
`

import {
  requestGetRestaurantDetailV2,
  requestUpdateRestaurantListV2,
} from 'app/api/restaurant/api'
import {
  DayOfTheWeekEnum,
  IBusinessHours,
  IPayloadCreateRestaurantV2,
  IRestaurant,
} from 'app/api/restaurant/model'
import R from 'app/assets/R'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { URL_SETTING } from 'app/containers/admin/Settings/constant'
import { notificationController } from 'app/controllers/notification-controller'
import { LanguageSupportType } from 'app/i18n/constant'
import { RestaurantKeys } from 'app/react-query/query-key/restaurant'
import { useAppDispatch, useAppSelector } from 'app/redux/hooks'
import { setPathSetting } from 'app/redux/slices/pathSettingSlice'
import { isEqual } from 'lodash'
import moment from 'moment'
import { getRandomUuid } from 'parkway-web-common'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { IOpenTimeItem, ITimeOpenRestaurant } from './type'
import {
  getOrdinalNumberOfDayOfTheWeek,
  getTextDayOfDayOfTheWeek,
  timeToMilliseconds,
} from 'app/hook'
import { CurrencySupportType } from 'app/components/common/CurrencyPicker/type'
interface IForm {
  businessName?: string
  phoneNumber?: string
  email?: string
  timeZone?: string
  merchantId?: string
  languageCode?: string
  currency?: string
  addressLine1?: string
  addressLine2?: string
  locality?: string
  country?: string
  postalCode?: string
}

export const useHook = () => {
  const { t } = useTranslation()
  const query = useQueryClient()
  const [form] = BaseForm.useForm<IForm>()
  const dispatch = useAppDispatch()

  const [isLoading, setIsLoading] = useState(false)
  const [language, setLanguage] = useState<LanguageSupportType>('en')
  const [currency, setCurrency] = useState<CurrencySupportType>('USD')

  const [days, setDays] = useState<IOpenTimeItem[]>(
    Object.values(DayOfTheWeekEnum)?.map(itm => {
      const times: ITimeOpenRestaurant[] = []
      return {
        checked: false,
        dayId: `${getOrdinalNumberOfDayOfTheWeek(itm)}`,
        title: t(getTextDayOfDayOfTheWeek(itm)),
        key: itm,
        times,
      }
    }) ?? [],
  )

  const dataRestaurant = useAppSelector(
    state => state.pathSetting,
  )?.restaurantData

  const convertHoursToDays = (listTime: IBusinessHours[]) => {
    const res: IOpenTimeItem[] = Object.values(DayOfTheWeekEnum)?.map(itm => {
      const listTimeOfDayExist = listTime?.filter(
        time => time.dayOfWeek === itm,
      )
      const checked = !!listTimeOfDayExist?.length

      let times: ITimeOpenRestaurant[] = []

      if (listTimeOfDayExist?.length) {
        times = listTimeOfDayExist?.map(itemTime => {
          return {
            endTime: itemTime?.endLocalTime
              ? timeToMilliseconds(itemTime?.endLocalTime)
              : new Date(),
            startTime: itemTime?.startLocalTime
              ? timeToMilliseconds(itemTime?.startLocalTime)
              : new Date(),
            id: itemTime?.id ?? getRandomUuid(),
          }
        })
      }

      return {
        checked,
        dayId: `${getOrdinalNumberOfDayOfTheWeek(itm)}`,
        title: t(getTextDayOfDayOfTheWeek(itm)),
        key: itm,
        times,
      }
    })
    return res
  }

  const getDetail = async () => {
    try {
      if (!dataRestaurant?.id) return
      setIsLoading(true)
      const res: IRestaurant = await requestGetRestaurantDetailV2(
        dataRestaurant?.id,
      )
      if (res) {
        form.setFieldsValue({
          ...res,
          ...res.address,
        })

        if (res?.languageCode) setLanguage(res?.languageCode)
        if (res?.currency) setCurrency(res?.currency)

        setDays(convertHoursToDays(res?.businessHours ?? []))
      }
    } catch (error) {
      console.log({ error })
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    getDetail()
  }, [dataRestaurant])

  const onAddTimeRange = async (key: string) => {
    setDays(prev =>
      prev?.map(itm => {
        if (isEqual(itm?.key, key)) {
          return {
            ...itm,
            times: itm?.times?.concat([
              {
                endTime: timeToMilliseconds('00:00'),
                startTime: timeToMilliseconds('00:00'),
                id: getRandomUuid(),
              },
            ]),
          }
        } else return itm
      }),
    )
  }

  const onDeleteRangeTime = async (id: string, key: string) => {
    setDays(prev =>
      prev?.map(itm => {
        if (isEqual(itm?.key, key)) {
          return {
            ...itm,
            times: itm?.times?.filter(time => !isEqual(time?.id, id)),
          }
        } else return itm
      }),
    )
  }

  const onChangeRangeTime = async ({
    newItem,
    key,
  }: {
    newItem: ITimeOpenRestaurant
    key: string
  }) => {
    setDays(prev =>
      prev?.map(itm => {
        if (isEqual(itm?.key, key)) {
          return {
            ...itm,
            times: itm?.times?.map(time => {
              if (isEqual(time.id, newItem.id)) {
                return {
                  ...time,
                  endTime: timeToMilliseconds(
                    moment(newItem.endTime).format('HH:mm'),
                  ),
                  startTime: timeToMilliseconds(
                    moment(newItem.startTime).format('HH:mm'),
                  ),
                }
              } else return time
            }),
          }
        } else return itm
      }),
    )
  }

  const goBack = () => {
    return dispatch(setPathSetting(URL_SETTING.Restaurant))
  }

  const onSave = async (values: IForm) => {
    try {
      if (!dataRestaurant?.id) return
      setIsLoading(true)
      const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
      let businessHours: IBusinessHours[] = []
      days?.forEach(day => {
        day?.times?.forEach(time => {
          businessHours = businessHours.concat([
            {
              dayOfWeek: day?.key,
              endLocalTime: moment(time.endTime).format('HH:mm:ss'),
              startLocalTime: moment(time.startTime).format('HH:mm:ss'),
            } as IBusinessHours,
          ])
        })
      })
      const payload: IPayloadCreateRestaurantV2 = {
        email: values?.email,
        businessName: values?.businessName,
        phoneNumber: values?.phoneNumber,
        timeZone,
        languageCode: language,
        currency: currency,
        merchantId: 'MLH6GTT0JQH2J',
        businessHours,
        address: {
          country: values.country,
          addressLine1: values.addressLine1,
          locality: values?.locality,
          postalCode: values?.postalCode,
        },
      }
      const res = await requestUpdateRestaurantListV2(
        dataRestaurant?.id,
        payload,
      )

      if (res) {
        query.invalidateQueries({
          queryKey: RestaurantKeys.all,
        })

        notificationController.success({
          message: t(R.strings.save_success),
        })

        goBack()
      } else {
        query.invalidateQueries({
          queryKey: RestaurantKeys.all,
        })

        notificationController.success({
          message: t(R.strings.save_success),
        })

        goBack()
      }
    } catch (error) {
      console.log({ error })
    } finally {
      setIsLoading(false)
    }
  }

  const onChangeLanguage = (lang: LanguageSupportType) => {
    setLanguage(lang)
  }

  return {
    days,
    onAddTimeRange,
    onDeleteRangeTime,
    onChangeRangeTime,
    isLoading: isLoading,
    form,
    onSave,
    goBack,
    language,
    onChangeLanguage,
    currency,
    setCurrency,
  }
}

import { PaddingMain } from 'app/constant'
import { media } from 'parkway-web-common'
import styled from 'styled-components'

export const ScrollWrapper = styled.div`
  min-height: 500px;
  .content-body {
    padding: ${PaddingMain.Desktop.defaultHz} 0;
    padding-top: 0px;
    @media only screen and ${media.xs} {
      padding: ${PaddingMain.Mobile.defaultHz} 0;
    }

    @media only screen and ${media.md} {
      padding: ${PaddingMain.Desktop.defaultHz} 0;
      padding-top: 0px !important;
    }
  }
`

/* eslint-disable @typescript-eslint/no-explicit-any */
import { useAppDispatch, useAppSelector } from 'app/redux/hooks'
import { setCurrentPath } from 'app/redux/slices/pathSlice'
import { isEqual } from 'lodash'
import { colors, useResponsive } from 'parkway-web-common'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { SidebarNavigationItem, sidebarNavigation } from '../sidebar-navigation'
import * as S from './styles'

interface SliderContentProps {
  setCollapsed: (isCollapsed: boolean) => void
  isCollapsed?: boolean
}

const SliderMenu: React.FC<SliderContentProps> = ({
  setCollapsed,
  isCollapsed,
}) => {
  const { t } = useTranslation()
  const { mobileOnly } = useResponsive()

  const dispatch = useAppDispatch()
  const path = useAppSelector(state => state.path).path

  const renderLabel = ({
    nav,
    isSubMenu,
  }: {
    key: string
    isSubMenu?: boolean
    nav: SidebarNavigationItem
  }) => {
    const isSelected = isEqual(path, nav.url)

    const stylesOverwrite = (() => {
      if (!isCollapsed) {
        return
      }
      return {
        height: '30px',
      }
    })()

    return (
      <S.ItemMenuCustomWrapper
        align={'middle'}
        isSelected={isSelected}
        style={stylesOverwrite}
        onClick={() => dispatch(setCurrentPath(`${nav?.url}`))}
      >
        <S.LabelCustom isSelected={isSelected} isSubMenu={isSubMenu}>
          {t(nav.title)}
        </S.LabelCustom>
      </S.ItemMenuCustomWrapper>
    )
  }

  const onClickMenuItem = () => {
    if (mobileOnly) {
      setCollapsed(true)
    }
  }

  return (
    <S.Menu
      mode="inline"
      onClick={onClickMenuItem}
      items={sidebarNavigation.map(nav => {
        const isSubMenu = nav.children?.length
        return {
          key: nav.key,
          title: t(nav.title),
          label: renderLabel({ key: nav.key, isSubMenu: !!isSubMenu, nav }),
          style: {
            padding: 0,
            paddingLeft: 0,
            paddingInline: 0,
            backgroundColor: colors.white,
          },
        }
      })}
    />
  )
}

export default SliderMenu

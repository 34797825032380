import { BaseSpace } from 'app/components/common/BaseSpace'
import styled from 'styled-components'

export const RootWrapper = styled(BaseSpace)`
  width: 100%;
  border: 1px solid #00000040;
  border-radius: 12px;
  margin-top: 8px;
  padding: 20px 16px 20px 16px;
`

export const OpenTimeSelectWrapper = styled(BaseSpace)`
  width: 100%;
`

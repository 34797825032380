import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import { BaseTabs } from 'app/components/common/BaseTabs'
import { BORDER_RADIUS } from 'parkway-web-common'
import styled from 'styled-components'

export const RootWrapper = styled(BaseSpace)`
  padding: 20px;
  border-radius: 12px;
`
export const HeaderWrapper = styled(BaseRow)`
  max-height: 630px;
  width: 100%;
`

export const TabContent = styled(BaseTabs)`
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #00274d;
  }

  .ant-tabs-tab {
    color: #b5b5b5;
  }

  .ant-tabs-ink-bar {
    background: #00274d;
    border-radius: ${BORDER_RADIUS};
  }

  &.ant-tabs-top > .ant-tabs-nav::before {
    border-bottom: 1.5px solid rgba(57, 60, 73, 0.15);
  }
`

import { isEqual } from 'lodash'
import { PADDING, useResponsive } from 'parkway-web-common'
import React, { useMemo, useRef } from 'react'
import { Overlay } from '../../../../../common/Overlay'
import { SliderLogo } from '../SiderLogo'
import SliderMenu from '../SiderMenu'
import { sidebarNavigation } from '../sidebar-navigation'
import * as S from './styles'
import { BaseAvatar } from 'app/components/common/BaseAvatar'
import R from 'app/assets/R'
import { BaseSpace } from 'app/components/common/BaseSpace'
import { BaseImage } from 'app/components/common/BaseImage'
import { BaseButton } from 'app/components/common/BaseButton'

interface MainSliderProps {
  isCollapsed: boolean
  setCollapsed: (isCollapsed: boolean) => void
}

const MainSlider: React.FC<MainSliderProps> = ({
  isCollapsed,
  setCollapsed,
  ...props
}) => {
  const { mobileOnly, tabletOnly } = useResponsive()

  const isCollapsible = useMemo(
    () => mobileOnly && tabletOnly,
    [mobileOnly, tabletOnly],
  )

  const toggleSlider = () => setCollapsed(!isCollapsed)

  const isLastedMenu = useMemo(() => {
    return isEqual(
      location?.pathname,
      sidebarNavigation?.[sidebarNavigation?.length - 1]?.url,
    )
  }, [location?.pathname])

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const ref = useRef<any>(null)

  const heightOfSlide = ref?.current?.clientHeight ?? 0

  console.log({ heightOfSlide })

  return (
    <>
      <S.Slider
        trigger={null}
        collapsed={isCollapsed}
        collapsedWidth={90}
        collapsible={isCollapsible}
        width={240}
        {...props}
      >
        <div ref={ref}>
          <SliderLogo
            isSliderCollapsed={isCollapsed}
            toggleSlider={toggleSlider}
          />
          <S.SliderContent>
            <SliderMenu setCollapsed={setCollapsed} isCollapsed={isCollapsed} />
          </S.SliderContent>
        </div>
        <div
          style={{
            width: '100%',
            backgroundColor: 'white',
            height: heightOfSlide ? `calc(100vh - ${heightOfSlide}px)` : 450,
            borderTopRightRadius: isLastedMenu ? PADDING.md : 0,
            borderBottomRightRadius: PADDING.md,
            flexDirection: 'column',
            display: 'flex',
            justifyContent: 'end',
            alignItems: 'center',
            padding: '20px 0px',
            transition: `all 1s ease`,
          }}
        >
          <BaseSpace style={{ alignItems: 'center' }}>
            <BaseButton type="text">
              <BaseImage
                src={R.images.ic_notification}
                style={{ width: 24, height: 24 }}
                preview={false}
              />
            </BaseButton>
            <BaseAvatar size={50} alt="Khanh" src={R.images.ic_avatar} />
          </BaseSpace>
        </div>
      </S.Slider>
      {mobileOnly && <Overlay onClick={toggleSlider} show={!isCollapsed} />}
    </>
  )
}

export default MainSlider

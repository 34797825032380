import { ArrowLeftOutlined } from '@ant-design/icons'
import { IUpdateKiosk } from 'app/api/kiosk/model'
import { IRestaurant } from 'app/api/restaurant/model'
import R from 'app/assets/R'
import { ButtonVariant } from 'app/components/common/BaseButtonVariant'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { Loading } from 'app/components/common/Loading'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { BaseInput } from 'app/components/common/inputs/BaseInput'
import { LanguagePicker } from 'app/components/header/admin/components/SettingsDropdown/settings-overlay/LanguagePicker'
import { SpaceVertical } from 'app/components/styles'
import { URL_SETTING } from 'app/containers/admin/Settings/constant'
import { subscriptionPlans } from 'app/containers/landing-page/components/desktop-layout/LayoutThree'
import { notificationController } from 'app/controllers/notification-controller'
import { LanguageSupportType } from 'app/i18n/constant'
import { useCreateKiosks } from 'app/react-query/hook/kiosk'
import { useAppDispatch, useAppSelector } from 'app/redux/hooks'
import { setPathSetting } from 'app/redux/slices/pathSettingSlice'
import { getServicePackage } from 'app/service/storage/storage-service'
import { isNil } from 'lodash'
import { formatMoney } from 'parkway-web-common'
import { memo, ReactNode, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as S from './styles'
import SelectionRestaurant from '../../../components/SelectionLocation'

interface IFormKiosk {
  name: string
}

const initData: IFormKiosk = {
  name: '',
}

export enum PaymentMethodEnum {
  cash = 'cash',
  creditCard = 'credit-card',
  giftCard = 'gift-card',
}

interface IPropsItemPaymentMethod {
  isSelected?: boolean
  keyItem: PaymentMethodEnum
  onChange?: (value: PaymentMethodEnum) => void
}

const listPaymentMethod = [
  PaymentMethodEnum.cash,
  PaymentMethodEnum.creditCard,
  PaymentMethodEnum.giftCard,
]

const DefineValueSwitch = {
  [PaymentMethodEnum.cash]: {
    title: R.strings.payment_method_cash,
    description: 'Kiosk accepts cash as payment method',
  },
  [PaymentMethodEnum.creditCard]: {
    title: R.strings.payment_method_credit_card,
    description: 'Kiosk accepts credit cards as payment method',
  },
  [PaymentMethodEnum.giftCard]: {
    title: R.strings.payment_method_gift_card,
    description: 'Kiosk accepts gift cards as payment method',
  },
}

export const ItemPaymentMethod = ({
  isSelected,
  keyItem,
  onChange,
}: IPropsItemPaymentMethod) => {
  const { t } = useTranslation()
  const onChangePaymentMethod = () => {
    if (onChange) {
      onChange?.(keyItem)
    }
  }
  return (
    <BaseRow justify={'space-between'} align={'middle'}>
      <BaseCol>
        <BaseSpace size={4}>
          <BaseText
            children={
              DefineValueSwitch[keyItem]?.title
                ? t(DefineValueSwitch[keyItem]?.title)
                : '-'
            }
            style={{ fontFamily: 'Raleway' }}
            fontWeight="medium"
          />
          <BaseText
            children={DefineValueSwitch[keyItem]?.description ?? '-'}
            style={{ color: '#818181', fontFamily: 'Raleway' }}
            fontWeight="medium"
            fontSize="xxxs"
          />
        </BaseSpace>
      </BaseCol>
      <BaseCol>
        <S.SwitchButton checked={isSelected} onChange={onChangePaymentMethod} />
      </BaseCol>
    </BaseRow>
  )
}

const ItemInfo = ({
  label,
  value,
}: {
  label: string
  value?: string | ReactNode
}) => {
  return (
    <BaseRow justify={'space-between'} gutter={16} align={'middle'}>
      <BaseCol>
        <BaseText
          style={{ color: '#818181', fontFamily: 'Raleway' }}
          fontWeight="medium"
          fontSize="xxxs"
        >
          {label}
        </BaseText>
      </BaseCol>

      <BaseCol>
        <BaseText fontWeight="medium" children={isNil(value) ? '-' : value} />
      </BaseCol>
    </BaseRow>
  )
}

const AddYourKioskLayout = () => {
  const { t, i18n } = useTranslation()
  const [form] = BaseForm.useForm<IFormKiosk>()

  const kioskUpdateItem = useAppSelector(
    state => state.pathSetting,
  ).kioskUpdateItem

  const dispatch = useAppDispatch()

  const goBack = () => {
    dispatch(setPathSetting(URL_SETTING.Kiosks))
  }

  const packageData = getServicePackage()
  const [location, setLocation] = useState<string | undefined>(
    kioskUpdateItem?.location?.id,
  )

  const [paymentMethod, setPaymentMethod] = useState<PaymentMethodEnum[]>([])
  const [redeemLoyalty, setRedeemLoyalty] = useState<boolean>(false)
  const [redeemDiscount, setRedeemDiscount] = useState<boolean>(true)
  const [language, setLanguage] = useState<LanguageSupportType>(
    kioskUpdateItem?.language ?? 'en',
  )
  const [isActive, setIsActive] = useState(false)

  // const [images, setImages] = useState<IImage[]>([])

  const servicePackage = useMemo(() => {
    const packageExist = subscriptionPlans().find(
      item => item?.package === packageData,
    )

    if (packageExist) {
      return packageExist
    }

    return subscriptionPlans()[0]
  }, [packageData, subscriptionPlans, i18n.language])

  const { mutateAsync: mutateAsyncAddKiosk, isLoading: loadingUpdate } =
    useCreateKiosks()

  const onSubmit = async (value: IFormKiosk) => {
    if (!servicePackage) {
      notificationController.error({
        message: t(R.strings.error_message_service_package),
      })

      return
    }

    if (!location) {
      notificationController.error({
        message: t(R.strings.error_message_select_location),
      })

      return
    }

    if (paymentMethod.length === 0) {
      notificationController.error({
        message: t(R.strings.error_message_select_payment_method),
      })

      return
    }

    const dataToSend: IUpdateKiosk = {
      name: value.name,
      type: 'kiosk',
      location: location,
      payment_mode: paymentMethod,
      pricing_tier: `${servicePackage.priceNumber}`,
      reedem_loyalty: redeemLoyalty,
      reedem_discount: redeemDiscount,
      status: isActive,
      language,
    }
    await mutateAsyncAddKiosk(dataToSend)
  }

  const changePaymentMethod = (value: PaymentMethodEnum) => {
    const index = paymentMethod.findIndex(item => item === value)
    if (index !== -1) {
      const newPaymentMethod = paymentMethod.filter(item => item !== value)
      setPaymentMethod(newPaymentMethod)
    } else {
      setPaymentMethod([...paymentMethod, value])
    }
  }

  const onChangeRedeemLoyalty = () => {
    setRedeemLoyalty(!redeemLoyalty)
  }

  const onChangeRedeemDiscount = () => {
    setRedeemDiscount(!redeemDiscount)
  }

  const onChangeLanguageKiosk = (lang: LanguageSupportType) => {
    setLanguage(lang)
  }

  // const onPressAddImage = () => {
  //   setImages(prev => prev?.concat([{}]))
  // }

  // const onPressDeleteImage = (index: number) => {
  //   setImages(prev => prev?.filter((_, idx) => idx !== index))
  // }

  const onChangeLocation = (value: string) => {
    setLocation(value)
  }

  const onChangeLocationItem = (item: IRestaurant) => {
    setLanguage(item?.languageCode ?? 'en')
  }

  return (
    <S.RootWrapper>
      <S.HeaderWrapper justify={'space-between'} align={'middle'}>
        <BaseCol>
          <BaseRow gutter={8} align={'middle'}>
            <BaseCol>
              <S.BackButton onClick={goBack}>
                <ArrowLeftOutlined rev={undefined} />
              </S.BackButton>
            </BaseCol>
            <BaseCol>
              <BaseText
                fontWeight="semibold"
                fontSize="xl"
                fontFamily="Raleway"
              >
                {t(R.strings.kiosk_add_new_kiosk)}
              </BaseText>
            </BaseCol>
          </BaseRow>
        </BaseCol>

        <BaseCol>
          <ButtonVariant onClick={form.submit}>
            <BaseText children={t(R.strings.save)} />
          </ButtonVariant>
        </BaseCol>
      </S.HeaderWrapper>

      <S.FormDataWrapper
        form={form}
        initialValues={initData}
        onFinish={onSubmit}
      >
        <BaseSpace>
          <ItemInfo
            label={t(R.strings.kiosk_kiosk_name)}
            value={
              <BaseForm.Item name={'name'}>
                <BaseInput placeholder={t(R.strings.kiosk_kiosk_name)} />
              </BaseForm.Item>
            }
          />
          <ItemInfo
            label={t(R.strings.kiosk_your_subscription)}
            value={`$${formatMoney(servicePackage.price)}/${
              servicePackage.title
            }`}
          />
          <ItemInfo label={'Transaction Fee'} value={'$0.10/transaction'} />

          <ItemInfo
            label={t(R.strings.restaurant)}
            value={
              <SelectionRestaurant
                onChange={onChangeLocation}
                value={location}
                allowSelectAll={false}
                onChangeItem={onChangeLocationItem}
              />
            }
          />

          <ItemInfo
            label={t(R.strings.language)}
            value={
              <LanguagePicker
                onChange={onChangeLanguageKiosk}
                initValue={language}
              />
            }
          />
        </BaseSpace>

        <S.FormDataWrapper>
          <BaseSpace direction="vertical" size={10}>
            <BaseSpace>
              {listPaymentMethod.map((keyItem, index) => {
                const isSelected =
                  paymentMethod.findIndex(
                    keySelected => keySelected === keyItem,
                  ) !== -1
                return (
                  <ItemPaymentMethod
                    isSelected={isSelected}
                    key={index}
                    keyItem={keyItem}
                    onChange={changePaymentMethod}
                  />
                )
              })}
            </BaseSpace>

            <BaseRow justify={'space-between'} align={'middle'}>
              <BaseCol>
                <BaseSpace size={4}>
                  <BaseText
                    children={t(R.strings.kiosk_redeem_loyalty)}
                    style={{ fontFamily: 'Raleway' }}
                    fontWeight="medium"
                  />
                  <BaseText
                    children={
                      'Customers can redeem their loyalty rewards earned from previous purchases'
                    }
                    style={{ color: '#818181', fontFamily: 'Raleway' }}
                    fontWeight="medium"
                    fontSize="xxxs"
                  />
                </BaseSpace>
              </BaseCol>
              <BaseCol>
                <S.SwitchButton
                  checked={redeemLoyalty}
                  onChange={onChangeRedeemLoyalty}
                />
              </BaseCol>
            </BaseRow>

            <BaseRow justify={'space-between'} align={'middle'}>
              <BaseCol>
                <BaseSpace size={4}>
                  <BaseText
                    children={t(R.strings.kiosk_redeem_discount)}
                    style={{ fontFamily: 'Raleway' }}
                    fontWeight="medium"
                  />
                  <BaseText
                    children={
                      'Customers can redeem their discount coupons through this kiosk'
                    }
                    style={{ color: '#818181', fontFamily: 'Raleway' }}
                    fontWeight="medium"
                    fontSize="xxxs"
                  />
                </BaseSpace>
              </BaseCol>
              <BaseCol>
                <S.SwitchButton
                  checked={redeemDiscount}
                  onChange={onChangeRedeemDiscount}
                />
              </BaseCol>
            </BaseRow>

            <BaseRow justify={'space-between'} align={'middle'}>
              <BaseCol>
                <BaseSpace size={4}>
                  <BaseText
                    children={'Turn on Kiosk'}
                    style={{ fontFamily: 'Raleway' }}
                    fontWeight="medium"
                  />
                  <BaseText
                    children={
                      'Kiosks can be turned on/off when in use or not in use'
                    }
                    style={{ color: '#818181', fontFamily: 'Raleway' }}
                    fontWeight="medium"
                    fontSize="xxxs"
                  />
                </BaseSpace>
              </BaseCol>
              <BaseCol>
                <S.SwitchButton checked={isActive} onChange={setIsActive} />
              </BaseCol>
            </BaseRow>

            {/* <BaseSpace
                style={{
                  padding: PADDING.xs,
                  borderRadius: 12,
                  border: '1px solid #00000040',
                }}
              >
                <BaseSpace size={8}>
                  <BaseText
                    children={'Manage Kiosk Popups'}
                    fontWeight="bold"
                    fontFamily="Raleway"
                  />
                  <BaseText
                    children={'Images will popup when store is closed.'}
                    fontSize="xxxs"
                    fontWeight="semibold"
                    style={{ color: '#818181' }}
                  />
                </BaseSpace>

                {images?.map((item, index) => {
                  return (
                    <BaseCollapseWrapper
                      items={[
                        {
                          label: (
                            <BaseText
                              children={`Image ${index + 1}`}
                              fontWeight="semibold"
                            />
                          ),
                          children: (
                            <BaseRow gutter={[16, 16]} align={'middle'}>
                              <BaseCol xl={8}>
                                <div
                                  style={{
                                    height: 200,
                                    border: `1px solid ${
                                      item?.file ? 'transparent' : '#EFEFEF'
                                    }`,
                                  }}
                                >
                                  <UploadImage src={item?.file} />
                                </div>
                              </BaseCol>
                              <BaseCol
                                xl={16}
                                style={{
                                  height: 200,
                                }}
                              >
                                <div
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'space-between',
                                    height: '100%',
                                  }}
                                >
                                  <BaseRow>
                                    <BaseSpace size={4}>
                                      <BaseText
                                        children={'Popup every'}
                                        fontSize="xxxs"
                                        fontWeight="semibold"
                                        colorText="neutralBlack9Color"
                                        opacity="0.7"
                                        fontFamily="Raleway"
                                      />
                                      <BaseSelect
                                        placeholder={'Choose time'}
                                        options={[
                                          { label: 5, value: 5 },
                                          { label: 10, value: 10 },
                                          { label: 15, value: 15 },
                                          { label: 20, value: 20 },
                                          { label: 25, value: 25 },
                                          { label: 30, value: 30 },
                                          { label: 35, value: 35 },
                                        ]}
                                        style={{ width: '100%' }}
                                      />
                                    </BaseSpace>
                                  </BaseRow>

                                  <BaseRow justify={'end'}>
                                    <BaseCol>
                                      <BaseButton
                                        type="text"
                                        onClick={() =>
                                          onPressDeleteImage(index)
                                        }
                                        icon={
                                          <DeleteOutlined
                                            style={{ color: 'red' }}
                                            rev={undefined}
                                          />
                                        }
                                      />
                                    </BaseCol>
                                  </BaseRow>
                                </div>
                              </BaseCol>
                            </BaseRow>
                          ),
                        },
                      ]}
                      key={index}
                      expandIconPosition="right"
                    />
                  )
                })}

                <ButtonVariant
                  onClick={onPressAddImage}
                  children={
                    <BaseText children={'Add Image'} fontWeight="medium" />
                  }
                  style={{ width: '100%' }}
                />
              </BaseSpace> */}
          </BaseSpace>
          {loadingUpdate && <Loading />}
        </S.FormDataWrapper>
      </S.FormDataWrapper>
      <SpaceVertical $gap={24} />
    </S.RootWrapper>
  )
}

export default memo(AddYourKioskLayout)

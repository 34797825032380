import { BaseLayout } from 'app/components/common/BaseLayout'
import { PaddingMain } from 'app/constant'
import { LAYOUT, media } from 'parkway-web-common'
import styled, { css } from 'styled-components'

interface Header {
  $isTwoColumnsLayoutHeader: boolean
}

export const Header = styled(BaseLayout.Header)<Header>`
  line-height: 1.5;
  background-color: #f4f4f4;
  border-bottom: 0px solid var(--gray-border-color);
  @media only screen and ${media.md} {
    padding: ${LAYOUT.mobile.paddingVertical} ${LAYOUT.mobile.paddingHorizontal} !important;
    height: unset;
  }
  @media only screen and ${media.xs} {
    padding: ${PaddingMain.Mobile.defaultHz};
    height: ${LAYOUT.mobile.headerHeight};
  }

  @media only screen and ${media.md} {
    ${props =>
      props?.$isTwoColumnsLayoutHeader &&
      css`
        padding: 0;
      `}
  }
`

import { memo } from 'react'
import { isEqual } from 'lodash'
import LoginContainerDesktop from 'app/containers/auth/desktop/Login'
import LoginContainerMobile from 'app/containers/auth/mobile/Login'
import { useResponsive } from 'parkway-web-common'
import { PageTitle } from 'app/components/common/PageTitle'
import R from 'app/assets/R'
import { useTranslation } from 'react-i18next'

const LoginPageComp = () => {
  const { t } = useTranslation()
  const { isTablet } = useResponsive()

  return (
    <>
      <PageTitle>{t(R.strings.login)}</PageTitle>
      {!isTablet ? <LoginContainerMobile /> : <LoginContainerDesktop />}
    </>
  )
}
export const LoginPage = memo(LoginPageComp, isEqual)

/* eslint-disable @typescript-eslint/no-explicit-any */
import { ArrowLeftOutlined } from '@ant-design/icons'
import {
  IBodyChangePassword,
  requestPostChangePassword,
  requestPostResetPassword,
} from 'app/api/auth'
import R from 'app/assets/R'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import CodeInput from 'app/components/common/CodeInput'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import {
  BaseTemplatePageAuth,
  JupiterComponent,
} from 'app/components/layouts/AuthLayout/Component'
import { AUTH_CHANGE_PASSWORD_PATH } from 'app/components/router/route-path'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router'
import * as S from './styles'
import { notificationController } from 'app/controllers/notification-controller'

interface ChangePasswordFormData {
  password: string
  confirmPassword?: string
}

export const initValues: ChangePasswordFormData = {
  password: '12345678',
  confirmPassword: '12345678',
}

const VerifyEmailContainer: React.FC = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const params: { email: string } = useLocation().state

  const [isLoading, setLoading] = useState(false)
  const [code, setCode] = useState('')
  const [time, setTime] = useState(60)

  const onGoBack = () => {
    navigate(-1)
  }

  useEffect(() => {
    if (!params) {
      onGoBack()
    }
  }, [params])

  const handleSubmit = async (values: ChangePasswordFormData) => {
    setLoading(true)
    try {
      if (!code && code.length !== 6) {
        notificationController.error({
          message: 'Please enter code',
        })
        return
      }

      const payload: IBodyChangePassword = {
        new_password: values.password,
        reset_password_code: code,
      }

      await requestPostChangePassword(payload)

      navigate(AUTH_CHANGE_PASSWORD_PATH, {
        replace: true,
      })

      setLoading(false)
    } catch (error: any) {
      console.log({ error })
    } finally {
      setLoading(false)
    }
  }

  const onResendCode = async () => {
    try {
      setLoading(true)
      await requestPostResetPassword({
        email: params.email,
      })

      setTime(60)
    } catch (err: any) {
      console.log({ err })
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (time > 0) {
      const timer = setTimeout(() => {
        setTime(time - 1)
      }, 1000)

      return () => clearTimeout(timer)
    }
  }, [time])

  return (
    <BaseTemplatePageAuth>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flex: 1,
          height: 'calc(100% - 10%)',
        }}
      >
        <div style={{ width: 700, zIndex: 10 }}>
          <BaseForm
            layout="vertical"
            onFinish={handleSubmit}
            requiredMark="optional"
            initialValues={initValues}
          >
            <S.FormWrapperCustom direction="vertical" size={15}>
              <BaseSpace>
                <BaseRow justify={'start'} className="back-area">
                  <S.BackButtonCustom onClick={onGoBack}>
                    <ArrowLeftOutlined rev={undefined} />
                  </S.BackButtonCustom>
                </BaseRow>

                <S.Title>{'Enter your code'}</S.Title>

                <S.Description>
                  {t(R.strings.account_verification_d1)}
                  <S.EmailText>{` ${params?.email}. `}</S.EmailText>
                  {t(R.strings.account_verification_d2)}
                </S.Description>

                <BaseRow align={'middle'} justify={'center'}>
                  <CodeInput onChange={setCode} />
                </BaseRow>

                <BaseRow justify={'center'}>
                  <S.SubmitButton htmlType="submit" loading={isLoading}>
                    {'Reset'}
                  </S.SubmitButton>
                </BaseRow>

                <BaseRow gutter={10} align={'middle'} justify={'center'}>
                  {time === 0 ? (
                    <S.Description style={{ color: 'white' }}>
                      {t(R.strings.didnt_receive_the_code)}
                    </S.Description>
                  ) : null}
                  <BaseCol>
                    {time > 0 ? (
                      <S.ResendText>{`${t(
                        R.strings.resend_code_in,
                      )} ${time}s`}</S.ResendText>
                    ) : (
                      <S.ResendText onClick={onResendCode}>
                        {t(R.strings.resend_code)}
                      </S.ResendText>
                    )}
                  </BaseCol>
                </BaseRow>
              </BaseSpace>
            </S.FormWrapperCustom>
          </BaseForm>
        </div>
      </div>

      <JupiterComponent />
    </BaseTemplatePageAuth>
  )
}
export default VerifyEmailContainer

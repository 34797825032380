/* eslint-disable @typescript-eslint/no-explicit-any */
import { CloseOutlined } from '@ant-design/icons'
import Dragger from 'antd/es/upload/Dragger'
import { requestPostCategoryList } from 'app/api/product-management/api'
import { IPostCategory } from 'app/api/product-management/model'
import R from 'app/assets/R'
import { SvgPictureIcon } from 'app/assets/svg-assets'
import { ButtonVariant } from 'app/components/common/BaseButtonVariant'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseImage } from 'app/components/common/BaseImage'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { BaseInput } from 'app/components/common/inputs/BaseInput'
import {
  DividerLine,
  IRefModal,
  ModalComponent,
} from 'app/components/common/ModalComponent'
import { notificationController } from 'app/controllers/notification-controller'
import { ProductManagementKeys } from 'app/react-query/query-key/product-management'
import { handleUploadFileGetId } from 'app/service/general/service'
import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
interface IForm extends IPostCategory {
  image?: string
}

export const CreateModal = () => {
  const query = useQueryClient()
  const { t } = useTranslation()
  const refModal = useRef<IRefModal>(null)
  const [form] = BaseForm.useForm<IForm>()

  const [imageUrl, setImageUrl] = useState<string | undefined>(undefined)
  const [fileUpload, setFile] = useState<File | undefined>(undefined)

  const [isLoading, setIsLoading] = useState(false)

  // Xử lý sau khi upload thành công
  const handleUploadChange = (info: any) => {
    const file = info?.file
    if (file) {
      const url = URL.createObjectURL(file)
      setImageUrl(url)
      form.setFieldsValue({ image: url })
      setFile(file)
    }
  }

  const openModal = () => {
    refModal.current?.open?.()
  }

  const hideModal = () => {
    refModal.current?.hide?.()
  }

  const onSave = async (values: IForm) => {
    try {
      setIsLoading(true)

      if (!fileUpload) {
        return
      }

      const imageId = await handleUploadFileGetId(fileUpload)

      if (imageId) {
        await requestPostCategoryList({
          name: values?.name,
          imageId,
          displayName: values?.displayName,
        })

        query.invalidateQueries({
          queryKey: ProductManagementKeys.allCategory,
        })

        notificationController.success({
          message: t(R.strings.save_success),
        })

        hideModal()
      }
    } catch (error) {
      console.log({ error })
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <ModalComponent
      ref={refModal}
      titleModal="Create Category"
      renderContent={
        <BaseSpace>
          <DividerLine />

          <BaseForm form={form} onFinish={onSave}>
            <BaseSpace style={{ padding: '0px 26px' }}>
              <BaseText
                children={'Category details'}
                fontWeight="bold"
                fontSize="md"
                fontFamily="Raleway"
              />

              <BaseRow justify={'start'} gutter={16} align={'middle'}>
                <BaseCol xl={2}>
                  <BaseText children={'Name'} fontWeight="semibold" />
                </BaseCol>

                <BaseCol xl={16}>
                  <BaseForm.Item
                    name={'name'}
                    required
                    rules={[
                      {
                        required: true,
                        message: t(R.strings.require_field, {
                          field: 'Category name',
                        }),
                      },
                    ]}
                  >
                    <BaseInput
                      placeholder="Enter category name"
                      style={{ padding: '8px 10px', width: '100%' }}
                    />
                  </BaseForm.Item>
                </BaseCol>
              </BaseRow>
              <BaseRow justify={'start'} gutter={16} align={'middle'}>
                <BaseCol xl={2}>
                  <BaseText children={'Display name'} fontWeight="semibold" />
                </BaseCol>

                <BaseCol xl={16}>
                  <BaseForm.Item
                    name={'displayName'}
                    required
                    rules={[
                      {
                        required: true,
                        message: t(R.strings.require_field, {
                          field: 'Category display name',
                        }),
                      },
                    ]}
                  >
                    <BaseInput
                      placeholder="Enter category display name"
                      style={{ padding: '8px 10px', width: '100%' }}
                    />
                  </BaseForm.Item>
                </BaseCol>
              </BaseRow>

              <BaseRow justify={'start'} gutter={16} align={'middle'}>
                <BaseCol xl={2}>
                  <BaseText children={'Upload'} fontWeight="semibold" />
                </BaseCol>

                <BaseCol xl={16}>
                  <BaseForm.Item
                    name={'image'}
                    valuePropName="file"
                    required
                    rules={[
                      {
                        required: true,
                        message: t(R.strings.require_field, {
                          field: 'Image',
                        }),
                      },
                    ]}
                  >
                    <Dragger
                      name="file"
                      multiple={false}
                      onChange={handleUploadChange}
                      beforeUpload={() => false}
                      style={{
                        borderRadius: 10,
                        padding: '10px 20px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: 'white',
                        borderStyle: 'solid',
                      }}
                      showUploadList={false}
                    >
                      <BaseRow
                        gutter={16}
                        align={'middle'}
                        justify={'space-between'}
                      >
                        <BaseCol xl={8}>
                          <BaseImage
                            src={imageUrl ?? R.images.ic_logo_kiosk_master}
                            style={{ borderRadius: 10 }}
                            width="100%"
                            preview={false}
                          />
                        </BaseCol>
                        <BaseCol xl={14}>
                          <p>
                            Drag an image here,{' '}
                            <span style={{ color: '#1890ff' }}>upload</span>, or{' '}
                            <span style={{ color: '#1890ff' }}>
                              browse image library
                            </span>
                            .
                          </p>
                        </BaseCol>

                        <BaseCol xl={2}>
                          <SvgPictureIcon />
                        </BaseCol>
                      </BaseRow>
                    </Dragger>
                  </BaseForm.Item>
                </BaseCol>
              </BaseRow>
            </BaseSpace>
          </BaseForm>
        </BaseSpace>
      }
      footer={
        <div style={{ padding: '0px 20px', width: '100%' }}>
          <ButtonVariant
            onClick={form.submit}
            style={{ width: '100%' }}
            loading={isLoading}
          >
            <BaseText children={t(R.strings.save)} fontWeight="semibold" />
          </ButtonVariant>
        </div>
      }
      rightHeader={
        <BaseCol>
          <CloseOutlined rev={undefined} onClick={hideModal} />
        </BaseCol>
      }
      buttonOpenCustom={
        <ButtonVariant
          children={
            <BaseText children={'Create Category'} fontWeight="semibold" />
          }
          onClick={openModal}
        />
      }
    />
  )
}

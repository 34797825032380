import { SyncOutlined } from '@ant-design/icons'
import { DatePicker } from 'antd'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import { PADDING } from 'parkway-web-common'
import styled from 'styled-components'

export const RootWrapper = styled(BaseSpace)`
  width: 100%;
  padding: 20px;
  padding-bottom: 0px;
`
export const FilterWrapper = styled(BaseRow)`
  padding: ${PADDING.md} 0px;
`
export const BaseRangePicker = styled(DatePicker.RangePicker)`
  width: 100%;
  &.ant-picker {
    background: transparent;
    color: var(--background-color);
    border: 0.7px solid #cfd3d7;
    padding: ${PADDING.xxxxs} ${PADDING.xxs};
  }

  &.ant-picker .ant-picker-input > input {
    color: var(--background-color);
    background: transparent !important;
  }

  &.ant-picker .ant-picker-input > input::placeholder {
    color: #abbbc2;
  }
`

export const SyncIcon = styled(SyncOutlined)<{ $color?: string }>`
  svg {
    fill: ${props => props?.$color};
  }
`

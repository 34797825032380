// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.parent {
  margin-top: 200px;
  animation: expand 1.25s forwards ease-in-out; /* Animation cho phần tử cha */
  background-image: linear-gradient(to bottom, #42e5c6, #ffffff);
}

.container-animation-slide-up {
  opacity: 0;
  animation: slideUp 2s forwards ease-in-out;
}

.B {
  animation-delay: 1s;
}

.C {
  animation-delay: 1.5s;
}

.D {
  animation-delay: 2s;
}

.E {
  animation-delay: 2.5s;
}

@keyframes expand {
  0% {
    margin-top: 200px;
  }
  100% {
    margin-top: 0px; /* Chiều cao cuối cùng cho phần tử cha */
  }
}

@keyframes slideUp {
  0% {
    transform: translateY(100px);
    opacity: 0;
  }
  100% {
    transform: translateY(0); /* Đưa các phần tử về vị trí ban đầu */
    opacity: 1; /* Độ mờ trở về 1 khi hiển thị */
  }
}
`, "",{"version":3,"sources":["webpack://./src/app/containers/landing-page/components/mobile-layout/LayoutFour/styles.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,4CAA4C,EAAE,8BAA8B;EAC5E,8DAA8D;AAChE;;AAEA;EACE,UAAU;EACV,0CAA0C;AAC5C;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE;IACE,iBAAiB;EACnB;EACA;IACE,eAAe,EAAE,wCAAwC;EAC3D;AACF;;AAEA;EACE;IACE,4BAA4B;IAC5B,UAAU;EACZ;EACA;IACE,wBAAwB,EAAE,sCAAsC;IAChE,UAAU,EAAE,gCAAgC;EAC9C;AACF","sourcesContent":[".parent {\n  margin-top: 200px;\n  animation: expand 1.25s forwards ease-in-out; /* Animation cho phần tử cha */\n  background-image: linear-gradient(to bottom, #42e5c6, #ffffff);\n}\n\n.container-animation-slide-up {\n  opacity: 0;\n  animation: slideUp 2s forwards ease-in-out;\n}\n\n.B {\n  animation-delay: 1s;\n}\n\n.C {\n  animation-delay: 1.5s;\n}\n\n.D {\n  animation-delay: 2s;\n}\n\n.E {\n  animation-delay: 2.5s;\n}\n\n@keyframes expand {\n  0% {\n    margin-top: 200px;\n  }\n  100% {\n    margin-top: 0px; /* Chiều cao cuối cùng cho phần tử cha */\n  }\n}\n\n@keyframes slideUp {\n  0% {\n    transform: translateY(100px);\n    opacity: 0;\n  }\n  100% {\n    transform: translateY(0); /* Đưa các phần tử về vị trí ban đầu */\n    opacity: 1; /* Độ mờ trở về 1 khi hiển thị */\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

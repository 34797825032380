
function strings(){
    return{
        empowering_kiosk_efficiency: "empowering_kiosk_efficiency",
        streamlined_content_management: "streamlined_content_management",
        streamlined_content_management_d: "streamlined_content_management_d",
        remote_kiosk_control: "remote_kiosk_control",
        remote_kiosk_control_d: "remote_kiosk_control_d",
        personalized_recommendations: "personalized_recommendations",
        personalized_recommendations_d: "personalized_recommendations_d",
        advanced_reporting: "advanced_reporting",
        advanced_reporting_d: "advanced_reporting_d",
        personalized_recommendations_and_promotions: "personalized_recommendations_and_promotions",
        personalized_recommendations_and_promotions_d: "personalized_recommendations_and_promotions_d",
        revolutionizing_customer_engagement: "revolutionizing_customer_engagement",
        revolutionizing_customer_engagement_d: "revolutionizing_customer_engagement_d",
        sign_up_for_a_free_trial: "sign_up_for_a_free_trial",
        start_14_days_trail: "start_14_days_trail",
        home_subcribe: "home_subcribe",
        enter_your_email_address: "enter_your_email_address",
        footer_kiosify: "footer_kiosify",
        contact_us: "contact_us",
        home_slogan: "home_slogan",
        home_slogan_d: "home_slogan_d",
        create_your_kiosify_account: "create_your_kiosify_account",
        see_more: "see_more",
        find: "find",
        address: "address",
        language: "language",
        vietnamese: "vietnamese",
        english: "english",
        foreign_language: "foreign_language",
        home: "home",
        phone: "phone",
        email: "email",
        send: "send",
        next: "next",
        back: "back",
        send_code: "send_code",
        login: "login",
        user_name: "user_name",
        password: "password",
        login_success: "login_success",
        save_success: "save_success",
        forgot_password: "forgot_password",
        require_field: "require_field",
        regex_field: "regex_field",
        minimum_field: "minimum_field",
        maximum_field: "maximum_field",
        register: "register",
        confirm_password: "confirm_password",
        verify_by_phone: "verify_by_phone",
        verify_by_email: "verify_by_email",
        code_verify: "code_verify",
        message_verify_phone: "message_verify_phone",
        re_send_verify_code: "re_send_verify_code",
        resend: "resend",
        time_resend_verify_code: "time_resend_verify_code",
        verify: "verify",
        logout: "logout",
        full_name: "full_name",
        regex_name: "regex_name",
        regex_user_name: "regex_user_name",
        using_by: "using_by",
        require_email: "require_email",
        require_contact_name: "require_contact_name",
        regex_email: "regex_email",
        regex_phone: "regex_phone",
        send_contact_success: "send_contact_success",
        please_enter_correct_format: "please_enter_correct_format",
        medical_examination: "medical_examination",
        human_resources: "human_resources",
        finance: "finance",
        inventory_management: "inventory_management",
        purchasing_management: "purchasing_management",
        customer_care: "customer_care",
        clinic_management: "clinic_management",
        company: "company",
        organizational_chart: "organizational_chart",
        announcement: "announcement",
        internal_news: "internal_news",
        onboarding: "onboarding",
        report: "report",
        marketing: "marketing",
        accounting: "accounting",
        work_schedule: "work_schedule",
        attendance_record: "attendance_record",
        mailbox: "mailbox",
        my_requests: "my_requests",
        my_tasks: "my_tasks",
        my_income: "my_income",
        dashboard: "dashboard",
        profile: "profile",
        read_all: "read_all",
        view_all: "view_all",
        remember_me: "remember_me",
        parkway_dental: "parkway_dental",
        description_parkway_auth: "description_parkway_auth",
        development: "development",
        coming_soon: "coming_soon",
        customers: "customers",
        region: "region",
        revenue: "revenue",
          basic_package_title: "basic_package.title",
          basic_package_price: "basic_package.price",
          basic_package_benefit_1: "basic_package.benefit_1",
          basic_package_benefit_2: "basic_package.benefit_2",
          basic_package_benefit_3: "basic_package.benefit_3",
          basic_package_benefit_4: "basic_package.benefit_4",
          basic_package_benefit_5: "basic_package.benefit_5",
          basic_package_benefit_6: "basic_package.benefit_6",
          premium_package_title: "premium_package.title",
          premium_package_price: "premium_package.price",
          premium_package_benefit_1: "premium_package.benefit_1",
          premium_package_benefit_2: "premium_package.benefit_2",
          premium_package_benefit_3: "premium_package.benefit_3",
          premium_package_benefit_4: "premium_package.benefit_4",
          premium_package_benefit_5: "premium_package.benefit_5",
          premium_package_benefit_6: "premium_package.benefit_6",
          premium_package_benefit_7: "premium_package.benefit_7",
          smart_saving_package_title: "smart_saving_package.title",
          smart_saving_package_price: "smart_saving_package.price",
          smart_saving_package_benefit_1: "smart_saving_package.benefit_1",
          smart_saving_package_benefit_2: "smart_saving_package.benefit_2",
          smart_saving_package_benefit_3: "smart_saving_package.benefit_3",
          time_today: "time.today",
          time_this_week: "time.this_week",
          time_this_month: "time.this_month",
          time_days: "time.days",
          time_start_time_kiosk: "time.start_time_kiosk",
          time_end_time_kiosk: "time.end_time_kiosk",
          time_monday: "time.monday",
          time_tuesday: "time.tuesday",
          time_wednesday: "time.wednesday",
          time_thursday: "time.thursday",
          time_friday: "time.friday",
          time_satuday: "time.satuday",
          time_sunday: "time.sunday",
          error_message_service_package: "error_message.service_package",
          error_message_select_location: "error_message.select_location",
          error_message_select_payment_method: "error_message.select_payment_method",
          error_message_select_kiosk: "error_message.select_kiosk",
          success_message_create_kiosk: "success_message.create_kiosk",
          success_message_update_kiosk: "success_message.update_kiosk",
          table_customer: "table.customer",
          table_time: "table.time",
          table_date: "table.date",
          table_order: "table.order",
          table_total_payment: "table.total_payment",
          table_payment_method: "table.payment_method",
          table_status: "table.status",
          table_type: "table.type",
          table_name: "table.name",
          table_location: "table.location",
          table_kiosk_code: "table.kiosk_code",
          table_product_name: "table.product_name",
          table_price: "table.price",
          table_item_name: "table.item_name",
          table_category: "table.category",
          table_variation: "table.variation",
          table_up_sell_item: "table.up_sell_item",
          table_up_sell_at: "table.up_sell_at",
          table_quantity: "table.quantity",
          table_action: "table.action",
          table_options: "table.options",
          table_discount_name: "table.discount_name",
          table_discount_type: "table.discount_type",
          table_discount_apply: "table.discount_apply",
          table_item_price: "table.item_price",
          table_active: "table.active",
          table_bestseller: "table.bestseller",
          payment_method_cash: "payment_method.cash",
          payment_method_credit_card: "payment_method.credit_card",
          payment_method_gift_card: "payment_method.gift_card",
          dashboard_common_most_ordered: "dashboard_common.most_ordered",
          dashboard_common_most_type_order: "dashboard_common.most_type_order",
          dashboard_common_total_revenue: "dashboard_common.total_revenue",
          dashboard_common_pending_revenue: "dashboard_common.pending_revenue",
          dashboard_common_actual_revenue: "dashboard_common.actual_revenue",
          dashboard_common_compared_to_last_month: "dashboard_common.compared_to_last_month",
          dashboard_common_today_revenue: "dashboard_common.today_revenue",
          dashboard_common_branch_name: "dashboard_common.branch_name",
          dashboard_common_overall_today_summary: "dashboard_common.overall_today_summary",
          dashboard_common_area: "dashboard_common.area",
          dashboard_common_ordinal_number: "dashboard_common.ordinal_number",
          dashboard_common_clinic: "dashboard_common.clinic",
          dashboard_common_dishes_ordered: "dashboard_common.dishes_ordered",
          dashboard_common_view_all: "dashboard_common.view_all",
          dashboard_common_order_report: "dashboard_common.order_report",
          reports_common_title: "reports_common.title",
          reports_common_total_revenue: "reports_common.total_revenue",
          reports_common_total_dish_ordered: "reports_common.total_dish_ordered",
          reports_common_total_customer: "reports_common.total_customer",
          reports_common_order_report: "reports_common.order_report",
          reports_common_chart_header_realtime: "reports_common.chart_header_realtime",
          reports_common_chart_header_daily: "reports_common.chart_header_daily",
          reports_common_chart_header_today: "reports_common.chart_header_today",
          reports_common_chart_header_weekly: "reports_common.chart_header_weekly",
          reports_common_chart_header_monthly: "reports_common.chart_header_monthly",
          reports_common_chart_header_yearly: "reports_common.chart_header_yearly",
          reports_common_chart_label_monday: "reports_common.chart_label_monday",
          reports_common_chart_label_tuesday: "reports_common.chart_label_tuesday",
          reports_common_chart_label_wednesday: "reports_common.chart_label_wednesday",
          reports_common_chart_label_thursday: "reports_common.chart_label_thursday",
          reports_common_chart_label_friday: "reports_common.chart_label_friday",
          reports_common_chart_label_satuday: "reports_common.chart_label_satuday",
          reports_common_chart_label_sunday: "reports_common.chart_label_sunday",
          reports_common_chart_label_january: "reports_common.chart_label_january",
          reports_common_chart_label_february: "reports_common.chart_label_february",
          reports_common_chart_label_march: "reports_common.chart_label_march",
          reports_common_chart_label_april: "reports_common.chart_label_april",
          reports_common_chart_label_may: "reports_common.chart_label_may",
          reports_common_chart_label_june: "reports_common.chart_label_june",
          reports_common_chart_label_july: "reports_common.chart_label_july",
          reports_common_chart_label_august: "reports_common.chart_label_august",
          reports_common_chart_label_september: "reports_common.chart_label_september",
          reports_common_chart_label_october: "reports_common.chart_label_october",
          reports_common_chart_label_november: "reports_common.chart_label_november",
          reports_common_chart_label_december: "reports_common.chart_label_december",
          reports_common_chart_serrie_total_gross: "reports_common.chart_serrie_total_gross",
          reports_common_chart_serrie_total_net: "reports_common.chart_serrie_total_net",
          input_placeholder_search: "input_placeholder.search",
          input_placeholder_search_tab: "input_placeholder.search_tab",
          input_placeholder_select_location: "input_placeholder.select_location",
          input_placeholder_order_note: "input_placeholder.order_note",
          input_placeholder_search_only: "input_placeholder.search_only",
          input_placeholder_select_category: "input_placeholder.select_category",
          input_placeholder_device_name: "input_placeholder.device_name",
          input_placeholder_search_printer: "input_placeholder.search_printer",
          input_placeholder_search_profile: "input_placeholder.search_profile",
          input_placeholder_search_your_kiosk: "input_placeholder.search_your_kiosk",
          input_placeholder_write_your_content_here: "input_placeholder.write_your_content_here",
          input_placeholder_select_time: "input_placeholder.select_time",
          input_validate_required_kiosk_name: "input_validate.required_kiosk_name",
          information_branch: "information.branch",
          information_location: "information.location",
          information_phone_no: "information.phone_no",
          information_email: "information.email",
          kiosk_add_your_kiosk: "kiosk.add_your_kiosk",
          kiosk_edit_your_kiosk: "kiosk.edit_your_kiosk",
          kiosk_add_new_kiosk: "kiosk.add_new_kiosk",
          kiosk_kiosk_name: "kiosk.kiosk_name",
          kiosk_kiosk_code: "kiosk.kiosk_code",
          kiosk_your_subscription: "kiosk.your_subscription",
          kiosk_payment_method: "kiosk.payment_method",
          kiosk_redeem_loyalty: "kiosk.redeem_loyalty",
          kiosk_redeem_discount: "kiosk.redeem_discount",
          kiosk_open_time: "kiosk.open_time",
          kiosk_start_popups_when_the_store_is_closed: "kiosk.start_popups_when_the_store_is_closed",
          kiosk_message: "kiosk.message",
          notification_status_success: "notification_status.success",
          notification_status_info: "notification_status.info",
          notification_status_warning: "notification_status.warning",
          notification_status_error: "notification_status.error",
          product_management_category: "product_management.category",
          product_management_items: "product_management.items",
          product_management_up_sell: "product_management.up_sell",
          product_management_modifiers: "product_management.modifiers",
          product_management_discounts: "product_management.discounts",
          product_management_delete_item_up_sell: "product_management.delete_item_up_sell",
          product_management_delete_item_up_sell_description: "product_management.delete_item_up_sell_description",
          product_management_create_up_sell: "product_management.create_up_sell",
          product_management_menu_price: "product_management.menu_price",
          product_management_discount_price: "product_management.discount_price",
          product_management_item_level: "product_management.item_level",
          product_management_order_level: "product_management.order_level",
          form_item_select_up_sell_at_t: "form_item.select_up_sell_at_t",
          form_item_select_up_sell_at_p: "form_item.select_up_sell_at_p",
          form_item_select_item_t: "form_item.select_item_t",
          form_item_select_item_p: "form_item.select_item_p",
          form_item_select_item_variation_t: "form_item.select_item_variation_t",
          form_item_select_item_variation_p: "form_item.select_item_variation_p",
          form_item_select_price_t: "form_item.select_price_t",
          form_item_select_price_p: "form_item.select_price_p",
          form_item_price_t: "form_item.price_t",
          form_item_select_item_apply_t: "form_item.select_item_apply_t",
          form_item_select_item_apply_p: "form_item.select_item_apply_p",
          setting_menu_appearance_t: "setting_menu.appearance_t",
          setting_menu_appearance_d: "setting_menu.appearance_d",
          setting_menu_your_restaurant_t: "setting_menu.your_restaurant_t",
          setting_menu_your_restaurant_d: "setting_menu.your_restaurant_d",
          setting_menu_your_kiosk_t: "setting_menu.your_kiosk_t",
          setting_menu_your_kiosk_d: "setting_menu.your_kiosk_d",
          setting_menu_product_management_t: "setting_menu.product_management_t",
          setting_menu_product_management_d: "setting_menu.product_management_d",
          setting_menu_kds_t: "setting_menu.kds_t",
          setting_menu_kds_d: "setting_menu.kds_d",
          setting_menu_backup_t: "setting_menu.backup_t",
          setting_menu_backup_d: "setting_menu.backup_d",
          setting_menu_your_information_t: "setting_menu.your_information_t",
          setting_menu_your_information_d: "setting_menu.your_information_d",
          setting_menu_sync_data_from_square_t: "setting_menu.sync_data_from_square_t",
          setting_menu_sync_data_from_square_d: "setting_menu.sync_data_from_square_d",
          your_infomation_km_stand: "your_infomation.km_stand",
          your_infomation_km_printers: "your_infomation.km_printers",
          your_infomation_km_readers: "your_infomation.km_readers",
          your_infomation_device_id: "your_infomation.device_id",
          your_infomation_get_help: "your_infomation.get_help",
          your_infomation_printers: "your_infomation.printers",
          your_infomation_profiles: "your_infomation.profiles",
          your_infomation_connect_printer: "your_infomation.connect_printer",
          your_infomation_no_printer_connected: "your_infomation.no_printer_connected",
          your_infomation_troubleshoot: "your_infomation.troubleshoot",
          your_infomation_troubleshoot_d: "your_infomation.troubleshoot_d",
          your_infomation_create_profile: "your_infomation.create_profile",
          your_infomation_no_profile: "your_infomation.no_profile",
          your_infomation_printer_defaults: "your_infomation.printer_defaults",
          your_infomation_printer_defaults_d: "your_infomation.printer_defaults_d",
          your_infomation_receipts: "your_infomation.receipts",
          your_infomation_in_person_order_tickets: "your_infomation.in_person_order_tickets",
          your_infomation_online_order_tickets: "your_infomation.online_order_tickets",
          your_infomation_order_ticket_stubs: "your_infomation.order_ticket_stubs",
          your_infomation_void_tickets: "your_infomation.void_tickets",
          your_infomation_use_this_printer_for_receipts: "your_infomation.use_this_printer_for_receipts",
          your_infomation_use_this_printer_for_receipts_d: "your_infomation.use_this_printer_for_receipts_d",
          your_infomation_no_device: "your_infomation.no_device",
        about: "about",
        general: "general",
        connect_and_manage: "connect_and_manage",
        add_new: "add_new",
        add_more_food: "add_more_food",
        key_all_label: "key_all_label",
        restaurant: "restaurant",
        your_restaurant: "your_restaurant",
        see_your_restaurant: "see_your_restaurant",
        background: "background",
        text: "text",
        button: "button",
        custom: "custom",
        see_all: "see_all",
        review: "review",
        restaurant_name: "restaurant_name",
        fake_time: "fake_time",
          fake_tab_hot_dishes: "fake_tab.hot_dishes",
          fake_tab_cold_dishes: "fake_tab.cold_dishes",
          fake_tab_soup: "fake_tab.soup",
          fake_tab_grill: "fake_tab.grill",
          fake_tab_appetizer: "fake_tab.appetizer",
          fake_tab_dessert: "fake_tab.dessert",
        choose_dishes: "choose_dishes",
        bowls_available: "bowls_available",
        item: "item",
        qty: "qty",
        price: "price",
        fake_order_note: "fake_order_note",
        discount: "discount",
        sub_total: "sub_total",
        continue_to_payment: "continue_to_payment",
        save: "save",
        dark_mode: "dark_mode",
        light_mode: "light_mode",
          color_black: "color.black",
          color_white: "color.white",
          color_light_red: "color.light_red",
          color_light_green: "color.light_green",
          color_light_blue: "color.light_blue",
          color_light_orange: "color.light_orange",
          color_light_gray: "color.light_gray",
          color_light_cyan: "color.light_cyan",
          label_common_for_here: "label_common.for_here",
          label_common_to_go: "label_common.to_go",
          label_common_pick_up: "label_common.pick_up",
          label_common_late: "label_common.late",
          label_common_on_time: "label_common.on_time",
          label_common_caution: "label_common.caution",
          label_common_small: "label_common.small",
          label_common_medium: "label_common.medium",
          label_common_large: "label_common.large",
          label_common_extra_large: "label_common.extra_large",
          kds_standard_screen_option: "kds.standard_screen_option",
          kds_default_t: "kds.default_t",
          kds_default_d: "kds.default_d",
          kds_classic_t: "kds.classic_t",
          kds_classic_d: "kds.classic_d",
          kds_caution_time: "kds.caution_time",
          kds_late_time: "kds.late_time",
          kds_order_received_sms: "kds.order_received_sms",
          kds_order_bumped_from_kds_screen_sms: "kds.order_bumped_from_kds_screen_sms",
          kds_order_is_ready_for_pick_up_sms: "kds.order_is_ready_for_pick_up_sms",
          kds_transition_time: "kds.transition_time",
          kds_font_and_color: "kds.font_and_color",
          kds_font_size: "kds.font_size",
          kds_status_color: "kds.status_color",
          kds_order_type: "kds.order_type",
          kds_mesaging: "kds.mesaging",
        min: "min",
        on: "on",
        off: "off",
        see_with: "see_with",
        import: "import",
        week: "week",
        download_successful: "download_successful",
        import_file_successful: "import_file_successful",
        import_file_error: "import_file_error",
        choose_week: "choose_week",
        notification: "notification",
        not_done: "not_done",
        done: "done",
        in_progress: "in_progress",
        see_detail: "see_detail",
        mail: "mail",
        new_mail: "new_mail",
        important: "important",
        show_less: "show_less",
        collapse: "collapse",
        last_time_update: "last_time_update",
        service: "service",
        month: "month",
        import_file: "import_file",
        cancel: "cancel",
        export_excel: "export_excel",
        download_example_import_file: "download_example_import_file",
        solutions: "solutions",
        vision: "vision",
        programs: "programs",
        blog: "blog",
        product: "product",
        pricing: "pricing",
        resource: "resource",
        customer_login: "customer_login",
        sign_up: "sign_up",
        watch_free_demo: "watch_free_demo",
        sign_in_to_stay_connected: "sign_in_to_stay_connected",
        or_sign_in_with_other_accounts: "or_sign_in_with_other_accounts",
        dont_have_an_account: "dont_have_an_account",
        click_here_to_sign_up: "click_here_to_sign_up",
        sign_in: "sign_in",
        create_your_hope_ui_account: "create_your_hope_ui_account",
        first_name: "first_name",
        last_name: "last_name",
        phone_no: "phone_no",
        confirm_password_incorrect: "confirm_password_incorrect",
        i_agree_with_the_terms_of_use: "i_agree_with_the_terms_of_use",
        or_sign_up_with_other_accounts: "or_sign_up_with_other_accounts",
        already_have_an_account: "already_have_an_account",
        reset: "reset",
        reset_password: "reset_password",
        enter_your_email_address_and_we_ll_send_you_an_email_with_instructions_to_reset_your_password: "enter_your_email_address_and_we_ll_send_you_an_email_with_instructions_to_reset_your_password",
        back_to_home: "back_to_home",
        back_to_login: "back_to_login",
        message_verify_email: "message_verify_email",
        success: "success",
        subcribe_now: "subcribe_now",
        error: "error",
        admin: "admin",
        settings: "settings",
        not_found: "not_found",
        spicy_seasoned_seafood_noodles: "spicy_seasoned_seafood_noodles",
        salted_pasta_with_mushroom_sauce: "salted_pasta_with_mushroom_sauce",
        beef_dumpling_in_hot_and_sour_soup: "beef_dumpling_in_hot_and_sour_soup",
        healthy_noodle_with_spinach_leaf: "healthy_noodle_with_spinach_leaf",
        hot_spicy_fried_rice_with_omelet: "hot_spicy_fried_rice_with_omelet",
        spicy_instant_noodle_with_special_omelette: "spicy_instant_noodle_with_special_omelette",
        spicy_seasoned_seafood_noodles_short: "spicy_seasoned_seafood_noodles_short",
        salted_pasta_with_mushroom_sauce_short: "salted_pasta_with_mushroom_sauce_short",
        spicy_instant_noodle_with_special_omelette_short: "spicy_instant_noodle_with_special_omelette_short",
        healthy_noodle_with_spinach_leaf_short: "healthy_noodle_with_spinach_leaf_short",
        select_item_for_upsells: "select_item_for_upsells",
        recommended_foods: "recommended_foods",
        ex: "ex",
        spicy_seasoned_seafood_noodles_strawberry: "spicy_seasoned_seafood_noodles_strawberry",
        account_verification: "account_verification",
        email_verification: "email_verification",
        account_verification_d1: "account_verification_d1",
        account_verification_d2: "account_verification_d2",
        didnt_receive_the_code: "didnt_receive_the_code",
        resend_code_in: "resend_code_in",
        resend_code: "resend_code",
        account_verified: "account_verified",
        didnt_sync_data: "didnt_sync_data",
        click_here_to_connect_square_account: "click_here_to_connect_square_account",
        change_password: "change_password",
        card: "card",
        cardholder_name: "cardholder_name",
        please_input_cardholder_name: "please_input_cardholder_name",
        cardholder_name_must_be_at_least_6_characters: "cardholder_name_must_be_at_least_6_characters",
        start_subscription: "start_subscription",
        order_summary: "order_summary",
        Plane: "Plane",
        subscription_monthly: "subscription_monthly",
        fourteen_days_free_trail: "fourteen_days_free_trail",
        subtotal: "subtotal",
        taxes_if_applicable: "taxes_if_applicable",
        total: "total",
        save_this_card_for_future_use: "save_this_card_for_future_use",
        subcribe_for_free: "subcribe_for_free",
          language_option_en: "language_option.en",
          language_option_vi: "language_option.vi",
          language_option_ja: "language_option.ja",
          language_option_es: "language_option.es",
          language_option_fr: "language_option.fr",
          language_option_zh: "language_option.zh",
          currency_option_en: "currency_option.en",
          currency_option_vi: "currency_option.vi",
          currency_option_ja: "currency_option.ja",
          currency_option_es: "currency_option.es",
          currency_option_fr: "currency_option.fr",
          currency_option_zh: "currency_option.zh",
        feature: "feature",
        before_5_minutes: "before_5_minutes",
        upload: "upload",
        upload_your_image: "upload_your_image",
        confirm: "confirm",
        you_sync_data_success: "you_sync_data_success",
        thank_you_for_interest: "thank_you_for_interest"
}}
export default strings
        
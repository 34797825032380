import R from 'app/assets/R'
import BaseText from 'app/components/common/BaseText'
import { useAppDispatch, useAppSelector } from 'app/redux/hooks'
import { setPathReport } from 'app/redux/slices/pathReportSlice'
import { isEqual } from 'lodash'
import { useTranslation } from 'react-i18next'
import { URL_REPORTS } from '../../constant'
import * as S from './styles'

interface CardData {
  title: string
  path: string
  description?: string
}

const CARD_DATA: CardData[] = [
  {
    title: R.strings.reports_common_total_revenue,
    path: URL_REPORTS.TotalRevenue,
    description: 'Track total revenue',
  },
  {
    title: R.strings.reports_common_total_dish_ordered,
    path: URL_REPORTS.TotalDishOrdered,
    description: 'Track total dish ordered',
  },
  {
    title: R.strings.reports_common_total_customer,
    path: URL_REPORTS.TotalCustomer,
    description: 'Track total customers',
  },
  {
    title: R.strings.reports_common_order_report,
    path: URL_REPORTS.OrderReport,
    description: 'Track total orders',
  },
]

const HomeLayout = () => {
  const currentPath = useAppSelector(state => state?.pathReport)?.path

  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const handleNavigate = (path: string) => {
    dispatch(setPathReport(path))
  }

  return (
    <S.RootWrapper>
      {CARD_DATA.map((item, index) => {
        return (
          <S.CardItem
            key={index}
            onClick={() => handleNavigate(item.path)}
            $isLastItem={isEqual(index + 1, CARD_DATA.length)}
            $isFirstItem={isEqual(index, 0)}
            $isSelected={isEqual(currentPath, item?.path)}
            size={8}
          >
            <BaseText
              children={t(item.title)}
              fontSize="xs"
              fontWeight="medium"
            />
            <BaseText
              children={t(item?.description ?? '')}
              fontWeight="regular"
              style={{ color: '#9A9FA5' }}
            />
            <div
              style={{
                width: 9,
                height: '100%',
                position: 'absolute',
                top: 0,
                left: 0,
                backgroundColor: isEqual(currentPath, item?.path)
                  ? '#54E9CD'
                  : 'transparent',
                borderRadius: (() => {
                  switch (index) {
                    case 0:
                      return '100px 0px 100px 0px'
                    case CARD_DATA.length - 1:
                      return '0px 100px 0px 100px'
                    default:
                      return '0px 100px 100px 0px'
                  }
                })(),
              }}
            />
          </S.CardItem>
        )
      })}
    </S.RootWrapper>
  )
}

export default HomeLayout

import { BORDER_RADIUS } from 'parkway-web-common'
import React from 'react'
import VerificationInput, {
  VerificationInputProps,
} from 'react-verification-input'
import styled from 'styled-components'

const Base = styled.div`
  .container {
    max-width: 500px;
  }
  .vi__character {
    border-radius: ${BORDER_RADIUS};
  }
`

const CodeInput: React.FC<VerificationInputProps> = ({
  length = 6,
  ...props
}) => {
  return (
    <Base>
      <VerificationInput
        autoFocus
        validChars="0-9"
        length={length}
        {...props}
      />
    </Base>
  )
}

export default CodeInput

import R from 'app/assets/R'
import { SvgCheckedIcon } from 'app/assets/svg-assets'
import { PackageEnum } from 'app/common/enum'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import { SubscriptionPlanData } from 'app/constant'
import { t } from 'i18next'
import { useMemo } from 'react'
import { useInView } from 'react-intersection-observer'
import * as S from './styles'
import './styles.css'
export interface IPricing {
  package: string
  title: string
  price: string
  benefits: string[]
  priceNumber?: number
  item_id?: string
  plan_id?: string
}

export const subscriptionPlans = (): IPricing[] => {
  return [
    {
      package: SubscriptionPlanData[PackageEnum.Basic].name,
      title: t(R.strings.basic_package_title),
      price: t(R.strings.basic_package_price),
      priceNumber: 50,
      item_id: SubscriptionPlanData[PackageEnum.Basic].item_id,
      plan_id: SubscriptionPlanData[PackageEnum.Basic].plan_id,
      benefits: [
        t(R.strings.basic_package_benefit_1),
        t(R.strings.basic_package_benefit_2),
        t(R.strings.basic_package_benefit_3),
        t(R.strings.basic_package_benefit_4),
        t(R.strings.basic_package_benefit_5),
        t(R.strings.basic_package_benefit_6),
      ],
    },
    {
      package: SubscriptionPlanData[PackageEnum.Premium].name,
      title: t(R.strings.premium_package_title),
      price: t(R.strings.premium_package_price),
      priceNumber: 80,
      item_id: SubscriptionPlanData[PackageEnum.Premium].item_id,
      plan_id: SubscriptionPlanData[PackageEnum.Premium].plan_id,
      benefits: [
        t(R.strings.premium_package_benefit_1),
        t(R.strings.premium_package_benefit_2),
        t(R.strings.premium_package_benefit_3),
        t(R.strings.premium_package_benefit_4),
        t(R.strings.premium_package_benefit_5),
        t(R.strings.premium_package_benefit_6),
        t(R.strings.premium_package_benefit_7),
      ],
    },
    {
      package: SubscriptionPlanData[PackageEnum.SmartSaving].name,
      title: t(R.strings.smart_saving_package_title),
      price: t(R.strings.smart_saving_package_price),
      priceNumber: 30,
      item_id: SubscriptionPlanData[PackageEnum.SmartSaving].item_id,
      plan_id: SubscriptionPlanData[PackageEnum.SmartSaving].plan_id,
      benefits: [
        t(R.strings.smart_saving_package_benefit_1),
        t(R.strings.smart_saving_package_benefit_2),
        t(R.strings.smart_saving_package_benefit_3),
      ],
    },
  ]
}

const ItemBenefit = ({ value }: { value: string }) => {
  return (
    <BaseRow align={'middle'} gutter={12}>
      <BaseCol>
        <SvgCheckedIcon opacity={0.7} />
      </BaseCol>
      <BaseCol>
        <div className="value-free-trial">{value}</div>
      </BaseCol>
    </BaseRow>
  )
}

const LayoutThreeHome = () => {
  const { ref, inView } = useInView({ triggerOnce: true })
  const classNameView = useMemo(() => {
    if (inView) {
      return 'text-opacity-up-to'
    }
    return ''
  }, [inView])

  const classViewLeft = useMemo(() => {
    if (inView) {
      return 'container-left-to'
    }
    return ''
  }, [inView])

  const classViewRight = useMemo(() => {
    if (inView) {
      return 'container-right-to'
    }
    return ''
  }, [inView])

  const classViewCard = useMemo(() => {
    if (inView) {
      return {
        premium: 'premium-card-flip',
        basic: 'basic-card-flip',
        saving: 'saving-card-flip',
      }
    }
    return {
      premium: '',
      basic: '',
      saving: '',
    }
  }, [inView])

  return (
    <S.RootWrapper>
      <BaseCol xl={24} ref={ref}>
        <div
          className={classNameView}
          style={{
            fontSize: 14,
            color: 'black',
            fontWeight: 'normal',
            textAlign: 'center',
          }}
        >
          {'Boost your productivity'}
        </div>

        <S.TitleText className={classViewLeft}>
          {'A more effective way'}
        </S.TitleText>
        <S.TitleTextEfficiency className={classViewRight}>
          {'to track progress'}
        </S.TitleTextEfficiency>

        <div
          className={classNameView}
          style={{
            fontSize: 12,
            fontWeight: 'normal',
            textAlign: 'center',
            marginTop: 16,
          }}
        >
          {'Kiosify: Elevate Your Customer Experience with Self-Service'}
        </div>
      </BaseCol>

      <S.ListWrapper size={32}>
        <S.BaseItemCard
          direction="vertical"
          size={24}
          className={`base-card ${classViewCard.saving}`}
        >
          <div className="label">{'Smart Saving'}</div>

          <BaseRow align={'middle'} gutter={8}>
            <BaseCol>
              <div className="price">{'$30'}</div>
            </BaseCol>
            <BaseCol>
              <div className="time-package">{'/monthly'}</div>
            </BaseCol>
          </BaseRow>

          <div className="free-trial">{'Start 14 Days Trail'}</div>

          <BaseSpace size={12} direction="vertical">
            <ItemBenefit value="Per location" />
            <ItemBenefit value="Unlimited orders" />
            <ItemBenefit value="A dedicated account manager" />
            <ItemBenefit value="24/7 support" />
            <ItemBenefit value="Access to our online dashboard" />
            <ItemBenefit value="Reporting and analytics" />
          </BaseSpace>
        </S.BaseItemCard>

        <S.BaseItemCard
          direction="vertical"
          size={24}
          className={`base-card basic-card ${classViewCard.basic}`}
        >
          <BaseRow gutter={[12, 12]} align={'middle'} justify={'space-between'}>
            <BaseCol>
              <div className="label label-linear">{'Basic'}</div>
            </BaseCol>

            <BaseCol>
              <div className="most-popular">{'Most popular'}</div>
            </BaseCol>
          </BaseRow>

          <BaseRow align={'middle'} gutter={8}>
            <BaseCol>
              <div className="price">{'$50'}</div>
            </BaseCol>
            <BaseCol>
              <div className="time-package time-package-bold">{'/monthly'}</div>
            </BaseCol>
          </BaseRow>

          <div className="sign-up-now">{'Sign up now'}</div>

          <BaseSpace size={12} direction="vertical">
            <ItemBenefit value="Per location" />
            <ItemBenefit value="Up to 3 Kiosks" />
            <ItemBenefit value="Unlimited orders" />
            <ItemBenefit value="A dedicated account manager" />
            <ItemBenefit value="24/7 support" />
            <ItemBenefit value="Access to our online dashboard" />
            <ItemBenefit value="Reporting and analytics" />
          </BaseSpace>
        </S.BaseItemCard>
        <S.BaseItemCard
          direction="vertical"
          size={24}
          className={`base-card premium-card ${classViewCard.premium}`}
        >
          <div className="label">{'Premium'}</div>

          <BaseRow align={'middle'} gutter={8}>
            <BaseCol>
              <div className="price">{'$80'}</div>
            </BaseCol>
            <BaseCol>
              <div className="time-package time-package-overwrite">
                {'/monthly'}
              </div>
            </BaseCol>
          </BaseRow>

          <div className="free-trial sign-up-button ">{'Sign up now'}</div>

          <BaseSpace size={12} direction="vertical">
            <ItemBenefit value="Marketing support" />
            <ItemBenefit value="Multi-location support" />
            <ItemBenefit value="Customizable branding" />
            <ItemBenefit value="Free KDS" />
            <ItemBenefit value="Unlimited Orders" />
            <ItemBenefit value="Up to 10 Kioks" />
            <ItemBenefit value="Per location" />
            <ItemBenefit value="Advanced security features" />
          </BaseSpace>
        </S.BaseItemCard>
      </S.ListWrapper>
    </S.RootWrapper>
  )
}
export default LayoutThreeHome

import { BaseSelect } from 'app/components/common/selects/BaseSelect'
import { FONT_SIZE, FONT_WEIGHT } from 'parkway-web-common'
import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { LANGUAGES } from './data'
import { IPropsLanguagePicker } from './type'
import { LanguageSupportType } from 'app/i18n/constant'
import { requestChangeLanguageUser } from 'app/api/auth'
import { useAppDispatch } from 'app/redux/hooks'
import { Loading } from 'app/components/common/Loading'
import { changeLanguageUser } from 'app/redux/slices/userSlice'

export const LanguagePicker: React.FC<IPropsLanguagePicker> = ({
  type = 'guest',
  initValue,
  onChange,
}) => {
  const { t, i18n } = useTranslation()

  const dispatch = useAppDispatch()
  const [loading, setLoading] = useState(false)

  const options = useMemo(
    () =>
      LANGUAGES.map(item => ({
        value: item.value,
        label: (
          <>
            <span className="flag">{item.flag}</span>
            <span className="label">{t(item.label)}</span>
          </>
        ),
      })),
    [i18n.language],
  )

  const onChangeLanguageUser = async (value: LanguageSupportType) => {
    setLoading(true)
    try {
      await requestChangeLanguageUser(value)
      dispatch(changeLanguageUser(value))
      // value
      console.log(value)
    } catch (error) {
      console.log({ error })
    } finally {
      setLoading(false)
    }
  }

  const handleChangeLanguage = value => {
    if (onChange) {
      onChange(value)
      return
    }
    switch (type) {
      case 'user':
        onChangeLanguageUser(value)
        break

      default:
        break
    }

    i18n.changeLanguage(value)
  }

  const language = useMemo(() => {
    if (initValue) return initValue

    return i18n.language
  }, [i18n, initValue])

  return (
    <>
      <CustomSelectionLanguage
        value={language.toLowerCase()}
        options={options}
        popupMatchSelectWidth={false}
        placement="bottomRight"
        onChange={handleChangeLanguage}
      />
      {loading && <Loading />}
    </>
  )
}

const CustomSelectionLanguage = styled(BaseSelect)`
  min-width: 114px !important;
  width: 100%;
  height: 40px;
  .flag {
    margin-right: 8px;
    img {
      width: 20px !important;
      height: 14px !important;
      object-fit: cover;
    }
  }
  .label {
    font-size: ${FONT_SIZE.xs};
    font-weight: ${FONT_WEIGHT.medium};
    color: black;
  }

  .ant-select-arrow {
    color: #475569;
    top: 50% !important;
  }
  .ant-select-selection-item {
    display: flex;
    align-items: center;
    .flag {
      margin-left: 4px;
    }
    .label {
      color: var(--primary-color);
    }
  }
  .ant-select-dropdown {
    background-color: var(--background-color);
    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
      background-color: #29cc6a38 !important;
    }
  }
`

import { BaseAvatar } from 'app/components/common/BaseAvatar'
import { BaseCol } from 'app/components/common/BaseCol'
import { BasePopover } from 'app/components/common/BasePopover'
import { BaseRow } from 'app/components/common/BaseRow'
import React from 'react'
import { ProfileOverlay } from '../ProfileOverlay/ProfileOverlay'
import * as S from './styles'

export const ProfileDropdown: React.FC = () => {
  return (
    <BasePopover content={<ProfileOverlay />} trigger="click">
      <S.ProfileDropdownHeader as={BaseRow} gutter={[10, 10]} align="middle">
        <BaseCol>
          <BaseAvatar
            src={
              'https://storage.googleapis.com/kiosify-01/images/ic_avatar.png'
            }
            alt="User"
            shape="circle"
            size={32}
          />
        </BaseCol>
      </S.ProfileDropdownHeader>
    </BasePopover>
  )
}

import { BaseDivider } from 'app/components/common/BaseDivider'
import { BaseTypography } from 'app/components/common/BaseTypography'
import { FONT_SIZE, media } from 'parkway-web-common'
import styled from 'styled-components'

export const Text = styled(BaseTypography.Text)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  font-size: 0.875rem;
  font-weight: 600;

  & > a {
    display: block;
    color: var(--primary-color);
  }

  @media only screen and ${media.md} {
    font-size: ${FONT_SIZE.xs};
  }
`

export const ItemsDivider = styled(BaseDivider)`
  margin: 0;
`

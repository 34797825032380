const videos = {
    
    img_demo_3d: require("./videos/img_demo_3d.mp4"),
    lasted: require("./videos/lasted.mp4"),
    personalized: require("./videos/personalized.mp4"),
    recomandations: require("./videos/recomandations.mp4"),
    second: require("./videos/second.mp4"),
    vd_demo_3d_01: require("./videos/vd_demo_3d_01.mp4"),
    vd_demo_3d_02: require("./videos/vd_demo_3d_02.mp4"),
    vd_demo_3d_03: require("./videos/vd_demo_3d_03.mp4")
    }
export default videos
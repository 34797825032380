/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  IParamsList,
  IParamsUpdateStatus,
} from 'app/api/product-management/model'
import {
  requestChangeKioskStatusByCode,
  requestCreateKiosk,
  requestGetKioskList,
  requestUpdateKiosk,
} from 'app/api/kiosk/api'
import {
  ICreateKiosk,
  IKiosk,
  IUpdateKiosk,
  ResponseKioskList,
} from 'app/api/kiosk/model'
import { LOGOUT_PATH } from 'app/components/router/route-path'
import { useInfiniteQuery, useMutation, useQueryClient } from 'react-query'
import { useNavigate } from 'react-router'
import { KioskKeys } from '../query-key/kiosk'
import { IResponseDefine } from 'app/common/model'
import { notificationController } from 'app/controllers/notification-controller'
import { t } from 'i18next'
import R from 'app/assets/R'
import { setPathSetting } from 'app/redux/slices/pathSettingSlice'
import { URL_SETTING } from 'app/containers/admin/Settings/constant'
import { useAppDispatch } from 'app/redux/hooks'

export function useGetKioskList(params?: IParamsList, enabled = true) {
  const navigate = useNavigate()
  return useInfiniteQuery<IKiosk[], IParamsList>(
    KioskKeys.kioskList(params),
    async () => {
      try {
        const response: ResponseKioskList = await requestGetKioskList({
          ...params,
        })

        return response?.results ?? []
      } catch (error: any) {
        console.log({ error }, 'product-management.ts')
        if (error?.message === '401') {
          navigate(LOGOUT_PATH, { replace: true })
          return []
        }
        return []
      }
    },
    {
      enabled,
      cacheTime: 5000,
    },
  )
}

export const useUpdateKioskStatusByCode = () => {
  const navigate = useNavigate()
  const query = useQueryClient()
  const dispatch = useAppDispatch()
  return useMutation<
    IResponseDefine<boolean>,
    unknown,
    IParamsUpdateStatus,
    unknown
  >(
    async (params: any) => {
      try {
        const res = await requestChangeKioskStatusByCode(params)
        return res
      } catch (error: any) {
        if (error?.message === '401') {
          navigate(LOGOUT_PATH, { replace: true })
        }
      }
    },
    {
      onSuccess: () => {
        query.invalidateQueries({
          queryKey: KioskKeys.all,
        })
        dispatch(setPathSetting(URL_SETTING.Kiosks))
      },
    },
  )
}

export const useUpdateKiosksByCode = () => {
  const navigate = useNavigate()
  const query = useQueryClient()
  const dispatch = useAppDispatch()
  return useMutation<IResponseDefine<boolean>, unknown, IUpdateKiosk, unknown>(
    async (body: any) => {
      try {
        const res = await requestUpdateKiosk(body)
        notificationController.success({
          message: t(R.strings.success_message_update_kiosk),
        })
        return res
      } catch (error: any) {
        if (error?.message === '401') {
          navigate(LOGOUT_PATH, { replace: true })
        }
      }
    },
    {
      onSuccess: () => {
        query.invalidateQueries({
          queryKey: KioskKeys.all,
        })
        dispatch(setPathSetting(URL_SETTING.Kiosks))
      },
    },
  )
}

export const useCreateKiosks = () => {
  const navigate = useNavigate()
  const query = useQueryClient()
  const dispatch = useAppDispatch()
  return useMutation<IResponseDefine<boolean>, unknown, ICreateKiosk, unknown>(
    async (body: any) => {
      try {
        console.log({ body })

        const res = await requestCreateKiosk(body)

        notificationController.success({
          message: t(R.strings.success_message_create_kiosk),
        })
        return res
      } catch (error: any) {
        if (error?.message === '401') {
          navigate(LOGOUT_PATH, { replace: true })
        }
      }
    },
    {
      onSuccess: () => {
        query.invalidateQueries({
          queryKey: KioskKeys.all,
        })
        dispatch(setPathSetting(URL_SETTING.Kiosks))
      },
    },
  )
}

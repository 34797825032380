/* eslint-disable @typescript-eslint/no-explicit-any */
import { Rule } from 'antd/es/form'
import { requestPostResetPassword } from 'app/api/auth'
import R from 'app/assets/R'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { BaseTemplatePageAuth } from 'app/components/layouts/AuthLayout/Component'
import * as Auth from 'app/components/layouts/AuthLayout/styles'
import { AUTH_CHANGE_PASSWORD_PATH } from 'app/components/router/route-path'
import { REG_EMAIL } from 'parkway-web-common'
import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import * as S from './styles'

interface ResetPasswordFormData {
  email: string
}

interface RuleResetPassword {
  email: Rule[]
}

export const initValues: ResetPasswordFormData = {
  email: '',
}

const ResetPasswordContainer: React.FC = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const [isLoading, setLoading] = useState(false)

  const handleSubmit = async (values: ResetPasswordFormData) => {
    try {
      setLoading(true)
      const res = await requestPostResetPassword({
        email: values.email,
      })

      if (res) {
        navigate(AUTH_CHANGE_PASSWORD_PATH, {
          state: {
            email: values.email,
          },
        })
      }
    } catch (error: any) {
      console.log({ error })
    } finally {
      setLoading(false)
    }
  }

  const rule: RuleResetPassword = useMemo(() => {
    return {
      email: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.email),
          }),
        },
        {
          pattern: REG_EMAIL,
          message: t(R.strings.regex_email),
        },
      ],
    }
  }, [t])

  return (
    <BaseTemplatePageAuth>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flex: 1,
          height: 'calc(100% - 10%)',
        }}
      >
        <div style={{ width: '100%', paddingInline: 32 }}>
          <BaseForm
            layout="vertical"
            onFinish={handleSubmit}
            requiredMark="optional"
            initialValues={initValues}
          >
            <BaseSpace>
              <Auth.FormTitle>{'Forgot password'}</Auth.FormTitle>

              <S.DescriptionText>
                {t(
                  R.strings
                    .enter_your_email_address_and_we_ll_send_you_an_email_with_instructions_to_reset_your_password,
                )}
              </S.DescriptionText>
              <BaseCol xl={24}>
                <Auth.FormItem name="email" rules={rule.email}>
                  <Auth.FormInput placeholder="Your email here..." />
                </Auth.FormItem>
              </BaseCol>
              <BaseRow justify={'center'}>
                <BaseForm.Item noStyle>
                  <Auth.SubmitButton htmlType="submit" loading={isLoading}>
                    {t(R.strings.reset)}
                  </Auth.SubmitButton>
                </BaseForm.Item>
              </BaseRow>
            </BaseSpace>
          </BaseForm>
        </div>
      </div>
    </BaseTemplatePageAuth>
  )
}
export default ResetPasswordContainer

import {
  IDataMostOrdered,
  IDataReportTotal,
  IDataReportTypeOrder,
  IParamsListGetReportOrder,
} from 'app/api/dashboard/type'
import R from 'app/assets/R'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { usePagination } from 'app/hook'
import {
  useGetMostOrdered,
  useGetReportTotal,
  useGetReportTypeOrder,
} from 'app/react-query/hook/dashboard'
import { moment } from 'parkway-web-common'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MostOrderLayout } from './components'
import CustomChart from './components/CustomTypeChart'
import RevenueCard from './components/RevenueCard'
import TableOrderDashboard from './components/TableOrder'
import * as S from './styles'
import { BaseSpace } from 'app/components/common/BaseSpace'

export enum FilterReportOrderEnum {
  Today = 'today',
  ThisWeek = 'this_week',
  ThisMonth = 'this_month',
}
interface DateRange {
  startDate: string
  endDate: string
}

export const getMonthRange = (): DateRange => {
  const startDate = moment().startOf('month').toISOString()
  const endDate = moment().endOf('month').toISOString()
  return { startDate, endDate }
}

export const getWeekRange = (): DateRange => {
  const startDate = moment().startOf('week').toISOString()
  const endDate = moment().endOf('week').toISOString()
  return { startDate, endDate }
}

export const getTodayRange = (): DateRange => {
  const startDate = moment().startOf('day').toISOString()
  const endDate = moment().endOf('day').toISOString()
  return { startDate, endDate }
}

const DashboardContainer = () => {
  const { t, i18n } = useTranslation()
  // const [time, setTime] = useState<FilterReportOrderEnum>(
  //   FilterReportOrderEnum.Today,
  // )

  const [params, setParams] = useState<IParamsListGetReportOrder>({
    page: 1,
    page_size: 10,
    end_date: getTodayRange().endDate,
    start_date: getTodayRange().startDate,
  })

  // const onChangeTimeType = (value: FilterReportOrderEnum) => {
  //   setTime(value)

  //   switch (value) {
  //     case FilterReportOrderEnum.Today:
  //       setParams(prev => ({
  //         ...prev,
  //         end_date: getTodayRange().endDate,
  //         start_date: getTodayRange().startDate,
  //       }))
  //       break
  //     case FilterReportOrderEnum.ThisWeek:
  //       setParams(prev => ({
  //         ...prev,
  //         end_date: getWeekRange().endDate,
  //         start_date: getWeekRange().startDate,
  //       }))
  //       break
  //     case FilterReportOrderEnum.ThisMonth:
  //       setParams(prev => ({
  //         ...prev,
  //         end_date: getMonthRange().endDate,
  //         start_date: getMonthRange().startDate,
  //       }))
  //       break
  //   }
  // }

  const today = moment().locale(i18n.language)

  const { flattenDataList } = usePagination()

  const { data: dataReportTotal, isLoading: loadingReportTotal } =
    useGetReportTotal()

  const reportTotal = useMemo(() => {
    const res: IDataReportTotal = flattenDataList(dataReportTotal)
    return res
  }, [dataReportTotal])

  const { data: dataMostOrdered, isLoading: loadingMostOrdered } =
    useGetMostOrdered()

  const mostOrdered = useMemo(() => {
    const res: IDataMostOrdered[] = flattenDataList(dataMostOrdered)

    return res ?? []
  }, [dataMostOrdered])

  const { data: dataReportTypeOrder, isLoading: loadingReportTypeOrder } =
    useGetReportTypeOrder()

  const reportTypeOrder = useMemo(() => {
    const res: IDataReportTypeOrder = flattenDataList(dataReportTypeOrder)

    return res ?? {}
  }, [dataReportTypeOrder])

  const onChangePage = (page: number, pageSize?: number) => {
    setParams({
      ...params,
      page,
      page_size: pageSize ?? 10,
    })
  }

  return (
    <S.RootWrapper>
      <BaseRow align={'middle'} gutter={32}>
        <BaseCol>
          <S.HomeText>{t(R.strings.dashboard)}</S.HomeText>
        </BaseCol>
        <BaseCol>
          <S.TextDate>{today.format('dddd, D MMM YYYY')}</S.TextDate>
        </BaseCol>
      </BaseRow>

      <BaseSpace size={32}>
        <BaseRow gutter={[32, 32]} justify={'space-between'} align={'top'}>
          <BaseCol xl={12}>
            <RevenueCard
              loading={loadingReportTotal}
              reportTotal={reportTotal}
            />
          </BaseCol>
          <BaseCol xl={12}>
            <CustomChart
              data={{
                pickup: reportTypeOrder?.total_pickup ?? 0,
                togo: reportTypeOrder?.total_togo ?? 0,
                for_here: reportTypeOrder?.total_forhere ?? 0,
              }}
              isLoading={loadingReportTypeOrder}
            />
          </BaseCol>
        </BaseRow>

        <BaseRow gutter={[32, 32]} align={'top'} justify={'space-between'}>
          <BaseCol xl={16}>
            <TableOrderDashboard params={params} onChangePage={onChangePage} />
            {/* <S.TableWrapper direction="vertical" size={20}>
            <BaseRow
              align={'middle'}
              gutter={[15, 10]}
              justify={'space-between'}
            >
              <BaseCol>
                <S.HomeText>
                  {t(R.strings.dashboard_common_order_report)}
                </S.HomeText>
              </BaseCol>
              <BaseCol>
                <BaseButtonsForm.Item name="select-multiple">
                  <S.ChartHeaderSelectTime
                    defaultValue={FilterReportOrderEnum.Today}
                    onChange={value =>
                      onChangeTimeType(value as FilterReportOrderEnum)
                    }
                    value={time}
                  >
                    <Option value={FilterReportOrderEnum.Today}>
                      {t(R.strings.time_today)}
                    </Option>
                    <Option value={FilterReportOrderEnum.ThisWeek}>
                      {t(R.strings.time_this_week)}
                    </Option>
                    <Option value={FilterReportOrderEnum.ThisMonth}>
                      {t(R.strings.time_this_month)}
                    </Option>
                  </S.ChartHeaderSelectTime>
                </BaseButtonsForm.Item>
              </BaseCol>
            </BaseRow>
            <TableOrderDashboard params={params} onChangePage={onChangePage} />
          </S.TableWrapper> */}
          </BaseCol>
          <BaseCol xl={8}>
            <MostOrderLayout
              mostOrdered={mostOrdered}
              isLoading={loadingMostOrdered}
            />
          </BaseCol>
        </BaseRow>
      </BaseSpace>
    </S.RootWrapper>
  )
}

export default DashboardContainer

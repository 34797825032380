import _ from 'lodash'
import { Pagination } from 'parkway-web-common'

export const initialPagination: Pagination = {
  current: 1,
  pageSize: 30,
}

export function randomMoney(max?: number): number {
  return _.random(max ?? 10000000)
}

export function randomPercent(): number {
  return _.random(100)
}

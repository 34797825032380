import R from 'app/assets/R'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { colors } from 'parkway-web-common'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useInView } from 'react-intersection-observer'
import * as S from './styles'
import './styles.css'
import { BaseSpace } from 'app/components/common/BaseSpace'

const ItemColumn = ({
  classNameView,
  source,
  description,
  title,
}: {
  classNameView?: string
  source: string
  title: string
  description: string
}) => {
  return (
    <S.ItemWrapper>
      <video
        autoPlay
        loop
        muted
        style={{
          width: '100%',
          height: '100%',
          position: 'absolute',
          zIndex: 0,
          objectFit: 'cover',
          borderRadius: 16,
        }}
        src={source}
      />

      <div className="info">
        <S.AreaText className={classNameView}>
          <div
            style={{
              textAlign: 'center',
              color: colors.black,
              fontSize: 24,
              fontWeight: 'bold',
            }}
          >
            {title}
          </div>
        </S.AreaText>
        <div
          style={{
            textAlign: 'center',
            color: colors.black,
            fontSize: 16,
          }}
          className={classNameView}
        >
          {description}
        </div>
      </div>
    </S.ItemWrapper>
  )
}

const ContentLayout = () => {
  const { t } = useTranslation()
  const { ref, inView } = useInView({ triggerOnce: false })
  const { ref: ref01, inView: inView01 } = useInView({ triggerOnce: false })
  const { ref: ref02, inView: inView02 } = useInView({ triggerOnce: false })
  const classNameView = useMemo(() => {
    if (inView) {
      return 'text-opacity-up-to'
    }
    return ''
  }, [inView])

  const classItemViewDelay = useMemo(() => {
    if (inView01) {
      return {
        1: 'delay-animation-1s',
        2: 'delay-animation-2s',
      }
    }
    return {}
  }, [inView01])

  const classItemViewDelay02 = useMemo(() => {
    if (inView02) {
      return {
        3: 'delay-animation-3s',
        4: 'delay-animation-4s',
      }
    }
    return {}
  }, [inView02])

  return (
    <BaseCol style={{ marginTop: 120 }}>
      <div
        className={classNameView}
        style={{
          fontSize: 14,
          fontWeight: 'normal',
          textAlign: 'center',
          color: 'black',
        }}
        ref={ref}
      >
        {'Everything you need'}
      </div>

      <S.AreaText className={classNameView}>
        <S.TitleText className={classNameView}>
          {'Empowering Kiosk'}
        </S.TitleText>
      </S.AreaText>
      <S.TitleTextEfficiency className={classNameView}>
        {'Efficiency'}
      </S.TitleTextEfficiency>

      <S.AreaText />
      <S.AreaText />
      <S.AreaText />
      <S.AreaText />
      <BaseSpace direction="vertical" size={32}>
        <BaseRow gutter={[32, 32]} justify={'center'} ref={ref01}>
          <S.ColItem className={classItemViewDelay[1]}>
            <ItemColumn
              description={t(R.strings.streamlined_content_management_d)}
              title={t(R.strings.streamlined_content_management)}
              source={R.videos.img_demo_3d}
              classNameView={classNameView}
            />
          </S.ColItem>
          <S.ColItem className={classItemViewDelay[2]}>
            <ItemColumn
              title={t(R.strings.remote_kiosk_control)}
              description={t(R.strings.remote_kiosk_control_d)}
              source={R.videos.vd_demo_3d_01}
              classNameView={classNameView}
            />
          </S.ColItem>
        </BaseRow>
        <BaseRow gutter={[32, 32]} justify={'center'} ref={ref02}>
          <S.ColItem className={classItemViewDelay02?.[3]}>
            <ItemColumn
              title={t(R.strings.personalized_recommendations)}
              description={t(R.strings.personalized_recommendations_d)}
              source={R.videos.vd_demo_3d_02}
              classNameView={classNameView}
            />
          </S.ColItem>
          <S.ColItem className={classItemViewDelay02?.[4]}>
            <ItemColumn
              title={t(R.strings.advanced_reporting)}
              description={t(R.strings.advanced_reporting_d)}
              source={R.videos.vd_demo_3d_03}
              classNameView={classNameView}
            />
          </S.ColItem>
        </BaseRow>
      </BaseSpace>
    </BaseCol>
  )
}
export default ContentLayout

import {
  ArrowLeftOutlined,
  DeleteOutlined,
  EditOutlined,
} from '@ant-design/icons'
import { requestDeleteRestaurantListV2 } from 'app/api/restaurant/api'
import R from 'app/assets/R'
import {
  SvgCaretRightCollapseIcon,
  SvgCustomerRestautantIcon,
  SvgDishOrderRestaurantIcon,
  SvgOrdersRestaurantIcon,
  SvgSaleRevenueRestaurantIcon,
} from 'app/assets/svg-assets'
import { ButtonVariant } from 'app/components/common/BaseButtonVariant'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { URL_SETTING } from 'app/containers/admin/Settings/constant'
import { notificationController } from 'app/controllers/notification-controller'
import { convertAddressToString } from 'app/hook'
import { RestaurantKeys } from 'app/react-query/query-key/restaurant'
import { useAppDispatch, useAppSelector } from 'app/redux/hooks'
import { setPathSetting } from 'app/redux/slices/pathSettingSlice'
import { convertColor } from 'app/styles/themes/themeVariables'
import { isEmpty } from 'lodash'
import {
  BORDER_RADIUS,
  FONT_WEIGHT,
  formatMoney,
  PADDING,
} from 'parkway-web-common'
import { memo, ReactNode, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import * as S from './styles'
import { OpenTimeLayout } from './components'

const ItemInfo = ({ label, value }: { label: string; value?: string }) => {
  return (
    <S.InfoContentItemWrapper
      justify={'space-between'}
      gutter={16}
      align={'middle'}
    >
      <BaseCol>
        <S.InfoContentItemTitle>{label}</S.InfoContentItemTitle>
      </BaseCol>

      <BaseCol>
        <BaseText fontWeight="medium" children={isEmpty(value) ? '-' : value} />
      </BaseCol>
    </S.InfoContentItemWrapper>
  )
}

const ItemSaleRevenue = ({
  title,
  value,
  description,
  descriptionValue,
  image,
}: {
  title: string
  value: number
  description: string
  descriptionValue: number
  image: ReactNode
}) => {
  return (
    <BaseSpace
      style={{
        padding: PADDING.xxs,
        borderRadius: BORDER_RADIUS,
        border: `1px solid #00000040`,
        minHeight: 140,
      }}
      size={8}
    >
      <BaseRow justify={'space-between'} gutter={[16, 16]} align={'middle'}>
        <BaseCol>
          <BaseText
            children={title}
            fontWeight="semibold"
            fontFamily="Raleway"
          />
        </BaseCol>

        <BaseCol>
          <SvgCaretRightCollapseIcon style={{ cursor: 'pointer' }} />
        </BaseCol>
      </BaseRow>

      <BaseRow gutter={16} align={'bottom'} justify={'space-between'}>
        <BaseCol xl={14}>
          <BaseSpace size={8}>
            <BaseRow justify={'start'} align={'middle'} gutter={2}>
              <BaseCol>
                <BaseText
                  children={'$'}
                  fontWeight="bold"
                  style={{ color: '#818181' }}
                />
              </BaseCol>
              <BaseCol>
                <BaseText
                  children={formatMoney(value)}
                  fontSize="md"
                  fontWeight="bold"
                />
              </BaseCol>
            </BaseRow>

            <span
              style={{
                color: '#B0B0B0',
                fontWeight: FONT_WEIGHT.medium,
                fontSize: 10,
              }}
            >
              {description}
              <span
                style={{
                  color: convertColor.primaryColor,
                  fontWeight: FONT_WEIGHT.semibold,
                }}
              >
                {' '}
                ${formatMoney(descriptionValue)}
              </span>
            </span>
          </BaseSpace>
        </BaseCol>

        <BaseCol>{image}</BaseCol>
      </BaseRow>
    </BaseSpace>
  )
}

const RestaurantDetailLayout = () => {
  const { t } = useTranslation()
  const query = useQueryClient()
  const dispatch = useAppDispatch()
  const [isLoading, setLoading] = useState(false)
  const dataRestaurant = useAppSelector(
    state => state.pathSetting,
  )?.restaurantData

  useEffect(() => {
    if (!dataRestaurant) {
      dispatch(setPathSetting(URL_SETTING.Restaurant))
    }
  }, [])

  const goBack = () => {
    return dispatch(setPathSetting(URL_SETTING.Restaurant))
  }

  const addressRestaurant = useMemo(() => {
    return convertAddressToString(dataRestaurant?.address)
  }, [dataRestaurant])

  const onPressEdit = () => {
    dispatch(setPathSetting(URL_SETTING.RestaurantUpdate))
  }

  const onPressDelete = async () => {
    if (!dataRestaurant?.id) return

    try {
      setLoading(true)
      const res = await requestDeleteRestaurantListV2(dataRestaurant?.id)
      if (res) {
        query.invalidateQueries({
          queryKey: RestaurantKeys.all,
        })

        notificationController.success({
          message: t(R.strings.save_success),
        })

        goBack()
      }
    } catch (error) {
      console.log({ error })
    } finally {
      setLoading(false)
    }
  }

  return (
    <S.RootWrapper>
      <S.HeaderWrapper justify={'space-between'} align={'middle'}>
        <BaseCol>
          <BaseRow gutter={8} align={'middle'}>
            <BaseCol>
              <S.BackButton onClick={goBack}>
                <ArrowLeftOutlined rev={undefined} />
              </S.BackButton>
            </BaseCol>
            <BaseCol>
              <BaseText
                fontWeight="semibold"
                fontSize="xl"
                fontFamily="Raleway"
              >
                {dataRestaurant?.businessName ?? '-'}
              </BaseText>
            </BaseCol>
          </BaseRow>
        </BaseCol>
        <BaseCol>
          <BaseRow gutter={16} align={'middle'}>
            <BaseCol>
              <ButtonVariant
                children={<BaseText children={'Edit'} fontWeight="semibold" />}
                onClick={onPressEdit}
                icon={
                  <EditOutlined
                    rev={undefined}
                    style={{ color: convertColor.primaryColor }}
                  />
                }
              />
            </BaseCol>

            <BaseCol>
              <ButtonVariant
                onClick={onPressDelete}
                icon={
                  <DeleteOutlined rev={undefined} style={{ color: 'red' }} />
                }
                children={
                  <BaseText
                    children={'Delete'}
                    style={{ color: 'red' }}
                    fontWeight="semibold"
                  />
                }
                loading={isLoading}
              />
            </BaseCol>
          </BaseRow>
        </BaseCol>
      </S.HeaderWrapper>

      <BaseSpace style={{ padding: `0 ${PADDING.xxs}` }}>
        <S.InfoContentWrapper direction="vertical" size={15}>
          <ItemInfo
            label={t(R.strings.information_branch)}
            value={dataRestaurant?.businessName}
          />
          <ItemInfo
            label={t(R.strings.information_location)}
            value={addressRestaurant}
          />
          <ItemInfo
            label={t(R.strings.information_phone_no)}
            value={dataRestaurant?.phoneNumber}
          />
          <ItemInfo
            label={t(R.strings.information_email)}
            value={dataRestaurant?.email}
          />
        </S.InfoContentWrapper>

        <BaseRow gutter={[16, 16]} align={'middle'} justify={'space-between'}>
          <BaseCol xl={12}>
            <ItemSaleRevenue
              title="Sales Revenue"
              value={10000}
              description="Total sales revenue increased this month by about"
              descriptionValue={400}
              image={<SvgSaleRevenueRestaurantIcon />}
            />
          </BaseCol>
          <BaseCol xl={12}>
            <ItemSaleRevenue
              title="Customers"
              value={23456}
              description="Total customers increased this month by about"
              descriptionValue={900}
              image={<SvgCustomerRestautantIcon />}
            />
          </BaseCol>
        </BaseRow>

        <BaseRow gutter={[16, 16]} align={'middle'} justify={'space-between'}>
          <BaseCol xl={12}>
            <ItemSaleRevenue
              title="Dish Ordered"
              value={12923}
              description="Total dish ordered increased this month by about"
              descriptionValue={44200}
              image={<SvgDishOrderRestaurantIcon />}
            />
          </BaseCol>
          <BaseCol xl={12}>
            <ItemSaleRevenue
              title="Orders"
              value={305320}
              description="Total orders increased this month by about"
              descriptionValue={92030}
              image={<SvgOrdersRestaurantIcon />}
            />
          </BaseCol>
        </BaseRow>

        <OpenTimeLayout />
      </BaseSpace>
    </S.RootWrapper>
  )
}

export default memo(RestaurantDetailLayout)

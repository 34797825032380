/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { CloseOutlined } from '@ant-design/icons'
import { requestPostModifier } from 'app/api/product-management/api'
import {
  IMultipleModifiers,
  IPostModifier,
} from 'app/api/product-management/model'
import R from 'app/assets/R'
import { ButtonVariant } from 'app/components/common/BaseButtonVariant'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseCollapse } from 'app/components/common/BaseCollapse'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { CurrencySupportType } from 'app/components/common/CurrencyPicker/type'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { IRefModal, ModalComponent } from 'app/components/common/ModalComponent'
import { notificationController } from 'app/controllers/notification-controller'
import { ProductManagementKeys } from 'app/react-query/query-key/product-management'
import { convertColor } from 'app/styles/themes/themeVariables'
import { t } from 'i18next'
import { BORDER_RADIUS, PADDING } from 'parkway-web-common'
import { useRef, useState } from 'react'
import { useQueryClient } from 'react-query'
import styled from 'styled-components'
import { HookFormModal } from '../hook'

export const CreateModal = () => {
  const query = useQueryClient()
  const refModal = useRef<IRefModal>(null)
  const [form] = BaseForm.useForm<IPostModifier>()
  const [currency, setCurrency] = useState<CurrencySupportType>('USD')

  const [loadingSave, setLoadingSave] = useState(false)

  const [locationIdsSelected, setLocationIdsSelected] = useState<string[]>([])
  const [priceListsSelected, setPriceListsSelected] = useState<
    IMultipleModifiers[]
  >([{ name: '', price: '' }])

  const openModal = () => {
    refModal.current?.open?.()
  }

  const hideModal = () => {
    refModal.current?.hide?.()
  }

  const onSave = async (values: IPostModifier) => {
    try {
      setLoadingSave(true)
      const res = await requestPostModifier({
        name: values?.name,
        locationIds: locationIdsSelected,
        type: 'Multiple',
        multipleModifiers: priceListsSelected?.map((item, index) => ({
          name: item?.name,
          price: item?.price,
          position: index,
          currency,
        })),
        displayName: values?.displayName,
      })

      if (res) {
        setLocationIdsSelected([])
        setPriceListsSelected([])
        form.resetFields()
        query.invalidateQueries({
          queryKey: ProductManagementKeys.allModifier,
        })

        notificationController.success({
          message: t(R.strings.save_success),
        })

        hideModal()
      }
    } catch (error) {
      console.log({ error })
    } finally {
      setLoadingSave(false)
    }
  }

  return (
    <ModalComponent
      ref={refModal}
      titleModal="Add new"
      renderContent={
        <BaseSpace>
          <BaseForm form={form} onFinish={onSave}>
            <HookFormModal
              currency={currency}
              setCurrency={setCurrency}
              locationIdsSelected={locationIdsSelected}
              setLocationIdsSelected={setLocationIdsSelected}
              priceListsSelected={priceListsSelected}
              setPriceListsSelected={setPriceListsSelected}
            />
          </BaseForm>
        </BaseSpace>
      }
      footer={
        <div style={{ padding: '0px 20px', width: '100%' }}>
          <ButtonVariant
            onClick={form.submit}
            style={{ width: '100%' }}
            loading={loadingSave}
          >
            <BaseText children={'Add new'} fontWeight="semibold" />
          </ButtonVariant>
        </div>
      }
      rightHeader={
        <BaseCol>
          <CloseOutlined rev={undefined} onClick={hideModal} />
        </BaseCol>
      }
      buttonOpenCustom={
        <ButtonVariant
          children={<BaseText children={'Add new'} fontWeight="semibold" />}
          onClick={openModal}
          style={{ width: '100%' }}
        />
      }
    />
  )
}

const BaseCollapseWrapper = styled(BaseCollapse)`
  width: 100%;

  .ant-collapse-content {
    border-top: 1px solid ${convertColor.neutralBlack9Color};
    background-color: ${convertColor.backgroundColor};
  }

  .ant-collapse-header {
    background-color: var(--background-color);
    border-radius: ${BORDER_RADIUS}!important;
    align-items: center !important;
  }

  .ant-collapse-header[aria-expanded='true'] {
    border-radius: ${BORDER_RADIUS}!important;
    border-bottom-left-radius: ${BORDER_RADIUS} !important;
    border-bottom-right-radius: ${BORDER_RADIUS} !important;
  }

  .header-content {
    padding: ${PADDING.md};
    background-color: #f9fafb;
  }
`

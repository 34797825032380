import { ApiClient, ApiClientV2 } from 'app/service/api-service'
import { RestaurantApiEndpoint } from './contant'
import {
  IParamsRestaurantList,
  IParamsRestaurantListV2,
  IPayloadCreateRestaurantV2,
  IPayloadUpdateLocationTime,
  IRestaurant,
} from './model'

export const requestGetRestaurantList = (params: IParamsRestaurantList) =>
  ApiClient.Get({
    url: RestaurantApiEndpoint.GET_RESTAURANT_LIST,
    params: { params },
  })

export const requestGetRestaurantDetail = (id?: string) =>
  ApiClient.Get({
    url: RestaurantApiEndpoint.GET_RESTAURANT_DETAIL.replace('{id}', id ?? ''),
    params: { id },
  })

export const requestSyncRestaurant = (body: IRestaurant) =>
  ApiClient.Post({ url: RestaurantApiEndpoint.SYNC_RESTAURANT, body })

export const requestUploadImageRestaurant = (body: FormData) =>
  ApiClient.Post({ url: RestaurantApiEndpoint.UPDATE_IMAGE, body })

export const requestUpdateRestaurantOpenTime = ({
  id,
  ...body
}: IPayloadUpdateLocationTime) =>
  ApiClient.Put({ url: `${RestaurantApiEndpoint.BUSINESS_HOURS}${id}`, body })

export const requestDeleteRestaurantOpenTime = (id: string) =>
  ApiClient.Delete({
    url: `${RestaurantApiEndpoint.BUSINESS_HOURS}${id}`,
    body: {},
  })

export const requestCreateRestaurantOpenTime = (
  body: IPayloadUpdateLocationTime,
) => ApiClient.Post({ url: RestaurantApiEndpoint.BUSINESS_HOURS, body })

// v2
export const requestGetRestaurantListV2 = (body: IParamsRestaurantListV2) =>
  ApiClientV2.Post({
    url: RestaurantApiEndpoint.GET_LOCATION_V2,
    body,
  })
export const requestGetRestaurantDetailV2 = (id: string) =>
  ApiClientV2.Get({
    url: `${RestaurantApiEndpoint.LOCATION_V2}/${id}`,
    params: {},
  })

export const requestPostRestaurantListV2 = (body: IPayloadCreateRestaurantV2) =>
  ApiClientV2.Post({
    url: RestaurantApiEndpoint.LOCATION_V2,
    body,
  })

export const requestUpdateRestaurantListV2 = (
  id: string,
  body: IPayloadCreateRestaurantV2,
) =>
  ApiClientV2.Put({
    url: `${RestaurantApiEndpoint.LOCATION_V2}/${id}`,
    body,
  })

export const requestDeleteRestaurantListV2 = (id: string) =>
  ApiClientV2.Delete({
    url: `${RestaurantApiEndpoint.LOCATION_V2}/${id}`,
    body: {},
  })

export const RestaurantApiEndpoint = {
  GET_RESTAURANT_LIST: '/merchant/location/get-location/',
  SYNC_RESTAURANT: 'merchant/location/sync-locations/',
  UPDATE_IMAGE: 'merchant/location/image/',
  BUSINESS_HOURS: 'merchant/bussiness-hours/',
  GET_RESTAURANT_DETAIL: '/merchant/location/{id}/detail/',

  LOCATION_V2: 'locations',
  GET_LOCATION_V2: 'locations/search',
}

/* eslint-disable @typescript-eslint/no-explicit-any */
import { IResponseDefine } from 'app/common/model'
import { CurrencySupportType } from 'app/components/common/CurrencyPicker/type'

export interface ICategory {
  id?: string
  name?: string
  item_count?: number
  status?: boolean
  image_upload?: string
  description?: string

  position?: number
  itemCount?: number
  image?: string

  displayName?: string
}

export interface IParamsList {
  page_size?: number
  page?: number
  search?: string
  location_id?: string
  category_id?: string
  name?: string
  start_date?: string
  end_date?: string
  location?: string
  item_id?: string
}

export interface IParamsListV2 {
  page_size?: number
  page?: number
  searchKey?: string
  size?: number

  // items?:
  categoryId?: string
  locationId?: string
}

export interface IPostCategory {
  name: string
  imageId?: string
  displayName?: string
}

export interface IPostItems {
  name?: string
  categoryId?: string
  imageId?: string
  description?: string
  locationIds?: string[]
  modifierListIds?: string[]
  itemVariations?: IVariationProductManagement[]
  displayName?: string

  taxIncluded?: boolean
  taxRate?: number
}

export interface IPostModifier {
  name: string
  type: 'Single' | 'Multiple'
  multipleModifiers: IMultipleModifiers[]
  locationIds: string[]
  displayName?: string
}

export interface IPostUpSell {
  name?: string
  imageId?: string
  upsellAt?: string
  itemIds?: string[]
  price?: number
  locationIds?: string[]
  displayName?: string
  currency?: CurrencySupportType
}

export interface IParamsUpdateStatus {
  id?: string
  status?: boolean
  bestseller?: boolean
  code?: string
}

export const ParamsListDefault: IParamsList = {
  page_size: 10,
  page: 1,
  search: '',
  name: '',
  category_id: '',
  location_id: '',
  location: '',
}

export const ParamsListDefaultV2: IParamsListV2 = {
  page_size: 10,
  page: 1,
  searchKey: '',
}

export interface IResponseQueryList<T> {
  total?: number
  data: T
}

export interface IPropsTableProductManagement<T> {
  data: T[]
  total: number
  onChangePage: (page: number, pageSize?: number) => void
  loadingList?: boolean
  params?: IParamsList
}

export interface ITax {
  tax_id?: string
  name?: string
  present_at_all_locations?: boolean
  present_at_location_ids?: string[]
  present_at_location_names?: string[]
  percentage?: string
}

export interface IMultipleModifiers {
  name?: string
  price?: number | string
  position?: number
  currency?: CurrencySupportType
}
export interface IModifier {
  modifierlist_id?: string
  set_name?: string
  present_at_all_locations?: boolean
  present_at_location_ids?: string[]
  present_at_location_names?: string[]
  status?: boolean
  options?: string[]
  edit?: {
    list_id?: string[]
    modifiers?: string[]
    price?: number[]
    list_currency?: string[]
    image?: string[]
    list_items: IItemProductManagement[]
  }
  id?: string
  name?: string
  presentAtLocationNames?: string[]
  presentAtLocationIds?: string[]
  multipleModifiers?: IMultipleModifiers[]
  displayName?: string
}

interface IItemVariation {
  id: string
  name: string
  price: any
  currency: CurrencySupportType
}
export interface IItemProductManagement {
  item_id?: string
  name?: string
  present_at_all_locations?: boolean
  present_at_location_ids?: string[]
  present_at_location_names?: string[]
  upsell?: IUpSellProductManagement[]
  status?: boolean
  variations?: string[]
  variation_ids?: string[]
  price_range?: number[]
  price_list?: number[]
  currency_list?: string[]
  isSelected?: boolean
  bestseller?: boolean
  image?: string
  upsell_count?: number

  id?: string

  bestSeller?: boolean
  presentAtAllLocations?: boolean
  upsellCount?: number
  presentAtLocationIds?: string[]
  presentAtLocationNames?: string[]
  absentAtLocationIds?: string[]
  itemVariations?: IItemVariation[]
  categoryId?: string
  category?: ICategory

  displayName?: string
  taxIncluded?: boolean
  taxRate?: number
}

export interface IDiscountProductManagement {
  discount_id?: string
  name?: string
  present_at_all_locations?: boolean
  present_at_location_ids?: string[]
  present_at_location_names?: string[]
  discount_type?: string
  money_amount?: string
  apply_to?: string
  status?: boolean
}

interface IITemValue {
  id: string
  name?: string
}

export interface IUpSellProductManagement {
  id?: string
  item_id?: IITemValue[]
  upsell_at?: string
  item?: string
  selection_type?: string
  varation?: string
  price?: string
  max_qty?: number
  present_at_all_locations?: boolean
  present_at_location_ids?: string[]
  present_at_location_names?: string[]
  absent_at_location_ids?: string[]
  status?: string
  user_fk?: number
  item_by_upsell?: IITemValue[]
  image?: string
  name?: string
  currency?: CurrencySupportType

  presentAtLocationNames?: string[]
  itemIds?: string[]
  presentAtLocationIds?: string[]
  upsellAt?: string
  displayName?: string
}

export interface IParamsGetVariationByItemId {
  id?: string | number
}

export interface IVariationProductManagement {
  currency?: string
  id?: string
  item_id?: string
  location_overrides?: any
  measurement_unit_id?: string
  name?: string
  ordinal?: number
  price_money?: string
  user_fk?: number
  version?: number

  price?: string

  // using in cpn
  isSelected?: boolean
  displayName?: string
}

export type ResponseCategory = IResponseDefine<ICategory[]>

export type ResponseTax = IResponseDefine<ITax[]>

export type ResponseModifier = IResponseDefine<IModifier[]>

export type ResponseItem = IResponseDefine<IItemProductManagement[]>

export type ResponseDiscount = IResponseDefine<IDiscountProductManagement[]>

export type ResponseUpSell = IResponseDefine<IUpSellProductManagement[]>

export type ResponseVariation = IResponseDefine<IVariationProductManagement[]>

export interface IPayloadChangePositionCate {
  movedCategoryId: string
  newPosition: number
}

import React from 'react'
import { DesktopHeader } from './layouts/DesktopHeader'
import { MobileHeader } from './layouts/MobileHeader'
import { useResponsive } from 'parkway-web-common'

interface HeaderProps {
  toggleSlider: () => void
  isSliderOpened: boolean
  isTwoColumnsLayout: boolean
}

export const Header: React.FC<HeaderProps> = ({
  toggleSlider,
  isSliderOpened,
  isTwoColumnsLayout,
}) => {
  const { isTablet } = useResponsive()

  return isTablet ? (
    <DesktopHeader isTwoColumnsLayout={isTwoColumnsLayout} />
  ) : (
    <MobileHeader toggleSlider={toggleSlider} isSliderOpened={isSliderOpened} />
  )
}

import R from 'app/assets/R'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseImage } from 'app/components/common/BaseImage'
import { FONT_SIZE, FONT_WEIGHT, PADDING, colors } from 'parkway-web-common'
import styled from 'styled-components'

export const RootWrapper = styled(BaseCol)`
  padding: 48px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`

export const AreaText = styled.div`
  padding: ${PADDING.xxxxs} 0px;
  text-align: left;
  font-family: 'Plus Jakarta Sans', sans-serif !important;
`

export const DescriptionText = styled.span`
  color: ${colors.black};
  font-size: 20px;
  font-weight: ${FONT_WEIGHT.medium};
  display: flex;
  justify-content: center;
  font-family: 'Plus Jakarta Sans', sans-serif !important;
`
export const ImageWrapper = styled(BaseImage)`
  width: 100%;
  height: 100%;
  box-shadow: var(--box-shadow);
`

export const SmallWrapper = styled(BaseCol)`
  padding: ${PADDING.xs};
  display: flex;
  flex-direction: column;
  justify-content: end;
`

export const TextTitleSmall = styled.span`
  color: ${colors.black};
  font-size: ${FONT_SIZE.xxxl};
  font-weight: ${FONT_WEIGHT.bold};
  font-family: 'Plus Jakarta Sans', sans-serif !important;
`

export const TextDescriptionSmall = styled.span`
  color: ${colors.black};
  font-size: ${FONT_SIZE.md};
  font-weight: ${FONT_WEIGHT.medium};
  font-family: 'Plus Jakarta Sans', sans-serif !important;
`

export const TitleText = styled.span`
  font-size: 40px;
  font-weight: ${FONT_WEIGHT.bold};
  color: ${colors.black};
  text-align: center;
  display: flex;
  justify-content: center;
  font-family: 'Plus Jakarta Sans', sans-serif !important;
  width: 100%;

  display: inline-block; /* Để phù hợp với kích thước của chữ */
  background-image: linear-gradient(to bottom, #000000, #3c9f87);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
`
export const TitleTextEfficiency = styled.span`
  font-size: 26px;
  font-weight: ${FONT_WEIGHT.bold};
  color: ${colors.black};
  text-align: center;
  display: flex;
  justify-content: center;
  font-family: 'Plus Jakarta Sans', sans-serif !important;
  width: 100%;

  display: inline-block; /* Để phù hợp với kích thước của chữ */
  background-image: linear-gradient(to bottom, #276657, #60ffd9);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
`

export const ItemWrapper = styled.div<{ $width: number }>`
  border-radius: 30px;
  width: calc(100vw - 48px);
  height: 320px;
  position: relative;
  .info {
    position: absolute;
    bottom: 0;
    padding: 16px;
    align-items: center;
    text-align: center;
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    opacity: 0.7;
    width: 100%;
    justify-content: center;
    transition: height 2s ease, background-image 2s ease;
    border-radius: 0px 0px 30px 30px;
  }

  &:hover {
    .info {
      background-image: linear-gradient(
        to bottom,
        #fcfffd,
        #aaffef,
        #54e9cd,
        #fcfffd
      );
      height: 100%;
      transition: height 2s ease, background-image 2s ease;
      border-radius: 30px;
    }

    cursor: url('${R.images.ic_cursor}'), auto;
  }
`
export const ColItem = styled(BaseCol)``

import { ColorPicker } from 'antd'
import { IKioskKitchen } from 'app/api/kiosk-kitchen/model'
import R from 'app/assets/R'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseImage } from 'app/components/common/BaseImage'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { Loading } from 'app/components/common/Loading'
import { BaseTimePicker } from 'app/components/common/pickers/BaseTimePicker'
import { BaseSelect } from 'app/components/common/selects/BaseSelect'
import { LoadingWrapper, Text } from 'app/components/styles'
import {
  useGetKioskKitchen,
  useUpdateKioskKitchen,
} from 'app/react-query/hook/kiosk-kitchen'
import dayjs from 'dayjs'
import moment from 'moment'
import { memo, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  FONT_DATA,
  MESSAGING_DATA,
  ORDER_TYPE_COLORS_DATA,
  STANDARD_SCREEN,
  STANDARD_SCREEN_OTHER_OPTIONS,
  STATUS_COLORS_DATA,
  TRANSITION_TIMES_FOR_HERE,
  TRANSITION_TIMES_PICKUP,
  TRANSITION_TIMES_TO_GO,
  TransitionTimesListValueData,
} from './constant'
import * as S from './styles'
import styled from 'styled-components'
import { convertColor } from 'app/styles/themes/themeVariables'

const ItemTransitionTime = ({
  listValue,
  data,
  onChange,
  title,
}: {
  title: string
  listValue: TransitionTimesListValueData[]
  data: IKioskKitchen | undefined
  onChange: (body: IKioskKitchen, loadingTarget: LoadingTarget) => void
}) => {
  const { t } = useTranslation()

  return (
    <BaseSpace direction="vertical" size={10} style={{ width: '100%' }}>
      <S.ItemTransitionItemWrapper>
        <BaseRow gutter={[16, 16]} align={'middle'} justify={'space-between'}>
          {listValue?.map((value, index) => {
            const time: number = (data?.[value.key] as number) || 0
            return (
              <BaseCol xl={12} style={{ width: '100%' }}>
                <S.CustomPopover
                  key={index}
                  arrow={false}
                  content={
                    <BaseTimePicker
                      size="small"
                      format={'mm:ss'}
                      showNow={false}
                      defaultValue={dayjs(time)}
                      allowClear={false}
                      onChange={selectedTime =>
                        onChange(
                          {
                            [value.key]:
                              ((selectedTime?.minute() ?? 0) * 60 +
                                (selectedTime?.second() ?? 0)) *
                              1000,
                          },
                          'transitionTimes',
                        )
                      }
                      getPopupContainer={triggerNode => triggerNode}
                    />
                  }
                  title="Change time"
                  trigger="click"
                  placement="bottomRight"
                >
                  <BaseRow
                    justify={'space-between'}
                    align={'middle'}
                    style={{
                      cursor: 'pointer',
                      border: `1px solid #EFEFEF`,
                      padding: 10,
                      borderRadius: 12,
                    }}
                  >
                    <BaseCol>
                      <S.TextTransitionTime>
                        {`${title} ${t(value.label)?.toLowerCase()}`}
                      </S.TextTransitionTime>
                    </BaseCol>
                    <BaseCol>
                      <BaseRow gutter={8} align={'middle'}>
                        <BaseCol>
                          <Text
                            $fontSize="10px"
                            $color={convertColor.primaryColor}
                            $fontWeight="400"
                            $inline
                          >
                            {moment(time).format('mm:ss')} {t(R.strings.min)}
                          </Text>
                        </BaseCol>
                        <BaseCol>
                          <BaseImage
                            width={24}
                            height={24}
                            src={R.images.ic_caret_right_restaurant}
                            preview={false}
                          />
                        </BaseCol>
                      </BaseRow>
                    </BaseCol>
                  </BaseRow>
                </S.CustomPopover>
              </BaseCol>
            )
          })}
        </BaseRow>
      </S.ItemTransitionItemWrapper>
    </BaseSpace>
  )
}
const MemoizedItemTransitionTime = memo(ItemTransitionTime)

const ItemFontColor = ({
  title,
  color,
  loadingSave,
  onChange,
}: {
  title?: string
  color?: string
  loadingSave?: boolean
  onChange?: (color: string) => void
}) => {
  const { t } = useTranslation()
  const [currentColor, setCurrentColor] = useState(color ?? '')

  const save = () => {
    onChange?.(currentColor)
  }

  return (
    <ColorPicker
      value={currentColor}
      onChangeComplete={color => setCurrentColor(color.toHexString())}
      onOpenChange={() => setCurrentColor(color ?? '')}
      placement="bottomRight"
      panelRender={panel => (
        <>
          {panel}
          <S.ColorPickerFooter justify={'end'}>
            <S.SaveButton onClick={save} loading={loadingSave}>
              {t(R.strings.save)}
            </S.SaveButton>
          </S.ColorPickerFooter>
        </>
      )}
    >
      <BaseSpace style={{ cursor: 'pointer' }} size={4}>
        <BaseText
          style={{ color: '#818181' }}
          fontWeight="medium"
          fontFamily="Raleway"
        >
          {title}
        </BaseText>
        <BaseRow
          style={{ padding: 10, borderRadius: 10, border: '1px solid #EFEFEF' }}
          gutter={[5, 5]}
          align={'middle'}
        >
          <BaseCol>
            <S.ColorDot color={color} />
          </BaseCol>
          <BaseCol>
            <BaseText fontWeight="medium" children={color} />
          </BaseCol>
        </BaseRow>
      </BaseSpace>
    </ColorPicker>
  )
}

const ItemFontMessaging = ({
  title,
  status,
  onClick,
  des,
}: {
  title?: string
  status?: boolean
  onClick?: (value: boolean) => void
  des?: string
}) => {
  return (
    <BaseRow
      justify={'space-between'}
      gutter={[10, 10]}
      align={'middle'}
      style={{ cursor: 'pointer' }}
      onClick={() => onClick && onClick(!status)}
    >
      <BaseCol>
        <BaseSpace size={4}>
          <BaseText children={title} fontWeight="semibold" />
          <BaseText
            children={des ?? '-'}
            fontWeight="medium"
            style={{ color: '#818181' }}
          />
        </BaseSpace>
      </BaseCol>
      <BaseCol>
        <S.SwitchButton checked={status} />
      </BaseCol>
    </BaseRow>
  )
}

type LoadingTarget =
  | 'standardScreenOption'
  | 'transitionTimes'
  | 'fontsAndColors'
  | 'messaging'

const KdsSettingLayout = () => {
  const {
    data,
    refetch: getKioskKitchenData,
    isLoading: loadingData,
  } = useGetKioskKitchen()
  const { mutate: mutateUpdateKioskKitchen } = useUpdateKioskKitchen()

  const { t } = useTranslation()

  const [standardScreenOptionLoading, setStandardScreenOptionLoading] =
    useState(false)
  const [transitionTimesLoading, setTransitionTimesLoading] = useState(false)
  const [fontsAndColorsLoading, setFontsAndColorsLoading] = useState(false)
  const [messagingLoading, setMessagingLoading] = useState(false)

  const updateLoadingState = (loadingTarget: LoadingTarget, value: boolean) => {
    loadingTarget === 'standardScreenOption' &&
      setStandardScreenOptionLoading(value)
    loadingTarget === 'transitionTimes' && setTransitionTimesLoading(value)
    loadingTarget === 'fontsAndColors' && setFontsAndColorsLoading(value)
    loadingTarget === 'messaging' && setMessagingLoading(value)
  }

  const onUpdateKioskKitchenData = useCallback(
    (body: IKioskKitchen, loadingTarget: LoadingTarget) => {
      updateLoadingState(loadingTarget, true)

      const formatBody: IKioskKitchen = { ...data, ...body }
      delete formatBody.user_fk

      mutateUpdateKioskKitchen(formatBody, {
        onSuccess: () =>
          getKioskKitchenData().finally(() =>
            updateLoadingState(loadingTarget, false),
          ),
      })
    },
    [data],
  )

  return (
    <S.RootWrapper>
      <BaseSpace direction="vertical" size={15}>
        {/* standard screen option */}
        <LoadingWrapper>
          {(loadingData || standardScreenOptionLoading) && <Loading />}
          <BaseSpace direction="vertical" size={15}>
            <S.OptionWrapper
              gutter={[20, 20]}
              align={'middle'}
              justify={'space-around'}
            >
              <BaseCol xl={12}>
                <S.OptionItem
                  onClick={() =>
                    onUpdateKioskKitchenData(
                      { standard_screen: STANDARD_SCREEN.Default },
                      'standardScreenOption',
                    )
                  }
                  direction="vertical"
                  size={10}
                  $isSelected={
                    data?.standard_screen === STANDARD_SCREEN.Default
                  }
                >
                  <BaseImage
                    preview={false}
                    src={R.images.ic_option_kds_default}
                  />
                  <BaseSpace size={8}>
                    <S.TitleOption>{'2 rows stack'}</S.TitleOption>
                    <S.DescriptionOption>
                      {'Tickets stacks in two rows , left to right.'}
                    </S.DescriptionOption>
                  </BaseSpace>
                </S.OptionItem>
              </BaseCol>
              <BaseCol xl={12}>
                <S.OptionItem
                  direction="vertical"
                  size={10}
                  onClick={() => {
                    onUpdateKioskKitchenData(
                      { standard_screen: STANDARD_SCREEN.Classic },
                      'standardScreenOption',
                    )
                  }}
                  $isSelected={
                    data?.standard_screen === STANDARD_SCREEN.Classic
                  }
                >
                  <BaseImage
                    preview={false}
                    src={R.images.ic_option_kds_classic}
                  />
                  <BaseSpace size={4}>
                    <S.TitleOption>{t(R.strings.kds_classic_t)}</S.TitleOption>
                    <S.DescriptionOption>
                      {'Tickets in one horizontal row, left to right.'}
                    </S.DescriptionOption>
                  </BaseSpace>
                </S.OptionItem>
              </BaseCol>
            </S.OptionWrapper>
            <BaseSpace>
              {STANDARD_SCREEN_OTHER_OPTIONS.map((item, index) => {
                return (
                  <S.OptionOtherItem
                    key={index}
                    justify={'space-between'}
                    gutter={[10, 10]}
                    align={'middle'}
                    wrap={false}
                  >
                    <BaseCol xl={20}>
                      <BaseSpace size={4}>
                        <BaseText fontWeight="semibold">
                          {t(item.title)}
                        </BaseText>
                        <BaseText
                          fontWeight="medium"
                          style={{ color: '#818181' }}
                        >
                          {item?.des ?? '-'}
                        </BaseText>
                      </BaseSpace>
                    </BaseCol>
                    <BaseCol>
                      <S.SwitchButton
                        checked={data?.[item.key]}
                        onChange={value =>
                          onUpdateKioskKitchenData(
                            { [item.key]: value },
                            'standardScreenOption',
                          )
                        }
                      />
                    </BaseCol>
                  </S.OptionOtherItem>
                )
              })}
            </BaseSpace>
          </BaseSpace>
        </LoadingWrapper>

        {/* transition times */}
        <LoadingWrapper>
          {transitionTimesLoading && <Loading />}
          <S.TransitionWrapper>
            <BaseText fontWeight="semibold" fontFamily="Raleway">
              {'Transition Time'}
            </BaseText>
            <S.ListTransitionItemWrapper direction="vertical" size={10}>
              {[
                TRANSITION_TIMES_FOR_HERE,
                TRANSITION_TIMES_PICKUP,
                TRANSITION_TIMES_TO_GO,
              ].map((item, index) => {
                return (
                  <MemoizedItemTransitionTime
                    key={index}
                    title={t(item.title)}
                    listValue={item.listValue}
                    data={data}
                    onChange={onUpdateKioskKitchenData}
                  />
                )
              })}
            </S.ListTransitionItemWrapper>
          </S.TransitionWrapper>
        </LoadingWrapper>

        {/* fonts and colors */}
        <LoadingWrapper>
          {(loadingData || fontsAndColorsLoading) && <Loading />}
          <S.FontColorWrapper size={20}>
            <BaseSpace size={8}>
              <BaseText
                children={'Font Size'}
                fontWeight="medium"
                fontFamily="Raleway"
                style={{ color: '#818181' }}
              />

              <SelectWrapper
                options={FONT_DATA?.map(item => ({
                  label: t(item.title),
                  value: item.size,
                }))}
                onChange={value =>
                  onUpdateKioskKitchenData(
                    { font_size: value as number },
                    'fontsAndColors',
                  )
                }
                value={data?.font_size}
                style={{ width: '100%' }}
                placeholder="Select font"
              />
            </BaseSpace>

            <BaseSpace
              size={10}
              style={{
                width: '100%',
                border: '1px solid #EFEFEF',
                borderRadius: 12,
                padding: 12,
              }}
            >
              <S.TitleTransition>
                {t(R.strings.kds_status_color)}
              </S.TitleTransition>
              <S.ItemTransitionItemWrapper>
                <BaseRow gutter={16} align={'middle'} justify={'space-between'}>
                  {STATUS_COLORS_DATA.map((item, index) => {
                    return (
                      <BaseCol xl={8}>
                        <ItemFontColor
                          key={index}
                          title={t(item.title)}
                          color={data?.status_color?.[item.key]}
                          loadingSave={fontsAndColorsLoading}
                          onChange={color =>
                            onUpdateKioskKitchenData(
                              {
                                status_color: {
                                  ...data?.status_color,
                                  [item.key]: color,
                                },
                              },
                              'fontsAndColors',
                            )
                          }
                        />
                      </BaseCol>
                    )
                  })}
                </BaseRow>
              </S.ItemTransitionItemWrapper>
            </BaseSpace>
            <BaseSpace
              size={10}
              style={{
                width: '100%',
                border: '1px solid #EFEFEF',
                borderRadius: 12,
                padding: 12,
              }}
            >
              <S.TitleTransition>
                {t(R.strings.kds_order_type)}
              </S.TitleTransition>
              <S.ItemTransitionItemWrapper>
                <BaseRow gutter={16} align={'middle'} justify={'space-between'}>
                  {ORDER_TYPE_COLORS_DATA.map((item, index) => {
                    return (
                      <BaseCol key={index} xl={8}>
                        <ItemFontColor
                          key={index}
                          title={t(item.title)}
                          color={data?.order_type?.[item.key]}
                          loadingSave={fontsAndColorsLoading}
                          onChange={color =>
                            onUpdateKioskKitchenData(
                              {
                                order_type: {
                                  ...data?.order_type,
                                  [item.key]: color,
                                },
                              },
                              'fontsAndColors',
                            )
                          }
                        />
                      </BaseCol>
                    )
                  })}
                </BaseRow>
              </S.ItemTransitionItemWrapper>
            </BaseSpace>
          </S.FontColorWrapper>
        </LoadingWrapper>

        {/* messaging */}
        <LoadingWrapper>
          {(messagingLoading || loadingData) && <Loading />}
          <S.ItemTransitionTime>
            {MESSAGING_DATA.map((item, index) => {
              return (
                <ItemFontMessaging
                  key={index}
                  title={t(item.title)}
                  status={data?.[item.key]}
                  onClick={value =>
                    onUpdateKioskKitchenData({ [item.key]: value }, 'messaging')
                  }
                  des={item?.des}
                />
              )
            })}
          </S.ItemTransitionTime>
        </LoadingWrapper>
      </BaseSpace>
    </S.RootWrapper>
  )
}

export default memo(KdsSettingLayout)

const SelectWrapper = styled(BaseSelect)`
  .ant-select-selector {
    border: 1px solid #efefef !important;
  }
`

import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import { BaseSelect } from 'app/components/common/selects/BaseSelect'
import styled from 'styled-components'

export const RootWrapper = styled(BaseSpace)`
  position: relative;
`

export const TitleWrapper = styled(BaseRow)`
  margin-bottom: 8px;
  .title {
    font-size: 14px;
    font-weight: 400;
  }
`

export const CustomTargetSelect = styled(BaseSelect)`
  border-bottom: 1px solid #d9d9d94d;
  margin-bottom: 16px;
  min-width: 120px !important;
  & .ant-select-selector {
    background-color: transparent !important;
    color: #ffffff;
    border: unset !important;
    box-shadow: unset !important;
  }
  & .ant-select-arrow {
    top: 50% !important;
  }
  & .ant-select-selection-item {
    color: #d9d9d94d !important;
  }
  & .ant-select-dropdown {
    background-color: #1f1d2b;
    color: #ffffff;
    border: 1px solid #d9d9d94d;
    & .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
      background-color: #d9d9d94d !important;
    }
    &
      .ant-select-item-option-selected:not(.ant-select-item-option-disabled)
      .ant-select-item-option-content {
      color: #ffffff !important;
    }
  }
  & .ant-select-item-option-content {
    color: #ffffff;
  }
  & .ant-select-item-option:not(.ant-select-item-option-selected):hover {
    background-color: #d9d9d926 !important;
  }
`

export const ColorCircleWrapper = styled(BaseRow)``

export const ColorCircleItem = styled(BaseCol)<{ $bgColor: string }>`
  .color-cicle {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    background-color: ${props => props.$bgColor};
    cursor: pointer;
    border: 1.5px solid #5d9b8f;
  }
`

export const ColorCircleGradientItem = styled(BaseCol)`
  .gradient-area {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    cursor: pointer;
    background: linear-gradient(
      135deg,
      #ff0808 0%,
      #047941 52.6%,
      #cd05ff 100%
    );
    .white-area {
      width: 80%;
      height: 80%;
      border-radius: 50%;
      background-color: #ffffff;
    }
  }
`

import R from 'app/assets/R'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { ADMIN_PATH } from 'app/components/router/route-path'
import { isEmpty, isEqual } from 'lodash'
import { SPACE_PATH, moment } from 'parkway-web-common'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router'
import { ThemePicker } from '../components/SettingsDropdown/settings-overlay/ThemePicker'
import { ProfileDropdown } from '../components/profile/ProfileDropdown'
import * as S from '../styles'

interface DesktopHeaderProps {
  isTwoColumnsLayout: boolean
}

export const DesktopHeader: React.FC<DesktopHeaderProps> = ({
  isTwoColumnsLayout,
}) => {
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const { t } = useTranslation()

  const path = useMemo(() => {
    if (isEqual(pathname, ADMIN_PATH.HOME)) {
      return {
        lastPath: t(R.strings.dashboard),
        middlePathName: '',
        middlePath: '',
      }
    }

    if (isEqual(pathname, ADMIN_PATH.ADMIN_DASHBOARD)) {
      return {
        lastPath: t(R.strings.report),
        middlePathName: '',
        middlePath: '',
      }
    }

    const convertedPath = pathname.replace(/-/g, '_').replace(/admin/g, '')
    const pathComponents = convertedPath
      .split('/')
      .filter(component => component !== '')

    if (pathComponents.length > 1) {
      const splitIndex = pathComponents.length - 1
      const middleArray = pathComponents.slice(0, splitIndex)
      const lastArray = pathComponents.slice(splitIndex)
      return {
        lastPath: lastArray
          .map(component => `${t(`${component}`)}`)
          .join(SPACE_PATH),
        middlePathName: middleArray
          .map(component => `${t(`${component}`)}`)
          .join(SPACE_PATH),
        middlePath: middleArray.map(component => component).join(SPACE_PATH),
      }
    }

    const translatedPath = pathComponents
      .map(component => `${t(`${component}`)}`)
      .join(SPACE_PATH)

    return {
      lastPath: translatedPath,
      middlePathName: '',
      middlePath: '',
    }
  }, [pathname, t])

  const today = moment().locale('en')
  const formattedDate = today.format('dddd, D MMM YYYY')

  const renderPath = (() => {
    return (
      <S.CurrentPath>
        {!isEmpty(path?.middlePathName) ? (
          <S.HomeText
            onClick={() => navigate(path.middlePath)}
          >{`${path.middlePathName}${SPACE_PATH}`}</S.HomeText>
        ) : (
          ''
        )}
        {path.lastPath}
      </S.CurrentPath>
    )
  })()

  const leftSide = isTwoColumnsLayout ? (
    <S.SearchColumn xl={16} xxl={17}>
      <BaseRow justify="space-between">
        <BaseCol xl={15} xxl={12}>
          {renderPath}
          <BaseCol>
            <S.TextDate>{formattedDate}</S.TextDate>
          </BaseCol>
        </BaseCol>
        <BaseCol></BaseCol>
      </BaseRow>
    </S.SearchColumn>
  ) : (
    <>
      <BaseCol xl={12} xxl={12}>
        {renderPath}
        <BaseCol>
          <S.TextDate>{formattedDate}</S.TextDate>
        </BaseCol>
      </BaseCol>
      <BaseCol></BaseCol>
    </>
  )

  return (
    <BaseRow justify="space-between" align="middle">
      {leftSide}

      <S.ProfileColumn xl={7} xxl={7} $isTwoColumnsLayout={isTwoColumnsLayout}>
        <BaseRow align="middle" justify="end" gutter={[10, 5]}>
          <BaseCol>
            <ThemePicker />
          </BaseCol>
          <BaseCol>
            <ProfileDropdown />
          </BaseCol>
        </BaseRow>
      </S.ProfileColumn>
    </BaseRow>
  )
}

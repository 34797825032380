import R from 'app/assets/R'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseImage } from 'app/components/common/BaseImage'
import { PaddingMain } from 'app/constant'
import { FONT_SIZE, FONT_WEIGHT, PADDING, colors } from 'parkway-web-common'
import styled from 'styled-components'

export const RootWrapper = styled(BaseCol)`
  padding: ${PADDING.xxxxl} ${PaddingMain.Desktop.defaultHz};
  padding-bottom: 7rem;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 500px;
`

export const RoteBackground = styled.div`
  position: absolute;
  width: 500px;
  height: 500px;
  bottom: -120px;
  left: 35%;
  transform: translateX(-50%);
  background: url('${R.images.ic_img_hero}') no-repeat;
  background-size: cover;
  background-position: center;
  animation: rotateBackground 10s linear infinite;
  z-index: 0;
`

export const AreaText = styled.div`
  padding: ${PADDING.xs} 0px;
  text-align: center;
  font-family: 'Plus Jakarta Sans', sans-serif !important;
  &.with-bg-img {
    background: url('${'https://storage.googleapis.com/kiosify-01/images/img_gradient_background.png'}')
      no-repeat;
    background-size: 100% 100%;
  }
`
export const DescriptionText = styled.span`
  color: ${colors.black};
  font-weight: ${FONT_WEIGHT.medium};
  font-size: ${FONT_SIZE.md};
  text-align: center;
  font-family: 'Plus Jakarta Sans', sans-serif !important;
`

export const TitleText = styled.span`
  font-size: 48px;
  font-weight: ${FONT_WEIGHT.bold};
  color: ${colors.black};
  text-align: center;
  font-family: 'Plus Jakarta Sans', sans-serif !important;
`
export const DescriptionFooter = styled.span`
  color: #64748b;
  font-size: ${FONT_SIZE.md};
  font-weight: ${FONT_WEIGHT.regular};
`

export const ImageGradient = styled(BaseImage)`
  position: absolute;
  top: -50px;
  width: 100% !important;
  height: 200px !important;
`

import R from 'app/assets/R'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { useTranslation } from 'react-i18next'
import * as S from './styles'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { notificationController } from 'app/controllers/notification-controller'
import { requestPostSubscriberEmail } from 'app/api/landing-page'
import { REG_EMAIL } from 'parkway-web-common'

const LayoutFourHome = () => {
  const { t } = useTranslation()
  const [form] = BaseForm.useForm<{ email: string }>()

  const handleSubmit = async ({ email }: { email: string }) => {
    try {
      await requestPostSubscriberEmail({ email })
      notificationController.success({
        message: t(R.strings.thank_you_for_interest),
      })
      form.setFieldValue('email', '')
    } catch (error) {
      console.log({ error })
    }
  }

  return (
    <S.RootWrapper>
      <BaseCol xl={14}>
        <S.AreaText />
        <S.DescriptionText>{t(R.strings.subcribe_for_free)}</S.DescriptionText>
        <S.AreaText>
          <S.TitleText>{t(R.strings.home_subcribe)}</S.TitleText>
        </S.AreaText>
        <S.AreaText />
        <S.AreaText />
      </BaseCol>
      <S.BaseColFullWidth>
        <BaseForm
          form={form}
          initialValues={{ email: '' }}
          onFinish={handleSubmit}
        >
          <BaseRow gutter={[16, 16]} align={'middle'} justify={'center'}>
            <S.BaseColFullWidth>
              <S.BaseFormItem
                name="email"
                style={{ textAlign: 'center' }}
                rules={[
                  {
                    pattern: REG_EMAIL,
                    message: t(R.strings.regex_email),
                  },
                ]}
              >
                <S.InputEmail
                  placeholder={t(R.strings.enter_your_email_address)}
                />
              </S.BaseFormItem>
            </S.BaseColFullWidth>
            <BaseCol>
              <S.ButtonEmail onClick={form?.submit}>
                {t(R.strings.subcribe_now)}
              </S.ButtonEmail>
            </BaseCol>
          </BaseRow>
        </BaseForm>
      </S.BaseColFullWidth>
    </S.RootWrapper>
  )
}
export default LayoutFourHome

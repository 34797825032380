/* eslint-disable @typescript-eslint/no-explicit-any */
import { requestUploadImage } from './api'

export const handleDownloadFile = async ({
  fileUrl,
  fileName,
}: {
  fileUrl: string
  fileName: string
}) => {
  try {
    const response = await fetch(fileUrl)
    const blob = await response.blob()
    const url = window.URL.createObjectURL(blob)

    const a = document.createElement('a')
    a.href = url
    a.download = fileName
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  } catch (error) {
    console.error('Error downloading file:', error)
  }
}

export const getImageBase64URL = (file): Promise<string | null> => {
  if (!file) return Promise.resolve(null)

  return new Promise(resolve => {
    const reader = new FileReader()

    reader.onload = () => {
      const base64Image = reader.result as string
      resolve(base64Image)
    }

    reader.onerror = () => resolve(null)

    reader.readAsDataURL(file)
  })
}

export const getFileExtension = mimeType => {
  const mimeMap = {
    'image/jpeg': 'jpg',
    'image/png': 'png',
    'image/gif': 'gif',
    'image/webp': 'webp',
    'image/svg+xml': 'svg',
    'application/pdf': 'pdf',
    'text/plain': 'txt',
    'application/json': 'json',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      'docx',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx',
  }
  return mimeMap[mimeType] || 'bin' // Nếu không tìm thấy, mặc định là "bin"
}

export const uploadBlobFile = async (
  blobUrl: any,
  originalFileName?: string,
  type?: string,
) => {
  try {
    const response = await fetch(blobUrl)
    const blob = await response.blob()
    // Tạo tên file mới
    const newFileName = `${originalFileName ?? 'image'}.${type ?? 'png'}`

    // Chuyển Blob thành File với tên mới
    const file = new File([blob], newFileName, { type: blob.type })

    const formData = new FormData()
    formData.append('file', file)

    // Gửi dữ liệu lên server
    const res = await requestUploadImage(formData)
    console.log({ res })

    return res
  } catch (error) {
    console.error('Lỗi khi upload:', error)
  }
}

export const handleUploadFile = async (fileUpload: File) => {
  const newFormData = new FormData()
  newFormData.append('file', fileUpload)
  const res = await requestUploadImage(newFormData)
  return res
}

export const handleUploadFileGetId = async (fileUpload?: File) => {
  if (!fileUpload) {
    return
  }
  const res = await handleUploadFile(fileUpload)
  return res?.id
}

export const handleUploadFileGetUrl = async (fileUpload?: File) => {
  if (!fileUpload) {
    return
  }
  const res = await handleUploadFile(fileUpload)
  return res?.url
}

/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { DeleteOutlined, SearchOutlined } from '@ant-design/icons'
import { IMultipleModifiers } from 'app/api/product-management/model'
import { IRestaurant } from 'app/api/restaurant/model'
import R from 'app/assets/R'
import {
  SvgSquareCheckIcon,
  SvgUnCheckedCreateItemIcon,
} from 'app/assets/svg-assets'
import { BaseButton } from 'app/components/common/BaseButton'
import { ButtonVariant } from 'app/components/common/BaseButtonVariant'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseCollapse } from 'app/components/common/BaseCollapse'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { CurrencyPicker } from 'app/components/common/CurrencyPicker'
import { CurrencySupportType } from 'app/components/common/CurrencyPicker/type'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { BaseInput } from 'app/components/common/inputs/BaseInput'
import { InputNumber } from 'app/components/common/inputs/InputNumber'
import { Loading } from 'app/components/common/Loading'
import { customCurrencyValue, useDebounce } from 'app/hook'
import { useGetRestaurantListV2 } from 'app/react-query/hook/restaurant'
import { convertColor } from 'app/styles/themes/themeVariables'
import { t } from 'i18next'
import { isEmpty, isEqual } from 'lodash'
import { BORDER_RADIUS, PADDING } from 'parkway-web-common'
import { useMemo, useState } from 'react'
import styled from 'styled-components'

interface IProps {
  currency: CurrencySupportType
  setCurrency: React.Dispatch<React.SetStateAction<CurrencySupportType>>
  locationIdsSelected: string[]
  setLocationIdsSelected: React.Dispatch<React.SetStateAction<string[]>>
  priceListsSelected: IMultipleModifiers[]
  setPriceListsSelected: React.Dispatch<
    React.SetStateAction<IMultipleModifiers[]>
  >
}

export const HookFormModal = ({
  currency,
  setCurrency,
  locationIdsSelected,
  setLocationIdsSelected,
  priceListsSelected,
  setPriceListsSelected,
}: IProps) => {
  const [searchLocation, setSearchLocation] = useState('')

  const searchDebounceLocation = useDebounce(searchLocation, 500)

  const { data: locationsData, isLoading: loadingLocations } =
    useGetRestaurantListV2({ searchKey: searchDebounceLocation })

  const locations: IRestaurant[] = useMemo(() => {
    return locationsData?.filter(itm => isEqual(itm?.currency, currency)) ?? []
  }, [locationsData, currency])

  const onSelectLocation = (id?: string) => {
    if (!id) return

    const itemExist = locationIdsSelected?.find(idSelected =>
      isEqual(idSelected, id),
    )

    if (itemExist) {
      setLocationIdsSelected(prev =>
        prev?.filter(idSelected => !isEqual(idSelected, id)),
      )
    } else {
      setLocationIdsSelected(prev => prev?.concat([id]))
    }
  }

  const onPressSelectedLocationAll = () => {
    if (locationIdsSelected?.length) {
      setLocationIdsSelected([])
    } else {
      setLocationIdsSelected(locations?.map(itm => itm?.id ?? ''))
    }
  }

  const onPressNewRecord = () => {
    setPriceListsSelected(prev =>
      prev?.concat([
        {
          name: '',
          price: '',
        },
      ]),
    )
  }

  const onDeletePriceListByIndex = (index: number) => {
    let newPriceLists = priceListsSelected?.filter(
      (_, idx) => !isEqual(idx, index),
    )

    if (!newPriceLists?.length) {
      newPriceLists = newPriceLists?.concat([
        {
          name: '',
          price: '',
        },
      ])
    }

    setPriceListsSelected(newPriceLists)
  }

  const onChangeNameByIndex = (name: string, index: number) => {
    setPriceListsSelected(prev =>
      prev?.map((itm, idx) => {
        if (isEqual(idx, index)) {
          return {
            ...itm,
            name,
          }
        }
        return itm
      }),
    )
  }

  const onChangePriceByIndex = (
    price: string | null | number,
    index: number,
  ) => {
    if (!price) return
    setPriceListsSelected(prev =>
      prev?.map((itm, idx) => {
        if (isEqual(idx, index)) {
          return {
            ...itm,
            price: `${price}`,
          }
        }
        return itm
      }),
    )
  }

  const onChangeCurrency = (cu: CurrencySupportType) => {
    setCurrency(cu)
  }

  return (
    <BaseSpace style={{ padding: '0px 26px' }} size={12}>
      <BaseText
        children={'Details'}
        fontWeight="bold"
        fontSize="md"
        fontFamily="Raleway"
      />

      <BaseSpace size={8}>
        <BaseSpace size={0}>
          <BaseText children={'Item Name'} opacity="0.7" />

          <BaseForm.Item
            name={'name'}
            required
            rules={[
              {
                required: true,
                message: t(R.strings.require_field, {
                  field: 'Name',
                }),
              },
            ]}
          >
            <BaseInput
              placeholder="Enter item name"
              style={{ padding: '8px 10px', width: '100%' }}
            />
          </BaseForm.Item>
        </BaseSpace>

        <BaseSpace size={0}>
          <BaseText children={'Item Display Name'} opacity="0.7" />

          <BaseForm.Item
            name={'displayName'}
            required
            rules={[
              {
                required: true,
                message: t(R.strings.require_field, {
                  field: 'Display Name',
                }),
              },
            ]}
          >
            <BaseInput
              placeholder="Enter item display name"
              style={{ padding: '8px 10px', width: '100%' }}
            />
          </BaseForm.Item>
        </BaseSpace>

        <BaseSpace size={0}>
          <BaseText children={'Currency'} opacity="0.7" />

          <BaseForm.Item>
            <CurrencyPicker onChange={onChangeCurrency} initValue={currency} />
          </BaseForm.Item>
        </BaseSpace>
      </BaseSpace>

      <BaseCollapseWrapper
        items={[
          {
            key: 'locations',
            label: <BaseText children={'Location'} fontWeight="semibold" />,
            children: (
              <BaseSpace>
                <BaseForm.Item>
                  <BaseInput
                    placeholder="Search location"
                    prefix={<SearchOutlined rev={undefined} />}
                    style={{ padding: '12px 12px', width: '100%' }}
                    onChange={e => setSearchLocation(e?.target?.value)}
                  />
                </BaseForm.Item>

                <BaseRow gutter={16} align={'middle'} justify={'space-between'}>
                  <BaseCol>
                    <BaseText
                      children={`Selected: ${locationIdsSelected?.length}`}
                      style={{ color: '#818181' }}
                      fontWeight="semibold"
                      fontSize="xxxs"
                    />
                  </BaseCol>

                  <BaseCol>
                    <div
                      style={{ cursor: 'pointer' }}
                      onClick={onPressSelectedLocationAll}
                    >
                      <BaseText
                        children={`Select all`}
                        style={{ color: '#0090FF' }}
                        fontWeight="medium"
                        fontSize="xxxs"
                      />
                    </div>
                  </BaseCol>
                </BaseRow>

                {locations?.map((location, index) => {
                  const isSelected = locationIdsSelected?.find(id =>
                    isEqual(location?.id, id),
                  )
                  return (
                    <BaseRow
                      gutter={16}
                      align={'middle'}
                      justify={'space-between'}
                      key={index}
                      onClick={() => onSelectLocation(location?.id)}
                      style={{ cursor: 'pointer' }}
                    >
                      <BaseCol>
                        <BaseText
                          children={location?.name ?? location?.businessName}
                          fontWeight="semibold"
                        />
                      </BaseCol>

                      <BaseCol
                        xl={2}
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        {isSelected ? (
                          <SvgSquareCheckIcon />
                        ) : (
                          <SvgUnCheckedCreateItemIcon />
                        )}
                      </BaseCol>
                    </BaseRow>
                  )
                })}

                {loadingLocations ? <Loading size="small" /> : null}
              </BaseSpace>
            ),
          },
        ]}
        expandIconPosition="end"
        defaultActiveKey={['locations']}
      />

      <BaseCollapseWrapper
        items={[
          {
            key: 'priceList',
            label: <BaseText children={'Price Lists'} fontWeight="semibold" />,
            children: (
              <BaseSpace>
                {priceListsSelected?.map((item, index) => {
                  return (
                    <BaseRow
                      gutter={16}
                      align={'middle'}
                      key={index}
                      justify={'space-between'}
                    >
                      <BaseCol xl={10}>
                        <BaseSpace size={0}>
                          <BaseText children={'Name'} opacity="0.7" />

                          <BaseForm.Item>
                            <BaseInput
                              placeholder="Name"
                              value={item?.name}
                              onChange={e =>
                                onChangeNameByIndex(
                                  e?.target?.value ?? '',
                                  index,
                                )
                              }
                            />
                          </BaseForm.Item>
                        </BaseSpace>
                      </BaseCol>
                      <BaseCol xl={10}>
                        <BaseSpace size={0}>
                          <BaseText children={'Price'} opacity="0.7" />

                          <BaseForm.Item>
                            <InputNumber
                              placeholder="Price of priceList"
                              min={0}
                              style={{ width: '100%' }}
                              value={item?.price}
                              onChange={e => onChangePriceByIndex(e, index)}
                            />
                          </BaseForm.Item>
                        </BaseSpace>
                      </BaseCol>
                      <BaseCol>
                        <BaseText
                          style={{ marginTop: 20 }}
                          children={customCurrencyValue(currency)?.labelInput}
                        />
                      </BaseCol>
                      <BaseCol>
                        <BaseButton
                          style={{ marginTop: 20 }}
                          type="text"
                          icon={
                            <DeleteOutlined
                              rev={undefined}
                              style={{ color: 'red' }}
                              onClick={() => onDeletePriceListByIndex(index)}
                            />
                          }
                        />
                      </BaseCol>
                    </BaseRow>
                  )
                })}

                <ButtonVariant
                  children={t(R.strings.add_new)}
                  disabled={
                    !!priceListsSelected?.find(itm => {
                      if (isEmpty(itm?.name)) return true

                      if (isEmpty(itm?.price)) {
                        if (Number(itm?.price) === 0) return true
                      }

                      return false
                    })
                  }
                  style={{ width: '100%' }}
                  onClick={onPressNewRecord}
                />
              </BaseSpace>
            ),
          },
        ]}
        expandIconPosition="end"
        defaultActiveKey={['priceList']}
      />
    </BaseSpace>
  )
}

const BaseCollapseWrapper = styled(BaseCollapse)`
  width: 100%;

  .ant-collapse-content {
    border-top: 1px solid ${convertColor.neutralBlack9Color};
    background-color: ${convertColor.backgroundColor};
  }

  .ant-collapse-header {
    background-color: var(--background-color);
    border-radius: ${BORDER_RADIUS}!important;
    align-items: center !important;
  }

  .ant-collapse-header[aria-expanded='true'] {
    border-radius: ${BORDER_RADIUS}!important;
    border-bottom-left-radius: ${BORDER_RADIUS} !important;
    border-bottom-right-radius: ${BORDER_RADIUS} !important;
  }

  .header-content {
    padding: ${PADDING.md};
    background-color: #f9fafb;
  }
`

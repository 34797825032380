import { DownOutlined } from '@ant-design/icons'
import { media } from 'parkway-web-common'
import styled from 'styled-components'
import { HeaderActionWrapper } from '../../../styles'
import { colors } from 'parkway-web-common'

export const ProfileDropdownHeader = styled(HeaderActionWrapper)`
  cursor: pointer;

  @media only screen and ${media.md} {
    border-radius: 50px;
    padding: 0.3125rem 0.2rem;
  }
`

export const TitleProfile = styled(HeaderActionWrapper)`
  color: ${colors.white};
  font-weight: bold;
  font-size: 12px;
`

export const PositionProfile = styled(HeaderActionWrapper)`
  color: ${colors.white};
  font-weight: 400;
  font-size: 10px;
`

export const DownArrow = styled(DownOutlined)`
  color: var(--text-secondary-color);

  @media only screen and ${media.md} {
    color: var(--text-main-color);
  }
`

/* eslint-disable @typescript-eslint/no-explicit-any */
import { DeleteOutlined, EllipsisOutlined } from '@ant-design/icons'
import { Empty } from 'antd'
import { requestDeleteModifier } from 'app/api/product-management/api'
import {
  IModifier,
  IResponseQueryList,
  ParamsListDefaultV2,
} from 'app/api/product-management/model'
import R from 'app/assets/R'
import { BaseButton } from 'app/components/common/BaseButton'
import { BaseCol } from 'app/components/common/BaseCol'
import { BasePagination } from 'app/components/common/BasePagination'
import { BasePopconfirm } from 'app/components/common/BasePopconfirm'
import { BasePopover } from 'app/components/common/BasePopover'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { Loading } from 'app/components/common/Loading'
import { notificationController } from 'app/controllers/notification-controller'
import { paginateList, useDebounce, usePagination } from 'app/hook'
import { useGetModifierList } from 'app/react-query/hook/product-management'
import { ProductManagementKeys } from 'app/react-query/query-key/product-management'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import SearchComponent from '../../../../components/Search'
import { CreateModal, UpdateModal } from './modals'
import * as S from './styles'

export const Modifiers = () => {
  const query = useQueryClient()
  const [isLoadingDelete, setIsLoadingDelete] = useState(false)
  const [params, setParams] = useState(ParamsListDefaultV2)
  const paramsDebounce = useDebounce(params, 500)

  const { data, isLoading: loading } = useGetModifierList({
    searchKey: paramsDebounce?.searchKey,
  })
  const { flattenDataList } = usePagination()

  const modifierData = useMemo(() => {
    const modifier: IResponseQueryList<IModifier[]> = flattenDataList(data)
    return modifier
  }, [data, params])

  const onChangeSearch = (value: string) => {
    setParams({
      ...params,
      searchKey: value,
    })
  }

  const { t } = useTranslation()

  const onPressDelete = async (id?: string) => {
    if (!id) return
    try {
      setIsLoadingDelete(true)
      const res = await requestDeleteModifier(id)
      if (res) {
        query.invalidateQueries({
          queryKey: ProductManagementKeys.allModifier,
        })

        notificationController.success({
          message: t(R.strings.success),
        })
      }
    } catch (error) {
      console.log({ error })
    } finally {
      setIsLoadingDelete(false)
    }
  }

  const modifiers = useMemo(() => {
    return paginateList(
      modifierData?.data ?? [],
      +(paramsDebounce.page ?? 1),
      paramsDebounce.size ?? 10,
    )
  }, [modifierData?.data, paramsDebounce])

  const onChangePagination = (page: number, size: number) => {
    setParams(prev => ({
      ...prev,
      page,
      size,
    }))
  }

  return (
    <S.RootWrapper direction="vertical" size={5}>
      <S.FilterWrapper
        gutter={[20, 10]}
        align={'middle'}
        justify={'space-between'}
      >
        <BaseCol xl={12}>
          <BaseRow gutter={16} align={'middle'}>
            <BaseCol xl={12}>
              <SearchComponent
                onChange={onChangeSearch}
                initValue={params?.searchKey}
              />
            </BaseCol>
          </BaseRow>
        </BaseCol>

        <BaseCol xl={5}>
          <CreateModal />
        </BaseCol>
      </S.FilterWrapper>

      <BaseSpace
        size={8}
        style={{
          overflowY: 'auto',
          maxHeight: 500,
          minHeight: 400,
        }}
      >
        {modifiers?.length ? (
          modifiers?.map((item, index) => {
            return (
              <BaseRow
                gutter={16}
                align={'middle'}
                justify={'space-between'}
                style={{
                  marginLeft: '8px',
                  display: 'flex',
                  flex: 1,
                  margin: '10px 0',
                  padding: '10px',
                  backgroundColor: '#FFFFFF',
                  borderRadius: '6px',
                  boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                  width: '100%',
                }}
                key={index + 1}
              >
                <BaseCol
                  style={{
                    display: 'flex',
                    flex: 1,
                  }}
                >
                  <BaseRow gutter={16} align={'middle'}>
                    <BaseCol>
                      <BaseSpace size={4}>
                        <BaseText
                          fontWeight="semibold"
                          children={`${item?.name} ${
                            item?.displayName ? `(${item?.displayName})` : ''
                          }`}
                        />
                        <BaseText style={{ color: '#818181' }} fontSize="xxxs">
                          {item?.presentAtLocationNames?.join(', ')}
                        </BaseText>
                      </BaseSpace>
                    </BaseCol>
                  </BaseRow>
                </BaseCol>
                <BaseCol>
                  <BaseRow gutter={16} align={'middle'}>
                    <BaseCol>
                      <BasePopover
                        trigger={'click'}
                        content={
                          <BaseSpace size={8}>
                            <UpdateModal item={item} />
                            <BasePopconfirm
                              title="Delete the task"
                              description="Are you sure to delete this task?"
                              onConfirm={() => onPressDelete(item?.id)}
                              onCancel={undefined}
                              okText="Yes"
                              cancelText="No"
                            >
                              <BaseButton
                                icon={<DeleteOutlined rev={undefined} />}
                                children={'Delete'}
                                type="text"
                                style={{
                                  color: 'red',
                                }}
                                loading={isLoadingDelete}
                              />
                            </BasePopconfirm>
                          </BaseSpace>
                        }
                        placement="bottomRight"
                      >
                        <BaseButton
                          icon={<EllipsisOutlined rev={undefined} />}
                          type="text"
                        />
                      </BasePopover>
                    </BaseCol>
                  </BaseRow>
                </BaseCol>
              </BaseRow>
            )
          })
        ) : (
          <Empty />
        )}
      </BaseSpace>
      <BaseRow align={'middle'} justify={'center'}>
        <BasePagination
          pageSize={paramsDebounce.size}
          current={paramsDebounce.page}
          total={modifierData?.total ?? 0}
          onChange={onChangePagination}
        />
      </BaseRow>
      {loading ? <Loading /> : null}
    </S.RootWrapper>
  )
}

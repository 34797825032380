import { IParamsListGetReportOrder } from 'app/api/dashboard/type'

export const DashboardKeys = {
  all: ['All'] as const,
  reportTotalAll: () => [...DashboardKeys.all, 'reportTotal'] as const,
  reportTotal: () => [...DashboardKeys.reportTotalAll(), {}] as const,

  reportTypeOrderAll: () => [...DashboardKeys.all, 'reportTypeOrder'] as const,
  reportTypeOrder: () => [...DashboardKeys.reportTotalAll(), {}] as const,

  mostOrderedAll: () => [...DashboardKeys.all, 'mostOrdered'] as const,
  mostOrdered: () => [...DashboardKeys.mostOrderedAll(), {}] as const,

  reportOrderLists: () => [...DashboardKeys.all, 'reportOrderList'] as const,
  reportOrderList: (filters?: IParamsListGetReportOrder) =>
    [...DashboardKeys.reportOrderLists(), { filters }] as const,
}

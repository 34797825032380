import { ApiClient } from 'app/service/api-service'
import { DashboardApiEndpoint } from './constant'
import { ParamsList } from 'parkway-web-common'

export const requestGetReportTotal = () =>
  ApiClient.Get({
    url: DashboardApiEndpoint.ReportTotal,
    params: {},
  })

export const requestGetReportTypeOrder = () =>
  ApiClient.Get({
    url: DashboardApiEndpoint.ReportTypeOrder,
    params: {},
  })

export const requestGetMostOrdered = () =>
  ApiClient.Get({
    url: DashboardApiEndpoint.MostOrdered,
    params: {},
  })

export const requestGetReportOrder = (params: ParamsList) =>
  ApiClient.Get({
    url: DashboardApiEndpoint.ReportOrder,
    params: { params },
  })

import R from 'app/assets/R'
import {
  CONTACT_US_PATH,
  PRICING_PATH,
  PRODUCT_PATH,
  SERVICE_PATH,
} from 'app/components/router/route-path'
import React from 'react'

export interface SidebarNavigationItem {
  title: string
  key: string
  url?: string
  children?: SidebarNavigationItem[]
  icon?: React.ReactNode
  iconFocus?: React.ReactNode
}

export const sidebarNavigation: SidebarNavigationItem[] = [
  {
    title: R.strings.feature,
    key: R.strings.product,
    url: PRODUCT_PATH,
  },
  {
    title: R.strings.service,
    key: R.strings.service,
    url: SERVICE_PATH,
  },
  {
    title: R.strings.pricing,
    key: R.strings.pricing,
    url: PRICING_PATH,
  },
  {
    title: R.strings.contact_us,
    key: R.strings.resource,
    url: CONTACT_US_PATH,
  },
]

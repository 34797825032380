/* eslint-disable @typescript-eslint/no-unused-vars */
import { DeleteOutlined, SearchOutlined } from '@ant-design/icons'
import { IAppearance } from 'app/api/appearance/model'
import R from 'app/assets/R'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import BaseText from 'app/components/common/BaseText'
import { Loading } from 'app/components/common/Loading'
import * as SR from 'app/containers/admin/Settings/components/RightSide/styles'
import {
  APPERANCE_COLOR_TARGETS,
  InterfaceThemeEnum,
} from 'app/containers/admin/Settings/constant'
import {
  useGetRestaurantDataByLocation,
  useUpdateRestaurantDataByLocation,
} from 'app/react-query/hook/appearance'
import { memo, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as S from './styles'
import { BaseSpace } from 'app/components/common/BaseSpace'
import { BaseAvatar } from 'app/components/common/BaseAvatar'
import { BaseButton } from 'app/components/common/BaseButton'
import { FONT_WEIGHT } from 'parkway-web-common'
import { ColorCustomSetting } from '../../components'
import { BaseInput } from 'app/components/common/inputs/BaseInput'
import { BaseImage } from 'app/components/common/BaseImage'
import { isEqual } from 'lodash'

export const KEY_ALL_ID = 'all'
export const COLOR_PROFILE_LIST = [
  { colorName: R.strings.color_black, colorCode: '#000000' },
  { colorName: R.strings.color_white, colorCode: '#FFFFFF' },
  { colorName: R.strings.color_light_red, colorCode: '#F79992' },
  { colorName: R.strings.color_light_green, colorCode: '#76D0A3' },
  { colorName: R.strings.color_light_blue, colorCode: '#7EC2F3' },
  { colorName: R.strings.color_light_orange, colorCode: '#FAAF76' },
  { colorName: R.strings.color_light_gray, colorCode: '#666666' },
  { colorName: R.strings.color_light_cyan, colorCode: '#76CDD3' },
]

const AppearanceLayout = () => {
  const [restaurantLocation, setRestaurantLocation] = useState(KEY_ALL_ID)
  const [bgColor, setBGColor] = useState('transparent')
  const [textColor, setTextColor] = useState('#FFFFFF')
  const [selectedItemColor, setSelectedItemColor] = useState('#FFFFFF')
  const [secondaryTextColor, setSecondaryTextColor] = useState('#FFFFFF')
  const [buttonColor, setButtonColor] = useState('#29CC6A')
  const [customColorValue, setCustomColorValue] = useState(bgColor)
  const [customColorTarget, setCustomColorTarget] = useState(
    APPERANCE_COLOR_TARGETS.Text,
  )
  const [interfaceTheme, setInterfaceTheme] = useState(InterfaceThemeEnum.LIGHT)

  const { t } = useTranslation()

  const { data, isFetching: loading } =
    useGetRestaurantDataByLocation(restaurantLocation)
  const { mutate: mutateSaveAppearance, isLoading: loadingSave } =
    useUpdateRestaurantDataByLocation()

  useEffect(() => {
    const isAll = restaurantLocation === KEY_ALL_ID
    const newBGColor = isAll
      ? 'transparent'
      : data?.pallete_color1 || 'transparent'
    const newTextColor = isAll ? '#black' : data?.pallete_color2 || '#black'
    const newButtonColor = isAll ? '#29CC6A' : data?.pallete_color3 || '#29CC6A'

    setBGColor(newBGColor)
    setTextColor(newTextColor)
    setButtonColor(newButtonColor)
    handleCustomColorCompChange(
      'target',
      newBGColor,
      newTextColor,
      newButtonColor,
    )
  }, [data])

  useEffect(() => {
    handleCustomColorCompChange('color')
  }, [customColorValue])

  useEffect(() => {
    handleCustomColorCompChange('target')
  }, [customColorTarget])

  const handleCustomColorCompChange = (
    changeType: 'color' | 'target',
    newBGColor?: string,
    newTextColor?: string,
    newButtonColor?: string,
  ) => {
    switch (customColorTarget) {
      case APPERANCE_COLOR_TARGETS.Background:
        changeType === 'color' && setBGColor(customColorValue)
        changeType === 'target' && setCustomColorValue(newBGColor || bgColor)
        break
      case APPERANCE_COLOR_TARGETS.Text:
        changeType === 'color' && setTextColor(customColorValue)
        changeType === 'target' &&
          setCustomColorValue(newTextColor || textColor)
        break
      case APPERANCE_COLOR_TARGETS.Button:
        changeType === 'color' && setButtonColor(customColorValue)
        changeType === 'target' &&
          setCustomColorValue(newButtonColor || buttonColor)
        break
      default:
        break
    }
  }

  const save = () => {
    const body: IAppearance = {
      ...data,
      pallete_name: `${data?.location_name} color`,
      pallete_color1: bgColor,
      pallete_color2: textColor,
      pallete_color3: buttonColor,
      location_fk: restaurantLocation,
    }

    mutateSaveAppearance(body)
  }

  return (
    <S.RootWrapper direction="vertical" size={16}>
      {/* <S.HeaderWrapper justify={'end'} align={'middle'}>
        <BaseCol>
          <S.SaveButton onClick={save} loading={loadingSave}>
            {t(R.strings.save)}
          </S.SaveButton>
        </BaseCol>
      </S.HeaderWrapper> */}

      <BaseImage
        src={R.images.img_preview_appearance}
        style={{
          width: '100%',
          height: 300,
          borderRadius: 12,
        }}
      />

      <BaseSpace style={{ maxHeight: 400, overflow: 'auto' }}>
        <div style={{ width: '98%' }}>
          <BaseRow align={'middle'} justify={'space-between'} gutter={[16, 16]}>
            <BaseCol>
              <BaseSpace size={8}>
                <BaseText
                  children={'Restaurant logo'}
                  fontWeight="semibold"
                  fontSize="xxs"
                />
                <BaseText
                  children={'Update your restaurant logo.'}
                  fontSize="xxxs"
                  fontWeight="medium"
                  style={{ color: '#818181' }}
                />
              </BaseSpace>
            </BaseCol>

            <BaseCol>
              <BaseRow gutter={16} align={'middle'}>
                <BaseCol>
                  <BaseAvatar
                    alt=""
                    src={R.images.ic_logo_kiosk_master}
                    size={34}
                  />
                </BaseCol>
                <BaseCol style={{ paddingRight: 0 }}>
                  <BaseButton
                    children={'Replace logo'}
                    style={{
                      fontSize: '10px',
                      fontWeight: FONT_WEIGHT.semibold,
                    }}
                  />
                </BaseCol>
              </BaseRow>
            </BaseCol>
          </BaseRow>
        </div>

        <BaseSpace size={8} style={{ width: '98%' }}>
          <BaseText
            children={'Interface theme'}
            fontWeight="semibold"
            fontSize="xxs"
          />
          <BaseText
            children={'Select theme.'}
            fontSize="xxxs"
            fontWeight="medium"
            style={{ color: '#818181' }}
          />

          <BaseRow gutter={[16, 16]} align={'middle'}>
            <BaseCol xl={8}>
              <S.ItemInterfaceTheme
                $isSelected={isEqual(
                  InterfaceThemeEnum.SYSTEM_PREFERENCES,
                  interfaceTheme,
                )}
                onClick={() =>
                  setInterfaceTheme(InterfaceThemeEnum.SYSTEM_PREFERENCES)
                }
              >
                <BaseImage
                  src={R.images.img_interface_theme_system}
                  style={{ width: '100%' }}
                  preview={false}
                />
              </S.ItemInterfaceTheme>
            </BaseCol>
            <BaseCol xl={8}>
              <S.ItemInterfaceTheme
                $isSelected={isEqual(InterfaceThemeEnum.LIGHT, interfaceTheme)}
                onClick={() => setInterfaceTheme(InterfaceThemeEnum.LIGHT)}
              >
                <BaseImage
                  src={R.images.img_interface_theme_light}
                  style={{ width: '100%' }}
                  preview={false}
                />
              </S.ItemInterfaceTheme>
            </BaseCol>
            <BaseCol xl={8}>
              <S.ItemInterfaceTheme
                $isSelected={isEqual(InterfaceThemeEnum.DARK, interfaceTheme)}
                onClick={() => setInterfaceTheme(InterfaceThemeEnum.DARK)}
              >
                <BaseImage
                  src={R.images.img_interface_theme_dark}
                  style={{ width: '100%' }}
                  preview={false}
                />
              </S.ItemInterfaceTheme>
            </BaseCol>
          </BaseRow>
        </BaseSpace>

        <div style={{ width: '98%' }}>
          <BaseSpace>
            <BaseSpace size={8}>
              <BaseText
                children={'Custom theme'}
                fontWeight="semibold"
                fontSize="xxs"
              />
              <BaseText
                children={
                  'Set your own app theme by changing the color of each interface elements.'
                }
                fontSize="xxxs"
                fontWeight="medium"
                style={{ color: '#818181' }}
              />
            </BaseSpace>

            <BaseRow
              align={'middle'}
              justify={'space-between'}
              gutter={[16, 16]}
              style={{
                borderRadius: 12,
                border: '1px solid #00000040',
                marginLeft: 0,
                padding: 12,
              }}
            >
              <BaseCol>
                <BaseSpace size={8}>
                  <BaseText
                    children={'Window background'}
                    fontWeight="semibold"
                    fontSize="xxs"
                  />
                  <BaseText
                    children={'#FBFBFB'}
                    fontSize="xxxs"
                    fontWeight="medium"
                    style={{ color: '#818181' }}
                  />
                </BaseSpace>
              </BaseCol>

              <BaseCol>
                <BaseRow gutter={16} align={'middle'}>
                  <BaseCol>
                    <ColorCustomSetting
                      title={t(R.strings.custom)}
                      isLoading={loading}
                      currentValue={customColorValue}
                      onChange={setCustomColorValue}
                      onTargetChange={setCustomColorTarget}
                    />
                  </BaseCol>

                  <BaseCol>
                    <BaseButton>
                      <BaseText children={'Edit color'} fontWeight="semibold" />
                    </BaseButton>
                  </BaseCol>
                </BaseRow>
              </BaseCol>
            </BaseRow>
            <BaseRow
              align={'middle'}
              justify={'space-between'}
              gutter={[16, 16]}
              style={{
                borderRadius: 12,
                border: '1px solid #00000040',
                marginLeft: 0,
                padding: 12,
              }}
            >
              <BaseCol>
                <BaseSpace size={8}>
                  <BaseText
                    children={'Selected items'}
                    fontWeight="semibold"
                    fontSize="xxs"
                  />
                  <BaseText
                    children={'#DDFBF5'}
                    fontSize="xxxs"
                    fontWeight="medium"
                    style={{ color: '#818181' }}
                  />
                </BaseSpace>
              </BaseCol>

              <BaseCol>
                <BaseRow gutter={16} align={'middle'}>
                  <BaseCol>
                    <ColorCustomSetting
                      title={t(R.strings.custom)}
                      isLoading={loading}
                      currentValue={customColorValue}
                      onChange={setCustomColorValue}
                      onTargetChange={setCustomColorTarget}
                    />
                  </BaseCol>

                  <BaseCol>
                    <BaseButton>
                      <BaseText children={'Edit color'} fontWeight="semibold" />
                    </BaseButton>
                  </BaseCol>
                </BaseRow>
              </BaseCol>
            </BaseRow>
            <BaseRow
              align={'middle'}
              justify={'space-between'}
              gutter={[16, 16]}
              style={{
                borderRadius: 12,
                border: '1px solid #00000040',
                marginLeft: 0,
                padding: 12,
              }}
            >
              <BaseCol>
                <BaseSpace size={8}>
                  <BaseText
                    children={'Sidebar'}
                    fontWeight="semibold"
                    fontSize="xxs"
                  />
                  <BaseText
                    children={'#FFFFFF'}
                    fontSize="xxxs"
                    fontWeight="medium"
                    style={{ color: '#818181' }}
                  />
                </BaseSpace>
              </BaseCol>

              <BaseCol>
                <BaseRow gutter={16} align={'middle'}>
                  <BaseCol>
                    <ColorCustomSetting
                      title={t(R.strings.custom)}
                      isLoading={loading}
                      currentValue={customColorValue}
                      onChange={setCustomColorValue}
                      onTargetChange={setCustomColorTarget}
                    />
                  </BaseCol>

                  <BaseCol>
                    <BaseButton>
                      <BaseText children={'Edit color'} fontWeight="semibold" />
                    </BaseButton>
                  </BaseCol>
                </BaseRow>
              </BaseCol>
            </BaseRow>
            <BaseRow
              align={'middle'}
              justify={'space-between'}
              gutter={[16, 16]}
              style={{
                borderRadius: 12,
                border: '1px solid #00000040',
                marginLeft: 0,
                padding: 12,
              }}
            >
              <BaseCol>
                <BaseSpace size={8}>
                  <BaseText
                    children={'Primary text'}
                    fontWeight="semibold"
                    fontSize="xxs"
                  />
                  <BaseText
                    children={'#000000'}
                    fontSize="xxxs"
                    fontWeight="medium"
                    style={{ color: '#818181' }}
                  />
                </BaseSpace>
              </BaseCol>

              <BaseCol>
                <BaseRow gutter={16} align={'middle'}>
                  <BaseCol>
                    <ColorCustomSetting
                      title={t(R.strings.custom)}
                      isLoading={loading}
                      currentValue={customColorValue}
                      onChange={setCustomColorValue}
                      onTargetChange={setCustomColorTarget}
                    />
                  </BaseCol>

                  <BaseCol>
                    <BaseButton>
                      <BaseText children={'Edit color'} fontWeight="semibold" />
                    </BaseButton>
                  </BaseCol>
                </BaseRow>
              </BaseCol>
            </BaseRow>
            <BaseRow
              align={'middle'}
              justify={'space-between'}
              gutter={[16, 16]}
              style={{
                borderRadius: 12,
                border: '1px solid #00000040',
                marginLeft: 0,
                padding: 12,
              }}
            >
              <BaseCol>
                <BaseSpace size={8}>
                  <BaseText
                    children={'Secondary text'}
                    fontWeight="semibold"
                    fontSize="xxs"
                  />
                  <BaseText
                    children={'#909090'}
                    fontSize="xxxs"
                    fontWeight="medium"
                    style={{ color: '#818181' }}
                  />
                </BaseSpace>
              </BaseCol>

              <BaseCol>
                <BaseRow gutter={16} align={'middle'}>
                  <BaseCol>
                    <ColorCustomSetting
                      title={t(R.strings.custom)}
                      isLoading={loading}
                      currentValue={customColorValue}
                      onChange={setCustomColorValue}
                      onTargetChange={setCustomColorTarget}
                    />
                  </BaseCol>

                  <BaseCol>
                    <BaseButton>
                      <BaseText children={'Edit color'} fontWeight="semibold" />
                    </BaseButton>
                  </BaseCol>
                </BaseRow>
              </BaseCol>
            </BaseRow>
          </BaseSpace>
        </div>
      </BaseSpace>
    </S.RootWrapper>
  )
}

export default memo(AppearanceLayout)

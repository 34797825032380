import React from 'react'
import styled from 'styled-components'
import { BaseSpin } from '../BaseSpin'

interface LoadingProps {
  size?: 'small' | 'default' | 'large'
  color?: string
}

export const Loading: React.FC<LoadingProps> = ({ size }) => {
  return (
    <SpinnerContainer>
      <BaseSpin size={size} />
    </SpinnerContainer>
  )
}

const SpinnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 1100;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
`

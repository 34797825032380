import { BaseButton } from 'app/components/common/BaseButton'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseImage } from 'app/components/common/BaseImage'
import { BaseRow } from 'app/components/common/BaseRow'
import { BORDER_RADIUS_2, PaddingMain } from 'app/constant'
import {
  BORDER_RADIUS,
  FONT_SIZE,
  FONT_WEIGHT,
  PADDING,
  colors,
  media,
} from 'parkway-web-common'
import styled from 'styled-components'

export const RootWrapper = styled(BaseCol)`
  padding: ${PaddingMain.Mobile.defaultHz} 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 200px;
  @media only screen and ${media.xs} {
    padding: ${PaddingMain.Mobile.defaultHz} ${PaddingMain.Mobile.defaultHz};
  }
`

export const AreaText = styled.div`
  padding: ${PADDING.xxs} 0px;
  text-align: left;
  font-family: 'Plus Jakarta Sans', sans-serif !important;
`

export const TitleText = styled.span`
  font-size: 36px;
  font-weight: ${FONT_WEIGHT.bold};
  color: ${colors.black};
  text-align: center;
  display: flex;
  justify-content: center;
  font-family: 'Plus Jakarta Sans', sans-serif !important;
`

export const DescriptionText = styled.span`
  color: ${colors.black};
  font-size: 20px;
  font-weight: ${FONT_WEIGHT.medium};
  display: flex;
  justify-content: center;
  font-family: 'Plus Jakarta Sans', sans-serif !important;
  text-align: center;
`

export const ListWrapper = styled(BaseRow)`
  width: 100%;
  position: relative;
  padding-top: 40px;
`

export const ContentWrapper = styled.div`
  border-radius: ${BORDER_RADIUS_2};
  padding: ${PADDING.xxxxl} 3rem;
  background-color: ${colors.white};
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  width: 100%;
  transition: all 0.5s ease;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const ItemWrapper = styled(BaseCol)`
  position: relative;
`

export const IconItem = styled(BaseImage)`
  width: 42px !important;
  transition: all 0.25s ease;
`

export const TitleItem = styled.span`
  color: ${colors.black};
  font-size: 40px;
  font-weight: ${FONT_WEIGHT.bold};
  transition: all 0.5s ease;
  text-align: center;
`

export const DescriptionItem = styled.p`
  color: ${colors.black};
  font-size: ${FONT_SIZE.md};
  transition: all 0.5s ease;
  font-weight: ${FONT_WEIGHT.regular};
  text-align: center;
  font-family: 'Plus Jakarta Sans', sans-serif !important;
`

export const TextPriceItem = styled.p`
  color: ${colors.black};
  font-weight: ${FONT_WEIGHT.semibold};
  font-size: 40px;
  font-family: 'Plus Jakarta Sans', sans-serif !important;
`
export const ListBenefitOption = styled(BaseRow)`
  width: 100%;
  padding: 86px 0px;
  .anticon svg {
    fill: black;
  }
`
export const TextOption = styled.span`
  color: ${colors.black};
  font-size: ${FONT_SIZE.xl};
  font-weight: ${FONT_WEIGHT.regular};
  font-family: 'Plus Jakarta Sans', sans-serif !important;
`
export const ButtonTrial = styled(BaseButton)`
  border-radius: ${BORDER_RADIUS};
  padding: ${PADDING.xxl} ${PADDING.xl};
  background-color: var(--green-button-color);
  border: none;
  color: ${colors.white};
  transition: all 0.5s ease;
  font-family: 'Plus Jakarta Sans', sans-serif !important;
  width: 100%;
  &:hover {
    color: ${colors.white} !important;
  }
`

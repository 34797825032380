import R from 'app/assets/R'
import { SvgPlayIcon } from 'app/assets/svg-assets'
import { ButtonVariant } from 'app/components/common/BaseButtonVariant'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { useTranslation } from 'react-i18next'
import * as S from './styles'

const HeaderHome = () => {
  const { t } = useTranslation()
  return (
    <S.RootWrapper>
      <BaseCol xl={17}>
        <S.AreaText className='with-bg-img'>
          <S.TitleText>{t(R.strings.home_slogan)}</S.TitleText>
        </S.AreaText>
        <S.AreaText>
          <S.DescriptionText>
            {t(R.strings.home_slogan_d)}
          </S.DescriptionText>
        </S.AreaText>
        <S.AreaText />

        <BaseRow gutter={[16, 16]} justify={'center'}>
          <BaseCol>
            <ButtonVariant isVariantOutline>
              {t(R.strings.customer_login)}
            </ButtonVariant>
          </BaseCol>
          <BaseCol>
            <ButtonVariant>
              <BaseRow gutter={10} align={'middle'}>
                <SvgPlayIcon />
                <BaseCol>{t(R.strings.watch_free_demo)}</BaseCol>
              </BaseRow>
            </ButtonVariant>
          </BaseCol>
        </BaseRow>
      </BaseCol>
    </S.RootWrapper>
  )
}
export default HeaderHome

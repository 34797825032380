export const ProductManagementApiEndpoint = {
  GET_CATEGORY_LIST: 'categories/search',
  GET_ITEM_LIST: 'items/search',
  GET_MODIFIER_LIST: 'modifierlists/search',
  GET_TAX_LIST: '/catalog/tax/get-taxs/',
  GET_DISCOUNT_LIST: '/catalog/discount/get-discounts/',
  GET_UP_SELL_LIST: 'upsells/search',
  GET_UPSELL_LIST_BY_ITEM: '/catalog/upsell/get-list-upsell-by-item/',

  DISCOUNT: '/catalog/discount/',
  TAX: '/catalog/tax/',
  MODIFIER: '/catalog/modifier-list/',
  ITEM: '/catalog/item/',
  CATEGORY: '/catalog/category/',
  UPSELL: '/catalog/upsell/',
  UPLOAD_IMAGE_CATEGORY: '/catalog/category/image/',
  GET_ITEM_VARIATION: '/get-item-variations/',

  RE_ODER_POSITION_CATEGORY: 'categories/reorder',
  CATEGORY_V2: 'categories',
  MODIFIER_V2: 'modifierlists',
  ITEMS_V2: 'items',
  UP_SELL_V2: 'upsells',
}

export default {
  empowering_kiosk_efficiency: '提升自助亭效率',
  streamlined_content_management: '精简的内容管理',
  streamlined_content_management_d:
    '轻松管理和更新您的自助亭内容，确保内容始终更新和引人入胜。',
  remote_kiosk_control: '远程自助亭控制',
  remote_kiosk_control_d:
    '随时随地掌控您的自助亭，监视其性能，并随时进行调整。',
  personalized_recommendations: '个性化推荐',
  personalized_recommendations_d:
    '利用客户数据提供个性化的产品推荐和促销活动。',
  advanced_reporting: '高级报告',
  advanced_reporting_d: '生成全面的报告，识别趋势，优化策略，做出明智的决策。',
  personalized_recommendations_and_promotions: '个性化推荐和促销',
  personalized_recommendations_and_promotions_d:
    '在Kiosify快速订单系统中解锁个性化推荐和促销',

  revolutionizing_customer_engagement:
    '彻底改变客户参与体验：Kiosify的个性化推荐和促销',
  revolutionizing_customer_engagement_d:
    'Kiosify充分利用客户数据提供个性化的产品推荐和促销活动。通过分析购买历史、浏览行为和人口统计信息，Kiosify可以根据每个客户的偏好和兴趣量身定制建议。这种个性化的方式通过展示与客户口味相符的相关产品和优惠，提升了客户满意度。',
  sign_up_for_a_free_trial: '注册免费试用',
  start_14_days_trail: '开始14天试用',
  home_subcribe: '订阅我们的新闻简报，获取最新更新',
  enter_your_email_address: '输入您的电子邮件地址',
  footer_kiosify: 'Kiosify：通过自助服务提升您的客户体验',
  contact_us: '联系我们',

  home_slogan: 'Kiosify：自助亭管理的未来',
  home_slogan_d: '适合您业务的自助点餐系统',
  create_your_kiosify_account: '创建您的Kiosify帐户',
  see_more: '查看更多',
  find: '搜索',
  address: '地址',
  language: '语言',
  vietnamese: 'Tiếng Việt（越南语）',
  english: 'English（英语）',
  foreign_language: '外语',
  home: '首页',
  phone: '电话',
  email: '电子邮件',
  send: '发送',
  next: '下一步',
  back: '返回',
  send_code: '发送验证码',
  login: '登录',
  user_name: '用户名',
  password: '密码',
  login_success: '登录成功',
  save_success: '保存成功',
  forgot_password: '忘记密码',
  require_field: '请输入 "{{field}}"，这是必填项',
  regex_field: '请输入正确格式的 "{{field}}"',
  minimum_field: '请输入至少 {{count}} 个字符',
  maximum_field: '请输入最多 {{count}} 个字符',
  register: '注册',
  confirm_password: '确认密码',
  verify_by_phone: '通过电话验证',
  verify_by_email: '通过电子邮件验证',
  code_verify: '验证码',
  message_verify_phone: '请检查手机号 {{phone}} 收到的 Zalo 消息以获取验证码。',
  re_send_verify_code: '重新发送验证码',
  resend: '重新发送',
  time_resend_verify_code: '{{timer}} 秒后重新发送验证码',
  verify: '验证',
  logout: '登出',
  full_name: '全名',
  regex_name: '姓名必须为正确格式',
  regex_user_name: '用户名必须为正确格式（不包括空格）',
  using_by: '使用者',
  require_email: '请输入电子邮件',
  require_contact_name: '请输入联系人姓名',
  regex_email: '请输入有效的电子邮件格式',
  regex_phone: '请输入有效的电话号码格式',
  send_contact_success: '联系信息发送成功，谢谢。',
  please_enter_correct_format: '请输入正确的格式',
  medical_examination: '体检',
  human_resources: '人力资源',
  finance: '财务',
  inventory_management: '库存管理',
  purchasing_management: '采购管理',
  customer_care: '客户关怀',
  clinic_management: '诊所管理',
  company: '公司',
  organizational_chart: '组织架构图',
  announcement: '公告',
  internal_news: '内部新闻',
  onboarding: '入职',
  report: '报告',
  marketing: '市场营销',
  accounting: '会计',
  work_schedule: '工作计划',
  attendance_record: '考勤记录',
  mailbox: '邮箱',
  my_requests: '我的请求',
  my_tasks: '我的任务',
  my_income: '我的收入',
  dashboard: '仪表板',
  profile: '个人资料',
  read_all: '全部标记为已读',
  view_all: '查看全部',
  remember_me: '记住我',
  parkway_dental: 'Parkway Dental',
  description_parkway_auth:
    'Parkway Dental是一家全面而专业的牙科系统，尤其在正畸和种植方面表现出色，完全满足客户的牙科需求。',
  development: '正在开发中',
  coming_soon: '即将推出',
  customers: '客户',
  region: '地区',
  revenue: '收入',
  basic_package: {
    title: '基础',
    price: '$50/月',
    benefit_1: '最多3个亭',
    benefit_2: '无限订单',
    benefit_3: '专属客户经理',
    benefit_4: '24/7支持',
    benefit_5: '访问我们的在线仪表板',
    benefit_6: '报告和分析',
  },
  premium_package: {
    title: '高级',
    price: '$80/月',
    benefit_1: '最多10个亭',
    benefit_2: '无限订单',
    benefit_3: '免费KDS',
    benefit_4: '24/7支持',
    benefit_5: '可定制品牌',
    benefit_6: '多地点支持',
    benefit_7: '营销支持',
  },
  smart_saving_package: {
    title: '智能省钱',
    price: '$30/月/1个亭',
    benefit_1: '降低佣金费用',
    benefit_2: '优先客户支持',
    benefit_3: '访问独家功能',
  },
  time: {
    today: '今天',
    this_week: '本周',
    this_month: '本月',
    days: '天',
    start_time_kiosk: '开始时间',
    end_time_kiosk: '结束时间',
    monday: '星期一',
    tuesday: '星期二',
    wednesday: '星期三',
    thursday: '星期四',
    friday: '星期五',
    satuday: '星期六',
    sunday: '星期天',
  },
  error_message: {
    service_package: '请选择服务套餐',
    select_location: '请选择位置',
    select_payment_method: '请选择支付方式',
    select_kiosk: '请选择亭',
  },
  success_message: {
    create_kiosk: '创建亭成功',
    update_kiosk: '更新亭成功',
  },
  table: {
    customer: '客户',
    time: '时间',
    date: '日期',
    order: '订单',
    total_payment: '总付款',
    payment_method: '付款方式',
    status: '状态',
    type: '类型',
    name: '姓名',
    location: '位置',
    kiosk_code: '亭代码',
    product_name: '产品名称',
    price: '价格',
    item_name: '项目名称',
    category: '类别',
    variation: '变体',
    up_sell_item: '上销售项目',
    up_sell_at: '上销售于',
    quantity: '数量',
    action: '操作',
    options: '选项',
    discount_name: '折扣名称',
    discount_type: '折扣类型',
    discount_apply: '应用折扣',
    item_price: '项目价格',
    active: '活跃',
    bestseller: '畅销商品',
  },
  payment_method: {
    cash: '现金',
    credit_card: '信用卡',
    gift_card: '礼品卡',
  },
  dashboard_common: {
    most_ordered: '最多订购',
    most_type_order: '最常见的订单类型',
    total_revenue: '总收入',
    pending_revenue: '待收收入',
    actual_revenue: '实际收入',
    compared_to_last_month: '与上个月相比',
    today_revenue: '今天的收入',
    branch_name: '分店名称',
    overall_today_summary: '今天的整体摘要',
    area: '区域',
    ordinal_number: '序号',
    clinic: '诊所',
    dishes_ordered: '订购的菜品',
    view_all: '查看全部',
    order_report: '订单报告',
  },
  reports_common: {
    title: '报告',
    total_revenue: '总收入',
    total_dish_ordered: '总点菜量',
    total_customer: '总顾客数',
    order_report: '订单报告',
    chart_header_realtime: '实时',
    chart_header_daily: '每日',
    chart_header_weekly: '每周',
    chart_header_monthly: '每月',
    chart_header_yearly: '每年',
    chart_label_monday: '周一',
    chart_label_tuesday: '周二',
    chart_label_wednesday: '周三',
    chart_label_thursday: '周四',
    chart_label_friday: '周五',
    chart_label_satuday: '周六',
    chart_label_sunday: '周日',
    chart_label_january: '一月',
    chart_label_february: '二月',
    chart_label_march: '三月',
    chart_label_april: '四月',
    chart_label_may: '五月',
    chart_label_june: '六月',
    chart_label_july: '七月',
    chart_label_august: '八月',
    chart_label_september: '九月',
    chart_label_october: '十月',
    chart_label_november: '十一月',
    chart_label_december: '十二月',
    chart_serrie_total_gross: '总毛收入',
    chart_serrie_total_net: '总净收入',
  },
  input_placeholder: {
    search: '搜索食物、咖啡等',
    search_tab: '搜索标签...',
    select_location: '选择位置',
    order_note: '订单备注...',
    search_only: '仅搜索',
    select_category: '选择类别',
    device_name: '设备名称',
    search_printer: '搜索打印机',
    search_profile: '搜索配置文件',
    search_your_kiosk: '搜索您的亭',
    write_your_content_here: '在此处编写您的内容...',
    select_time: '选择时间',
  },
  input_validate: {
    required_kiosk_name: '请输入您的亭名称！',
  },
  information: {
    branch: '分店',
    location: '位置',
    phone_no: '电话号码',
    email: '电子邮件',
  },
  kiosk: {
    add_your_kiosk: '添加您的亭',
    edit_your_kiosk: '编辑您的亭',
    add_new_kiosk: '添加新亭',
    kiosk_name: '亭名称',
    kiosk_code: '亭代码',
    your_subscription: '您的订阅',
    payment_method: '付款方式',
    redeem_loyalty: '兑换忠诚积分',
    redeem_discount: '兑换折扣',
    open_time: '营业时间',
    start_popups_when_the_store_is_closed: '在商店关闭时启动弹出窗口',
    message: '消息',
  },
  notification_status: {
    success: '成功',
    info: '信息',
    warning: '警告',
    error: '错误',
  },
  product_management: {
    category: '类别',
    items: '商品',
    up_sell: '上销售',
    modifiers: '修饰符',
    discounts: '折扣',
    delete_item_up_sell: '删除上销售项目',
    delete_item_up_sell_description: '您是否要删除项目',
    create_up_sell: '创建上销售',
    menu_price: '菜单价格',
    discount_price: '折扣价格',
    item_level: '商品级别',
    order_level: '订单级别',
  },
  form_item: {
    select_up_sell_at_t: '上销售于',
    select_up_sell_at_p: '选择上销售于',
    select_item_t: '选择商品',
    select_item_p: '选择商品',
    select_item_variation_t: '变体',
    select_item_variation_p: '选择商品变体',
    select_price_t: '选择价格',
    select_price_p: '选择价格',
    price_t: '价格',
    select_item_apply_t: '选择商品应用',
    select_item_apply_p: '选择商品应用',
  },
  setting_menu: {
    appearance_t: '外观',
    appearance_d: '暗黑和明亮模式、字体大小',
    your_restaurant_t: '您的餐厅',
    your_restaurant_d: '暗黑和明亮模式、字体大小',
    your_kiosk_t: '您的亭',
    your_kiosk_d: '查看您的亭信息',
    product_management_t: '产品管理',
    product_management_d: '管理您的产品、定价等',
    kds_t: 'KDS设置',
    kds_d: '暗黑和明亮模式、字体大小',
    backup_t: '备份',
    backup_d: '返回到以前的更新状态',
    your_information_t: '您的信息',
    your_information_d: '了解更多关于您的品牌名称、打印机、读卡器等的信息',
    sync_data_from_square_t: '从Square同步数据',
    sync_data_from_square_d: '立即同步您的数据来自Square',
  },
  your_infomation: {
    km_stand: 'KM Stand',
    km_printers: 'KM Printers',
    km_readers: 'KM Readers',
    device_id: '设备ID',
    get_help: '获取帮助',
    printers: '打印机',
    profiles: '配置文件',
    connect_printer: '连接打印机',
    no_printer_connected: '未连接打印机',
    troubleshoot: '故障排除',
    troubleshoot_d: '期望连接了打印机吗？',
    create_profile: '创建配置文件',
    no_profile: '无配置文件',
    printer_defaults: '打印机默认设置',
    printer_defaults_d:
      '选择您要使用此配置文件打印的项目。所有继承此配置文件的打印机将只打印这些项目。',
    receipts: '收据',
    in_person_order_tickets: '亲自下单的票据',
    online_order_tickets: '在线订单票据',
    order_ticket_stubs: '订单票据存根',
    void_tickets: '无效票据',
    use_this_printer_for_receipts: '将此打印机用于收据',
    use_this_printer_for_receipts_d:
      '打开时，此打印机还将打印账单、报告和打开现金抽屉',
    no_device: '无设备',
  },
  about: '关于',
  general: '通用',
  connect_and_manage: '连接和管理',
  add_new: '添加新的',
  add_more_food: '添加更多食物',
  key_all_label: '全部',
  restaurant: '餐厅',
  your_restaurant: '您的餐厅',
  see_your_restaurant: '查看您的餐厅',
  background: '背景',
  text: '文本',
  button: '按钮',
  custom: '自定义',
  see_all: '查看全部',
  review: '评论',
  restaurant_name: '餐厅名称',
  fake_time: '2024年11月18日星期六',
  fake_tab: {
    hot_dishes: '热菜',
    cold_dishes: '凉菜',
    soup: '汤',
    grill: '烤肉',
    appetizer: '开胃菜',
    dessert: '甜点',
  },
  choose_dishes: '选择菜品',
  bowls_available: '可用碗',
  item: '项目',
  qty: '数量',
  price: '价格',
  fake_order_note: '请，稍微辣一点。',
  discount: '折扣',
  sub_total: '小计',
  continue_to_payment: '继续付款',
  save: '保存',
  dark_mode: '深色模式',
  light_mode: '浅色模式',
  color: {
    black: '黑色',
    white: '白色',
    light_red: '浅红色',
    light_green: '浅绿色',
    light_blue: '浅蓝色',
    light_orange: '浅橙色',
    light_gray: '浅灰色',
    light_cyan: '浅青色',
  },
  label_common: {
    for_here: '堂食',
    to_go: '外带',
    pick_up: '取餐',
    late: '迟到',
    on_time: '准时',
    caution: '注意',
    small: '小',
    medium: '中',
    large: '大',
    extra_large: '特大',
  },
  kds: {
    standard_screen_option: '标准屏幕选项',
    default_t: '默认',
    default_d: '自上而下，自左而右。票据填充到适合的位置',
    classic_t: '经典',
    classic_d: '自上而下，自左而右。票据填充到适合的位置',
    caution_time: '注意时间',
    late_time: '迟到时间',
    order_received_sms: '已收到订单短信',
    order_bumped_from_kds_screen_sms: '从KDS屏幕推出订单短信',
    order_is_ready_for_pick_up_sms: '订单已准备好取货短信',
    transition_time: '过渡时间',
    font_and_color: '字体和颜色',
    font_size: '字体大小',
    status_color: '状态颜色',
    order_type: '订单类型',
    mesaging: '消息',
  },
  min: '分钟',
  on: '开启',
  off: '关闭',
  see_with: '查看',
  import: '导入',
  week: '周',
  download_successful: '下载成功',
  import_file_successful: '导入文件成功',
  import_file_error: '导入文件错误',
  choose_week: '选择周',
  notification: '通知',
  not_done: '未完成',
  done: '已完成',
  in_progress: '进行中',
  see_detail: '查看详情',
  mail: '邮件',
  new_mail: '新邮件',
  important: '重要',
  show_less: '显示更少',
  collapse: '折叠',
  last_time_update: '上次更新时间',
  service: '服务',
  month: '月',
  import_file: '导入文件',
  cancel: '取消',
  export_excel: '导出 Excel',
  download_example_import_file: '下载导入文件示例',
  solutions: '解决方案',
  vision: '愿景',
  programs: '程序',
  blog: '博客',
  product: '产品',
  pricing: '定价',
  resource: '资源',
  customer_login: '客户登录',
  sign_up: '注册',
  watch_free_demo: '观看免费演示',
  sign_in_to_stay_connected: '登录以保持连接。',
  or_sign_in_with_other_accounts: '或使用其他帐户登录？',
  dont_have_an_account: '还没有帐号？',
  click_here_to_sign_up: '点击这里注册。',
  sign_in: '登录',
  create_your_hope_ui_account: '创建您的 Hope UI 帐户',
  first_name: '名字',
  last_name: '姓氏',
  phone_no: '电话号码',
  confirm_password_incorrect: '确认密码不正确',
  i_agree_with_the_terms_of_use: '我同意使用条款',
  or_sign_up_with_other_accounts: '或使用其他帐户注册？',
  already_have_an_account: '已经有一个账户',
  reset: '重置',
  reset_password: '重置密码',
  enter_your_email_address_and_we_ll_send_you_an_email_with_instructions_to_reset_your_password:
    '输入您的电子邮件地址，我们将通过电子邮件发送重置密码的说明',
  back_to_home: '返回主页',
  back_to_login: '返回登录',
  message_verify_email:
    '已向您的 email@domain.com 发送了一封电子邮件。请查收来自公司的电子邮件，并单击内附的链接以重置密码。',
  success: '成功',
  subcribe_now: '立即订阅',
  error: '错误',
  admin: '管理员',
  settings: '设置',
  not_found: '未找到',
  spicy_seasoned_seafood_noodles: '辣味海鲜面',
  salted_pasta_with_mushroom_sauce: '蘑菇酱咸味意面',
  beef_dumpling_in_hot_and_sour_soup: '酸辣汤牛肉饺子',
  healthy_noodle_with_spinach_leaf: '菠菜叶健康面',
  hot_spicy_fried_rice_with_omelet: '炒饭煎蛋辣味',
  spicy_instant_noodle_with_special_omelette: '辣味速食面特色煎蛋',
  spicy_seasoned_seafood_noodles_short: '辣味海鲜面...',
  salted_pasta_with_mushroom_sauce_short: '蘑菇酱咸味意面...',
  spicy_instant_noodle_with_special_omelette_short: '辣味速食面...',
  healthy_noodle_with_spinach_leaf_short: '菠菜叶健康面...',
  select_item_for_upsells: '选择 Upsells 项目',
  recommended_foods: '推荐食品',
  ex: '例如',
  spicy_seasoned_seafood_noodles_strawberry: '辣味海鲜面、草莓',
  account_verification: '帐户验证',
  email_verification: '电子邮件验证',
  account_verification_d1: '我们已向您发送了一封临时的6位登录代码',
  account_verification_d2: '请使用此代码登录到您的帐户。',
  didnt_receive_the_code: '没有收到代码？',
  resend_code_in: '重新发送代码',
  resend_code: '重新发送代码',
  account_verified: '帐户已验证',
  didnt_sync_data: '没有同步数据？',
  click_here_to_connect_square_account: '点击此处连接 Square 帐户',
  change_password: '更改密码',
  card: '卡',
  cardholder_name: '持卡人姓名',
  please_input_cardholder_name: '请输入持卡人姓名！',
  cardholder_name_must_be_at_least_6_characters: '持卡人姓名必须至少6个字符',
  start_subscription: '开始订阅',
  order_summary: '订单摘要',
  Plane: '平面',
  subscription_monthly: '订阅（每月）',
  fourteen_days_free_trail: '14天免费试用',
  subtotal: '小计',
  taxes_if_applicable: '税费（如适用）',
  total: '总计',
  save_this_card_for_future_use: '保存此卡以备将来使用。',
  subcribe_for_free: '免费订阅',
  language_option: {
    en: '英语',
    vi: '越南语',
    ja: '日语',
    es: '西班牙语',
    fr: '法语',
    zh: '中文',
  },
  feature: '功能',
  before_5_minutes: '5分钟前',
  upload: '上传',
  upload_your_image: '上传您的图像',
  confirm: '确认',
  you_sync_data_success: '您已成功同步数据',
  thank_you_for_interest: '感谢您对Kiosify的关注，我们会尽快与您联系。',
}

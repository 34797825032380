import R from 'app/assets/R'
import BaseText from 'app/components/common/BaseText'
import { Loading } from 'app/components/common/Loading'
import { useTranslation } from 'react-i18next'
import { SelectOpenTimeItem } from './SelectOpenTimeItem'
import { useHook } from './hook'
import * as S from './styles'

export const OpenTimeLayout = () => {
  const { t } = useTranslation()
  const {
    days,
    onAddTimeRange,
    onDeleteRangeTime,
    onChangeRangeTime,
    isLoading,
  } = useHook()

  return (
    <S.RootWrapper>
      <BaseText fontSize="xs" fontWeight="semibold">
        {t(R.strings.kiosk_open_time)}
      </BaseText>
      <S.OpenTimeSelectWrapper direction="vertical" size={25}>
        {days.map((item, index) => {
          return (
            <SelectOpenTimeItem
              key={index}
              item={item}
              index={index}
              onAddTimeRangeOfDay={onAddTimeRange}
              onDeleteRangeTime={onDeleteRangeTime}
              onChangeRangeTime={onChangeRangeTime}
            />
          )
        })}
      </S.OpenTimeSelectWrapper>
      {isLoading && <Loading />}
    </S.RootWrapper>
  )
}

import { ApiClient } from 'app/service/api-service'
import { SubscriptionPlanApiEndpoint } from './contant'
import { ICreateSubscriptionPlan, IParamsSubscriptionPlanList } from './model'

export const requestGetSubscriptionPlanList = (
  params: IParamsSubscriptionPlanList,
) =>
  ApiClient.Get({
    url: SubscriptionPlanApiEndpoint.GET_SUB_DETAIL,
    params: { params },
  })

export const requestCreateSubscriptionPlanByEmail = (
  body: ICreateSubscriptionPlan,
) => ApiClient.Post({ url: SubscriptionPlanApiEndpoint.CREATE, body })

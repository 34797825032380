import LoginContainer from 'app/containers/auth/Login'
import { withLoading } from 'app/hocs/with-loading.hoc'
import React from 'react'
import { Route } from 'react-router-dom'
import NotAuth from './not-auth-router'
import {
  AUTH_PATH,
  AUTH_SPLASH_PATH,
  AUTH_VERIFY_EMAIL_CHANGE_PASS_PATH,
  CHANGE_PASSWORD_PATH,
  CHANGE_PASSWORD_SUCCESS_PATH,
  LOGIN_PATH,
  LOGOUT_PATH,
  RESET_PASSWORD_PATH,
  SERVICE_PACKAGE,
  SIGN_UP_PATH,
  VERIFIED_EMAIL_PATH,
  VERIFY_EMAIL_PATH,
} from './route-path'
import { SignUpPage } from 'app/page/authentication/sign-up'
import { ResetPasswordPage } from 'app/page/authentication/reset-password'
import { VerifyEmailPage } from 'app/page/authentication/verify-email'
import { AccountVerifiedPage } from 'app/page/authentication/verified-email'
import { ServicePackagePage } from 'app/page/authentication/service-package'
import { ChangePasswordPage } from 'app/page/authentication/change-password'
import { ChangePasswordSuccessPage } from 'app/page/authentication/change-password-success'
import { SplashLoginPage } from 'app/containers/auth/SplashLogin'
import { VerifyEmailChangePassPage } from 'app/page/authentication/verify-email-change-password'
const Logout = React.lazy(() => import('./logout-router'))
const AuthLayout = React.lazy(() => import('app/components/layouts/AuthLayout'))

const AuthLayoutFallback = withLoading(AuthLayout)
const LogoutFallback = withLoading(Logout)
const Login = withLoading(LoginContainer)
const Splash = withLoading(SplashLoginPage)
const SignUp = withLoading(SignUpPage)
const VerifyEmail = withLoading(VerifyEmailPage)
const AccountVerified = withLoading(AccountVerifiedPage)
const ServicePackage = withLoading(ServicePackagePage)
const ResetPassword = withLoading(ResetPasswordPage)
const ChangePassword = withLoading(ChangePasswordPage)
const VerifyEmailChangePass = withLoading(VerifyEmailChangePassPage)
const ChangePasswordSuccess = withLoading(ChangePasswordSuccessPage)

const authLayout = (
  <NotAuth>
    <AuthLayoutFallback />
  </NotAuth>
)

export const AuthRouter = () => {
  return (
    <>
      <Route path={AUTH_PATH} element={authLayout}>
        <Route path={LOGIN_PATH} element={<Login />} />
        <Route path={AUTH_SPLASH_PATH} element={<Splash />} />
        <Route path={SIGN_UP_PATH} element={<SignUp />} />
        <Route path={VERIFY_EMAIL_PATH} element={<VerifyEmail />} />
        <Route path={VERIFIED_EMAIL_PATH} element={<AccountVerified />} />
        <Route path={SERVICE_PACKAGE} element={<ServicePackage />} />
        <Route path={RESET_PASSWORD_PATH} element={<ResetPassword />} />
        <Route path={CHANGE_PASSWORD_PATH} element={<ChangePassword />} />
        <Route
          path={AUTH_VERIFY_EMAIL_CHANGE_PASS_PATH}
          element={<VerifyEmailChangePass />}
        />
        <Route
          path={CHANGE_PASSWORD_SUCCESS_PATH}
          element={<ChangePasswordSuccess />}
        />
      </Route>
      <Route path={LOGOUT_PATH} element={<LogoutFallback />} />
    </>
  )
}

// Authentication
export const AUTH_PATH = '/auth'
export const LOGOUT_PATH = '/logout'
export const LOGIN_PATH = 'login'
export const VERIFY_EMAIL_PATH = 'verify-email'
export const SIGN_UP_PATH = 'sign-up'
export const SERVICE_PACKAGE = 'service-package'
export const RESET_PASSWORD_PATH = 'reset-password'
export const CHANGE_PASSWORD_PATH = 'change-password'
export const CHANGE_PASSWORD_SUCCESS_PATH = 'change-password-success'

export const AUTH_SPLASH_PATH = '/auth/splash'
export const AUTH_LOGIN_PATH = '/auth/login'
export const AUTH_SIGN_UP_PATH = '/auth/sign-up'
export const AUTH_SERVICE_PACKAGE_PATH = '/auth/service-package'
export const AUTH_VERIFY_EMAIL_PATH = '/auth/verify-email'
export const AUTH_VERIFY_EMAIL_CHANGE_PASS_PATH =
  '/auth/verify-email-change-pass'
export const AUTH_RESET_PASSWORD_PATH = '/auth/reset-password'
export const AUTH_CHANGE_PASSWORD_PATH = '/auth/change-password'
export const AUTH_CHANGE_PASSWORD_SUCCESS_PATH = '/auth/change-password-success'

// Main
export const DASHBOARD_PATH = '/'

export const ADMIN_PATH = {
  HOME: '/admin',
  DASHBOARD: 'dashboard',
  REPORTS: 'report',
  SETTING: 'settings',
  ADMIN_DASHBOARD: '/admin/dashboard',
  ADMIN_REPORTS: '/admin/report',
  ADMIN_SETTINGS: '/admin/settings',
}

export const PRODUCT_PATH = '/product'
export const SERVICE_PATH = '/service'
export const PRICING_PATH = '/pricing'
export const CONTACT_US_PATH = '/contact_us'

export const PROFILE_PATH = '/profile'

// svg import auto

export { ReactComponent as Svg1AppeaIcon} from './svg/svg_1_appea.svg';
export { ReactComponent as Svg2AppeaIcon} from './svg/svg_2_appea.svg';
export { ReactComponent as Svg3AppeaIcon} from './svg/svg_3_appea.svg';
export { ReactComponent as Svg4AppeaIcon} from './svg/svg_4_appea.svg';
export { ReactComponent as Svg5AppeaIcon} from './svg/svg_5_appea.svg';
export { ReactComponent as SvgArrowSeparateIcon} from './svg/svg_arrow_separate.svg';
export { ReactComponent as SvgArrowTrendingDownIcon} from './svg/svg_arrow_trending_down.svg';
export { ReactComponent as SvgArrowTrendingUpIcon} from './svg/svg_arrow_trending_up.svg';
export { ReactComponent as SvgBlockItemIcon} from './svg/svg_block_item.svg';
export { ReactComponent as SvgBoxIsoIcon} from './svg/svg_box_iso.svg';
export { ReactComponent as SvgCaretDownIcon} from './svg/svg_caret_down.svg';
export { ReactComponent as SvgCaretRightCollapseIcon} from './svg/svg_caret_right_collapse.svg';
export { ReactComponent as SvgCaretUpCollapseIcon} from './svg/svg_caret_up_collapse.svg';
export { ReactComponent as SvgChartDashboardIcon} from './svg/svg_chart_dashboard.svg';
export { ReactComponent as SvgCheckedIcon} from './svg/svg_checked.svg';
export { ReactComponent as SvgCircleAddIcon} from './svg/svg_circle_add.svg';
export { ReactComponent as SvgClockIcon} from './svg/svg_clock.svg';
export { ReactComponent as SvgCustomerRestautantIcon} from './svg/svg_customer_restautant.svg';
export { ReactComponent as SvgDashboardIcon} from './svg/svg_dashboard.svg';
export { ReactComponent as SvgDashboardExampleIcon} from './svg/svg_dashboard_example.svg';
export { ReactComponent as SvgDashboardFocusIcon} from './svg/svg_dashboard_focus.svg';
export { ReactComponent as SvgDeleteIcon} from './svg/svg_delete.svg';
export { ReactComponent as SvgDesktopIcon} from './svg/svg_desktop.svg';
export { ReactComponent as SvgDishOrderRestaurantIcon} from './svg/svg_dish_order_restaurant.svg';
export { ReactComponent as SvgDragVerticalIcon} from './svg/svg_drag_vertical.svg';
export { ReactComponent as SvgFinanceIcon} from './svg/svg_finance.svg';
export { ReactComponent as SvgGlobeIcon} from './svg/svg_globe.svg';
export { ReactComponent as SvgHalfMoonIcon} from './svg/svg_half_moon.svg';
export { ReactComponent as SvgHomeDashboardIcon} from './svg/svg_home_dashboard.svg';
export { ReactComponent as SvgLanguageIcon} from './svg/svg_language.svg';
export { ReactComponent as SvgLogoIcon} from './svg/svg_logo.svg';
export { ReactComponent as SvgMapPinIcon} from './svg/svg_map_pin.svg';
export { ReactComponent as SvgNoteIcon} from './svg/svg_note.svg';
export { ReactComponent as SvgOrdersRestaurantIcon} from './svg/svg_orders_restaurant.svg';
export { ReactComponent as SvgPageFlipIcon} from './svg/svg_page_flip.svg';
export { ReactComponent as SvgPictureIcon} from './svg/svg_picture.svg';
export { ReactComponent as SvgPlayIcon} from './svg/svg_play.svg';
export { ReactComponent as SvgPrinterIcon} from './svg/svg_printer.svg';
export { ReactComponent as SvgPurchasingManagementIcon} from './svg/svg_purchasing_management.svg';
export { ReactComponent as SvgPurchasingManagementFocusIcon} from './svg/svg_purchasing_management_focus.svg';
export { ReactComponent as SvgReportIcon} from './svg/svg_report.svg';
export { ReactComponent as SvgReportFocusIcon} from './svg/svg_report_focus.svg';
export { ReactComponent as SvgRocketIcon} from './svg/svg_rocket.svg';
export { ReactComponent as SvgSaleRevenueRestaurantIcon} from './svg/svg_sale_revenue_restaurant.svg';
export { ReactComponent as SvgSelectBlockIcon} from './svg/svg_select_block.svg';
export { ReactComponent as SvgSelectListIcon} from './svg/svg_select_list.svg';
export { ReactComponent as SvgSettingAdminBlackIcon} from './svg/svg_setting_admin_black.svg';
export { ReactComponent as SvgSettingDashboardIcon} from './svg/svg_setting_dashboard.svg';
export { ReactComponent as SvgSortAscendingIcon} from './svg/svg_sort_ascending.svg';
export { ReactComponent as SvgSortDescIcon} from './svg/svg_sort_desc.svg';
export { ReactComponent as SvgSquareCheckIcon} from './svg/svg_square_check.svg';
export { ReactComponent as SvgSunLightIcon} from './svg/svg_sun_light.svg';
export { ReactComponent as SvgTableCloseIcon} from './svg/svg_table_close.svg';
export { ReactComponent as SvgTableOpenIcon} from './svg/svg_table_open.svg';
export { ReactComponent as SvgTimerIcon} from './svg/svg_timer.svg';
export { ReactComponent as SvgUnCheckedCreateItemIcon} from './svg/svg_un_checked_create_item.svg';
export { ReactComponent as SvgVoiceCircleIcon} from './svg/svg_voice_circle.svg';
export { ReactComponent as SvgWalkingIcon} from './svg/svg_walking.svg';
        
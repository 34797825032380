import R from 'app/assets/R'
import { PageTitle } from 'app/components/common/PageTitle'
import ReportContainer from 'app/containers/admin/Reports'
import { useTranslation } from 'react-i18next'

const ReportsPage = () => {
  const { t } = useTranslation()
  return (
    <>
      <PageTitle>{t(R.strings.reports_common_title)}</PageTitle>
      <ReportContainer />
    </>
  )
}
export default ReportsPage

import { ArrowUpOutlined } from '@ant-design/icons'
import { Card } from 'antd'
import { IDataReportTotal } from 'app/api/dashboard/type'
import R from 'app/assets/R'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { Loading } from 'app/components/common/Loading'
import { formatMoneyDollar } from 'app/hook'
import { convertColor } from 'app/styles/themes/themeVariables'
import { FONT_WEIGHT } from 'parkway-web-common'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const StyledCard = styled(Card)`
  width: 100%;
  border-radius: 12px;
  background-color: white;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;

  .ant-card-body {
    padding: 0;
  }

  .total-item {
    background-color: #fafafa;
    border: 1px solid #efefef;
    padding: 12px;
    border-radius: 12px;
  }
`

const PercentageChange = styled.div<{
  $up?: boolean
  $isShowBackground?: boolean
}>`
  font-size: 12px;
  color: ${({ $up }) => ($up ? '#6dc24f' : '#FF6A55')};
  display: flex;
  align-items: center;
  font-weight: ${FONT_WEIGHT.regular};

  padding: 2px 8px;
  border-radius: 12px;
  ${props => {
    if (!props?.$isShowBackground) return ''
    return `background-color: ${
      props.$up
        ? convertColor.statesGreenLightColor
        : convertColor.statesRedLightColor
    };
    
    font-weight: ${FONT_WEIGHT.medium};
    `
  }}
  svg {
    margin-right: 4px;
    fill: ${({ $up }) => ($up ? '#6dc24f' : '#FF6A55')};
  }
`

const RevenueCard: React.FC<{
  loading: boolean
  reportTotal: IDataReportTotal
}> = ({ loading, reportTotal }) => {
  const { t } = useTranslation()
  return (
    <StyledCard>
      <BaseSpace>
        <BaseSpace>
          <BaseText
            children={t(R.strings.reports_common_total_revenue)}
            fontSize="xl"
            fontWeight="regular"
          />

          <BaseRow gutter={[30, 8]} align={'middle'} justify={'start'}>
            <BaseCol>
              <BaseText
                children={formatMoneyDollar(
                  reportTotal?.total_revenue?.total_amount ?? 0,
                  reportTotal?.total_revenue?.currency ?? 'USD',
                )}
                style={{ fontSize: 40 }}
                fontWeight="bold"
              />
            </BaseCol>
            <BaseCol>
              <PercentageChange $isShowBackground $up>
                <ArrowUpOutlined rev={undefined} /> 0.00%
              </PercentageChange>
            </BaseCol>
          </BaseRow>
        </BaseSpace>

        <BaseRow
          gutter={16}
          align={'middle'}
          justify={'space-between'}
          className="total-item"
        >
          <BaseCol>
            <BaseText
              children={t(R.strings.reports_common_total_dish_ordered)}
              fontSize="xs"
              fontWeight="medium"
            />
          </BaseCol>

          <BaseCol>
            <BaseRow gutter={[4, 4]} align={'middle'}>
              <BaseCol>
                <PercentageChange $up>
                  <ArrowUpOutlined rev={undefined} /> 0%
                </PercentageChange>
              </BaseCol>
              <BaseCol>
                <BaseText
                  children={formatMoneyDollar(
                    reportTotal?.total_dish_ordered ?? 0,
                    '',
                  )}
                  fontSize="lg"
                  fontWeight="bold"
                />
              </BaseCol>
            </BaseRow>
          </BaseCol>
        </BaseRow>

        <BaseRow
          gutter={16}
          align={'middle'}
          justify={'space-between'}
          className="total-item"
        >
          <BaseCol>
            <BaseText
              children={t(R.strings.reports_common_total_customer)}
              fontSize="xs"
              fontWeight="medium"
            />
          </BaseCol>

          <BaseCol>
            <BaseRow gutter={[4, 4]} align={'middle'}>
              <BaseCol>
                <PercentageChange $up>
                  <ArrowUpOutlined rev={undefined} /> 0%
                </PercentageChange>
              </BaseCol>
              <BaseCol>
                <BaseText
                  children={formatMoneyDollar(
                    reportTotal?.total_customer ?? 0,
                    '',
                  )}
                  fontSize="lg"
                  fontWeight="bold"
                />
              </BaseCol>
            </BaseRow>
          </BaseCol>
        </BaseRow>
      </BaseSpace>

      {loading ? <Loading /> : null}
    </StyledCard>
  )
}

export default RevenueCard

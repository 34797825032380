import React, { useState } from 'react'
import { Outlet } from 'react-router-dom'
import MainContent from '../MainContent'
import MainSlider from '../sider/MainSider'
import * as S from './styles'

const MainLayoutAdmin: React.FC = () => {
  // const [isTwoColumnsLayout, setIsTwoColumnsLayout] = useState(true)
  const [siderCollapsed, setSiderCollapsed] = useState(true)
  // const { isDesktop } = useResponsive()
  // const location = useLocation()

  // const toggleSider = () => setSiderCollapsed(!siderCollapsed)

  // useEffect(() => {
  //   setIsTwoColumnsLayout(
  //     [ADMIN_PATH.DASHBOARD].includes(location.pathname) && isDesktop,
  //   )
  // }, [location.pathname, isDesktop])

  return (
    <S.LayoutMaster>
      <MainSlider
        isCollapsed={siderCollapsed}
        setCollapsed={setSiderCollapsed}
      />
      <S.LayoutMain>
        <MainContent id="main-content" $isTwoColumnsLayout={false}>
          <div style={{ height: '100%' }}>
            <Outlet />
          </div>
        </MainContent>
      </S.LayoutMain>
    </S.LayoutMaster>
  )
}

export default MainLayoutAdmin

import { SvgDashboardExampleIcon } from 'app/assets/svg-assets'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseImage } from 'app/components/common/BaseImage'
import { PaddingMain } from 'app/constant'
import { FONT_SIZE, FONT_WEIGHT, PADDING, colors } from 'parkway-web-common'
import styled from 'styled-components'

export const RootWrapper = styled(BaseCol)`
  padding: ${PaddingMain.Mobile.defaultHz} 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`

export const AreaText = styled.div`
  padding: ${PADDING.xxs} 0px;
  text-align: left;
  font-family: 'Plus Jakarta Sans', sans-serif !important;
`

export const ImageWrapper = styled(SvgDashboardExampleIcon)`
  width: 100%;
  height: 100%;
`

export const TitleText = styled.span`
  font-size: 36px;
  font-weight: ${FONT_WEIGHT.bold};
  color: ${colors.black};
  text-align: center;
  display: flex;
  font-family: 'Plus Jakarta Sans', sans-serif !important;
`

export const ItemWrapper = styled.div`
  padding: ${PADDING.xl};
  border-radius: 30px;
  cursor: pointer;
  width: 100%;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  transition: all 1s ease;
  height: 100%;
  &:hover {
    transition: all 1s ease;
    box-shadow: rgba(13, 148, 138, 0.3) 0px 0px 50px 20px,
      rgba(13, 148, 138, 0.2) 0px 10px 10px -5px;
  }
`

export const TextTitleItem = styled.span`
  font-size: 30px;
  font-weight: ${FONT_WEIGHT.semibold};
  color: ${colors.black};
  font-family: 'Plus Jakarta Sans', sans-serif !important;
`
export const DescriptionItem = styled.span`
  font-size: ${FONT_SIZE.md};
  font-weight: ${FONT_WEIGHT.regular};
  color: ${colors.black};
  font-family: 'Plus Jakarta Sans', sans-serif !important;
`

export const IconBase = styled(BaseImage)`
  width: 100px !important;
  height: 100px !important;
`

export const ColItem = styled(BaseCol)`
  width: 100%;
`

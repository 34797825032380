import ContentLayout from './content-layout'
import * as S from './styles'

const LayoutTwoHome = () => {
  return (
    <S.RootWrapper>
      <ContentLayout />
    </S.RootWrapper>
  )
}
export default LayoutTwoHome

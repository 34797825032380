import R from 'app/assets/R'
import _ from 'lodash'
import {
  CONTACT_US_PATH,
  PRICING_PATH,
  PRODUCT_PATH,
  SERVICE_PATH,
} from '../../router/route-path'

export interface IHeaderMenu {
  id?: number
  titleKeyI18n: string
  path: string
}

// path need to change in future
export const DataHeaderMenu: IHeaderMenu[] = [
  {
    id: _.random(100),
    titleKeyI18n: R.strings.feature,
    path: PRODUCT_PATH,
  },
  {
    id: _.random(100),
    titleKeyI18n: R.strings.service,
    path: SERVICE_PATH,
  },
  {
    id: _.random(100),
    titleKeyI18n: R.strings.pricing,
    path: PRICING_PATH,
  },
  {
    id: _.random(100),
    titleKeyI18n: R.strings.contact_us,
    path: CONTACT_US_PATH,
  },
]

/* eslint-disable @typescript-eslint/no-explicit-any */
import { ArrowLeftOutlined } from '@ant-design/icons'
import { LoginResponse, requestPostLogin } from 'app/api/auth'
import { requestCreateSubscriptionPlanByEmail } from 'app/api/subscription-plan/api'
import {
  ICreateSubscriptionPlan,
  ISubscriptionPlan,
} from 'app/api/subscription-plan/model'
import R from 'app/assets/R'
import { ENV_CONFIG } from 'app/common/config'
import { PackageEnum } from 'app/common/enum'
import { IResponseDefine } from 'app/common/model'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import { Loading } from 'app/components/common/Loading'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import * as Auth from 'app/components/layouts/AuthLayout/styles'
import { ADMIN_PATH, AUTH_LOGIN_PATH } from 'app/components/router/route-path'
import { SubscriptionPlanData } from 'app/constant'
import { subscriptionPlans } from 'app/containers/landing-page/components/desktop-layout/LayoutThree'
import { notificationController } from 'app/controllers/notification-controller'
import { formatMoneyDollar, usePagination } from 'app/hook'
import { useGetSubscriptionPlanList } from 'app/react-query/hook/subscription-plan'
import { useAppDispatch } from 'app/redux/hooks'
import { setUser } from 'app/redux/slices/userSlice'
import {
  getEmailAuth,
  getPassword,
  getServicePackage,
  setEmailAuth,
  setPassword,
  setToken,
} from 'app/service/storage/storage-service'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import * as S from './styles'
import './styles.css'

interface ServicePackageFormData {
  cardholderName?: string
}

export const initValues: ServicePackageFormData = {
  cardholderName: '',
}

const ServicePackageContainer: React.FC = () => {
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [form] = BaseForm.useForm<ServicePackageFormData>()

  const email = getEmailAuth()
  const password = getPassword()

  const goBack = () => {
    navigate(-1)
  }

  useEffect(() => {
    if (!email) {
      goBack()
    }
  }, [email, password])

  const service = getServicePackage()

  const [servicePackage, setServicePackage] = useState(
    service ??
      subscriptionPlans()?.[0]?.title ??
      SubscriptionPlanData[PackageEnum.Basic].name,
  )

  const { data: dataSubscriptionPlanBefore } = useGetSubscriptionPlanList()

  const { flattenDataList } = usePagination()

  const dataSubscriptionPlan: ISubscriptionPlan[] = useMemo(() => {
    return flattenDataList(dataSubscriptionPlanBefore)
  }, [dataSubscriptionPlanBefore])

  const itemSelected = useMemo(() => {
    return subscriptionPlans().find(item => item.package === servicePackage)
  }, [servicePackage, i18n.language])

  const dataPricingResult = useMemo(() => subscriptionPlans(), [i18n.language])

  const price = useMemo(() => {
    return itemSelected?.priceNumber ?? 0
  }, [itemSelected])

  const [isLoading, setLoading] = useState(false)

  const cardTokenizeResponseReceived = async () => {
    setLoading(true)
    form.submit()
    try {
      if (email && servicePackage) {
        // const body: IBodySendPaymentInfo = {
        //   email,
        //   cardholder_name: form.getFieldValue('cardholderName'),
        //   source_id: data?.token,
        // }

        const itemSubScription = dataSubscriptionPlan?.find(
          itm => itm?.name === servicePackage,
        )

        const bodySubscriptionPlan: ICreateSubscriptionPlan = {
          email,
          item_id: itemSubScription?.item_id ?? itemSelected?.item_id,
          plan_id: itemSubScription?.plan_id ?? itemSelected?.plan_id,
        }

        await requestCreateSubscriptionPlanByEmail(bodySubscriptionPlan)

        // try {
        //   await requestPostCardOnFile(body)
        // } catch (error) {
        //   console.log({ error })
        // }

        setServicePackage(servicePackage)
        if (password) {
          const res: IResponseDefine<LoginResponse> = await requestPostLogin({
            email,
            password,
          })
          const data = res?.data
          if (
            data?.user_steps?.step_sync_from_square &&
            data?.user_steps?.step_verified_email &&
            data?.user_steps?.step_payment_info
          ) {
            setToken(res.data.access_token)
            dispatch(setUser(res.data.user))
            navigate(ADMIN_PATH.HOME)
            setPassword('')
            setEmailAuth('')
            notificationController.success({
              message: "You've successfully subscribed to the service.",
            })
            return
          }
        } else {
          navigate(AUTH_LOGIN_PATH, {
            replace: true,
          })
          notificationController.success({
            message: "You've successfully subscribed to the service.",
          })
          return
        }
        console.log('done')
      }
    } catch (error: any) {
      console.log({ error })
      // notificationController.error(error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Auth.PaymentBgWrapper>
      <Auth.ServicePackageWrapper>
        <S.LayoutMain
          gutter={[30, 10]}
          justify={'space-evenly'}
          align={'middle'}
          wrap={false}
        >
          <BaseCol xl={12}>
            <S.BaseFormWrapper minWidth="320px">
              <BaseForm
                layout="vertical"
                requiredMark="optional"
                initialValues={initValues}
                size={'large'}
                form={form}
              >
                <S.FormWrapper direction="vertical" size={20}>
                  <S.FormTitle>{'Payment'}</S.FormTitle>
                  <BaseRow justify={'start'} align={'middle'} gutter={[10, 10]}>
                    {dataPricingResult.map((item, index) => {
                      const isSelected = servicePackage === item?.package
                      return (
                        <BaseCol
                          key={index}
                          onClick={() => setServicePackage(item?.package)}
                        >
                          <S.TextServicePackage isSelected={isSelected}>
                            {item?.title}
                          </S.TextServicePackage>
                          <S.ItemServicePackage isSelected={isSelected}>
                            <BaseRow
                              justify={'start'}
                              align={'middle'}
                              gutter={[5, 5]}
                            >
                              <S.CircleComponent isSelected={isSelected}>
                                <div className="small-circle" />
                              </S.CircleComponent>
                              <BaseCol>{item?.price}</BaseCol>
                            </BaseRow>
                          </S.ItemServicePackage>
                        </BaseCol>
                      )
                    })}
                  </BaseRow>

                  <BaseSpace direction="vertical" size={10}>
                    <S.TextServicePackage isSelected={true}>
                      {t(R.strings.card)}
                    </S.TextServicePackage>
                    <S.ItemServicePackage isSelected={true}>
                      <BaseRow
                        justify={'start'}
                        align={'middle'}
                        gutter={[10, 5]}
                      >
                        <S.CircleComponent isSelected={true}>
                          <div className="small-circle" />
                        </S.CircleComponent>
                        <BaseCol>
                          <S.ImageCard
                            src={R.images.ic_master_card}
                            width={24}
                            height={24}
                            preview={false}
                          />
                        </BaseCol>
                        <BaseCol>
                          <S.ImageCard
                            src={R.images.ic_visa}
                            width={24}
                            height={24}
                            preview={false}
                          />
                        </BaseCol>
                        <BaseCol>
                          <S.ImageCard
                            src={R.images.ic_JCB}
                            width={24}
                            height={24}
                            preview={false}
                          />
                        </BaseCol>
                        <BaseCol>
                          <S.ImageCard
                            src={R.images.ic_american_express}
                            width={24}
                            height={24}
                            preview={false}
                          />
                        </BaseCol>
                      </BaseRow>
                    </S.ItemServicePackage>
                  </BaseSpace>

                  <S.FormWrapper direction="vertical" size={10}>
                    <S.TextCardInfo>{'Payment Details'}</S.TextCardInfo>
                    <S.FormItem
                      name="cardholderName"
                      label={t(R.strings.cardholder_name)}
                      rules={[
                        {
                          required: true,
                          message: t(R.strings.please_input_cardholder_name),
                        },
                        {
                          min: 6,
                          message: t(
                            R.strings
                              .cardholder_name_must_be_at_least_6_characters,
                          ),
                        },
                      ]}
                    >
                      <Auth.FormInput
                        placeholder={t(R.strings.cardholder_name)}
                      />
                    </S.FormItem>
                    <S.PaymentFormWrapper
                      applicationId={ENV_CONFIG?.APPLICATION_PAYMENT_SQUARE_ID}
                      locationId={ENV_CONFIG?.LOCATION_ID}
                      cardTokenizeResponseReceived={
                        cardTokenizeResponseReceived
                      }
                    >
                      <S.CreditCardWrapper>
                        <S.ButtonSubmit>
                          {t(R.strings.start_subscription)}
                        </S.ButtonSubmit>
                      </S.CreditCardWrapper>
                    </S.PaymentFormWrapper>
                  </S.FormWrapper>
                </S.FormWrapper>
              </BaseForm>
            </S.BaseFormWrapper>
          </BaseCol>
          <BaseCol xl={12}>
            <S.InfoPackageWrapper direction="vertical" size={20}>
              <S.BackButton onClick={goBack}>
                <ArrowLeftOutlined rev={undefined} />
              </S.BackButton>

              <S.TextOrderSummary>
                {t(R.strings.order_summary)}
              </S.TextOrderSummary>

              <S.InfoPackageItem
                justify={'space-between'}
                align={'middle'}
                gutter={[10, 10]}
              >
                <BaseCol>
                  <BaseSpace direction="vertical" size={10}>
                    <S.TextOrderSummary>{`${itemSelected?.title} ${t(
                      R.strings.Plane,
                    )}`}</S.TextOrderSummary>
                    <BaseSpace direction="vertical" size={3}>
                      <S.TextOrderSummaryDescription>
                        {t(R.strings.subscription_monthly)}
                      </S.TextOrderSummaryDescription>
                      <S.TextOrderSummaryDescriptionTrial>
                        {t(R.strings.fourteen_days_free_trail)}
                      </S.TextOrderSummaryDescriptionTrial>
                    </BaseSpace>
                  </BaseSpace>
                </BaseCol>
                <BaseCol>
                  <S.TextOrderSummary>{`${itemSelected?.price}`}</S.TextOrderSummary>
                </BaseCol>
              </S.InfoPackageItem>

              <S.TotalPaymentWrapper direction="vertical" size={15}>
                <S.ItemWrapperPayment
                  justify={'space-between'}
                  align={'middle'}
                  gutter={[10, 10]}
                >
                  <BaseCol className="text">{t(R.strings.sub_total)}</BaseCol>
                  <BaseCol className="text">{`${formatMoneyDollar(
                    itemSelected?.priceNumber ?? 0,
                  )}`}</BaseCol>
                </S.ItemWrapperPayment>
                <S.ItemWrapperPayment
                  justify={'space-between'}
                  align={'middle'}
                  gutter={[10, 10]}
                >
                  <BaseCol className="text">
                    {t(R.strings.taxes_if_applicable)}
                  </BaseCol>
                  <BaseCol className="text">{`${formatMoneyDollar(
                    '0',
                  )}`}</BaseCol>
                </S.ItemWrapperPayment>
                <S.ItemWrapperPayment
                  justify={'space-between'}
                  align={'middle'}
                  gutter={[10, 10]}
                >
                  <BaseCol className="total">{t(R.strings.total)}</BaseCol>
                  <BaseCol className="total">{`${formatMoneyDollar(
                    price,
                  )}`}</BaseCol>
                </S.ItemWrapperPayment>
              </S.TotalPaymentWrapper>
              <S.CheckBox>
                <S.TextOrderSummaryDescription>
                  {t(R.strings.save_this_card_for_future_use)}
                </S.TextOrderSummaryDescription>
              </S.CheckBox>
            </S.InfoPackageWrapper>
          </BaseCol>
        </S.LayoutMain>
        {isLoading && <Loading />}
      </Auth.ServicePackageWrapper>
    </Auth.PaymentBgWrapper>
  )
}
export default ServicePackageContainer

import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as S from './ProfileOverlay.styles';
import { LOGOUT_PATH, PROFILE_PATH } from 'app/components/router/route-path';
import R from 'app/assets/R';

export const ProfileOverlay: React.FC = ({ ...props }) => {
  const { t } = useTranslation();

  return (
    <div {...props}>
      <S.Text>
        <Link to={PROFILE_PATH}>{t(R.strings.profile)}</Link>
      </S.Text>
      <S.ItemsDivider />
      <S.Text>
        <Link to={LOGOUT_PATH}>{t(R.strings.logout)}</Link>
      </S.Text>
    </div>
  );
};

import R from 'app/assets/R'
import { BaseButton } from 'app/components/common/BaseButton'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseImage } from 'app/components/common/BaseImage'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import { BORDER_RADIUS_2, PaddingMain } from 'app/constant'
import {
  BORDER_RADIUS,
  FONT_SIZE,
  FONT_WEIGHT,
  PADDING,
  colors,
} from 'parkway-web-common'
import styled from 'styled-components'

export const RootWrapper = styled(BaseCol)`
  padding: ${PaddingMain.Desktop.defaultHz} 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 100px;
`

export const AreaText = styled.div`
  padding: ${PADDING.xxxxs} 0px;
  text-align: left;
  font-family: 'Plus Jakarta Sans', sans-serif !important;
`

export const TitleText = styled.span`
  font-size: 35px;
  font-weight: ${FONT_WEIGHT.bold};
  color: ${colors.black};
  text-align: center;
  display: flex;
  justify-content: center;
  font-family: 'Plus Jakarta Sans', sans-serif !important;
  width: 100%;

  display: inline-block; /* Để phù hợp với kích thước của chữ */
  background-image: linear-gradient(
    to bottom,
    #000000,
    #3c9f87
  ); /* Màu sắc của đường kẻ */
  -webkit-background-clip: text; /* Cắt nền theo chữ cho Safari */
  -webkit-text-fill-color: transparent; /* Làm cho màu chữ trong suốt để hiển thị gradient */
  background-clip: text; /* Cắt nền theo chữ */
  color: transparent; /* Màu chữ ban đầu là trong suốt */
`
export const TitleTextEfficiency = styled.span`
  font-size: 35px;
  font-weight: ${FONT_WEIGHT.bold};
  color: ${colors.black};
  text-align: center;
  display: flex;
  justify-content: center;
  font-family: 'Plus Jakarta Sans', sans-serif !important;
  width: 100%;

  display: inline-block; /* Để phù hợp với kích thước của chữ */
  background-image: linear-gradient(
    to bottom,
    #276657,
    #60ffd9
  ); /* Màu sắc của đường kẻ */
  -webkit-background-clip: text; /* Cắt nền theo chữ cho Safari */
  -webkit-text-fill-color: transparent; /* Làm cho màu chữ trong suốt để hiển thị gradient */
  background-clip: text; /* Cắt nền theo chữ */
  color: transparent; /* Màu chữ ban đầu là trong suốt */
`

export const DescriptionText = styled.span`
  color: ${colors.black};
  font-size: 12x;
  font-weight: ${FONT_WEIGHT.medium};
  display: flex;
  justify-content: center;
  font-family: 'Plus Jakarta Sans', sans-serif !important;
`

export const ListWrapper = styled(BaseSpace)`
  width: 100%;
  position: relative;
  padding-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const ContentWrapper = styled.div`
  border-radius: ${BORDER_RADIUS_2};
  padding: 32px 8px;
  background-color: ${colors.white};
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  width: 100%;
  transition: all 0.5s ease;
  cursor: pointer;
  position: absolute;
  top: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 820px;
  &:hover {
    padding-top: 8px;
    .title {
      transition: all 0.25s ease;
      font-size: 34px;
    }
    .description {
      transition: all 0.25s ease;
      font-size: ${FONT_SIZE.xl};
    }
    .price {
      transition: all 0.25s ease;
      font-size: 42px;
    }
    .icon {
      transition: all 0.25s ease;
      width: 48px !important;
    }
    .button-trial {
      transition: all 0.5s ease;
      background-color: var(--green-button-color); // need change
      color: ${colors.white};
    }
    transition: all 0.5s ease;
    box-shadow: rgba(149, 157, 165, 0.5) 0px 0px 50px 20px,
      rgba(149, 157, 165, 0.5) 0px 10px 10px -5px;
    top: -70px;
  }
`

export const ContentWrapper2 = styled.div`
  border-radius: ${BORDER_RADIUS_2};
  width: 100%;
  transition: all 0.5s ease;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const ItemWrapper = styled(BaseCol)`
  position: relative;
  height: 500px;
  min-width: 250px;
  max-width: 330px;
`

export const IconItem = styled(BaseImage)`
  width: 42px !important;
  transition: all 0.25s ease;
`

export const TitleItem = styled.span`
  color: ${colors.black};
  font-size: 30px;
  font-weight: ${FONT_WEIGHT.bold};
  text-align: center;
  transition: all 0.25s ease;
`

export const DescriptionItem = styled.p`
  color: ${colors.black};
  font-size: ${FONT_SIZE.md};
  font-weight: ${FONT_WEIGHT.semibold};
  text-align: center;
  font-family: 'Plus Jakarta Sans', sans-serif !important;
`

export const TextPriceItem = styled.p`
  color: ${colors.black};
  font-weight: ${FONT_WEIGHT.bold};
  font-size: 36px;
  font-family: 'Plus Jakarta Sans', sans-serif !important;
  text-align: center;
`
export const ListBenefitOption = styled(BaseRow)`
  width: 100%;
  padding: 60px 0px;
  .anticon svg {
    fill: black;
  }
`
export const TextOption = styled.span`
  color: ${colors.black};
  font-size: ${FONT_SIZE.xl};
  font-weight: ${FONT_WEIGHT.regular};
  font-family: 'Plus Jakarta Sans', sans-serif !important;
`
export const ButtonTrial = styled(BaseButton)`
  border-radius: ${BORDER_RADIUS};
  padding: ${PADDING.xxl} ${PADDING.xl};
  background-color: ${colors.grayC4}; // need change
  border: none; // need change
  color: ${colors.black};
  transition: all 0.5s ease;
  font-family: 'Plus Jakarta Sans', sans-serif !important;
  width: 100%;

  &:hover {
    transition: all 0.5s ease !important;
    color: ${colors.white} !important;
    box-shadow: rgba(41, 204, 106, 0.3) 0px 0px 30px 10px,
      rgba(41, 204, 106, 0.2) 0px 5px 5px -2px;
  }
`

export const BaseItemCard = styled(BaseSpace)`
  &:hover {
    cursor: url('${R.images.ic_cursor}'), auto;
  }
  padding-bottom: 50px;
  min-width: calc(100vw - 48px);
`

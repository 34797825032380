import { ItemType, MenuItemType } from 'antd/lib/menu/hooks/useItems'
import { BaseImage } from 'app/components/common/BaseImage'
import { ADMIN_PATH } from 'app/components/router/route-path'
import { isEmpty, isEqual } from 'lodash'
import { useResponsive } from 'parkway-web-common'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { SidebarNavigationItem, sidebarNavigation } from '../sidebar-navigation'
import * as S from './styles'

interface SliderContentProps {
  setCollapsed: (isCollapsed: boolean) => void
  isCollapsed?: boolean
}

export const sidebarNavFlat = sidebarNavigation.reduce(
  (result: SidebarNavigationItem[], current) =>
    result.concat(
      current.children && current.children.length > 0
        ? current.children
        : current,
    ),
  [],
)

const SliderMenu: React.FC<SliderContentProps> = ({ setCollapsed }) => {
  const { t } = useTranslation()
  const { mobileOnly } = useResponsive()
  const location = useLocation()
  const navigate = useNavigate()

  const currentMenuItem = useMemo(() => {
    const itemExist = sidebarNavFlat.find(
      ({ url }) => url === location.pathname,
    )

    if (itemExist) {
      return itemExist
    }

    const pathNames = location.pathname
      .split('/')
      .filter(item => !isEmpty(item))

    const commonItem = sidebarNavFlat.find(side => {
      const exist = pathNames.find(item => isEqual(side?.url, `/${item}`))
      return !!exist
    })

    if (commonItem) {
      return commonItem
    }
  }, [sidebarNavFlat, location.pathname])

  const defaultSelectedKeys = currentMenuItem ? [currentMenuItem.key] : []

  const renderLabel = ({
    key,
    nav,
    isSubMenu,
    index,
  }: {
    key: string
    isSubMenu?: boolean
    nav: SidebarNavigationItem
    index: number
  }) => {
    const isSelected = key === defaultSelectedKeys?.[0]
    let isTopItemSelected = false
    let isBottomItemSelected = false

    if (!isSelected) {
      if (sidebarNavigation?.[index - 1]?.key === defaultSelectedKeys?.[0]) {
        isTopItemSelected = true
      }

      if (sidebarNavigation?.[index + 1]?.key === defaultSelectedKeys?.[0]) {
        isBottomItemSelected = true
      }
    }

    return (
      <S.ItemMenuCustomWrapper
        align={'middle'}
        isSelected={isSelected}
        justify={'center'}
        isTopItemSelected={isTopItemSelected}
        isBottomItemSelected={isBottomItemSelected}
        onClick={() => navigate(nav.url ?? ADMIN_PATH.DASHBOARD)}
      >
        <S.ItemMenuCustom
          isSelected={isSelected}
          isTopItemSelected={isTopItemSelected}
          isBottomItemSelected={isBottomItemSelected}
        >
          <S.LabelCustom isSelected={isSelected} isSubMenu={isSubMenu}>
            <BaseImage
              src={isSelected ? nav?.iconFocus : nav.icon}
              width={21}
              height={21}
              preview={false}
              className="image-fill"
            />
          </S.LabelCustom>
        </S.ItemMenuCustom>
      </S.ItemMenuCustomWrapper>
    )
  }

  const onClickMenuItem = () => {
    if (mobileOnly) {
      setCollapsed(true)
    }
  }

  return (
    <S.Menu
      mode="inline"
      defaultSelectedKeys={defaultSelectedKeys}
      onClick={onClickMenuItem}
      items={sidebarNavigation.map((nav, index) => {
        const isSubMenu = nav.children?.length
        return {
          key: nav.key,
          title: t(nav.title),
          label: renderLabel({
            key: nav.key,
            isSubMenu: !!isSubMenu,
            nav,
            index,
          }),
          style: {
            padding: 0,
            paddingInline: 0,
            backgroundColor: 'transparent',
            borderBottomRightRadius: 0,
            borderTopRightRadius: 0,
            borderRadius: 0,
            width: '100%',
            height: 'auto',
            marginBlock: 0,
            marginInline: 0,
          },
        } as ItemType<MenuItemType>
      })}
    />
  )
}

export default SliderMenu

import R from 'app/assets/R'
import { PageTitle } from 'app/components/common/PageTitle'
import { SettingContainer as SettingDe } from 'app/containers/admin/Settings'
import { SettingContainer as SettingMo } from 'app/containers/admin/mobile/Settings'
import { useResponsive } from 'parkway-web-common'
import { useTranslation } from 'react-i18next'

const SettingPage = () => {
  const { t } = useTranslation()
  const { isTablet } = useResponsive()
  return (
    <>
      <PageTitle>{t(R.strings.settings)}</PageTitle>
      {!isTablet ? <SettingMo /> : <SettingDe />}
    </>
  )
}
export default SettingPage

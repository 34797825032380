/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  requestGetMostOrdered,
  requestGetReportOrder,
  requestGetReportTotal,
  requestGetReportTypeOrder,
} from 'app/api/dashboard'
import {
  IDataMostOrdered,
  IDataReportOrder,
  IDataReportTotal,
  IDataReportTypeOrder,
  IParamsListGetReportOrder,
  ResponseMostOrderedType,
  ResponseReportOrderType,
  ResponseReportTotalType,
  ResponseReportTypeOrderType,
} from 'app/api/dashboard/type'
import { IResponseQueryList } from 'app/api/product-management/model'
import { LOGOUT_PATH } from 'app/components/router/route-path'
import { useInfiniteQuery } from 'react-query'
import { useNavigate } from 'react-router'
import { DashboardKeys } from '../query-key/dashboard'

export function useGetReportTotal(enabled = true) {
  const navigate = useNavigate()
  return useInfiniteQuery<IDataReportTotal, undefined>(
    DashboardKeys.reportTotal(),
    async () => {
      try {
        const response: ResponseReportTotalType = await requestGetReportTotal()

        return response?.data ?? {}
      } catch (error: any) {
        if (error?.message === '401') {
          navigate(LOGOUT_PATH, { replace: true })
          return {}
        }
        return {}
      }
    },
    {
      enabled,
      cacheTime: 5000,
    },
  )
}

export function useGetReportTypeOrder(enabled = true) {
  const navigate = useNavigate()
  return useInfiniteQuery<IDataReportTypeOrder, undefined>(
    DashboardKeys.reportTypeOrderAll(),
    async () => {
      try {
        const response: ResponseReportTypeOrderType =
          await requestGetReportTypeOrder()

        return response?.data ?? {}
      } catch (error: any) {
        if (error?.message === '401') {
          navigate(LOGOUT_PATH, { replace: true })
          return {}
        }
        return {}
      }
    },
    {
      enabled,
      cacheTime: 5000,
    },
  )
}

export function useGetMostOrdered(enabled = true) {
  const navigate = useNavigate()
  return useInfiniteQuery<IDataMostOrdered[], undefined>(
    DashboardKeys.mostOrdered(),
    async () => {
      try {
        const response: ResponseMostOrderedType = await requestGetMostOrdered()

        return response?.data ?? []
      } catch (error: any) {
        if (error?.message === '401') {
          navigate(LOGOUT_PATH, { replace: true })
          return []
        }
        return []
      }
    },
    {
      enabled,
      cacheTime: 5000,
    },
  )
}

export function useGetReportOrder(
  params: IParamsListGetReportOrder,
  enabled = true,
) {
  const navigate = useNavigate()
  return useInfiniteQuery<IResponseQueryList<IDataReportOrder[]>, undefined>(
    DashboardKeys.reportOrderList(params),
    async () => {
      try {
        const response: ResponseReportOrderType = await requestGetReportOrder(
          params,
        )

        const total = response?.count ?? 0

        return {
          data: response?.results ?? [],
          total,
        }
      } catch (error: any) {
        if (error?.message === '401') {
          navigate(LOGOUT_PATH, { replace: true })
          return {
            data: [],
            total: 0,
          }
        }
        return {
          data: [],
          total: 0,
        }
      }
    },
    {
      enabled: !!params && enabled,
      cacheTime: 5000,
    },
  )
}

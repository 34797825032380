import type { UploadFile, UploadProps } from 'antd'
import R from 'app/assets/R'
import { BaseImage } from 'app/components/common/BaseImage'
import BaseText from 'app/components/common/BaseText'
import { Loading } from 'app/components/common/Loading'
import { SpaceVertical, Text } from 'app/components/styles'
import { getImageBase64URL } from 'app/service/general/service'
import { memo, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CSSProperties } from 'styled-components'
import * as S from './styles'

const UploadButton = ({ textUpload }: { textUpload?: string }) => {
  const { t } = useTranslation()

  return (
    <S.UploadButton>
      <BaseText colorText="backgroundColor">
        {textUpload ?? t(R.strings.upload)}
      </BaseText>
    </S.UploadButton>
  )
}

// helper
const generateUploadFileObj = (data: Partial<UploadFile>): UploadFile => {
  const result: UploadFile = {
    uid: data.uid || '',
    name: data.name || '',
    status: data.status || 'done',
    thumbUrl: data.thumbUrl || '',
  }

  return result
}

interface UploadImageLayoutProps {
  width?: string
  height?: string
  src?: string
  isLoading?: boolean
  circleLayout?: boolean
  showBottomLabel?: boolean
  bottomLabelText?: boolean
  onChange?: (imageFile: File) => void
  styleUpload?: CSSProperties
  textUpload?: string
}

const UploadImageLayout = (props: UploadImageLayoutProps) => {
  const firstRender = useRef(true)
  const { t } = useTranslation()
  const [inPreview, setInPreview] = useState(false)
  const [fileList, setFileList] = useState<UploadFile[]>(
    props.src ? [generateUploadFileObj({ thumbUrl: props.src })] : [],
  )

  useEffect(() => {
    if (props.src && !firstRender.current) {
      const newFile = generateUploadFileObj({ thumbUrl: props.src })

      setTimeout(() => setFileList([newFile]))
    } else {
      firstRender.current = false
    }
  }, [props.src])

  const handleChange: UploadProps['onChange'] = async info => {
    if (info.fileList.length > 0) {
      const base64 =
        (await getImageBase64URL(info.fileList[0].originFileObj)) || ''
      const newFile = generateUploadFileObj({ thumbUrl: base64 })

      setFileList([newFile])

      const emitOnChangeValues = info.fileList[0].originFileObj as File
      props.onChange && props.onChange(emitOnChangeValues)
      return
    }

    setFileList([])
  }

  return (
    <S.RootWrapper>
      <S.UploadWrapper>
        {props.isLoading && <Loading />}
        <S.CustomUpload
          listType={props.circleLayout ? 'picture-circle' : 'picture-card'}
          fileList={fileList}
          onChange={handleChange}
          beforeUpload={() => false}
          onPreview={() => setInPreview(true)}
          accept=".png"
          maxCount={1}
          $width={props.width}
          $height={props.height}
          style={props?.styleUpload}
        >
          {fileList.length === 0 && (
            <UploadButton textUpload={props?.textUpload} />
          )}
        </S.CustomUpload>
      </S.UploadWrapper>
      <BaseImage
        src={fileList?.[0]?.thumbUrl ?? ''}
        preview={{
          visible: inPreview,
          onVisibleChange: visible => setInPreview(visible),
        }}
        style={{ display: 'none' }}
      />

      {props.showBottomLabel && (
        <>
          <SpaceVertical $gap={12} />
          <Text $fontSize="10px" $fontWeight="400" $center>
            {props.bottomLabelText ?? t(R.strings.upload_your_image)}
          </Text>
        </>
      )}
    </S.RootWrapper>
  )
}

export default memo(UploadImageLayout)
